import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
// import ISPBilling from './isp-billing/ISPBilling'
import ISPBillingUnitCalculation from '../../../../../pages/billing/professionalclaim/billing-conversion/isp-billing-unit-calculation-rule/ISPBillingUnitCalculation'
import CaseNotBilling from '../../../../../pages/billing/professionalclaim/billing-conversion/case-not-billing/CaseNotBilling'

const BillingConversionIndexRoutes: React.FC = () => {
  return (
    <Switch>
      {/* <Route path="/professionalclaim/billing-conversion/isp-billing">
          <ISPBilling />
        </Route> */}
      <Route path='/professionalclaim/billing-conversion/isp-billing-unit-calculation-rule'>
        <ISPBillingUnitCalculation />
      </Route>
      <Route path='/professionalclaim/billing-conversion/case-not-billing'>
        <CaseNotBilling />
      </Route>
      <Redirect
        from='/professionalclaim/billing-conversion'
        exact={true}
        to='/care/isp-program-template-liabrary/new'
      />
      <Redirect to='/professionalclaim/billing-conversion/' />
    </Switch>
  )
}

export default BillingConversionIndexRoutes
