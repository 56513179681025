
import React, { useEffect, useState } from 'react'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { useDispatch, useSelector } from 'react-redux';
import { getServiceProvider } from '../store/action';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import SearchTable from './SearchTable';
const selectAuth = (state: { auth: any }) => state.auth;

const Search = () => {
    const [clientNames, setClientNames] = useState([]);
    const [selectedClient, setSelectedClient] = useState('')
    const [showEditingForm, setShowEditingForm] = useState(true)
    const [formValues, setFormValues] = useState({
        ownerOrgID: '',
        serviceProviderName: '',
        serviceProviderCode: '',
        status: 'Approved',
        client: '',
    })
    const [searchValues, setSearchValues] = useState({
        ownerOrgID: '',
        serviceProviderName: '',
        serviceProviderCode: '',
        status: 'Approved',
        client: '',
    })
    const auth = useSelector(selectAuth);
    const dispatch = useDispatch()


    useEffect(() => {
        const fetchData = async () => {
            try {
                const result: any = await dispatch(getServiceProvider())
                const tempSP: any = result?.payload?.data

                const options = tempSP.map((data: { _id: string; firstName: string; lastName: string }) => (
                    { label: data.firstName + ' ' + data.lastName, value: data._id }
                ));
                options.unshift({ label: "- Select Client -", value: "" })
                setClientNames(options);
            } catch (error) {
                console.log(error)
            }
        }
        fetchData()
    }, [])
    console.log(clientNames)

    const handleFormChange = (e: { target: { name: string; value: string | number } }) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }))
    }

    const handleClientChange = (selectedOption: any) => {
        setSelectedClient(selectedOption.value)
    }

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={formValues}
                onSubmit={(values) => {
                    values.client = selectedClient
                    // setSelectedClient('')
                    setSearchValues(values)
                    setShowEditingForm(false)
                    console.log(values)
                }}
            >
                {({ values, setFieldValue }) => (
                    <div>
                        {
                            showEditingForm === false ? (
                                <SearchTable searchValues={searchValues} setShowEditingForm={setShowEditingForm} showEditingForm={showEditingForm} />
                            ) : (
                                <Form>
                                    <div className='fv-row mb-7 fv-plugins-icon-container p-5 card'>
                                        <div className='card-body'>
                                            <h1 className='mb-4'>Service Provider (Search)</h1>
                                            <div>
                                                <div className='row'>
                                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                                        <label className=' fw-bold fs-6 mb-2'>Owner Organization ID</label>
                                                        <Field
                                                            name='ownerOrgID'
                                                            type='Number'
                                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                                            placeholder='Owner Organization ID'
                                                            autoComplete="off"
                                                            onChange={(e: { target: any }) => {
                                                                setFieldValue("ownerOrgID", e.target.value);
                                                                handleFormChange(e);
                                                            }}
                                                        ></Field>
                                                    </div>
                                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                                        <label className=' fw-bold fs-6 mb-2'>Service Provider Code</label>
                                                        <Field
                                                            name='serviceProviderCode'
                                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                                            placeholder='Service Provider Code'
                                                            autoComplete="off"
                                                            onChange={(e: { target: any }) => {
                                                                setFieldValue("serviceProviderCode", e.target.value);
                                                                handleFormChange(e);
                                                            }}
                                                        ></Field>
                                                        <ErrorMessage name="serviceProviderCode" component="div" className='text-danger' />
                                                    </div>
                                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                                        <label className=' fw-bold fs-6 mb-2'>Service Provider Name</label>
                                                        <Field
                                                            name='serviceProviderName'
                                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                                            placeholder='Service Provider Name'
                                                            autoComplete="off"
                                                            onChange={(e: { target: any }) => {
                                                                setFieldValue("serviceProviderName", e.target.value);
                                                                handleFormChange(e);
                                                            }}
                                                        ></Field>
                                                        <ErrorMessage name="serviceProviderName" component="div" className='text-danger' />
                                                    </div>
                                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                                        <div className="d-flex">
                                                            <label className=' fw-bold fs-6 mb-2'>Status</label>
                                                        </div>
                                                        <Field
                                                            className='form-select form-select-lg mb-3'
                                                            as='select'
                                                            name='status'
                                                            onChange={(e: { target: any }) => {
                                                                setFieldValue("status", e.target.value);
                                                                handleFormChange(e);
                                                            }}
                                                        >
                                                            <option hidden>- Select Status -</option>
                                                            <option value='Approved'>Approved</option>
                                                            <option value='Discontinue'>Discontinued</option>
                                                        </Field>
                                                    </div>
                                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3 mt-0'>
                                                        <label className=' fw-bold fs-6 mb-2'>Client</label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            options={clientNames}
                                                            onChange={handleClientChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='d-flex flex-stack float-end'>
                                                    <div>
                                                        <Link to='/admin/service-directory'>
                                                            <button className='btn btn-primary mt-4 mx-2'>Cancel</button>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <button type='submit' className='btn btn-primary mt-4 me-4'>Search</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }

                    </div>
                )}
            </Formik>
        </div>
    )
}

export default Search