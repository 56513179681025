import {useEffect, useState, SetStateAction} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { RootState } from '../../../../../setup'
import {useHistory} from 'react-router-dom'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../reusable-components/tables/TableSearchBar'
import DynamicTable from '../../../../reusable-components/tables/DynamicTable'
import TableWrapper from '../../../../reusable-components/tables/TableWrapper'
import { getProgram } from './store/actions'
import { hasPermission } from '../../../../../_helper/hasPermission'
import { permissions } from '../../../../../_constants/permissions'
import { QueryData } from '../../../../reusable-components/tables/DynamicTableTypes'
import { LIST_PROGRAMS_COLUMNS, LIST_PROGRAMS_COLUMNS_DATA_MAPPING, LIST_PROGRAMS_SORTING_COLUMNS } from '../../care/care_table_constants'

const selectAuth = (state: RootState) => state.auth

const ListPrograms = ({
  location,
}: {
  location: {
    state: SetStateAction<{
      image: string
      title: string
      gender: string
      firstName: string
      lastName: string
      birthDate: string
      socialSecurityNumber: string
      medicaidNumber: string
      status: string
      email: string
      phoneNumber: string
      race: string
      ethnicity: string
      enteredDate: string
      enteredBy: string
      admittedBy: string
      lastUpdatedBy: string
      admissionDate: string
      addrInCareOf: string
      residentialAddr: boolean
      mailingAddr: boolean
      addrInfo: {
        st1: string
        st2: string
        city: string
        state: string
        zipCode: string
        country: string
        primaryPhone: string
        secondaryPhone: string
        additionalPhone: string
      }
      mailingInfo: {
        st1: string
        st2: string
        city: string
        state: string
        country: string
        zipCode: string
        primaryPhone: string
        secondaryPhone: string
        additionalPhone: string
      }
      insuranceInfo: {
        medicareNumber: string
        medicareDate: string
        medPlanDId: string
        medPlanDName: string
        medPlanDIssuer: string
        medPlanDRxBin: string
        medPlanDRxPcn: string
        medPlanDRxGrp: string
        otherBenefits: string
        insuranceCompany: string
        insuranceGroup: string
        insurancePolicyNumber: string
        insurancePolicyHolder: string
        files: string
      }
    }>
  }
}) => {
  const history = useHistory()
  const [programListing, setProgramListing] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [showIndividuals, setShowIndividuals] = useState(false)
  const [editProgramData, setEditProgramData] = useState({
    programName: '',
    siteName: '',
    siteAddress: '',
    programType: '',
    programCostCenterNumber: '',
    createDate: '',
    _id: '',
  })
  const [queryData, setQueryData] = useState<QueryData>({
    pageNumber: 1,
    pageSize: 5,
    currentPage: 1,
    totalCounts: 1,
    sortBy: 'createdAt',
    sortDir: 'asc',
    searchTerm: '',
  })

  const auth = useSelector(selectAuth)
  const dispatch = useDispatch()
  const userPermissions = auth.user.roleId

  useEffect(() => {
    const fetchClients = async (
      page: number,
      pageSize: number,
      sortBy: string,
      sortDir: string
    ) => {
      try {
        const result: any = await dispatch(
          getProgram({
            page: page,
            pageSize: pageSize,
            sortBy: sortBy,
            sortDir: sortDir,
            searchTerm: queryData.searchTerm,
          })
        )
        const {data, currentPage, totalCounts} = result.payload.data
        setProgramListing(data)
        setFilteredData(data)
        setQueryData((prevState: any) => {
          return {
            ...prevState,
            currentPage,
            totalCounts,
          }
        })
      } catch (err) {
        console.error('Error fetching contants:', err)
      }
    }

    fetchClients(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
  }, [
    queryData.pageNumber,
    queryData.pageSize,
    queryData.sortBy,
    queryData.sortDir,
    queryData.searchTerm,
  ])

  // Function to handle deletion of a row by its ID
  const onClickByIndividualButton = () => {
    history.push('/admin/general/enrollment-by-program')
  }
  const onView = (row: {_id: any}) => {
    history.push({
      pathname: `/admin/general/enrollment-by-program/${row._id}`,
    })
  }
  return (
    <div className='card'>
      <div className='card-body'>
        {!showIndividuals ? (
          <>
            <TableTitle title='Program Search'></TableTitle>
            <TableWrapper
              searchField={
                <TableSearchBar
                  label='Search'
                  variant='outlined'
                  size='small'
                  value={searchTerm}
                  setQueryData={setQueryData}
                  setSearchTerm={setSearchTerm}
                  margin='normal'
                  inputAdornmentPosition='start'
                ></TableSearchBar>
              }
              // tableButtons={
              //     <TableButton title='By Client' onClick={onClickByIndividualButton}></TableButton>
              // }
            />
          </>
        ) : (
          <></>
        )}
        <DynamicTable
          columns={LIST_PROGRAMS_COLUMNS}
          data={filteredData}
          canView={hasPermission(
            userPermissions,
            permissions.admin_general_enrollement_by_program_update
          )}
          canDelete={false}
          columnDataMapping={LIST_PROGRAMS_COLUMNS_DATA_MAPPING}
          sortingColumns={LIST_PROGRAMS_SORTING_COLUMNS}
          clickableRow={false}
          onView={onView}
          title='Are you sure you want to delete this Program?'
          setQueryData={setQueryData}
          queryData={queryData}
        />
      </div>
    </div>
  )
}

export default ListPrograms
