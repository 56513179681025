import { createSlice } from "@reduxjs/toolkit";
import { getFundingSource } from "../actions";

interface FundingSource {
    name: string;
    fundingProvider: Number;
    vendorID: Number;
    address: {
        street1: string;
        street2: string;
        city: string;
        state: string
        zipCode: Number;
        country: string;
    },
    title: string;
    firstName: string;
    lastName: string;
    phoneNumber: Number;
    ext: Number;
    faxNumber: Number;
    payer: string;
    payerType: string;
    email: string;
}

interface FundingSourceState {
    fundingSource: FundingSource[] | any;
    isLoading: boolean;
}

const initialState: FundingSourceState = {
    fundingSource: [],
    isLoading: false,
};


export const fundingSourceInitialSlice = createSlice({
    name: 'fundingSource',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getFundingSource.fulfilled, (state, action) => {
                state.fundingSource = action.payload;
                state.isLoading = false;
            })
            .addCase(getFundingSource.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getFundingSource.rejected, (state, action) => {
                state.isLoading = false
            })
    },
})

export default fundingSourceInitialSlice.reducer