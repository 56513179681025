import React from 'react'
import { Route, Switch, BrowserRouter as Router, BrowserRouter } from 'react-router-dom'
import New from '../../../../../pages/admin/billing/billing-provider/new/New'
import ListProvider from '../../../../../pages/admin/billing/billing-provider/list/ListProvider'
import Edit from '../../../../../pages/admin/billing/billing-provider/list/Edit'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { Error404 } from '../../../../../modules/errors/components/Error404'
import { RouteWithPermission } from '../../../../../../_helper/routesWithPermission'
import { permissions } from '../../../../../../_constants/permissions'
import { RootState } from '../../../../../../setup'

const BillingProviderIndexRoutes: React.FC = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const userPermissions = auth.user.roleId;
    const canUpdate = hasPermission(userPermissions, permissions.admin_billing_billingProvider_update)
    const canCreate = hasPermission(userPermissions, permissions.admin_billing_billingProvider_create)
    const canRead = hasPermission(userPermissions, permissions.admin_billing_billingProvider_view)
    return (
        <Switch>
            <RouteWithPermission path='/admin/billing/billing-provider/new' component={New} hasPerm={canRead && canCreate} />
            <RouteWithPermission path='/admin/billing/billing-provider/list/:id' component={Edit} hasPerm={canRead && canUpdate} />
            <RouteWithPermission path='/admin/billing/billing-provider/list' component={ListProvider} hasPerm={canRead} />
            <Route path='/error/404' component={Error404} />
        </Switch>
    )
}

export default BillingProviderIndexRoutes
