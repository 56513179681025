import {TextField} from '@mui/material'
import React from 'react'

const SocialSecurityNumberField = ({field, handleInputChange, formData}) => {
  return (
    <TextField
      type='text'
      size='small'
      name={field.name}
      className='textField'
      value={field.value ? field.value : formData[field.name] || ''}
      onChange={(e) => {
        const {value} = e.target
        let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 9)

        if (formattedValue.length > 3) {
          formattedValue = formattedValue.slice(0, 3) + '-' + formattedValue.slice(3)
        }
        if (formattedValue.length > 6) {
          formattedValue = formattedValue.slice(0, 6) + '-' + formattedValue.slice(6)
        }

        handleInputChange(e.target.name, formattedValue, '', e.target.type)
      }}
      variant='outlined'
      placeholder='xxxxxxxxx'
      required
      label='Social Security Number'
      autoComplete='off'
      error={Boolean(
        field.validation &&
          formData[field.name] &&
          !field.validation.pattern.test(formData[field.name])
      )}
      helperText={
        field.validation &&
        formData[field.name] &&
        !field.validation.pattern.test(formData[field.name])
          ? field.validation.message
          : ''
      }
    />
  )
}

export default SocialSecurityNumberField
