import CareGiverProfileTabs from '../ProfileHeader'
import JobDetails from './JobDetails'

export const CaregiverProfileJobDetails = () => {
  return (
    <>
      <CareGiverProfileTabs></CareGiverProfileTabs>
      <JobDetails></JobDetails>
    </>
  )
}
