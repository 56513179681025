import React, {useEffect, useState} from 'react'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import {
  CAREGIVER_PROFILE_JOB_DETAILS_FORM_INPUTS,
  CAREGIVER_PROFILE_PERSONAL_INFORMATION_FORM_INPUTS,
} from '../../../CAREGIVER_FORM_CONSTANTS'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import axios from 'axios'
import {toast} from 'react-toastify'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { permissions } from '../../../../../../_constants/permissions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../setup'
const selectAuth = (state: RootState) => state.auth

export default function PersonalInformation(props) {
  const [formData, setFormData] = useState({})
  const [uiElementsList, setUiElementsList] = useState([])
  const auth = useSelector(selectAuth)
  const userPermissions = auth.user.roleId;

  useEffect(() => {
    const tempElementsList = CAREGIVER_PROFILE_PERSONAL_INFORMATION_FORM_INPUTS()
    setUiElementsList(tempElementsList)
  }, [])
  useEffect(() => {
    axios.get(`caregivers/getById/${props.id}`).then((res) => {
      setFormData((prevState) => ({
        ...prevState,
        firstName: res.data?.careGiver?.firstName,
        lastName: res.data?.careGiver?.lastName,
        phone: res.data?.careGiver?.phone,
        email: res.data?.careGiver?.email,
        emergencyContactName: res.data?.careGiver?.emergencyContact?.name
          ? res.data?.careGiver?.emergencyContact?.name
          : '',
        emergencyRelationship: res.data?.careGiver?.emergencyContact?.relationship
          ? res.data?.careGiver?.emergencyContact?.relationship
          : '',
        emergencyPhone: res.data?.careGiver?.emergencyContact?.phoneNum
          ? res.data?.careGiver?.emergencyContact?.phoneNum
          : '',
        legalFirstName: res.data?.careGiver?.payrollInfo?.legalFirstName
          ? res.data?.careGiver?.payrollInfo?.legalFirstName
          : '',
        legalMiddleName: res.data?.careGiver?.payrollInfo?.legalMiddleName
          ? res.data?.careGiver?.payrollInfo?.legalMiddleName
          : '',
        legalLastName: res.data?.careGiver?.payrollInfo?.legalLastName
          ? res.data?.careGiver?.payrollInfo?.legalLastName
          : '',
        legalDateOfBirth: res.data?.careGiver?.payrollInfo?.dateOfBirth
          ? res.data?.careGiver?.payrollInfo?.dateOfBirth
          : '',
        legalSocialSecurityNumber: res.data?.careGiver?.payrollInfo?.socialSecurityNumber
          ? res.data?.careGiver?.payrollInfo?.socialSecurityNumber
          : '',
        addrInfo: {
            primaryPhone: res.data?.careGiver?.addressInfo?.primaryPhone
            ? res.data?.careGiver?.addressInfo?.primaryPhone
            : '',
          country: res.data?.careGiver?.addressInfo?.country
            ? res.data?.careGiver?.addressInfo?.country
            : '',
          st1: res.data?.careGiver?.addressInfo?.st1 ? res.data?.careGiver?.addressInfo?.st1 : '',
          st2: res.data?.careGiver?.addressInfo?.st2 ? res.data?.careGiver?.addressInfo?.st2 : '',
          city: res.data?.careGiver?.addressInfo?.city ? res.data?.careGiver?.addressInfo?.city : '',
          state: res.data?.careGiver?.addressInfo?.state
            ? res.data?.careGiver?.addressInfo?.state
            : '',
          zipCode: res.data?.careGiver?.addressInfo?.zipCode
            ? res.data?.careGiver?.addressInfo?.zipCode
            : '',
          }
      }))
    })
  }, [])
  const handleSubmitForm = (formData) => {
    const validatePhoneNumber = (phone) => {
      return phone && phone.toString().length === 10;
    };
    if (!validatePhoneNumber(formData['phone'])) {
      toast.error('Phone number must be exactly 10 digits');
      return;
    }
  
    if (!validatePhoneNumber(formData['emergencyPhone'])) {
      toast.error('Emergency phone number must be exactly 10 digits');
      return;
    }
  
    if (!validatePhoneNumber(formData['addrInfo']['primaryPhone'])) {
      toast.error('Primary address phone number must be exactly 10 digits');
      return;
    }
    let emergencyContact = {
      name: formData['emergencyContactName'],
      relationship: formData['emergencyRelationship'],
      phoneNum: formData['emergencyPhone'],
    }

    let payrollInfo = {
      legalFirstName: formData['legalFirstName'],
      legalMiddleName: formData['legalMiddleName'],
      legalLastName: formData['legalLastName'],
      dateOfBirth: formData['legalDateOfBirth'],
      socialSecurityNumber: formData['legalSocialSecurityNumber'],
    }
    let addressInfo = {
      country: formData['addrInfo']['country'],
      st1: formData['addrInfo']['st1'],
      st2: formData['addrInfo']['st2'],
      city: formData['addrInfo']['city'],
      state: formData['addrInfo']['state'],
      zipCode: formData['addrInfo']['zipCode'],
      primaryPhone: formData['addrInfo']['primaryPhone'],
    }
    let body = {
      firstName: formData['firstName'],
      lastName: formData['lastName'],
      phone: formData['phone'],
      email: formData['email'],
      emergencyContact: emergencyContact,
      payrollInfo: payrollInfo,
      addressInfo: addressInfo,
    }

    axios
      .patch('caregivers/updateProfile/' + props.id, body)
      .then((res) => {
        if (res.status == 201) {
          toast.success('Caregiver updated successfully')
        }
      })
      .catch((e) => toast.error("Couldn't update caregiver"))
  }
  const buttons = [
    {label: 'Cancel', variant: 'outlined', type: 'button'},
    {label: 'Save', variant: 'contained', type: 'submit', disabled: !hasPermission(userPermissions, permissions.care_giver_profile_personalInformation_update)},
  ]

  return (
    <>
      <FormWrapper
        title='Personal Information'
        content={
          <DynamicForm
            fields={uiElementsList}
            data={formData}
            onSubmit={handleSubmitForm}
            buttons={buttons}
          />
        }
      ></FormWrapper>
    </>
  )
}
