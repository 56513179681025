import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ProgramsList from '../../../../../pages/billing/professionalclaim/service-authorization/new/ProgramsList'
import ServiceAuthorizationSearch from '../../../../../pages/billing/professionalclaim/service-authorization/search/ServiceAuthorizationSearch'
import List from '../../../../../pages/billing/professionalclaim/service-authorization/list/List'
import Edit from '../../../../../pages/billing/professionalclaim/service-authorization/list/Edit'
import Clients from '../../../../../pages/billing/professionalclaim/service-authorization/new/Clients'
import EditClientAuthorization from '../../../../../pages/billing/professionalclaim/service-authorization/new/EditClientAuthorization'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { RouteWithPermission } from '../../../../../../_helper/routesWithPermission'
import { Error404 } from '../../../../../modules/errors/components/Error404'

const ServiceAuthorizationIndexRoutes: React.FC = () => {
    const auth = useSelector((state: { auth: any }) => state.auth);
    const userPermissions = auth.user.roleId;
    const canCreate = hasPermission(userPermissions, "BILLING.PROFESSIONAL_CLAIM.SERVICE_AUTHORIZATION.CREATE");
    const canRead = hasPermission(userPermissions, "BILLING.PROFESSIONAL_CLAIM.SERVICE_AUTHORIZATION.VIEW");
    const canSearch = hasPermission(userPermissions, "BILLING.PROFESSIONAL_CLAIM.SERVICE_AUTHORIZATION.SEARCH");
    return (
        <Switch>
            <RouteWithPermission path='/billing/professionalclaim/service-authorization/new/:programID/:clientID' component={EditClientAuthorization} hasPerm={canCreate} />
            <RouteWithPermission path='/billing/professionalclaim/service-authorization/new/:programID' component={Clients} hasPerm={canRead} />
            <RouteWithPermission path='/billing/professionalclaim/service-authorization/new' component={ProgramsList} hasPerm={canRead} />
            <RouteWithPermission path='/billing/professionalclaim/service-authorization/list/:id' component={Edit} hasPerm={canRead} />
            <RouteWithPermission path='/billing/professionalclaim/service-authorization/list' component={List} hasPerm={canRead} />
            <RouteWithPermission path='/billing/professionalclaim/service-authorization/search' component={ServiceAuthorizationSearch} hasPerm={false} />
            <Route path='/error/404' component={Error404} />
        </Switch>
    )
}

export default ServiceAuthorizationIndexRoutes
