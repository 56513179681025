import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updateContact } from '../store/action'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { CONTACTS_FORM_INPUTS } from '../../CARE_CONSTANTS'
import { toast } from 'react-toastify'
const selectAuth = (state) => state.auth;

const EditContacts = ({ selectedContact, setShowEditingForm }) => {
    const [data, setData] = useState({})
    const dispatch = useDispatch()
   
    useEffect(() => {
        setData(()=> {
            return {
                ...selectedContact,
            "guardianType": selectedContact.guardianInfo.guardianType,
            "guardianAuthority": selectedContact.guardianInfo.guardianAuthority,
            "guardianEstablishDate": selectedContact.guardianInfo.guardianEstablishDate,
            "guardianEstablishEndDate": selectedContact.guardianInfo.guardianEstablishEndDate,
            }
        })
    }, [])
    const handleSubmitForm = async (formData) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');

        const currentDateFormatted = `${year}-${month}-${day}`;
        const tempObj = {
            '_id': selectedContact?._id,
            "firstName": formData?.firstName,
            "lastName": formData?.lastName,
            "relaiontoind": formData?.relaiontoind,
            "agency": formData?.agency,
            "comments": formData?.comments,
            'isGuardian': formData?.isGuardian,
            "email": formData?.email,
            'addrInfo': {
                "st1": formData?.addrInfo?.st1,
                "st2": formData?.addrInfo?.st2,
                "city": formData?.addrInfo?.city,
                "state": formData?.addrInfo?.state,
                "zipCode": Number(formData?.addrInfo?.zipCode),
                "country": formData?.addrInfo?.country,
                "primaryPhone": Number(formData?.addrInfo?.primaryPhone),
            },
            'mailingInfo': {
                "st1": formData?.mailingInfo?.st1,
                "st2": formData?.mailingInfo?.st2,
                "city": formData?.mailingInfo?.city,
                "state": formData?.mailingInfo?.state,
                "zipCode": Number(formData?.mailingInfo?.zipCode),
                "primaryPhone": Number(formData?.mailingInfo?.primaryPhone),
                "country": formData?.mailingInfo?.country,
            },
        } 
        if (formData.guardianEstablishDate && formData.guardianEstablishEndDate) {
            if (formData.guardianEstablishDate <= currentDateFormatted && formData.guardianEstablishEndDate > formData.guardianEstablishDate) {
                tempObj.guardianInfo = {
                    "guardianType": formData?.guardianType,
                    "guardianAuthority": formData?.guardianAuthority,
                    "guardianEstablishDate": formData?.guardianEstablishDate,
                    "guardianEstablishEndDate": formData?.guardianEstablishEndDate,
                };
            } else {
                if (formData.guardianEstablishDate > currentDateFormatted) {
                    toast.error('Guardian Establish Start Date should not be later than today');
                    return;
                } else if (formData.guardianEstablishEndDate < formData.guardianEstablishDate) {
                    toast.error('Guardian Establish End Date should not be earlier than Start Date');
                    return;
                }
            }
        }
        try{
      const response = await dispatch(updateContact(tempObj))
      if(response?.payload?.status === 400 && response?.payload?.success === false) {
        toast.error(response?.payload?.message)
    } else if (response?.payload?.status === 200 && response?.payload?.success) {
        toast.success('Contact updated successfully')
        setShowEditingForm(true)
    } 
        } catch (err) {
            console.log(err)
            toast.error('Error while updating contact')
        }
    }

    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];
    return (
        <FormWrapper
            title={`Edit Contact of ${selectedContact.firstName} ${selectedContact.lastName}`}
            content={
                <DynamicForm
                    fields={CONTACTS_FORM_INPUTS}
                    data={data}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        />
    )
}

export default EditContacts
