import { useEffect, useRef, useState } from "react"
import { useDispatch } from 'react-redux'
import { getFundingSource } from "../../../../admin/billing/funding-source/store/actions"
import { getOneSA, getServiceAuth, updateServiceAuth } from "../store/actions"
import { GetDescriptionCode } from "../../../../admin/billing/description-code/store/action"
import {
    TextField, Button, Grid,
     FormControlLabel, Autocomplete,
    Typography, Checkbox, Box
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { useParams, useHistory } from "react-router-dom"
import axios from "axios"
import { getAttendanceType } from "../../../../admin/billing/attendance-type/store/action"
import RateHistoryDialog from '../../../../../reusable-components/RateHistory/RateHistoryDialog'
import * as validator from '../../../../../reusable-components/Validation/Validations';
import { AppDispatch } from "../../../../../../setup/redux/Store"

const field = {
    label: "ICD-10",
    name: "primaryDiagnosis",
    type: "asyncSelectICD",
    options: [],
}

interface Option {
    label: string;
    value: string;
  }
interface FormValues {
    _id: string;
    programID: string;
    clientID: string;
    IDType: Option;
    fundingSourceID: Option;
    authorizationNumber: string | number; // can be either a string or number
    serviceDescription?: Option; // optional field, not provided in initial data
    accountingNumber: string | number; // can be either a string or number
    beginDate: string; // typically dates would be strings, format can vary (e.g., 'MM/DD/YYYY')
    endDate: string;
    servfirstName: string;
    servlastName: string;
    organizationAgency: string;
    serviceCoordinatorNumber: string | number; // can be either string or number
    phoneNumber: string; // phone numbers can be represented as strings or numbers
    rateHistory: boolean; // boolean for rateHistory
    rateHistoryValue: any[]; // assuming this to be an array of any type, as it is not provided
    extension: string;
    comments: string;
    status: string;
    unitMeasure: string;
    unitRate: number;
    claimType: string;
    primaryDiagnosis: string;
    procedureModifier: string[]; // array of strings
    diagnosisPointer: string[]; // array of strings
    procedureQualifier: string;
    unitOfMeasure: string;
    roundingAlgorithm: string;
    allowedIncrement: string;
    serviceID: string;
    totalBillable: number;
    totalAmount: number;
    priorAuth: string | number; // can be either string or number
    dataCollectionMethod: string;
    attendanceType: Option;
    remainingUnits: number;
}

const Edit = () => {
    const [fundingSources, setFundingSources] = useState([]);
    const [descriptionCodes, setDescriptionCodes] = useState([]);
    const [desSwitch, setDesSwitch] = useState(false);
    const [selectedDescriptionCode, setSelectedDescriptionCode] = useState('');
    const [attendanceTypes, setAttendanceTypes] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [desCodes, setDesCodes] = useState({
        label: '', value: ''
    });
    const parameters : { id: string }= useParams()
    const history = useHistory()
    const [selectedDescriptionCodeObj, setSelectedDescriptionCodeObj] = useState({
        serviceDescription: '',
        serviceCode: '',
        unitMeasure: '',
        unitRate: 0,
        claimType: '',
        primaryDiagnosis: '',
        procedureModifier: ['', '', '', ''],
        diagnosisPointer: ['', '', '', ''],
        procedureQualifier: '',
        unitOfMeasure: '',
        roundingAlgorithm: '',
        allowedIncrement: '',
        _id: ''
    })
    const [formValues, setFormValues] = useState<FormValues>({
        _id: '',
        programID: '',
        clientID: '',
        IDType: {
            label: '',
            value: ''
          },
        fundingSourceID: {
            label: '',
            value: ''
          },
        authorizationNumber: '',
        serviceDescription: {
            label: '',
            value: ''
          },
        accountingNumber: '',
        beginDate: '',
        endDate: '',
        servfirstName: '',
        servlastName: '',
        organizationAgency: '',
        serviceCoordinatorNumber: '',
        phoneNumber: '',
        rateHistory: false,
        rateHistoryValue: [],
        extension: '',
        comments: '',
        // files: [],
        status: '',
        unitMeasure: '',
        unitRate: 0,
        claimType: '',
        primaryDiagnosis: '',
        procedureModifier: [],
        diagnosisPointer: [],
        procedureQualifier: '',
        unitOfMeasure: '',
        roundingAlgorithm: '',
        allowedIncrement: '',
        serviceID: '',
        totalBillable: 0,
        totalAmount: 0,
        priorAuth: '',
        dataCollectionMethod: '',
        attendanceType: {
            label: '',
            value: ''
          },
        remainingUnits: 0,
    })

    const dispatch = useDispatch<AppDispatch>()
    const inputFieldRef: any = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (parameters.id) {
                    const [servResult, descriptionResult, fundingSourceResult, serviceAuthsResult, attendanceResult] = await Promise.all([
                        dispatch(getOneSA(parameters.id)),
                        dispatch(GetDescriptionCode({})),
                        dispatch(getFundingSource({})),
                        dispatch(getServiceAuth({})),
                        dispatch(getAttendanceType({})),
                    ])
                    const tempObj = servResult.payload.data
                    let tempDes = descriptionResult.payload.data
                    const tempFun = fundingSourceResult.payload.data
                    let tempServAuths = serviceAuthsResult.payload.data;
                    const tempAtt = attendanceResult.payload.data;
                    const updatedDes = [
                        ...tempDes.map((DES) => ({ label: DES.serviceDescription, value: DES._id }))
                    ]
                    const updatedAT = [
                        ...tempAtt.map((AT) => ({ label: AT.name, value: AT._id }))
                    ]
                    const matchingDES = updatedDes.find((AT) => AT.value === tempObj.serviceID);
                    const filteredDES = await matchingDES
                        ? { label: matchingDES.label, value: matchingDES.value }
                        : null;
                    tempDes = tempDes.filter((obj: { deactivateStatus: boolean }) => obj.deactivateStatus === false)
                    tempServAuths = tempServAuths.filter((obj: { status: string }) => obj.status === "Approved")
                    let selectedServAuths = tempServAuths.filter((servAuth: { clientID: string }) => servAuth.clientID === formValues.clientID);
                    let alreadySelectedDesc = selectedServAuths.map((obj: { serviceID: string }) => obj.serviceID)
                    tempDes = tempDes.filter((obj: { _id: string }) => (
                        !alreadySelectedDesc.includes(obj._id)
                    ))
                    setDescriptionCodes(tempDes)

                    const updatedFun = [
                        ...tempFun.map((FUN) => ({ label: FUN.name, value: FUN._id }))
                    ]
                    setFundingSources(updatedFun)
                    const matchingFUN = updatedFun.find((FUN) => FUN.value === tempObj.fundingSourceID);
                    const filteredFUN = await matchingFUN
                        ? { label: matchingFUN.label, value: matchingFUN.value }
                        : null;
                    const matchingAT = updatedAT.find((AT) => AT.value === tempObj.attendanceType);
                    const filteredAT = await matchingAT
                        ? { label: matchingAT.label, value: matchingAT.value }
                        : null;

                    const tempServAuth = {
                        _id: tempObj._id,
                        programID: tempObj.programID,
                        clientID: tempObj.clientID,
                        IDType: tempObj.IDType,
                        fundingSourceID: filteredFUN,
                        authorizationNumber: tempObj.authorizationNumber,
                        accountingNumber: tempObj.accountingNumber,
                        beginDate: formatDate(tempObj.beginDate),
                        endDate: formatDate(tempObj.endDate),
                        servfirstName: tempObj.servfirstName,
                        servlastName: tempObj.servlastName,
                        organizationAgency: tempObj.organizationAgency,
                        serviceCoordinatorNumber: tempObj.serviceCoordinatorNumber,
                        phoneNumber: tempObj.phoneNumber,
                        rateHistory: tempObj.rateHistory, 
                        rateHistoryValue: tempObj?.rateHistoryValue?.map(rateHistory=> ({
                            ...rateHistory,
                            dateFrom: formatDate(rateHistory.dateFrom),
                            dateTo: formatDate(rateHistory.dateTo),
                        })),
                        extension: tempObj.extension,
                        comments: tempObj.comments,
                        status: tempObj.status,
                        unitMeasure: tempObj.unitMeasure,
                        unitRate: tempObj.unitRate,
                        claimType: tempObj.claimType,
                        primaryDiagnosis: tempObj.primaryDiagnosis,
                        procedureModifier: tempObj.procedureModifier,
                        diagnosisPointer: tempObj.diagnosisPointer,
                        procedureQualifier: tempObj.procedureQualifier,
                        unitOfMeasure: tempObj.unitOfMeasure,
                        roundingAlgorithm: tempObj.roundingAlgorithm,
                        allowedIncrement: tempObj.allowedIncrement,
                        serviceID: tempObj.serviceID,
                        totalBillable: tempObj.totalBillable,
                        totalAmount: tempObj.totalAmount,
                        priorAuth: tempObj.priorAuth,
                        dataCollectionMethod: tempObj.dataCollectionMethod,
                        attendanceType: filteredAT,
                        remainingUnits: tempObj.remainingUnits,
                    }
                    setDesCodes(filteredDES)
                    setAttendanceTypes(updatedAT)
                    setFormValues(tempServAuth)
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [parameters.id])

    useEffect(() => {
        if (selectedDescriptionCode !== '') {
            const tempdes: any = descriptionCodes.find((des: { _id: string }) =>
                des._id === selectedDescriptionCode)!
            setSelectedDescriptionCodeObj(tempdes)
            setFormValues(prevState => ({
                ...prevState,
                serviceDescription: tempdes.serviceDescription,
                serviceCode: tempdes.serviceCode,
                unitMeasure: tempdes.unitMeasure,
                unitRate: tempdes.unitRate,
                claimType: tempdes.claimType,
                primaryDiagnosis: tempdes.primaryDiagnosis,
                procedureModifier: tempdes.procedureModifier,
                diagnosisPointer: tempdes.diagnosisPointer,
                procedureQualifier: tempdes.procedureQualifier,
                unitOfMeasure: tempdes.unitOfMeasure,
                roundingAlgorithm: tempdes.roundingAlgorithm,
                allowedIncrement: tempdes.allowedIncrement,
                serviceID: tempdes._id,
                totalBillable: 10,
                totalAmount: 10 * tempdes.unitRate,
            }))
        }
    }, [])

    
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
      };
      
    const getData = async (value) => {
        try {
            let response
            if (field.name === 'primaryDiagnosis') {
                response = await axios.post(`/icd10/findICD`, {
                    value: value
                })
            }
            return response.data.data.map(item => ({
                label: item.info,
                value: item.info
            }));
        } catch (error) {
            console.error("Error fetching allergies:", error);
            return [];
        }
    };

    const loadOptions = async (inputValue) => {
        if (inputValue.length >= 3) {
            const response = await getData(inputValue)
            setFilteredOptions(response);
        } else {
            setFilteredOptions([]); // Clear options when input length is less than 3
        }
    };

    // const handleFormChange = (e: { target: { name: string; value: string | number | boolean; type: any; checked: any } }) => {
    //     const { name, value, type, checked } = e.target;
    //     if (type === 'checkbox') {
    //         setFormValues((prevValues) => ({
    //             ...prevValues,
    //             [name]: checked
    //         }));
    //     } else {
    //         setFormValues((prevValues) => ({
    //             ...prevValues,
    //             [name]: value
    //         }));
    //     }
    // };
    const handleSubmit = async (values) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');

        const currentDateFormatted = `${year}-${month}-${day}`;
        const validations = [
            { field: 'servfirstName', validation: validator.NAME_VALIDATION },
            { field: 'servlastName', validation: validator.NAME_VALIDATION },
            { field: 'phoneNumber', validation: validator.PHONE_NUMBER_VALIDATION },
            // { field: 'extension', validation: validator.PHONE_NUMBER_VALIDATION },
        ]

        values.attendanceType = formValues.attendanceType?.value
        values.fundingSourceID = values.fundingSourceID?.value
        values.priorAuth = Number(formValues.priorAuth)
        values.phoneNumber = Number(formValues.phoneNumber)
        // values.extension = Number(formValues.extension)
        values.authorizationNumber = Number(formValues.authorizationNumber)
        values.accountingNumber = Number(formValues.accountingNumber)
        values.serviceCoordinatorNumber = Number(formValues.serviceCoordinatorNumber)
        
        for (const { field, validation } of validations) {
            if (values[field] !== undefined && values[field] !== null && values[field] !== '' && values[field] !== 0) {
                if (!validation.pattern.test(values[field])) {
                    toast.error(`Validation Failed For ${field}`);
                    return;
                }
            }
        }
        try {
            if (values.beginDate <= currentDateFormatted && values.endDate >= values.beginDate) {
                const response = await dispatch(updateServiceAuth(values));
            if (response.payload.status == 200 && response.payload && response.payload.success === true) {
                toast.success('Service Authorization Updated Successfully')
                history.push('/billing/professionalclaim/service-authorization/list')
            } else {
                toast.error('Error Updating Service Authorization')
            }
            } else {
                if (values.beginDate > currentDateFormatted) {
                    toast.error('Begin Date should not be later than today');
                } else if (values.endDate < values.beginDate) {
                    toast.error('End Date should not be earlier than Begin Date');
                }
            }
        } catch (err) {
            console.log(err)
        }
    }
    return (
        <>
            <div className="card">
                <form className="card-body" onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(formValues)
                }} >
                    <h3 className='text-primary'>Service Authorization (Edit)</h3>
                    <div className="row">
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2'>
                            <Autocomplete
                                id='IDType'
                                options={[{label:'Medicaid Number', value:'Medicaid Number'}, { label: 'Medicare Number', value:'Medicare Number' }]}
                                size='small'
                                value={formValues.IDType}
                                isOptionEqualToValue={(option, value) => option.value == value.value}
                                onChange={(e, newValue) => {
                                    setFormValues({ ...formValues, IDType: newValue })
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="ID Type"
                                        variant="outlined"
                                        required
                                    />
                                )}
                            />
                            {/* Display error message for IDType here */}
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2'>
                            <Autocomplete
                                id='fundingSourceID'
                                size='small'
                                options={fundingSources}
                                value={formValues.fundingSourceID}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                forcePopupIcon={true}
                                // getOptionLabel={(option) => option.label}
                                onChange={(e, newValue) => {
                                    setFormValues({ ...formValues, fundingSourceID: newValue })
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Funding Source ID"
                                        name="fundingSourceID"
                                        variant="outlined"
                                        required
                                    />
                                )}
                            />
                            {/* Display error message for fundingSourceID here */}
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2'>
                            <TextField
                                type='number'
                                name='authorizationNumber'
                                size='small'
                                value={formValues.authorizationNumber}
                                fullWidth
                                label='Authorization Number'
                                autoComplete='off'
                                onChange={(e) => {
                                    setFormValues((prevState) => {
                                        return {
                                            ...prevState,
                                            authorizationNumber: e.target.value
                                        }
                                    })
                                }}
                            />
                        </div>

                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2'>
                            <TextField
                                type='number'
                                name='accountingNumber'
                                size='small'
                                value={formValues.accountingNumber}
                                fullWidth
                                label='Accounting Number'
                                autoComplete='off'
                                onChange={(e) => {
                                    setFormValues((prevState) => {
                                        return {
                                            ...prevState,
                                            accountingNumber: e.target.value
                                        }
                                    })
                                }}
                            />
                        </div>

                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    name='beginDate'
                                    slotProps={{ textField: { label: 'Begin Date', size: 'small', required: true, style: { width: '100%' } } }}
                                    value={dayjs(formValues.beginDate)}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            const newDate = newValue?.format('MM/DD/YYYY');
                                            setFormValues((prevState) => {
                                                return {
                                                    ...prevState,
                                                    beginDate: newDate
                                                }
                                            })
                                          }
                                    }}
                                />
                            </LocalizationProvider>
                        </div>

                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    slotProps={{ textField: { label: 'Emd Date', size: 'small', required: true, style: { width: '100%' } } }}
                                    name='endDate'
                                    value={dayjs(formValues.endDate)}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            const newDate = newValue.format('YYYY-MM-DD');
                                            setFormValues((prevState) => ({
                                            ...prevState,
                                            endDate: newDate,
                                            }));
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <h3 className='my-3 text-primary'>Service Coordinator</h3>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2'>
                            <TextField
                                type='text'
                                name='servfirstName'
                                size='small'
                                fullWidth
                                value={formValues.servfirstName}
                                label='First Name'
                                autoComplete='off'
                                onChange={(e) => {
                                    setFormValues((prevState) => {
                                        return {
                                            ...prevState,
                                            servfirstName: e.target.value
                                        }
                                    })
                                }}
                                error={Boolean(formValues.servfirstName && !validator.NAME_VALIDATION.pattern.test(formValues.servfirstName))}
                                helperText={formValues.servfirstName && !validator.NAME_VALIDATION.pattern.test(formValues.servfirstName) ? validator.NAME_VALIDATION.message : ''}
                            />
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2'>
                            <TextField
                                type='text'
                                name='servlastName'
                                size='small'
                                value={formValues.servlastName}
                                fullWidth
                                label='Last Name'
                                autoComplete='off'
                                onChange={(e) => {
                                    setFormValues((prevState) => {
                                        return {
                                            ...prevState,
                                            servlastName: e.target.value
                                        }
                                    })
                                }}
                                error={Boolean(formValues.servlastName && !validator.NAME_VALIDATION.pattern.test(formValues.servlastName))}
                                helperText={formValues.servlastName && !validator.NAME_VALIDATION.pattern.test(formValues.servlastName) ? validator.NAME_VALIDATION.message : ''}
                            />
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2'>
                            <TextField
                                type='text'
                                name='organizationAgency'
                                size='small'
                                value={formValues.organizationAgency}
                                fullWidth
                                label='Organization/Agency'
                                autoComplete='off'
                                onChange={(e) => {
                                    setFormValues((prevState) => {
                                        return {
                                            ...prevState,
                                            organizationAgency: e.target.value
                                        }
                                    })
                                }}
                            />
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2'>
                            <TextField
                                type='number'
                                name='serviceCoordinatorNumber'
                                size='small'
                                value={formValues.serviceCoordinatorNumber}
                                fullWidth
                                label='Service Coordinator Number'
                                autoComplete='off'
                                onChange={(e) => {
                                    setFormValues((prevState) => {
                                        return {
                                            ...prevState,
                                            serviceCoordinatorNumber: e.target.value
                                        }
                                    })
                                }}
                            />
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2'>
                            <TextField
                                type='number'
                                name='phoneNumber'
                                size='small'
                                value={formValues.phoneNumber}
                                fullWidth
                                label='Phone Number'
                                autoComplete='off'
                                onChange={(e) => {
                                    setFormValues((prevState) => {
                                        return {
                                            ...prevState,
                                            phoneNumber: e.target.value
                                        }
                                    })
                                }}
                                error={Boolean(formValues.phoneNumber && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formValues.phoneNumber))}
                                helperText={formValues.phoneNumber && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formValues.phoneNumber) ? validator.PHONE_NUMBER_VALIDATION.message : ''}
                            />
                        </div>
                        {/* <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2'>
                            <div>
                                <TextField
                                    type='number'
                                    name='extension'
                                    size='small'
                                    value={formValues.extension}
                                    fullWidth
                                    label='Extension'
                                    autoComplete='off'
                                    onChange={(e) => {
                                        setFormValues((prevState) => {
                                            return {
                                                ...prevState,
                                                extension: e.target.value
                                            }
                                        })
                                    }}
                                    error={Boolean(formValues.extension && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formValues.extension))}
                                    helperText={formValues.extension && !validator.PHONE_NUMBER_VALIDATION.pattern.test(formValues.extension) ? validator.PHONE_NUMBER_VALIDATION.message : ''}
                                />
                            </div>
                        </div> */}
                        <div>
                            <div>
                                <div className='row mt-2'>
                                    <Grid item style={{ backgroundColor: "BlanchedAlmond", marginLeft: '7px', maxWidth: 'fit-content' }}>
                                        <Typography className='mt-2 mb-2'>
                                            Service Dates that do not fall under any of the following date ranges will use the default Unit Rate.
                                        </Typography>
                                    </Grid>
                                    <div className='mt-2 mb-2'>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name='rateHistory'
                                                    checked={formValues.rateHistory}
                                                    onChange={(e) => {
                                                        setFormValues((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                rateHistory: e.target.checked
                                                            }
                                                        })
                                                    }}
                                                />
                                            }
                                            label="Enable Rate History "
                                        />
                                      </div>
                                    </div>
                                    <RateHistoryDialog  formValues={formValues} setFormValues={setFormValues} isEnabled={formValues.rateHistory}/>
                          </div>    
                        </div>
                        <h3 className='mt-3 text-primary'>Service Information</h3>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-4'>
                            <Autocomplete
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                forcePopupIcon={true}
                                size='small'
                                options={descriptionCodes?.map((des) => ({
                                    value: des._id,
                                    label: `${des.serviceDescription} / ${des.serviceCode}`,
                                }))}
                                // getOptionLabel={(option) => option.label}
                                value={desCodes}
                                onChange={(e, newValue) => {
                                    if (newValue) {
                                        setSelectedDescriptionCode(newValue.value);
                                        setDesSwitch(!desSwitch);
                                    }
                                }}
                                ref={inputFieldRef}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label='- Select Description Code -'
                                        required
                                    />
                                )}
                            />
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-4'>
                            <TextField
                                type='text'
                                name='unitMeasure'
                                size='small'
                                required
                                fullWidth
                                label='Unit of Measure (Label)'
                                autoComplete='off'
                                value={formValues.unitMeasure}
                                onChange={(e) => {
                                    setFormValues((prevState) => {
                                        return {
                                            ...prevState,
                                            unitMeasure: e.target.value
                                        }
                                    })
                                }}
                            />
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 my-2'>
                            <TextField
                                type='number'
                                name='totalBillable'
                                size='small'
                                value={formValues.totalBillable}
                                fullWidth
                                label='Total Billable Units'
                                autoComplete='off'
                                required
                                onChange={(e) => {
                                    setFormValues((prevState) => {
                                        return {
                                            ...prevState,
                                            totalBillable: parseInt(e.target.value)
                                        }
                                    })
                                    if (formValues.totalBillable && formValues.unitRate) {
                                        setFormValues((prevState) => ({
                                            ...prevState,
                                            totalAmount: prevState.unitRate * prevState.totalBillable,
                                        }));
                                    }
                                }}
                                variant="outlined"
                            />
                        </div>

                        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 my-2'>
                            <TextField
                                type='number'
                                name='unitRate'
                                size='small'
                                value={formValues.unitRate}
                                fullWidth
                                label='Default Unit Rate'
                                required
                                autoComplete='off'
                                onChange={(e) => {
                                    setFormValues((prevState) => {
                                        return {
                                            ...prevState,
                                            unitRate: parseInt(e.target.value)
                                        }
                                    })
                                    if (formValues.totalBillable && formValues.unitRate) {
                                        setFormValues((prevState) => ({
                                            ...prevState,
                                            totalAmount: prevState.unitRate * prevState.totalBillable,
                                        }));
                                    }
                                }}
                                variant="outlined"
                            />
                        </div>

                        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 my-2'>
                            <TextField
                                type='number'
                                name='totalAmount'
                                size='small'
                                label='Total Authorized Amount ($)'
                                value={formValues.totalAmount}
                                fullWidth
                                disabled
                                onChange={(e) => {
                                    setFormValues((prevState) => {
                                        return {
                                            ...prevState,
                                            totalAmount: parseInt(e.target.value)
                                        }
                                    })
                                }}
                                variant="outlined"
                            />
                        </div>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                            <div className='row'>
                                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12'>
                                    <label className='fw-bold fs-6'>Procedure Modifiers</label>
                                    <TextField
                                        type='text'
                                        name='procedureModifier[0]'
                                        size='small'
                                        fullWidth
                                        placeholder=''
                                        value={formValues.procedureModifier[0]}
                                        onChange={(e) => {
                                            setFormValues((prevValues) => ({
                                                ...prevValues,
                                                procedureModifier: [
                                                    e.target.value,
                                                    prevValues.procedureModifier[1],
                                                    prevValues.procedureModifier[2],
                                                    prevValues.procedureModifier[3],
                                                    // add more values to update as needed
                                                ],
                                            }));
                                        }}
                                        variant="outlined"
                                    />
                                </div>

                                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 my-3'>
                                    <div className='mt-3'>
                                        <TextField
                                            type='text'
                                            name='procedureModifier[1]'
                                            value={formValues.procedureModifier[1]}
                                            size='small'
                                            fullWidth
                                            placeholder=''
                                            onChange={(e) => {
                                                setFormValues((prevValues) => ({
                                                    ...prevValues,
                                                    procedureModifier: [
                                                        prevValues.procedureModifier[0],
                                                        e.target.value,
                                                        prevValues.procedureModifier[2],
                                                        prevValues.procedureModifier[3],
                                                        // add more values to update as needed
                                                    ],
                                                }));
                                            }}
                                            variant="outlined"
                                        />
                                    </div>
                                </div>

                                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 my-3'>
                                    <div className='mt-3'>
                                        <TextField
                                            type='text'
                                            name='procedureModifier[2]'
                                            value={formValues.procedureModifier[2]}
                                            size='small'
                                            fullWidth
                                            placeholder=''
                                            onChange={(e) => {
                                                setFormValues((prevValues) => ({
                                                    ...prevValues,
                                                    procedureModifier: [
                                                        prevValues.procedureModifier[0],
                                                        prevValues.procedureModifier[1],
                                                        e.target.value,
                                                        prevValues.procedureModifier[3],
                                                        // add more values to update as needed
                                                    ],
                                                }));
                                            }}
                                            variant="outlined"
                                        />
                                    </div>
                                </div>

                                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 my-3'>
                                    <div className='mt-3'>
                                        <TextField
                                            type='text'
                                            name='procedureModifier[3]'
                                            value={formValues.procedureModifier[3]}
                                            size='small'
                                            fullWidth
                                            placeholder=''
                                            onChange={(e) => {
                                                setFormValues((prevValues) => ({
                                                    ...prevValues,
                                                    procedureModifier: [
                                                        prevValues.procedureModifier[0],
                                                        prevValues.procedureModifier[1],
                                                        prevValues.procedureModifier[2],
                                                        e.target.value,
                                                        // add more values to update as needed
                                                    ],
                                                }));
                                            }}
                                            variant="outlined"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                            <Autocomplete
                                id={field.name}
                                size="small"
                                value={formValues.primaryDiagnosis}
                                options={filteredOptions}
                                onInputChange={(_, newValue) => loadOptions(newValue)}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                freeSolo={true}
                                forcePopupIcon={true}
                                onChange={(_, newValue) => {
                                        // setDiagnosisForm(newValue.value)
                                    if(newValue) {
                                    setFormValues((prevState) => {
                                        return {
                                               ...prevState,
                                            primaryDiagnosis: newValue.value
                                        }
                                    })
                                    }
                                       
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={`Search for ICD-10`}
                                        required
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </div>

                        <div className='row my-2'>
                            <div className="d-flex">
                                <label className='fw-bold fs-6'>Diagnosis Code Pointer</label>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12'>
                                <TextField
                                    type='text'
                                    name='diagnosisPointer[0]'
                                    value={formValues.diagnosisPointer[0]}
                                    size='small'
                                    fullWidth
                                    placeholder='1'
                                    onChange={(e) => {
                                        setFormValues((prevValues) => ({
                                            ...prevValues,
                                            diagnosisPointer: [
                                                e.target.value,
                                                prevValues.diagnosisPointer[1],
                                                prevValues.diagnosisPointer[2],
                                                prevValues.diagnosisPointer[3],
                                                // add more values to update as needed
                                            ],
                                        }));
                                    }}
                                    variant="outlined"
                                />
                            </div>

                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12'>
                                <TextField
                                    type='text'
                                    name='diagnosisPointer[1]'
                                    size='small'
                                    value={formValues.diagnosisPointer[1]}
                                    fullWidth
                                    placeholder=''
                                    onChange={(e) => {
                                        setFormValues((prevValues) => ({
                                            ...prevValues,
                                            diagnosisPointer: [
                                                prevValues.diagnosisPointer[0],
                                                e.target.value,
                                                prevValues.diagnosisPointer[2],
                                                prevValues.diagnosisPointer[3],
                                                // add more values to update as needed
                                            ],
                                        }));
                                    }}
                                    variant="outlined"
                                />
                            </div>

                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12'>
                                <TextField
                                    type='text'
                                    name='diagnosisPointer[2]'
                                    value={formValues.diagnosisPointer[2]}
                                    size='small'
                                    fullWidth
                                    placeholder=''
                                    onChange={(e) => {
                                        setFormValues((prevValues) => ({
                                            ...prevValues,
                                            diagnosisPointer: [
                                                prevValues.diagnosisPointer[0],
                                                prevValues.diagnosisPointer[1],
                                                e.target.value,
                                                prevValues.diagnosisPointer[3],
                                                // add more values to update as needed
                                            ],
                                        }));
                                    }}
                                    variant="outlined"
                                />
                            </div>

                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12'>
                                <TextField
                                    type='text'
                                    name='diagnosisPointer[3]'
                                    value={formValues.diagnosisPointer[3]}
                                    size='small'
                                    fullWidth
                                    placeholder=''
                                    onChange={(e) => {
                                        setFormValues((prevValues) => ({
                                            ...prevValues,
                                            diagnosisPointer: [
                                                prevValues.diagnosisPointer[0],
                                                prevValues.diagnosisPointer[1],
                                                prevValues.diagnosisPointer[2],
                                                e.target.value,
                                            ],
                                        }));
                                    }}
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                            <TextField
                                type='number'
                                name='priorAuth'
                                size='small'
                                label='Prior Authorization Number'
                                value={formValues.priorAuth}
                                fullWidth
                                onChange={(e) => {
                                    setFormValues((prevState) => {
                                        return {
                                            ...prevState,
                                            priorAuth: e.target.value
                                        }
                                    })
                                }}
                                variant="outlined"
                            />
                        </div> 
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-3'>
              <Grid
                item
                style={{
                  backgroundColor: 'BlanchedAlmond',
                  maxWidth: 'fit-content',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
              >
                <Typography style={{fontWeight: 'bold', fontSize:'14px'}} className='pt-2 pb-2'>
                  Unit of Measure (1 hour)
                </Typography>
              </Grid>
            </div>
                        <div>
                            {/* <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                                {/* <div className="d-flex">
                                    <p className="text-danger">*</p>
                                    <h3 className="fw-bold fs-6 mb-2 text-primary">Method of Data Collection</h3>
                                </div> 
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        name='dataCollectionMethod'
                                        value={formValues.dataCollectionMethod}
                                        onChange={(e: { target: any }) => {
                                            handleFormChange(e)
                                            setFormValues((prevState) => {
                                                return {
                                                    ...prevState,
                                                    dataCollectionMethod: e.target.value
                                                }
                                            })
                                            setAttendanceTypeBool(true);
                                        }}
                                    >
                                        <FormControlLabel
                                            value='Attendance'
                                            control={<Radio />}
                                            label='Attendance'
                                            required
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div> */}
                            {/* {attendanceTypeBool ? ( */}
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                                    <Autocomplete
                                        options={attendanceTypes}
                                        size='small'
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        freeSolo={true}
                                        forcePopupIcon={true}
                                        value={formValues.attendanceType}
                                        onChange={(event, newValue) => {
                                            setFormValues((prevState) => {
                                                return {
                                                    ...prevState,
                                                    attendanceType: newValue ? newValue : ''
                                                }
                                            })
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" required label='Attendance Type' />}
                                    />
                                </div>
                            {/* ) : null} */}
                        </div>
                        {/* <div>
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mb-4'>
                                <Autocomplete
                                    options={unitOfMeasureOptions}
                                    size='small'
                                    value={formValues.unitOfMeasure}
                                    freeSolo={true}
                                    forcePopupIcon={true}
                                    fullWidth
                                    renderInput={(params) => <TextField {...params} variant='outlined' label='Unit of Measure (Calculation)' />}
                                    onChange={(_, newValue) => {
                                        setFormValues((prevState) => {
                                            return {
                                                ...prevState,
                                                unitOfMeasure: newValue ? newValue : ''
                                            }
                                        })
                                    }}
                                    isOptionEqualToValue={(option, value) => option === value}
                                />
                            </div>

                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-2'>
                                <label className=' fw-bold fs-6 mb-2'>Rounding Algorithm</label>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        name='roundingAlgorithm'
                                        value={formValues.roundingAlgorithm}
                                        row
                                        onChange={(e) => {
                                            handleFormChange(e);
                                            setFormValues((prevState) => {
                                                return {
                                                    ...prevState,
                                                    roundingAlgorithm: e.target.value
                                                }
                                            })
                                        }}
                                    >
                                        {roundingAlgorithmOptions.map((option) => (
                                            <div
                                                className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12'
                                                key={option}
                                            >
                                                <FormControlLabel
                                                    value={option}
                                                    control={<Radio />}
                                                    label={option}
                                                />
                                            </div>
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                                <label className=' fw-bold fs-6 mb-2'>Smallest Allowed Increment</label>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        name='allowedIncrement'
                                        value={formValues.allowedIncrement}
                                        row
                                        onChange={(e) => {
                                            handleFormChange(e);
                                            setFormValues((prevState) => {
                                                return {
                                                    ...prevState,
                                                    allowedIncrement: e.target.value
                                                }
                                            })
                                        }}
                                    >
                                        {allowedIncrementOptions.map((option) => (
                                            <div
                                                className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12'
                                                key={option}
                                            >
                                                <FormControlLabel
                                                    value={option}
                                                    control={<Radio />}
                                                    label={option}
                                                />
                                            </div>
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div> */}

                        <Box sx={{ marginTop: '24px', }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'right',
                                borderBottomLeftRadius: '4px',
                                borderBottomRightRadius: '4px',
                                padding: '6px 0',
                            }} >
                                <Button
                                    type='button'
                                    variant='outlined'
                                    sx={{
                                        color: '#000',
                                        backgroundColor: '#f2f2f2',
                                        '&:hover': {
                                            backgroundColor: '#f2f2f2',
                                        },
                                    }}
                                    onClick={() => history.goBack()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type='submit'
                                    sx={{
                                        marginLeft: '10px',
                                        backgroundColor: '#28a745',
                                        color: '#fff',
                                        '&:hover': {
                                            backgroundColor: '#218838',
                                        },
                                    }}
                                    variant='outlined'
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Edit