import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {Tabs, Tab, useMediaQuery, useTheme} from '@mui/material'

function GeneralHeader() {
  const location = useLocation()

  const getActiveTabIndex = () => {
    if (location.pathname.startsWith('/records/general/nurses-notes')) return 0
    // if (location.pathname.startsWith('/admin/general/site')) return 1
    // if (location.pathname.startsWith('/admin/general/programs')) return 2
    // if (location.pathname.startsWith('/admin/general/scoring-method')) return 3
    // if (location.pathname.startsWith('/admin/general/enrollment-by-program')) return 4
    return false
  }

  const value = getActiveTabIndex()

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-3 pb-0'>
          <div className='d-flex overflow-auto h-60px'>
            <Tabs
              value={value}
              variant='scrollable'
              scrollButtons={isSmallScreen ? 'auto' : 'on'}
              aria-label='General Admin Tabs'
              sx={{
                flexGrow: 1,
                '.MuiTab-root': {
                  textTransform: 'none',
                  minWidth: 150,
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                },
                '.Mui-selected': {
                  color: theme.palette.primary.main,
                },
                '.MuiTabs-indicator': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              <Tab label='Nurses/Therapy-Notes' component={Link} to='/records/general/nurses-notes/list' />
              {/* <Tab label='Programs' component={Link} to='/admin/general/programs/list' />
              <Tab label='Scoring Method' component={Link} to='/admin/general/scoring-method/list' />
              <Tab label='Enrollment-by-Program' component={Link} to='/admin/general/enrollment-by-program' /> */}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralHeader
