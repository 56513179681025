
import { useEffect, useRef, useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { Modal } from 'bootstrap'
import { useDispatch } from 'react-redux'
import { randomID } from "../../../../../../_helper/randomID"
import { getFundingSource } from "../../../../admin/billing/funding-source/store/actions"
import { getAttendanceType } from "../../../../admin/billing/attendance-type/store/action"
import { GetDescriptionCode } from "../../../../admin/billing/description-code/store/action"
import { getServiceAuth, updateServiceAuth } from "../../service-authorization/store/actions"

const Edit = ({ setShowEditingForm, selectedValue }) => {
    const [fundingSources, setFundingSources] = useState([]);
    const [descriptionCodes, setDescriptionCodes] = useState([]);
    const [desSwitch, setDesSwitch] = useState(false);
    const [selectedDescriptionCode, setSelectedDescriptionCode] = useState('');
    const [addServiceBool, setAddServiceBool] = useState(true);
    const [attendanceTypeBool, setAttendanceTypeBool] = useState(false);
    const [attendanceTypes, setAttendanceTypes] = useState([]);
    const [desCodes, setDesCodes] = useState(selectedValue.service);
    const [submitBool, setSubmitBool] = useState(false);
    const [addServiceBool1, setAddServiceBool1] = useState(false);
    const [statusBool, setStatusBool] = useState(selectedValue.status);
    const [formValues, setFormValues] = useState(selectedValue);
    const [showForm, setShowForm] = useState(false);
    const [selectedDescriptionCodeObj, setSelectedDescriptionCodeObj] = useState({
        accountingNumber: '',
        serviceDescription: '',
        serviceCode: '',
        unitMeasure: '',
        unitRate: '',
        claimType: '',
        primaryDiagnosis: '',
        procedureModifier: ['', '', '', ''],
        diagnosisPointer: ['', '', '', ''],
        electronicUnit: '',
        procedureQualifier: '',
        addService: '',
        validationTotalBillable: '',
        unitOfMeasure: '',
        roundingAlgorithm: '',
        allowedIncrement: '',
    });
    const [subFormValues, setSubFormValues] = useState({
        accountingNumber: selectedDescriptionCodeObj.accountingNumber,
        serviceDescription: selectedDescriptionCodeObj.serviceDescription,
        serviceCode: selectedDescriptionCodeObj.serviceCode,
        unitMeasure: selectedDescriptionCodeObj.unitMeasure,
        unitRate: selectedDescriptionCodeObj.unitRate,
        claimType: selectedDescriptionCodeObj.claimType,
        primaryDiagnosis: selectedDescriptionCodeObj.primaryDiagnosis,
        procedureModifier: selectedDescriptionCodeObj.procedureModifier,
        diagnosisPointer: selectedDescriptionCodeObj.diagnosisPointer,
        electronicUnit: selectedDescriptionCodeObj.electronicUnit,
        procedureQualifier: selectedDescriptionCodeObj.procedureQualifier,
        addService: selectedDescriptionCodeObj.addService,
        validationTotalBillable: selectedDescriptionCodeObj.validationTotalBillable,
        unitOfMeasure: selectedDescriptionCodeObj.unitOfMeasure,
        roundingAlgorithm: selectedDescriptionCodeObj.roundingAlgorithm,
        allowedIncrement: selectedDescriptionCodeObj.allowedIncrement,
        totalBillable: '',
        totalAmount: 0,
        priorAuth: '',
        dataCollectionMethod: '',
        attendanceType: '',
        remainingUnits: '',
        _id: '',
        serviceID: ''
    });

    const dispatch = useDispatch()
    const modalRef = useRef(null);
    const inputFieldRef = useRef(null);

    useEffect(() => {
        if (selectedDescriptionCode !== '') {
            const tempdes = descriptionCodes.find(des => des.serviceDescription + des.serviceCode === selectedDescriptionCode)
            setSelectedDescriptionCodeObj(tempdes)
            setSubFormValues({
                accountingNumber: tempdes.accountingNumber,
                serviceDescription: tempdes.serviceDescription,
                serviceCode: tempdes.serviceCode,
                unitMeasure: tempdes.unitMeasure,
                unitRate: tempdes.unitRate,
                claimType: tempdes.claimType,
                primaryDiagnosis: tempdes.primaryDiagnosis,
                procedureModifier: tempdes.procedureModifier,
                diagnosisPointer: tempdes.diagnosisPointer,
                electronicUnit: tempdes.electronicUnit,
                procedureQualifier: tempdes.procedureQualifier,
                addService: tempdes.addService,
                validationTotalBillable: tempdes.validationTotalBillable,
                unitOfMeasure: tempdes.unitOfMeasure,
                roundingAlgorithm: tempdes.roundingAlgorithm,
                allowedIncrement: tempdes.allowedIncrement,
                totalBillable: '',
                totalAmount: 0,
                priorAuth: '',
                dataCollectionMethod: '',
                attendanceType: '',
                serviceID: ''
            })
        }
    }, [desSwitch])

    useEffect(() => {
        const fetchFundingSource = async () => {
            const result = await dispatch(getFundingSource({}));
            let tempObj = result.payload.data;
            setFundingSources(tempObj)
        }

        const fetchAttendance = async () => {
            const attendanceResult = await dispatch(getAttendanceType({}))
            const tempAtt = attendanceResult.payload.data;
            setAttendanceTypes(tempAtt)
        }

        const fetchDescription = async () => {
            const descriptionResult = await dispatch(GetDescriptionCode({}))
            let tempDes = descriptionResult.payload.data;
            const serviceAuthsResult = await dispatch(getServiceAuth({}))
            let tempServAuths = serviceAuthsResult.payload.data;
            let selectedServAuths = tempServAuths.filter((servAuth) => servAuth.firstName === formValues.firstName && servAuth.lastName === formValues.lastName);

            // Get an array of unique serviceDescription values from selectedServAuths
            const serviceDescriptions = new Set();
            selectedServAuths.forEach(({ service }) => {
                service.forEach(({ serviceDescription }) => {
                    serviceDescriptions.add(serviceDescription);
                });
            });

            // Filter tempDes based on whether serviceDescription is in the Set
            tempDes = tempDes.filter(({ serviceDescription }) => {
                return !serviceDescriptions.has(serviceDescription);
            });
            setDescriptionCodes(tempDes)
        }

        fetchAttendance()
        fetchDescription()
        fetchFundingSource()
    }, [])

    useEffect(() => {
        const modal = new Modal(modalRef.current);
        return () => {
            modal.dispose();
        };
    }, []);

    const handleOpen = () => {
        const modal = Modal.getInstance(modalRef.current);
        modal.show();
    }

    const handleClose = () => {
        const modal = Modal.getInstance(modalRef.current);
        modal.hide();
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubFormChange = (e) => {
        const { name, value } = e.target;
        setSubFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }))
    }

    const deleteDesc = (id) => {
        setDesCodes(prevState => (prevState.filter(code => code._id !== id)))
    }

    const editAuth = (id) => {
        let selectedDesc = desCodes.find(code => code._id === id)
        inputFieldRef.current.value = selectedDesc.serviceDescription + selectedDesc.serviceCode
        if (selectedDesc.dataCollectionMethod === 'Attendance') {
            setAttendanceTypeBool(true)
        }
        setSubFormValues(selectedDesc)
        handleOpen()
    }
    
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={subFormValues}
                onSubmit={async (values) => {
                    if (!values._id) {
                        values._id = randomID()
                    }
                    values.remainingUnits = values.totalBillable
                    handleClose()
                    setSelectedDescriptionCode('')
                    setSubFormValues({
                        accountingNumber: '',
                        serviceDescription: '',
                        serviceCode: '',
                        unitMeasure: '',
                        unitRate: '',
                        claimType: '',
                        primaryDiagnosis: '',
                        procedureModifier: ['', '', '', ''],
                        diagnosisPointer: ['', '', '', ''],
                        electronicUnit: '',
                        procedureQualifier: '',
                        addService: '',
                        validationTotalBillable: '',
                        unitOfMeasure: '',
                        roundingAlgorithm: '',
                        allowedIncrement: '',
                        totalBillable: '',
                        totalAmount: 0,
                        priorAuth: '',
                        dataCollectionMethod: '',
                        attendanceType: '',
                        remainingUnits: '',
                        serviceID: ''
                    })
                    inputFieldRef.current.value = '';
                    setAttendanceTypeBool(false)
                    setSelectedDescriptionCodeObj({})
                    setDesCodes(prevDesCodes => {
                        const index = prevDesCodes.findIndex(obj => obj._id === values._id);
                        if (index !== -1) {
                            const updatedDesCodes = [...prevDesCodes];
                            updatedDesCodes[index] = values;
                            return updatedDesCodes;
                        } else {
                            return [...prevDesCodes, values];
                        }
                    });
                }}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form>
                        {/* MODAL FOR ADDING */}
                        <div className="modal fade" ref={modalRef}>
                            <div className="modal-dialog modal-dialog-centered mw-1000px">
                                <div className="modal-content">
                                    <div className='modal-body scroll-y mx-lg-5'>
                                        <div className='d-flex justify-content-between py-3'>
                                            <h2>Service</h2>
                                            <i className="bi bi-x-lg"
                                                data-bs-dismiss="modal" aria-label="Close"
                                                onClick={() => {
                                                    setSelectedDescriptionCode('')
                                                    setSubFormValues({
                                                        accountingNumber: '',
                                                        serviceDescription: '',
                                                        serviceCode: '',
                                                        unitMeasure: '',
                                                        unitRate: '',
                                                        claimType: '',
                                                        primaryDiagnosis: '',
                                                        procedureModifier: ['', '', '', ''],
                                                        diagnosisPointer: ['', '', '', ''],
                                                        electronicUnit: '',
                                                        procedureQualifier: '',
                                                        addService: '',
                                                        validationTotalBillable: '',
                                                        unitOfMeasure: '',
                                                        roundingAlgorithm: '',
                                                        allowedIncrement: '',
                                                        totalBillable: '',
                                                        totalAmount: 0,
                                                        priorAuth: '',
                                                        dataCollectionMethod: '',
                                                        attendanceType: '',
                                                        serviceID: ''
                                                    })
                                                    inputFieldRef.current.value = '';
                                                    setAttendanceTypeBool(false)
                                                    setSelectedDescriptionCodeObj({})
                                                    setAddServiceBool1(true)
                                                }}></i>
                                        </div>

                                        <div className="w-100 py-5 px-3" style={{ backgroundColor: "#181c32" }}>
                                            <h6 style={{ color: "#f2f2f2" }}>Service Information</h6>
                                        </div>
                                        <div className='mb-5 col-mb-10 px-5' id='kt_serviceInfo'>
                                            <div className='my-3'>
                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-5'>
                                                    <div className='d-flex'>
                                                        <p className="text-danger">*</p><label className=' fw-bold fs-6 mb-2'>Service Description/Code</label>
                                                    </div>
                                                    {subFormValues.serviceDescription ? <p>{subFormValues.serviceDescription} / {subFormValues.serviceCode}</p> : null}
                                                    <select
                                                        name='descriptionCode'
                                                        className='form-select form-select-lg mb-3'
                                                        aria-label='.form-select-lg example'
                                                        required
                                                        onChange={(e) => {
                                                            setSelectedDescriptionCode(e.target.value)
                                                            setDesSwitch(!desSwitch)
                                                        }}
                                                        ref={inputFieldRef}
                                                        disabled={addServiceBool1 === true ? false : true}
                                                    >
                                                        <option value='' hidden>- Select Description Code -</option>
                                                        {
                                                            descriptionCodes.map((des) => (
                                                                <option value={des.serviceDescription + des.serviceCode} key={des._id}>{des.serviceDescription} / {des.serviceCode}</option>
                                                            ))
                                                        }
                                                    </select>

                                                </div>
                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-5'>
                                                    <div className='d-flex'>
                                                        <p className="text-danger">*</p><label className=' fw-bold fs-6 mb-2'>Unit of Measure (Label)</label>
                                                    </div>
                                                    <Field
                                                        type='text'
                                                        name='unitMeasure'
                                                        className='form-control form-control-solid mb-3 mb-lg-0'
                                                        autoComplete='off'
                                                        required
                                                        onChange={(e) => {
                                                            handleSubFormChange(e);
                                                            setFieldValue('unitMeasure', e.target.value);
                                                        }}
                                                    />
                                                    <ErrorMessage name="unitMeasure" component="div" className='text-danger' />
                                                </div>
                                                <div className="row my-2">
                                                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 my-3'>
                                                        <div className='d-flex'>
                                                            <p className="text-danger">*</p><label className="fw-bold fs-6 mb-2">Total Billable Units</label>
                                                        </div>
                                                        <Field
                                                            type='number'
                                                            name='totalBillable'
                                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                                            autoComplete='off'
                                                            required
                                                            onChange={(e) => {
                                                                handleSubFormChange(e);
                                                                setFieldValue('totalBillable', e.target.value);
                                                                if (subFormValues.totalBillable && subFormValues.unitRate) {
                                                                    setSubFormValues(prevState => ({
                                                                        ...prevState,
                                                                        totalAmount: prevState.unitRate * prevState.totalBillable
                                                                    }));
                                                                }
                                                            }}
                                                        />
                                                        <ErrorMessage name="totalBillable" component="div" className='text-danger' />
                                                    </div>
                                                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 my-3'>
                                                        <div className='d-flex'>
                                                            <p className="text-danger">*</p><label className="fw-bold fs-6 mb-2">Default Unit Rate</label>
                                                        </div>
                                                        <Field
                                                            type='number'
                                                            name='unitRate'
                                                            step="any"
                                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                                            required
                                                            autoComplete='off'
                                                            onChange={(e) => {
                                                                handleSubFormChange(e);
                                                                setFieldValue('unitRate', e.target.value);
                                                                if (subFormValues.totalBillable && subFormValues.unitRate) {
                                                                    setSubFormValues(prevState => ({
                                                                        ...prevState,
                                                                        totalAmount: prevState.unitRate * prevState.totalBillable
                                                                    }));
                                                                }
                                                            }}
                                                        />
                                                        <ErrorMessage name="unitRate" component="div" className='text-danger' />
                                                    </div>
                                                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 my-3'>
                                                        <label className="fw-bold fs-6 mb-2">Total Authorized Amount ($)</label>
                                                        <Field
                                                            type='number'
                                                            step="any"
                                                            name='totalAmount'
                                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                                            onChange={(e) => {
                                                                handleSubFormChange(e);
                                                                setFieldValue('totalAmount', e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                                                        <div className='row'>
                                                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12'>
                                                                <label className=' fw-bold fs-6 mb-2'>Procedure Modifiers</label>
                                                                <Field
                                                                    type='text'
                                                                    name='procedureModifier[0]'
                                                                    className='form-control form-control-solid mb-lg-0'
                                                                    placeholder=''
                                                                    onChange={(e) => {
                                                                        setSubFormValues((prevValues) => ({
                                                                            ...prevValues,
                                                                            procedureModifier: [
                                                                                e.target.value,  // update the first value
                                                                                prevValues.procedureModifier[1], // keep the second value
                                                                                prevValues.procedureModifier[2], // keep the third value
                                                                                prevValues.procedureModifier[3], // keep the fourth value
                                                                                // add more values to update as needed
                                                                            ]
                                                                        }));

                                                                        setFieldValue('procedureModifier[0]', e.target.value);
                                                                    }}
                                                                ></Field>
                                                            </div>
                                                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 my-5'>
                                                                <div className='mt-3'>
                                                                    <Field
                                                                        type='text'
                                                                        name='procedureModifier[1]'
                                                                        className='form-control form-control-solid mb-lg-0'
                                                                        placeholder=''
                                                                        onChange={(e) => {
                                                                            setSubFormValues((prevValues) => ({
                                                                                ...prevValues,
                                                                                procedureModifier: [
                                                                                    prevValues.procedureModifier[0],
                                                                                    e.target.value,
                                                                                    prevValues.procedureModifier[2],
                                                                                    prevValues.procedureModifier[3],
                                                                                    // add more values to update as needed
                                                                                ]
                                                                            }));

                                                                            setFieldValue('procedureModifier[1]', e.target.value);
                                                                        }}
                                                                    ></Field>
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 my-5'>
                                                                <div className='mt-3'>
                                                                    <Field
                                                                        type='text'
                                                                        name='procedureModifier[2]'
                                                                        className='form-control form-control-solid mb-lg-0'
                                                                        placeholder=''
                                                                        onChange={(e) => {
                                                                            setSubFormValues((prevValues) => ({
                                                                                ...prevValues,
                                                                                procedureModifier: [
                                                                                    prevValues.procedureModifier[0],
                                                                                    prevValues.procedureModifier[1],
                                                                                    e.target.value,
                                                                                    prevValues.procedureModifier[3],
                                                                                    // add more values to update as needed
                                                                                ]
                                                                            }));

                                                                            setFieldValue('procedureModifier[2]', e.target.value);
                                                                        }}
                                                                    ></Field>
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 my-5'>
                                                                <div className='mt-3'>
                                                                    <Field
                                                                        type='text'
                                                                        name='procedureModifier[3]'
                                                                        className='form-control form-control-solid mb-3 mb-lg-0'
                                                                        placeholder=''
                                                                        onChange={(e) => {
                                                                            setSubFormValues((prevValues) => ({
                                                                                ...prevValues,
                                                                                procedureModifier: [
                                                                                    prevValues.procedureModifier[0],
                                                                                    prevValues.procedureModifier[1],
                                                                                    prevValues.procedureModifier[2],
                                                                                    e.target.value,
                                                                                    // add more values to update as needed
                                                                                ]
                                                                            }));
                                                                            setFieldValue('procedureModifier[3]', e.target.value);
                                                                        }}
                                                                    ></Field>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                                                        <div className="d-flex">
                                                            <p className="text-danger">*</p><label className=' fw-bold fs-6 mb-2'>Primary Diagnosis Code</label>
                                                        </div>
                                                        <div className="d-flex">
                                                            <h6>ICD-10:</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h5>{subFormValues.primaryDiagnosis}</h5>
                                                        </div>
                                                        {/* <DiagnosticsSearching setDiagnostics={setDiagnostics} /> */}
                                                    </div>

                                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-1'>
                                                        <div className="d-flex">
                                                            <p className="text-danger">*</p><label className=' fw-bold fs-6 mb-2'>Diagnosis Code Pointer</label>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 my-5'>
                                                                <Field
                                                                    type='text'
                                                                    name='diagnosisPointer[0]'
                                                                    className='form-control form-control-solid mb-3 mb-lg-0'
                                                                    placeholder='1'
                                                                    onChange={(e) => {
                                                                        setSubFormValues((prevValues) => ({
                                                                            ...prevValues,
                                                                            diagnosisPointer: [
                                                                                e.target.value,
                                                                                prevValues.diagnosisPointer[1],
                                                                                prevValues.diagnosisPointer[2],
                                                                                prevValues.diagnosisPointer[3],
                                                                                // add more values to update as needed
                                                                            ]
                                                                        }));
                                                                        setFieldValue('diagnosisPointer[0]', e.target.value);
                                                                    }}
                                                                ></Field>
                                                            </div>
                                                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 my-5'>
                                                                <Field
                                                                    type='text'
                                                                    name='diagnosisPointer[1]'
                                                                    className='form-control form-control-solid mb-3 mb-lg-0'
                                                                    placeholder=''
                                                                    onChange={(e) => {
                                                                        setSubFormValues((prevValues) => ({
                                                                            ...prevValues,
                                                                            diagnosisPointer: [
                                                                                prevValues.diagnosisPointer[0],
                                                                                e.target.value,
                                                                                prevValues.diagnosisPointer[2],
                                                                                prevValues.diagnosisPointer[3],
                                                                                // add more values to update as needed
                                                                            ]
                                                                        }));
                                                                        setFieldValue('diagnosisPointer[1]', e.target.value);
                                                                    }}
                                                                ></Field>
                                                            </div>
                                                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 my-5'>
                                                                <Field
                                                                    type='text'
                                                                    name='diagnosisPointer[2]'
                                                                    className='form-control form-control-solid mb-3 mb-lg-0'
                                                                    placeholder=''
                                                                    onChange={(e) => {
                                                                        setSubFormValues((prevValues) => ({
                                                                            ...prevValues,
                                                                            diagnosisPointer: [
                                                                                prevValues.diagnosisPointer[0],
                                                                                prevValues.diagnosisPointer[1],
                                                                                e.target.value,
                                                                                prevValues.diagnosisPointer[3],
                                                                                // add more values to update as needed
                                                                            ]
                                                                        }));
                                                                        setFieldValue('diagnosisPointer[2]', e.target.value);
                                                                    }}
                                                                ></Field>
                                                            </div>
                                                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 my-5'>
                                                                <Field
                                                                    type='text'
                                                                    name='diagnosisPointer[3]'
                                                                    className='form-control form-control-solid mb-3 mb-lg-0'
                                                                    placeholder=''
                                                                    onChange={(e) => {
                                                                        setSubFormValues((prevValues) => ({
                                                                            ...prevValues,
                                                                            diagnosisPointer: [
                                                                                prevValues.diagnosisPointer[0],
                                                                                prevValues.diagnosisPointer[1],
                                                                                prevValues.diagnosisPointer[2],
                                                                                e.target.value,
                                                                                // add more values to update as needed
                                                                            ]
                                                                        }));
                                                                        setFieldValue('diagnosisPointer[3]', e.target.value);
                                                                    }}
                                                                ></Field>
                                                            </div>
                                                            <ErrorMessage name='diagnosisPointer' component='div' className='text-danger' />
                                                        </div>
                                                    </div>
                                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                                        <label className="fw-bold fs-6 mb-2">Prior Authorization Number</label>
                                                        <Field
                                                            type='number'
                                                            name='priorAuth'
                                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                                            onChange={(e) => {
                                                                handleSubFormChange(e);
                                                                setFieldValue('priorAuth', e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-100 py-5 px-3" style={{ backgroundColor: "#181c32" }}>
                                            <h6 style={{ color: "#f2f2f2" }}>Data Collection Information</h6>
                                        </div>
                                        <div className='mb-5 col-mb-10 p-5' id='kt_dataCollectionInfo'>
                                            <div className='my-3'>
                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                                                    <div className="d-flex">
                                                        <p className="text-danger">*</p><h3 className="fw-bold fs-6 mb-2 text-primary">Method of Data Collection</h3>
                                                    </div>
                                                    {/* <div className='form-check my-4 mx-2'>
                                                        <Field
                                                            className='form-check-input'
                                                            type='radio'
                                                            name='dataCollectionMethod'
                                                            value='BillingDataInput'
                                                            required
                                                            onChange={(e) => {
                                                                handleSubFormChange(e)
                                                                setFieldValue('dataCollectionMethod', e.target.value);
                                                                setFieldValue('attendanceType', '');
                                                                setAttendanceTypeBool(false);
                                                            }}
                                                        />
                                                        <label className='form-check-label'>Billing Data Input</label>
                                                    </div> */}
                                                    <div className='form-check my-4 mx-2'>
                                                        <Field
                                                            className='form-check-input'
                                                            type='radio'
                                                            name='dataCollectionMethod'
                                                            value='Attendance'
                                                            required
                                                            onChange={(e) => {
                                                                handleSubFormChange(e)
                                                                setFieldValue('dataCollectionMethod', e.target.value);
                                                                setAttendanceTypeBool(true);
                                                            }}
                                                        />
                                                        <label className='form-check-label'>Attendance</label>
                                                    </div>
                                                    <ErrorMessage name="dataCollectionMethod" component="div" className='text-danger' />
                                                </div>
                                                {
                                                    attendanceTypeBool ?
                                                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                                                            <label className=' fw-bold fs-6 mb-2'>Attendance Type</label>
                                                            <Field
                                                                className='form-select form-select-lg mb-3'
                                                                as='select'
                                                                autoComplete='off'
                                                                name='attendanceType'
                                                                onChange={(e) => {
                                                                    handleSubFormChange(e);
                                                                    setFieldValue('attendanceType', e.target.value);
                                                                }}
                                                                required={attendanceTypeBool}
                                                            >
                                                                <option hidden value=''>- Select Attendance Type -</option>
                                                                {
                                                                    attendanceTypes.map((att, index) => (
                                                                        <option value={att.name} key={att._id}>{att.name}</option>
                                                                    ))
                                                                }
                                                            </Field>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </div>

                                        <div className="w-100 py-5 px-3" style={{ backgroundColor: "#181c32" }}>
                                            <h6 style={{ color: "#f2f2f2" }}>Automatic Unit Calculation From Time In/Out</h6>
                                        </div>
                                        <div className='mb-5 mx-3 col-mb-10' id='kt_autoTimeInOut'>
                                            <div className='fv-row fv-plugins-icon-container p-5'>                                                    <div className='row'>
                                                <h3 className='mb-5 text-primary display-7'>Automatic Unit Calculation From Time In/Out</h3>
                                                <div style={{ backgroundColor: "BlanchedAlmond" }} className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                                                    <p className='mt-5'>Please check <b>Billable Unit Calculator</b> to make sure you have chosen the desired settings for Automatic Unit Calculation From Time In/Out.</p>
                                                </div>
                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                                                    <label className=' fw-bold fs-6 mb-2'>Unit of Measure (Calculation)</label>
                                                    <Field
                                                        className='form-select form-select-lg mb-3'
                                                        as='select'
                                                        autoComplete='off'
                                                        name='unitOfMeasure'
                                                        onChange={(e) => {
                                                            handleSubFormChange(e);
                                                            setFieldValue('unitOfMeasure', e.target.value);
                                                        }}
                                                    >
                                                        <option>Select Unit of Measure (Calculation)</option>
                                                        <option value='5 Minutes'>5 Minutes</option>
                                                        <option value='6 Minutes'>6 Minutes</option>
                                                        <option value='15 Minutes'>15 Minutes</option>
                                                        <option value='20 Minutes'>20 Minutes</option>
                                                        <option value='30 Minutes'>30 Minutes</option>
                                                        <option value='1 Hour'>1 Hour</option>
                                                        <option value='2 Hours'>2 Hours</option>
                                                    </Field>
                                                </div>

                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                                                    <label className=' fw-bold fs-6 mb-2'>Rounding Algorithm</label>
                                                    <div className='row'>
                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='roundingAlgorithm'
                                                                    value='Half Up'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('roundingAlgorithm', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    Half Up
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='roundingAlgorithm'
                                                                    value='Half Down'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('roundingAlgorithm', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    Half Down
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='roundingAlgorithm'
                                                                    value='Up'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('roundingAlgorithm', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    Up
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='roundingAlgorithm'
                                                                    value='Down'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('roundingAlgorithm', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    Down
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='roundingAlgorithm'
                                                                    value='Quarter Up'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('roundingAlgorithm', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    Quarter Up
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className='row'>
                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='roundingAlgorithm'
                                                                    value='Quarter Down'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('roundingAlgorithm', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    Quarter Down
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='roundingAlgorithm'
                                                                    value='Two Third Up'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('roundingAlgorithm', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    Two Third Up
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='roundingAlgorithm'
                                                                    value='Two Third Down'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('roundingAlgorithm', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    Two Third Down
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='roundingAlgorithm'
                                                                    value='Three Quarter Up'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('roundingAlgorithm', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    Three Quarter Up
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='roundingAlgorithm'
                                                                    value='Three Quarter Down'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('roundingAlgorithm', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    Three Quarter Down
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-0'>
                                                            <div className='form-check mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='roundingAlgorithm'
                                                                    value='None'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('roundingAlgorithm', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    None
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                                                    <label className=' fw-bold fs-6 mb-2'>Smallest Allowed Increment</label>
                                                    <div className='row'>
                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='allowedIncrement'
                                                                    value='5 minutes'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('allowedIncrement', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    5 Minutes
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='allowedIncrement'
                                                                    value='6 Minutes'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('allowedIncrement', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    6 Minutes
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='allowedIncrement'
                                                                    value='7.5 Minutes'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('allowedIncrement', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    7.5 Minutes
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='allowedIncrement'
                                                                    value='10 Minutes'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('allowedIncrement', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    10 Minutes
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='allowedIncrement'
                                                                    value='15 Minutes'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('allowedIncrement', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    15 Minutes
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className='row'>
                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='allowedIncrement'
                                                                    value='20 Minutes'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('allowedIncrement', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    20 Minutes
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='allowedIncrement'
                                                                    value='30 Minutes'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('allowedIncrement', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    30 Minutes
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='allowedIncrement'
                                                                    value='45 Minutes'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('allowedIncrement', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    45 Minutes
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='allowedIncrement'
                                                                    value='60 Minutes'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('allowedIncrement', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    60 Minutes
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 my-3'>
                                                            <div className='form-check mt-2 mb-4'>
                                                                <Field
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='allowedIncrement'
                                                                    value='120 Minutes'
                                                                    onChange={(e) => {
                                                                        handleSubFormChange(e);
                                                                        setFieldValue('allowedIncrement', e.target.value);
                                                                    }}
                                                                />
                                                                <label className='form-check-label' htmlFor='exampleRadios1'>
                                                                    120 Minutes
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div className='d-flex flex-stack mx-5'>
                                                <div>
                                                    <button className='btn btn-secondary' data-bs-dismiss="modal" aria-label="Close"
                                                        onClick={() => {
                                                            setSelectedDescriptionCode('')
                                                            setSubFormValues({
                                                                accountingNumber: '',
                                                                serviceDescription: '',
                                                                serviceCode: '',
                                                                unitMeasure: '',
                                                                unitRate: '',
                                                                claimType: '',
                                                                primaryDiagnosis: '',
                                                                procedureModifier: ['', '', '', ''],
                                                                diagnosisPointer: ['', '', '', ''],
                                                                electronicUnit: '',
                                                                procedureQualifier: '',
                                                                addService: '',
                                                                validationTotalBillable: '',
                                                                unitOfMeasure: '',
                                                                roundingAlgorithm: '',
                                                                allowedIncrement: '',
                                                                totalBillable: '',
                                                                totalAmount: 0,
                                                                priorAuth: '',
                                                                dataCollectionMethod: '',
                                                                attendanceType: '',
                                                            })
                                                            inputFieldRef.current.value = '';
                                                            setAttendanceTypeBool(false)
                                                            setSelectedDescriptionCodeObj({})
                                                            setAddServiceBool1(true)
                                                        }}>Cancel</button>
                                                </div>
                                                <div>
                                                    <button className='btn btn-primary' type='submit' disabled={isSubmitting}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <Formik
                enableReinitialize={true}
                initialValues={formValues}
                onSubmit={async (values, { setSubmitting }) => {
                    if (submitBool === true) {
                        values.service = desCodes
                        values.status = statusBool
                        await dispatch(updateServiceAuth(values));
                        setShowEditingForm(false);
                        setSubmitBool(false)
                    }
                }}
            >
                {({ resetForm, isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <div className='card mb-5 col-mb-10' id='kt_content'>
                            <div id='kt_content_container' className='container-xxl'></div>
                            <div className='my-6'>
                                <h3 className='my-6 text-primary mx-10'>Service Authorization (Edit)</h3>
                                <div className='row mx-7'>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                        <label className='fw-bold fs-5 mb-2 w-50'>Program Name:</label>
                                        <label className='w-50 fs-6 mb-2'>{selectedValue.programName}</label>
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                        <label className='fw-bold fs-5 mb-2 w-50'>Client Name:</label>
                                        <label className='w-50 fs-6 mb-2'>{selectedValue.firstName} {selectedValue.lastName}</label>
                                    </div>
                                    <div className="row">
                                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                            <label className='fw-bold fs-5 mb-2 w-50'>Claim Type:</label>
                                            <label className='w-50 fs-6 mb-2 text-info'>* Professional Claim</label>
                                        </div>
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                        <div className='d-flex'>
                                            <p className="text-danger">*</p><label className='fw-bold fs-5 mb-2 w-50'>Client ID Type</label>
                                        </div>
                                        <Field
                                            as='select'
                                            name='IDType'
                                            className='form-select form-select-lg mb-3'
                                            aria-label='.form-select-lg example'
                                            required
                                            onChange={(e) => {
                                                handleFormChange(e);
                                                setFieldValue('IDType', e.target.value);
                                            }}
                                        >
                                            <option hidden value=''>- Select ID Type -</option>
                                            {/* <option value='formID'>Client Form ID</option> */}
                                            <option value='medicaidNumber'>Medicaid Number</option>
                                            <option value='medicareNumber'>Medicare Number</option>
                                        </Field>
                                        <ErrorMessage name="IDType" component="div" className='text-danger' />
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                        <label className='fw-bold fs-5 mb-2 w-50'>Funding Source</label>
                                        <Field
                                            as='select'
                                            name='fundingSource'
                                            className='form-select form-select-lg mb-3'
                                            aria-label='.form-select-lg example'
                                            onChange={(e) => {
                                                handleFormChange(e);
                                                setFieldValue('fundingSource', e.target.value);
                                            }}
                                        >
                                            <option hidden value=''>- Select Funding Source -</option>
                                            {
                                                fundingSources.map((fundingsource, index) => (
                                                    <option value={fundingsource.name} key={fundingsource.name}>{fundingsource.name}</option>
                                                ))
                                            }
                                        </Field>
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                        <label className=' fw-bold fs-6 mb-2'>Authorization Number</label>
                                        <Field
                                            type='text'
                                            name='authorizationNumber'
                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                            placeholder='Enter Authorization Number Here...'
                                            autoComplete='off'
                                            onChange={(e) => {
                                                handleFormChange(e);
                                                setFieldValue('authorizationNumber', e.target.value);
                                            }}
                                        ></Field>
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                        <label className=' fw-bold fs-6 mb-2'>Accounting Number</label>
                                        <Field
                                            type='text'
                                            name='accountingNumber'
                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                            placeholder='Enter Accounting Number Here...'
                                            autoComplete='off'
                                            onChange={(e) => {
                                                handleFormChange(e);
                                                setFieldValue('accountingNumber', e.target.value);
                                            }}
                                        ></Field>
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                        <div className='d-flex'>
                                            <p className="text-danger">*</p><label className='fw-bold fs-5 mb-2 w-50'>Begin Date</label>
                                        </div>
                                        <Field
                                            type='date'
                                            name='beginDate'
                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                            required
                                            onChange={(e) => {
                                                handleFormChange(e);
                                                setFieldValue('beginDate', e.target.value);
                                                const endDate = values.endDate;
                                                setAddServiceBool(e.target.value !== '' && endDate !== '');
                                            }}
                                        />
                                        <ErrorMessage name="beginDate" component="div" className='text-danger' />
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                        <div className='d-flex'>
                                            <p className="text-danger">*</p><label className='fw-bold fs-5 mb-2 w-50'>End Date</label>
                                        </div>
                                        <Field
                                            type='date'
                                            name='endDate'
                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                            required
                                            onChange={(e) => {
                                                handleFormChange(e);
                                                setFieldValue('endDate', e.target.value);
                                                const beginDate = values.beginDate;
                                                setAddServiceBool(e.target.value !== '' && beginDate !== '' || formValues.endDate);
                                            }}
                                        />
                                        <ErrorMessage name="endDate" component="div" className='text-danger' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card mb-5 col-mb-10' id='kt_content'>
                            <div id='kt_content_container' className='container-xxl'></div>
                            <div className='my-6'>
                                <h3 className='my-6 text-primary mx-10'>Service Coordinator</h3>
                                <div className='row mx-7'>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                        <label className=' fw-bold fs-6 mb-2'>First Name</label>
                                        <Field
                                            type='text'
                                            name='servfirstName'
                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                            placeholder='Enter First Name Here...'
                                            autoComplete='off'
                                            onChange={(e) => {
                                                handleFormChange(e);
                                                setFieldValue('servfirstName', e.target.value);
                                            }}
                                        ></Field>
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                        <label className=' fw-bold fs-6 mb-2'>Last Name</label>
                                        <Field
                                            type='text'
                                            name='servlastName'
                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                            placeholder='Enter Last Name Here...'
                                            autoComplete='off'
                                            onChange={(e) => {
                                                handleFormChange(e);
                                                setFieldValue('servlastName', e.target.value);
                                            }}
                                        ></Field>
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                        <label className=' fw-bold fs-6 mb-2'>Organization/Agency</label>
                                        <Field
                                            type='text'
                                            name='organizationAgency'
                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                            placeholder='Enter Organization/Agency Here...'
                                            autoComplete='off'
                                            onChange={(e) => {
                                                handleFormChange(e);
                                                setFieldValue('organizationAgency', e.target.value);
                                            }}
                                        ></Field>
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                        <label className=' fw-bold fs-6 mb-2'>Service Coordinator Number</label>
                                        <Field
                                            type='text'
                                            name='serviceCoordinatorNumber'
                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                            placeholder='Enter Service Coordinator Number Here...'
                                            autoComplete='off'
                                            onChange={(e) => {
                                                handleFormChange(e);
                                                setFieldValue('serviceCoordinatorNumber', e.target.value);
                                            }}
                                        ></Field>
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                        <label className=' fw-bold fs-6 mb-2'>Phone Number</label>
                                        <Field
                                            type='number'
                                            name='phoneNumber'
                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                            placeholder='Enter Phone Number Here...'
                                            autoComplete='off'
                                            onChange={(e) => {
                                                handleFormChange(e);
                                                setFieldValue('phoneNumber', e.target.value);
                                            }}
                                        ></Field>
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                                        <div className="w-50">
                                            <label className=' fw-bold fs-6 mb-2'>Extension</label>
                                            <Field
                                                type='number'
                                                name='extension'
                                                className='form-control form-control-solid mb-3 mb-lg-0'
                                                placeholder='Enter Extension Here...'
                                                autoComplete='off'
                                                onChange={(e) => {
                                                    handleFormChange(e);
                                                    setFieldValue('extension', e.target.value);
                                                }}
                                            ></Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='card mb-5 col-mb-10' id='kt_content'>
                            <div id='kt_content_container' className='container-xxl'></div>
                            <div className='my-6'>
                                <h3 className='my-6 text-primary mx-10'>Service</h3>
                                <div className='table-responsive mx-7'>
                                    <table
                                        className='table table-light table-rounded table-striped border border-2 table-hover fs-6 gy-5'
                                        id='kt_sc_table'
                                    >
                                        <thead>
                                            <tr className='text-start table-dark text-gray-400 table-rounded fw-bolder fs-7 text-uppercase gs-0'>
                                                <th className='px-3 min-w-100px'>Actions</th>
                                                <th className='min-w-100px'>Service Code</th>
                                                <th className='min-w-100px'>Service Description</th>
                                                <th className='min-w-100px'>Unit of Measure</th>
                                                <th className='min-w-100px'>Unit Rate ($)</th>
                                                <th className='min-w-100px'>Number of Units</th>
                                                <th className='min-w-100px'>Remaining Units</th>
                                                <th className='min-w-50px'>ICD-10 Code</th>
                                                <th className='min-w-100px'>Data Collection Method</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {
                                                desCodes?.map((des, index) => (
                                                    <EditSAuthRow des={des} key={index} deleteDesc={deleteDesc} editAuth={editAuth} />
                                                ))
                                            } */}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex justify-content-center mx-7 rounded-bottom py-3 bg-secondary">
                                    <button className="btn btn-success btn-sm"
                                        onClick={() => {
                                            setAddServiceBool1(true)
                                            handleOpen()
                                        }}
                                        // data-bs-toggle="modal" data-bs-target="#kt_modal_add_service"
                                        disabled={!addServiceBool || desCodes.length > 0}
                                    >
                                        Add New Service
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='card mb-5 col-mb-10' id='kt_content'>
                            <div id='kt_content_container' className='container-xxl'></div>
                            <div className='my-6'>
                                <h3 className='my-6 text-primary mx-10'>Comments</h3>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3 mx-7'>
                                    <div className="d-flex justify-content-center">
                                        <div className='w-75'>
                                            <label className=' fw-bold fs-6 mb-2'>Comments</label>
                                            <Field
                                                as='textarea'
                                                name='comments'
                                                autoComplete='off'
                                                className='form-control form-control-lg form-control-solid'
                                                rows='10'
                                                placeholder='Place Comments here'
                                                onChange={(e) => {
                                                    handleFormChange(e);
                                                    setFieldValue('comments', e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-6'>
                                <div className="d-flex justify-content-center rounded-bottom py-3 bg-secondary">
                                    <div className="mx-2">
                                        <button className="btn btn-white btn-sm" onClick={() => {
                                            resetForm()
                                            setShowForm(false)
                                        }}>Cancel</button>
                                    </div>
                                    <div className="mx-2">
                                        <button className={statusBool === "Approved" ? "btn btn-danger btn-sm" : "btn btn-primary btn-sm"}
                                            onClick={() => {
                                                setStatusBool(prevState => (
                                                    prevState === "Approved" ? "Discontinue" : "Approved"
                                                ))
                                            }}>{statusBool === "Approved" ? "Discontinue" : "Approved"}</button>
                                    </div>
                                    <div className="mx-2">
                                        <button className="btn btn-success btn-sm" type='submit' disabled={isSubmitting} onClick={() => {
                                            setSubmitBool(true)
                                        }}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )
                }
            </Formik >
        </>
    )
}

export default Edit