import { Route, Switch} from 'react-router-dom'
import SharedContactsSubSection from '../../../../../pages/admin/care/subMenuSection/SharedContacts/SharedContactsSubSection'
import React from 'react'

const SubMenuIndexRoutes: React.FC = () => {
  return (
    <Switch>
      {/* <Route path='/admin/care/sub/addressList/' component={AddressList} />*/}
      {/* <Route path='/admin/care/sub/sharedContact/' component={SharedContactsSubSection} /> */}
      {/* <Route path='/admin/care/sub/guardianList' component={GuardianList} />  */}
    </Switch>
  )
}

export default SubMenuIndexRoutes
