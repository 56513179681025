import React, {useEffect} from 'react'
import {GoogleMap, Marker} from '@react-google-maps/api'
import {toast} from 'react-toastify'

const containerStyle = {
  width: '90%',
  height: '400px',
}

const GoogleMaps = ({
  center,
  markerPosition,
}) => {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  }

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center)
    map.fitBounds(bounds)

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const locationSuccessCallBack = (position) => {
    // const tempCenter = {
    //   lat: position.coords.latitude,
    //   lng: position.coords.longitude,
    // }
  }

  const locationFailureCallBack = () => {
    toast.error('could not get users location')
  }

  const getUserLocation = async () => {
    const location = await navigator.geolocation.getCurrentPosition(
      locationSuccessCallBack,
      locationFailureCallBack,
      options
    )

    return location
  }
  useEffect(() => {
    getUserLocation()
  }, [])

  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={20}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{streetViewControl:false, mapTypeControlOptions:{mapTypeIds:['ROADMAP']}}}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <Marker position={markerPosition}></Marker>
      </GoogleMap>
    </>
  ) 
}

export default GoogleMaps

// moon loader in table
// view icons
