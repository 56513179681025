export const ATTENDANCE_FIELDS_INPUTS = [
    {
        label: "Client",
        name: "client",
        type: "select",
        options: [],
        required: false,
        rowNumber: 1
    },
    {
        label: "Attendance Type",
        name: "attendanceType",
        type: "multiSelect",
        options: [],
        required: true,
        rowNumber: 1
    },
    {
        label: "Service Description Code",
        name: "serviceDescription",
        type: "multiSelect",
        options: [],
        required: true,
        rowNumber: 2
    },
    {
        label: "Program",
        name: "programName",
        type: "select",
        options: [],
        required: false,
        rowNumber: 2
    },
    {
        label: "Start Date",
        name: "startDate",
        type: "date",
        required: true,
        rowNumber: 3
    },
    {
        label: "End Date",
        name: "endDate",
        type: "date",
        required: true,
        rowNumber: 3
    },
]

export const ATTENDANCE_OUTPUT_FIELD_INPUT = [
    {
        label: "Output Columns",
        name: "output",
        type: "multiSelect",
        options: [],
        required: false,
        rowNumber: 4
    },
]

export const NEW_ATTENDANCE_TYPE_DATA = {
    output: [],
    outputNames: [],
    programName: '',
    serviceDescription: [],
    attendanceType: [],
    startDate: '',
    endDate: '',
    attFormID: '',
    status: '',
    client: '',
    authID: '',
    durationHours: '',
    durationMinutes: '',
    billableSlot: '',
    serviceAuthStatus: '',
    enteredBy: '',
}