import {TextField} from '@mui/material'

const PhoneNumber = ({field, handleInputChange, formData, disabled}) => {
  return (
    <TextField
      sx={field.sx || {}}
      defaultValue={field.defaultValue ? field.defaultValue : undefined}
      label={field.label}
      variant='outlined'
      type='text'
      size='small'
      name={field.name}
      value={field.value ? field.value : formData[field.name] || ''}
      disabled={field?.disabled || disabled}
      onChange={(e) => {
        let phoneNumber = e?.target?.value?.replace(/[^0-9]/g, '')

        if (phoneNumber.length > 0 && (phoneNumber[0] === '0' || phoneNumber[0] === '1')) {
          phoneNumber = ''
        }

        if (phoneNumber.length <= 10) {
          handleInputChange(e.target.name, phoneNumber, '', e.target.type)
        }
      }}
      required={field.required || false}
      className='textField'
      error={Boolean(formData[field.name] && formData[field.name].toString().length !== 10)}
      helperText={
        formData[field.name] && formData[field.name].toString().length !== 10
          ? 'Please enter a valid phone number in the format XXXXXXXXXX'
          : ''
      }
    />
  )
}

export default PhoneNumber
