import React from 'react'
import ProfessionalClaimHeader from '../ProfessionalClaimHeader'

function ServiceAuthorizationHeader() {

  return (
    <div>
      <ProfessionalClaimHeader />
    </div>
  )
}

export default ServiceAuthorizationHeader