import { useEffect, useState } from 'react'
import axios from 'axios'
import '../../../../../../_helper/tableHeaderSticky/tableHeaderSticky.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../setup'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import TableExportToXlsxButton from '../../../../../reusable-components/tables/TableExportToXlsxButton'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import {
    INDIVIDUAL_CONTACT_LIST_COLUMNS_DATA_MAPPING,
    INDIVIDUAL_CONTACT_LIST_COLUMNS,
    INDIVIDUAL_CONTACT_LIST_SORTING_COLUMNS,
    INDIVIDUAL_LIST_EXPORT_TO_XLSX_BUTTON_HEADERS,
    INDIVIDUAL_LIST_DETAIL_EXPORT_TO_XLSX_BUTTON_HEADERS,
} from '../../care_table_constants'
import { permissions } from '../../../../../../_constants/permissions'
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper'
import AddButton from '../../../../../reusable-components/Buttons/AddButton'
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes'

const IndividualList = () => {
    const history = useHistory()
    const [clientsListing, setClientsListing] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [deletedId, setDeletedId] = useState('')
    const [queryData, setQueryData] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: '_id',
        sortDir: 'desc',
        searchTerm: ''
    })

    const authUser = useSelector((state: RootState) => state.auth)
    const userPermissions = authUser.user.roleId

    useEffect(() => {
        const fetchClients = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
            try {
                const response = await axios.get(`/clients/get?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${queryData.searchTerm}`);
                const { data, currentPage, totalCounts } = response.data;
                setClientsListing(data);
                setFilteredData(data);
                setQueryData((prevState) => {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    };
                });
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };
    
        fetchClients(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir);
    
    }, [deletedId, queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm]);
    // Function to handle deletion of a row by its ID
    const onDelete = async (row: any) => {
        try {
            const response = await axios.delete(`clients/delete`, {
                data: {
                    deleteID: row._id,
                    imageID: row.image
                },
            })
            if (response.status === 200) {
                toast.success('Client deleted successfully!') // Display success toast
                setDeletedId(row._id)
            }
        } catch (error) {
            console.error('Error deleting data:', error)
            toast.error('Error deleting client') // Display error toast
        }
    }

    const onUpdate = ({ _id }: { _id: string }) => {
        history.push(`/admin/care/individual-intake/list/${_id}`)
    }

    const onClickAddButton = () => {
        history.push('/admin/care/individual-intake/new')
    }

    const onClickSearchButton = () => {
        history.push('/admin/care/individual-intake/search')
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <TableTitle title='Client Intake List'></TableTitle>
                <TableWrapper
                    searchField={
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={searchTerm}
                            setQueryData={setQueryData}
                            setSearchTerm={setSearchTerm}
                            margin='normal'
                            inputAdornmentPosition='start'
                        />
                    }
                    tableButtons={
                        <>
                            {!!hasPermission(userPermissions, permissions.admin_care_create) && (
                                <AddButton
                                    onClick={onClickAddButton}
                                />
                            )}
                            {/* {!!hasPermission(userPermissions, permissions.admin_care_search) && (
                                <TableButton
                                    style={{ marginRight: '4px' }}
                                    variant={'contained'}
                                    color={'primary'}
                                    title='Advanced Search'
                                    onClick={onClickSearchButton}
                                />
                            )} */}
                        </>
                    }
                    dynamicTable={
                        <>
                            <DynamicTable
                                columns={INDIVIDUAL_CONTACT_LIST_COLUMNS}
                                data={filteredData}
                                canUpdate={!!hasPermission(userPermissions, permissions.admin_care_update)}
                                canDelete={!!hasPermission(userPermissions, permissions.admin_care_delete)}
                                columnDataMapping={INDIVIDUAL_CONTACT_LIST_COLUMNS_DATA_MAPPING}
                                onDelete={onDelete}
                                onUpdate={onUpdate}
                                sortingColumns={INDIVIDUAL_CONTACT_LIST_SORTING_COLUMNS}
                                title='Are you sure you want to delete this client?'
                                setQueryData={setQueryData}
                                queryData={queryData}
                            />
                            
                            
                        </>
                    }
                />
                <div className='d-flex gap-2'>
                  {!!hasPermission(userPermissions, permissions.admin_care_export) && (
                    <TableExportToXlsxButton
                      headers={INDIVIDUAL_LIST_EXPORT_TO_XLSX_BUTTON_HEADERS}
                      exportData={filteredData}
                      fileName='Clients_Details'
                      title='Export to XLSX'
                    ></TableExportToXlsxButton>
                  )}
                  {!!hasPermission(userPermissions, permissions.admin_care_export) && (
                    <TableExportToXlsxButton
                      headers={INDIVIDUAL_LIST_DETAIL_EXPORT_TO_XLSX_BUTTON_HEADERS}
                      exportData={filteredData}
                      fileName='Clients_Full_Details'
                      title='Detailed Export to XLSX'
                    ></TableExportToXlsxButton>
                  )}
                </div>

            </div>
        </div>
    )
}

export default IndividualList
