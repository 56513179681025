export const randomID = (param) => {
    // let length = 15
    // if(param !== undefined){
    //     length = param
    // }
    // var result           = '';
    // var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    // var charactersLength = characters.length;
    // for ( var i = 0; i < length; i++ ) {
    //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
    // }
    const result  = Date.now() + Math.round(Math.random() * 1e14)
    return result;
}