import InsuranceHeader from './InsuranceHeader'

function InsuranceIndex() {
    return (
        <div>
            <InsuranceHeader />
        </div>
    )
}

export default InsuranceIndex