import Modal from '@mui/material/Modal'
import {Card, CardContent, Typography, Box} from '@mui/material'
import DynamicForm from '../forms/DynamicForm'
import {makeStyles} from '@mui/styles'
import '../styles/index.css'

const useStyles = makeStyles({
  card: {
    maxWidth: '90%',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  content: {
    padding: '0rem',
  },
})

const DynamicAddModal = ({
  title,
  showAddScheduleModal,
  formInputs,
  handleSubmitForm,
  buttons,
  data,
}) => {
  const classes = useStyles()
  return (
    <Modal
      open={showAddScheduleModal}
      aria-labelledby='add-modal-title'
      aria-describedby='add-modal-description'
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '40vh',
        }}
      >
        <div
          style={{
            height: 180,
            textAlign: 'center',
            borderRadius: '6px',
            minWidth: '40rem',
          }}
        >
          <Card className={classes.card}>
            <CardContent className={classes.content}>
              <Typography
                variant='h3'
                sx={{paddingTop: '1rem', paddingBottom: '1rem'}}
                fontSize={20}
                fontWeight={'bold'}
              >
                {title}
              </Typography>

              <Box>
                <DynamicForm
                  fields={formInputs}
                  data={data}
                  onSubmit={handleSubmitForm}
                  buttons={buttons}
                  typographySx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginTop: '2rem',
                    marginBottom: '0.5rem',
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </div>
      </div>
    </Modal>
  )
}
export default DynamicAddModal
