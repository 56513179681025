import ServiceAuthorizationHeader from './ServiceAuthorizationHeader'

function ServiceAuthorizationIndex() {
  return (
    <div>
      <ServiceAuthorizationHeader/>
    </div>
  )
}

export default ServiceAuthorizationIndex