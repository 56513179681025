import {
  Avatar,
  Badge,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import {makeStyles} from '@mui/styles'
import {Fragment, useEffect, useRef, useState} from 'react'
import {Logout, PersonAdd, Settings} from '@mui/icons-material'
import axios from 'axios'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {toast} from 'react-toastify'
import io from 'socket.io-client'
import {useHistory} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  badge: {
    fontSize: 30,
    fontWeight: 'bold',
  },
}))

const selectAuth = (state: RootState) => state.auth

export default function NotificationsIcon() {
  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [socket, setSocket] = useState(null)
  const [open, setOpen] = useState(false)
  const [notificationList, setNotificationList] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const auth = useSelector(selectAuth)

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      setPageNo(1)
      return
    }
    setPageNo(1)
    setOpen(false)
  }

  const trimText = (text) => {
    if (text.length <= 240) {
      return text
    } else {
      return text.slice(0, 240) + '...'
    }
  }

  const getTimeFromTimeStamp = (timestamp) => {
    const date = new Date(timestamp);
    let hour = date.getHours();
    const minute = date.getMinutes();
    const period = hour >= 12 ? "PM" : "AM";
    if (hour > 12) {
      hour -= 12;
    } else if (hour === 0) {
      hour = 12;
    }
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
    const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`;
    const formattedTime = `${formattedHour}:${formattedMinute} ${period}`;
    return formattedTime;
  };

  const getTimeAndDateString = (date) => {
    const dateToRender = new Date(date)

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    return `${
      dateToRender.toDateString() == new Date().toDateString()
        ? 'Today'
        : (dateToRender.getDate() < 10 ? '0' + dateToRender.getDate() : dateToRender.getDate()) +
          ' ' +
          monthNames[dateToRender.getMonth()]
    } at ${getTimeFromTimeStamp(dateToRender)}`
  }

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`notification/${auth?.user._id}?page=${pageNo}`)
        .then((res) => {
          setNotificationList(res.data)
        })
        .catch((err) => toast.error(err.response.data.error))
    }
    fetchData()
  }, [open])

  useEffect(() => {
    let socketInstance = null
    if (process.env.NODE_ENV === 'development') {
      socketInstance = io(`${process.env.REACT_APP_DEV_API_URL}`, {
        query: {
          userId: auth?.user._id,
          providerCode: localStorage.getItem('providerCode')
        },
        path: '/api/notificationSockets',
      })
    } else {
      socketInstance = io(`${process.env.REACT_APP_API_URL}`, {
        query: {
          userId: auth?.user._id,
          providerCode: localStorage.getItem('providerCode')
        },
        path: '/api/notificationSockets',
      })
    }

    setSocket(() => socketInstance)
    return () => {
      // Clean up the socket connection when the component unmounts
      // if (socketInstance) {
      //   socketInstance.disconnect()
      // }
    }
  }, [])

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        console.log('notification socket connected')
      })

      socket.on('newNotification', (notification) => {
        toast.info(
          <div>
            <strong style={{color: 'blue'}}>{notification.title}</strong>
            <br />
            {notification.description}
          </div>,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 7000,
            draggable: true,
          }
        )
      })

      socket.on('disconnect', () => {
        console.log('notification socket disconnected')
      })
    }
  }, [socket])

  const onClickLoadMore = async () => {
    let page = pageNo + 1
    await axios
      .get(`notification/${auth?.user._id}?page=${page}`)
      .then((res) => {
        let newNotificationList = notificationList
        res.data.map((elem) => {
          newNotificationList.push(elem)
        })
        setNotificationList(newNotificationList)
        setPageNo(page)
      })
      .catch((err) => toast.error(err.response.data.error))
  }

  const onClickMenuItem = (moveableToOtherPage, clickURL) => {
    if (moveableToOtherPage) {
      history.push(clickURL)
      setOpen(false)
    }
  }

  return (
    <div style={{height: '4rem', width: '4rem', marginRight: '10px'}}>
      {/* <div> */}
      <IconButton onClick={(e) => handleToggle()} ref={anchorRef}>
        {/* <Badge
          color='primary'
          //   sx={{
          //     color: '#008080',
          //     typography: {
          //       fontSize: '1rem',
          //       fontWeight: 'bold',
          //     },
          //   }}
          classes={{badge: classes.badge}}
          badgeContent={100}
        > */}
        <CircleNotificationsIcon sx={{height: '3rem', width: '3rem'}} />

        {/* </Badge> */}
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='top-start'
        transition
        disablePortal
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'top right',
            }}
          >
            <Paper
              sx={{
                backgroundColor: '#2F3346',
                color: 'white',
                marginRight: '3rem',
                width: '30rem',
                height: '44rem',
                overflow: 'auto',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='composition-menu'
                  aria-labelledby='composition-button'
                  onKeyDown={handleListKeyDown}
                >
                  {notificationList?.map((eachNotification) => {
                    return (
                      <>
                        <Tooltip title={eachNotification?.description || ''} placement='bottom'>
                          <ListItem
                            onClick={() => {
                              onClickMenuItem(
                                eachNotification?.moveableToOtherPage || false,
                                eachNotification?.clickURL || ''
                              )
                            }}
                            alignItems='flex-start'
                          >
                            <ListItemAvatar>
                              <Avatar alt='Avatar' src='/static/images/avatar/1.jpg' />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography sx={{color: 'white'}} variant='h6'>
                                  {eachNotification?.title || ''}
                                </Typography>
                              }
                              secondary={
                                <Fragment>
                                  <Typography
                                    sx={{display: 'inline', color: 'white'}}
                                    component='span'
                                    variant='subtitle1'
                                  >
                                    {trimText(eachNotification?.description || '')}
                                  </Typography>
                                </Fragment>
                              }
                            />
                          </ListItem>
                        </Tooltip>
                        <ListItem>
                          <Typography sx={{color: 'white'}} variant='subtitle2'>
                            {getTimeAndDateString(new Date(eachNotification?.dateOfSending))}
                          </Typography>
                        </ListItem>
                        <Divider variant='middle' sx={{backgroundColor: 'white'}} />
                      </>
                    )
                  })}
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button onClick={() => onClickLoadMore()}>Load More</Button>
                  </div>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
