import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import SharedContactNew from '../../../../../pages/admin/general/shared-contact/new/SharedContactNew'
import SharedContactList from '../../../../../pages/admin/general/shared-contact/list/List'
import NewType from '../../../../../pages/admin/general/shared-contact/newType/List'
import SCList from '../../../../../pages/admin/general/shared-contact/info/List'
import SCClientList from '../../../../../pages/admin/general/shared-contact/info/Edit'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { RouteWithPermission } from '../../../../../../_helper/routesWithPermission'
import SharedContactEditForm from '../../../../../pages/admin/general/shared-contact/list/SharedContactEditForm'
import SharedContactTypeEditForm from  '../../../../../pages/admin/general/shared-contact/newType/SharedContactTypeEditForm'
import { Error404 } from '../../../../../modules/errors/components/Error404'
import NewTypeForm from '../../../../../pages/admin/general/shared-contact/newType/New'

const SharedContactIndexRoutes: React.FC = () => {
    const auth = useSelector((state: { auth: any }) => state.auth);
    const userPermissions = auth.user.roleId;
    const canUpdate = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.UPDATE');
    const canCreate = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.CREATE');
    const canRead = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.VIEW');
    const canUpdateTypeList = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.TYPE.VIEW');
    const canUpdateType = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.TYPE.CREATE');
    const canEditType=hasPermission(userPermissions,'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.TYPE.UPDATE');
    const canCreateInfo = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.SHARED_CONTACT_LIST.VIEW');
    const canUpdateInfoList = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.SHARED_CONTACT.SHARED_CONTACT_LIST.UPDATE');
    
    const getDefaultPath = () => {
        if (canCreate) return '/admin/general/shared-contact/new'
        if (canUpdate) return '/admin/general/shared-contact/list/:id'
        if (canRead) return '/admin/general/shared-contact/list'
        if (canUpdateType) return '/admin/general/shared-contact/type/new'
        if (canEditType) return '/admin/general/shared-contact/type/list/:id'
        if (canUpdateTypeList) return '/admin/general/shared-contact/type/list'
        if (canUpdateInfoList) return '/admin/general/shared-contact/info/:id'
        if (canCreateInfo) return '/admin/general/shared-contact/info/'
        return '/error/404'
      }

    return (
        <Switch>
      <Route exact path='/admin/general/shared-contact'>
        <Redirect to={getDefaultPath()} />
      </Route>
            <RouteWithPermission path='/admin/general/shared-contact/new' component={SharedContactNew} hasPerm={canCreate} />
            <RouteWithPermission path='/admin/general/shared-contact/list/:id' component={SharedContactEditForm} hasPerm={canUpdate} />
            <RouteWithPermission path='/admin/general/shared-contact/list' component={SharedContactList} hasPerm={canRead} />
            <RouteWithPermission path='/admin/general/shared-contact/type/new' component={NewTypeForm} hasPerm={canUpdateType} />
            <RouteWithPermission path='/admin/general/shared-contact/type/list/:id' component={SharedContactTypeEditForm} hasPerm={canEditType} />
            <RouteWithPermission path='/admin/general/shared-contact/type/list' component={NewType} hasPerm={canUpdateTypeList} />
            <RouteWithPermission path='/admin/general/shared-contact/info/:id' component={SCClientList} hasPerm={canUpdateInfoList} />
            <RouteWithPermission path='/admin/general/shared-contact/info/' component={SCList} hasPerm={canCreateInfo} />
            <Route path='/error/404' component={Error404} />
        </Switch>
    )
}

export default SharedContactIndexRoutes
