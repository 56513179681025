import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { TextField, Button, Box } from '@mui/material';
import { toast } from 'react-toastify';
import { createPassword, tokenValidation } from '../redux/AuthCRUD';

export function CreatePassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const history = useHistory()
 
  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await tokenValidation(token)
        if (!response.data.success) {
          history.push('/auth/login');
        }
      } catch (err) {
        console.log('Token validation error:', err);
        history.push('/auth/login');
      }
    };

    validateToken();
  }, [token, history]);
 
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (confirmPassword?.length && password !== confirmPassword) {
        toast.error('Password Mismatch');
        return;
    }
    
    try {
      const response = await createPassword(token, password, confirmPassword);
      if(response.status === 201 && response.data && response.data.success === true) {
        setPassword('');
        setConfirmPassword('');
        toast.success('Password Changed Successfully');
        history.push('/auth/login')
      } else {
        toast.error('Something went wrong. Please try again later.');
      }
      
    } catch (error) {
        console.error('Error:', error);
        if (error.response) {
            console.error('Error data:', error.response.data);
            toast.error(`Error: ${error.response.data.message || 'Server error'}`);
        } else if (error.request) {
            console.error('Error request:', error.request);
            toast.error('No response from server. Please try again later.');
        } else {
            console.error('Error message:', error.message);
            toast.error('Error in request setup. Please try again later.');
        }
    }
};

  return (
    <>
      <div className='d-flex justify-content-center'>
        <div className='card col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xm-12 col-12'>
          <div className='card-body'>
            <h1 className='mb-4'>Change Password</h1>
            <p className='col-xl-12 mb-5'>
              Your password must be at least 6 characters and should include a combination of
              numbers, letters, and special characters (!$@%).
            </p>
            <div style={{ width: '100%' }}>
              <form style={{ width: 'inherit' }} onSubmit={handleSubmit}>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xm-12 col-12'>
                  <TextField
                    label='New Password'
                    type='password'
                    size='small'
                    className='textField'
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xm-12 col-12'>
                  <Box mt={2} />
                  <TextField
                    label='Confirm Password'
                    type='password'
                    className='textField'
                    required
                    size='small'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <Box mt={2} />
                <div className='root'>
                  <div className='buttonContainer'>
                    <Button variant='contained' color='primary' type='submit'>
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePassword;
