import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import SharedContactHeader from './SharedContactHeader'
import SharedContactNew from './new/SharedContactNew'
import SharedContactList from './list/List'
import Link from './link/Link'
import ImportFromExcel from './import-from-excel/ImportFromExcel'
import NewType from './newType/List'
import SCList from './info/List'

function SharedContactIndex() {
    return (
        <div>
            <SharedContactHeader />
        </div>
    )
}

export default SharedContactIndex