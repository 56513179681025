import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import FundingSourceHeader from './FundingSourceHeader'
import New from './new/New'
// import Archive from './archive/Archive'
import List from './list/List'

function FundingSourceIndex() {
    return (
        <div>
            <FundingSourceHeader />
        </div>
    )
}

export default FundingSourceIndex