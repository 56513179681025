import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import AttendanceTypeHeader from './AttendanceTypeHeader'
import New from './new/New'
import List from './list/List.tsx'

function AttendanceTypeIndex() {
    return (
        <div>
            <AttendanceTypeHeader />
        </div>
    )
}

export default AttendanceTypeIndex