export const INDIVIDUAL_MEDICATION_COLUMNS = [
    'Client Name',
    'Prescribed On',
    'Discontinued On',
    'Dosage Information',
    'Side Effects',
]

export const INDIVIDUAL_MEDICATION_DISCONTINUED_COLUMNS = [
    'Client Name',
    'Prescribed On',
    'Discontinued On',
    'Dosage Information',
    'Side Effects',
]



export const INDIVIDUAL_MEDICATION_DATA_MAPPING = {
    'Client Name': 'clientFullName',
    'Prescribed On': 'prescribedOn',
    'Discontinued On': 'discontinuedOn',
    'Dosage Information': 'dosageInformation',
    'Side Effects': 'sideEffects',
}

export const INDIVIDUAL_MEDICATION_DISCONTINUED_DATA_MAPPING = {
    'Client Name': 'clientFullName',
    'Prescribed On': 'prescribedOn',
    'Dosage Information': 'dosageInformation',
    'Side Effects': 'sideEffects',
}

export const MISSED_MEDICATION_DISCONTINUED_DATA_MAPPING = {
    'Medication Name': 'medicationName',
    'Date': 'date',
    'Prescribed Time': 'timing',
    'Prescribed Dosage': 'dosage',
}
export const MISSED_MEDICATION_COLUMNS = [
    'Medication Name',
    'Prescribed On',
    'Prescribed Time',
    'Prescribed Dosage',
    'Side Effects'
]
export const MISSED_MEDICATION_DATA_MAPPING = {
    'Medication Name': 'medicationName',
    'Prescribed On': 'date',
    'Prescribed Time': 'timing',
    'Prescribed Dosage': 'dosage',
    'Side Effects': 'sideEffects',
}
export const MISSED_MEDICATION_SORTING_COLUMNS =  {
    'Medication Name': 'medicationName',
    'Prescribed On': 'date',
    'Prescribed Time': 'timing',
    'Prescribed Dosage': 'dosage',
    'Side Effects': 'sideEffects',
}


export const INDIVIDUAL_MEDICATION_SORTING_COLUMNS =  {
    'Client Name': 'clientFullName',
    'Prescribed On': 'prescribedOn',
    'Discontinued On': 'discontinuedOn',
    'Dosage Information': 'dosageInformation',
    'Side Effects': 'sideEffects',
}