import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import NewTemplateForm from './new-template/NewTemplateForm'
import NewProfessionalClaimForm from './new/NewProfessionalClaimForm'
import SearchProfessionalClaim from './search/SearchProfessionalClaim'
// import Search from './search/Search'
// import New from './new/New'
// import Archive from './archive/Archive'
// import NewTemplate from './new-template/NewTemplate'
import ProfessionalClaimHeader from './ProfessionalClaimHeader'
import ListProfessionClaim from './list/ListProfessionClaim'

function ProfessionalClaimIndex() {
    return (
        <div>
            <ProfessionalClaimHeader />
        </div>
    )
}

export default ProfessionalClaimIndex