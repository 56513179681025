import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { RouteWithPermission } from '../../../../_helper/routesWithPermission'
import RolesListing from '../../../pages/roles/RolesListing'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../_helper/hasPermission'
import { permissions } from '../../../../_constants/permissions'
import Roles from '../../../pages/roles/Roles'
import { Error404 } from '../../../modules/errors/components/Error404'

const RolesRoutes = () => {
    const auth = useSelector((state : { auth: any })=> state.auth)
    const userPermissions = auth.user.roleId;
    const canView = hasPermission(userPermissions, permissions.userManagement_roles)
    const canCreate = hasPermission(userPermissions, permissions.userManagement_roles_create)
    const canUpdate = hasPermission(userPermissions, permissions.userManagement_roles_update)

  return (
    <Switch>
        <RouteWithPermission path='/roles/:id' component={Roles} hasPerm={(canView && canUpdate)}/>
        <RouteWithPermission path='/roles/new' component={Roles} hasPerm={(canView && canCreate)}/>
        <RouteWithPermission path='/roles' component={RolesListing} hasPerm={canView}/>
        <Route path='error/404' component={Error404} />
    </Switch>
  )
}

export default RolesRoutes