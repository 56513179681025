import * as React from 'react'
import Button from '@mui/material/Button'
import {styled} from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { TextField} from '@mui/material'

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export default function AddBehaviourDialog({handleClose, label,title,behaviour,setBehaviour,handleSubmit}) {
  const [error, setError] = React.useState('')

  const handleSubmitLocal = () => {
    if (!behaviour.length) {
      setError('Behavior is Required')
      return
    }
    handleSubmit()
  }

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={true}>
      <DialogTitle sx={{m: 0, p: 2}} id='customized-dialog-title'>
        {title}
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <TextField
          label={label}
          variant='outlined'
          type='text'
          sx={{width:"400px"}}
          size='medium'
          name='behaviour'
          value={behaviour}
          onChange={({target: {value}}) => {
            if (error.length) {
              setError('')
            }
            setBehaviour(value)
          }}
          required
          className='textField'
          error={error.length}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmitLocal}>Save</Button>
      </DialogActions>
    </BootstrapDialog>
  )
}
