import {Redirect, Route, Switch} from 'react-router-dom'
import {RouteWithPermission} from '../../../../../_helper/routesWithPermission'
import {useSelector} from 'react-redux'
import {hasPermission} from '../../../../../_helper/hasPermission'
import {permissions} from '../../../../../_constants/permissions'
import {Error404} from '../../../../modules/errors/components/Error404'
import CaregiverSchedules from '../../../../pages/caregiver/Schedules/TeamSchedules/CaregiverSchedules'
import AllSchedules from '../../../../pages/caregiver/Schedules/AllSchedules/AllSchedules'
import MySchedules from '../../../../pages/caregiver/Schedules/MySchdeules/MySchedules'
import TimeOffs from '../../../../pages/caregiver/Schedules/TimeOffs/TimeOffs'

const SchedulesIndexRoute = () => {
  const auth = useSelector((state: {auth: any}) => state.auth)
  const userPermissions = auth.user.roleId

  const canViewMyAvailability = hasPermission(
    userPermissions,
    permissions.care_giver_schedules_myAvailability_view
  )
  const canViewSchedules = hasPermission(
    userPermissions,
    permissions.care_giver_schedules_schedules_view
  )
  const canViewTeamAvailability = hasPermission(
    userPermissions,
    permissions.care_giver_schedules_teamAvailability_view
  )
  const canViewTimeOff = hasPermission(
    userPermissions,
    permissions.care_giver_schedules_timeOff_view
  )
  const canView = hasPermission(
    userPermissions,
    permissions.care_giver_schedules_view
  )

  // Default path based on permissions
  const getDefaultPath = () => {
    if (canViewSchedules) return '/caregivers/schedules/allSchedules'
    if (canViewTeamAvailability) return '/caregivers/schedules/schedule'
    if (canViewMyAvailability) return '/caregivers/schedules/mySchedules'
    if (canViewTimeOff) return '/caregivers/schedules/timeOffs'
    // Fallback to 404 if no permissions
    return '/error/404'
  }

  return (
    <Switch>
      {/* Default redirect if no specific path is chosen */}
      <Route exact path='/caregivers/schedules/allSchedule'>
        <Redirect to={getDefaultPath()} />
      </Route>

      {/* Conditional Routes based on permissions */}
      <RouteWithPermission
        path='/caregivers/schedules/allSchedules'
        component={AllSchedules}
        hasPerm={canView && canViewSchedules}
      />
      <RouteWithPermission
        path='/caregivers/schedules/mySchedules'
        component={MySchedules}
        hasPerm={canView && canViewMyAvailability}
      />
      <RouteWithPermission
        path='/caregivers/schedules/schedule'
        component={CaregiverSchedules}
        hasPerm={canView && canViewTeamAvailability}
      />
      <RouteWithPermission
        path='/caregivers/schedules/timeOffs'
        component={TimeOffs}
        hasPerm={canView && canViewTimeOff}
      />

      {/* Fallback for 404 */}
      <Route path='/error/404' component={Error404} />
    </Switch>
  )
}

export default SchedulesIndexRoute
