import { Redirect, Route, Switch } from 'react-router-dom'
import ContactHeader from './ContactHeader'
import ContactList from './list/List'
import ContactNewTable from './new/Table'
import ContactNewForm from './new/New'
import ListContacts from './editContact/List'
import EditContacts from './editContact/Edit'
import ImportFromExcel from './import-from-excel/ImportFromExcel'

function ContactIndex() {
    return (
        <div>
            <ContactHeader />
        </div>
    )
}

export default ContactIndex