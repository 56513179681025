export const columns = ['Team Member', 'Email', 'Phone', 'Access', 'Role', 'Wage', 'Zone']
export const sortingColumns = {
  'Team Member': 'fullName',
  Email: 'email',
  Phone: 'phone',
  Access: 'accessLevel',
  Role: 'fullRole',
  Wage: 'wage',
  Zone: 'caregiverTimezone',
}
export const columnDataMapping = {
  'Team Member': 'fullName',
  Email: 'email',
  Phone: 'phone',
  Access: 'accessLevel',
  Role: 'fullRole',
  Wage: 'wage',
  Zone: 'caregiverTimezone',
}
export const htmlColumns = ['Team Member', 'Role', 'Wage']


export const exportToxlsxButtonHeaders = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Social Security Number', key: 'socialSecurityNumber' },
  { label: 'Medicaid Number', key: 'medicaidNumber' },
  { label: 'Status', key: 'status' },
  { label: 'Entered By', key: 'enteredBy' },
  { label: 'Admitted By', key: 'admittedBy' },
  { label: 'Admission Date', key: 'admissionDate' },
  { label: 'Birth Date', key: 'birthDate' },
  { label: 'Last Updated By', key: 'lastUpdatedBy' },
  { label: 'Mailing Address', key: 'mailingaddress' },
]
