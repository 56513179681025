import React, {useEffect, useState} from 'react'
import {enableMFA, verifyMFACode, isMFAEnabled, disableMFA} from '../redux/AuthCRUD'
import {TextField, Button, Box} from '@mui/material'
import {toast} from 'react-toastify'
import {QRCodeSVG} from 'qrcode.react'
import OTPInput from 'react-otp-input'
import {MoonLoader} from 'react-spinners'
import {useHistory} from 'react-router-dom'
export function MFA() {
  const [enterCode, setEnterCode] = useState(false)
  const [totp, setTotp] = useState('')
  const [url, setUrl] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()
  const [isMfaEnabled, setIsMFAEnabled] = useState(false)

  useEffect(() => {
    checkIfMFAEnabled()
  }, [])

  const checkIfMFAEnabled = async () => {
    try {
      const isMFAEnabledResponse = await isMFAEnabled()
      if (isMFAEnabledResponse?.data?.mfaEnabled) {
        setIsMFAEnabled(true)
        return
      }
      const response = await enableMFA()
      setIsMFAEnabled(false)
      setUrl(response.data.data)
      console.log(response.data.data)
    } catch (e) {
      toast.error(e?.response?.data?.error || e?.message || 'Something went wrong')
    } finally {
      setIsLoading(false)
    }
  }
  const handleSubmit = async () => {
    if (totp.length < 6) {
      toast.error('Please provide valid TOTP')
      return
    }
    try {
      setIsLoading(true)
      await verifyMFACode(totp)
      toast.success('MFA has been successfully set up and enabled as preferred')
      history.push('/dashboard')
    } catch (e) {
      toast.error(e?.response?.data?.error || e?.message || 'Something went wrong')
    } finally {
      setIsLoading(false)
    }
  }

  const disableMFAFunc = async () => {
    try {
      const isMFAEnabledResponse = await disableMFA()
      console.log(isMFAEnabledResponse, 'isMFAEnabledResponse')
      checkIfMFAEnabled()
    } catch (e) {
      toast.error(e?.response?.data?.error || e?.message || 'Something went wrong')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading ? (
        <div className='d-flex justify-content-center'>
          <MoonLoader color='#9db2fc' size={80} />
        </div>
      ) : (
        <div className='d-flex justify-content-center'>
          <div className='card  col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12 col-12'>
            <div className='card-body'>
              <h1 className='mb-4'>Multi factor Authentication(MFA)</h1>
              {isMfaEnabled ? (
                <>
                  <p className='col-xl-12 mb-5'>MFA is already enabled</p>
                  <div className='root'>
                    <div
                      className='buttonContainer'
                      style={{display: 'flex', justifyContent: 'center', gap: 10}}
                    >
                      <Button variant='contained' color='primary' onClick={() => disableMFAFunc()}>
                        Disable MFA
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p className='col-xl-12 mb-5'>Enable MFA to get extra layer of security</p>
                  {enterCode ? (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: '100%',
                          gap: 10,
                        }}
                      >
                        <label>Enter One Time Password</label>
                        <OTPInput
                          value={totp}
                          onChange={(val) => setTotp(val)}
                          numInputs={6}
                          renderInput={(props) => <input {...props} />}
                          inputStyle={{
                            width: '3rem',
                            height: '3rem',
                            margin: '0 0.5rem',
                            fontSize: '1rem',
                            borderRadius: 4,
                            border: '1px solid rgba(0,0,0,0.3)',
                          }}
                        />
                      </div>
                      <div className='root'>
                        <div
                          className='buttonContainer'
                          style={{display: 'flex', justifyContent: 'center', gap: 10}}
                        >
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={() => setEnterCode(false)}
                          >
                            Scan Again
                          </Button>
                          <Button variant='contained' color='primary' onClick={handleSubmit}>
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          paddingTop: '10px',
                          paddingBottom: '10px',
                          justifyContent: 'center',
                        }}
                      >
                        <QRCodeSVG size={200} value={url} />
                      </div>
                      <div className='root'>
                        <div
                          className='buttonContainer'
                          style={{display: 'flex', justifyContent: 'center', gap: 10}}
                        >
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={() => setEnterCode(true)}
                          >
                            Enter Code
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
