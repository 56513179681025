import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { RouteWithPermission } from '../../../../_helper/routesWithPermission'
import Users from '../../../pages/users/Users'
import AddUser from '../../../pages/users/AddUser'
import EditUser from '../../../pages/users/EditUser'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../_helper/hasPermission'
import { permissions } from '../../../../_constants/permissions'
import { Error404 } from '../../../modules/errors/components/Error404'

const UsersRoute = () => {
    const auth = useSelector((state : { auth: any })=> state.auth)
    const userPermissions = auth.user.roleId;
    const canView = hasPermission(userPermissions, permissions.user_management_user_view)
    const canCreate = hasPermission(userPermissions, permissions.user_management_user_create)
    const canUpdate = hasPermission(userPermissions, permissions.user_management_user_update)
  return (
    <Switch>
        <RouteWithPermission path='/users/new' component={AddUser} hasPerm={(canView && canCreate)} />
        <RouteWithPermission path='/users/:id' component={EditUser} hasPerm={(canView && canUpdate)} />
        <RouteWithPermission path='/users' component={Users} hasPerm={canView} />
        <Route path='error/404' component={Error404} />
    </Switch>
  )
}

export default UsersRoute