import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import React, {useEffect, useState} from 'react'
import { useHistory} from 'react-router-dom'
import {RootState} from '../../../../../setup'
import {useSelector} from 'react-redux'
import {hasPermission} from '../../../../../_helper/hasPermission'
import {permissions} from '../../../../../_constants/permissions'

const selectAuth = (state: RootState) => state.auth

const TimesheetsTabs = () => {
  const [value, setValue] = useState(0)
  const history = useHistory()
  const auth = useSelector(selectAuth)
  const path = history?.location?.pathname
  const isCaregiver = auth?.user?.isCaregiver
  const userPermissions = auth.user.roleId

  const hasTimeSheetsPermission = hasPermission(
    userPermissions,
    permissions.care_giver_time_sheets_timeSheets_view
  )
  const hasCompanySheetsPermission = hasPermission(
    userPermissions,
    permissions.care_giver_time_sheets_companySheet_view
  )
  const hasSummariesPermission = hasPermission(
    userPermissions,
    permissions.care_giver_time_sheets_summaries_view
  )
  useEffect(() => {
    const visibleTabs = [];
  
    // Populate visibleTabs based on user role and permissions
    if (isCaregiver && hasTimeSheetsPermission) {
      visibleTabs.push('timesheets');
    } else {
      if (hasSummariesPermission) visibleTabs.push('summaries');
      if (hasCompanySheetsPermission) visibleTabs.push('company-timesheets');
    }
  
    const getPathIndex = () => {
      if (path.includes('timesheets') && isCaregiver && hasTimeSheetsPermission) {
        return visibleTabs.indexOf('timesheets');
      }
      if (path.includes('timesheets-summaries') && hasSummariesPermission) {
        return visibleTabs.indexOf('summaries');
      }
      if (path.includes('company-timesheets') && hasCompanySheetsPermission) {
        return visibleTabs.indexOf('company-timesheets');
      }
      return -1; // No matching path
    };
  
    const redirectToFirstTab = () => {
      const firstTab = visibleTabs[0];
      if (firstTab === 'timesheets') {
        history.push('/caregivers/sheets/timesheets');
      } else if (firstTab === 'summaries') {
        history.push('/caregivers/sheets/timesheets-summaries');
      } else if (firstTab === 'company-timesheets') {
        history.push('/caregivers/sheets/company-timesheets');
      } else {
        history.push('/error/404');
      }
    };
  
    if (visibleTabs.length > 0) {
      const tabIndex = getPathIndex();
      if (tabIndex >= 0) {
        setValue(tabIndex); // Set the tab based on the current path
      } else {
        redirectToFirstTab(); // Redirect to the first available tab
      }
    } else {
      history.push('/error/404'); // No visible tabs, show error page
    }
  }, [path, isCaregiver, hasTimeSheetsPermission, hasSummariesPermission, hasCompanySheetsPermission, history]);
  

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-3 pb-0'>
          <div className='d-flex overflow-auto h-60px'>
            <Tabs value={value} onChange={handleChange}  sx={{ '.MuiTab-root': {
                  textTransform: 'none',
                  minWidth: 150,
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                },}}>
              {isCaregiver && hasTimeSheetsPermission && (
                <Tab label='Timesheets' onClick={() => history.push('/caregivers/sheets/timesheets')} />
              )}
              {!isCaregiver && hasSummariesPermission && (
                <Tab
                  label='Summaries'
                  onClick={() => history.push('/caregivers/sheets/timesheets-summaries')}
                />
              )}
              {!isCaregiver && hasCompanySheetsPermission && (
                <Tab
                  label='Company Timesheets'
                  onClick={() => history.push('/caregivers/sheets/company-timesheets')}
                />
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimesheetsTabs
