import React, {FC, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Container, CssBaseline, Box, Typography, Grid, useTheme, useMediaQuery} from '@mui/material'
import DynamicIcon from './DynamicIcon'
import {iconsData} from './Dashboard_contstant'
import {hasPermission} from '../../../_helper/hasPermission'
import {RootState} from '../../../setup'
const selectAuth = (state: RootState) => state.auth

const DashboardWrapper: FC = () => {
  const [filteredData, setFilteredData] = useState([])
  const auth = useSelector(selectAuth)
  const userPermissions = auth.user.roleId
  //   const classes = useStyles()

  useEffect(() => {
    const filteredIconsData = iconsData.filter((iconData) => {
      if (iconData.permission) {
        return hasPermission(userPermissions, iconData.permission)
      }
      return true
    })
    setFilteredData(filteredIconsData)
  }, [userPermissions])

  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('xs'))
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isMd = useMediaQuery(theme.breakpoints.down('md'))

  const getFontSize = () => {
    if (isXs) return '1.5rem'
    if (isSm) return '2rem'
    if (isMd) return '2.5rem'
    return '3rem'
  }
  const is720p = useMediaQuery(
    '(width: 1280px), (width: 1366px)'
  );
  const is1080p = useMediaQuery(
    '(width: 1920px)'
  );

  return (
    <Container style={{height: '80vh'}} component='main' maxWidth='lg'>
      <CssBaseline />
      <Box>
        <Grid style={{height: '120px'}}>
          <Typography
            variant='h2'
            fontWeight={600}
            mt='2rem'
            mb='2rem'
            component='div'
            align='center'
            sx={{fontSize: getFontSize()}}
          >
            Welcome To Your Dashboard
          </Typography>
        </Grid>
        <Grid
          style={{
            height: isSm?"75vh": is720p?"70vh": is1080p?"80vh": '83vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            scrollbarWidth: isSm? 'none': 'auto',
            msOverflowStyle: isSm? 'none': 'auto',
          }}
          container
          spacing={8}
        >
          {filteredData.map((data, index) => (
            <Grid  item key={index} xs={6} sm={6} md={4} lg={3} xl={3}>
              <DynamicIcon {...data} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  )
}

export {DashboardWrapper}
