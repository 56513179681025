import React from 'react'
import {Card, CardContent, Typography, Box} from '@mui/material'
import {makeStyles} from '@mui/styles'

const useStyles = makeStyles({
  card: {
    maxWidth: '100%',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  content: {
    padding: '0rem',
  },
})

const FormWrapper = ({title, content}: any) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography
          variant='h3'
          sx={{paddingTop: '1.5rem', paddingBottom: '1.5rem', paddingLeft: '1rem'}}
          fontSize={20}
          fontWeight={'bold'}
        >
          {title}
        </Typography>

        <Box>{content}</Box>
      </CardContent>
    </Card>
  )
}

export default FormWrapper
