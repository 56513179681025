import React from 'react'
import { useSelector } from 'react-redux';
import { hasPermission } from '../../../../../_helper/hasPermission';
import { permissions } from '../../../../../_constants/permissions';
import { Switch } from 'react-router-dom';
import { RouteWithPermission } from '../../../../../_helper/routesWithPermission';
import NoteEntries from '../../../../pages/caregiver/NotesEntries/NoteEntries';
import NewNoteEntries from '../../../../pages/caregiver/NotesEntries/Add/NewNoteEntries';
import EditNoteEntry from '../../../../pages/caregiver/NotesEntries/Edit/EditNoteEntry';

const NotesEntriesRoutes = () => {
    const auth = useSelector((state: { auth: any }) => state.auth);
    const userPermissions = auth.user.roleId;
    const canView = hasPermission(userPermissions, permissions.care_giver_notes_view)
    const canViewNotes = hasPermission(userPermissions, permissions.care_giver_notes_note_view)
    const canCreate = hasPermission(userPermissions, permissions.care_giver_notes_note_entries_create)
    const canUpdate = hasPermission(userPermissions, permissions.care_giver_notes_note_entries_update)

  return (
    <Switch>
        <RouteWithPermission path='/caregivers/notesEntries/new/:id' component={NewNoteEntries} hasPerm={(canView && (canCreate && canViewNotes))} />
        <RouteWithPermission path='/caregivers/notesEntries/edit/:id' component={EditNoteEntry} hasPerm={(canView && (canUpdate && canViewNotes))} />
        <RouteWithPermission path='/caregivers/notesEntries/:id' component={NoteEntries} hasPerm={(canView && canViewNotes)} />
    </Switch>
  )
}

export default NotesEntriesRoutes