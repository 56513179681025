import React, { useEffect, useRef, useState } from 'react';
import {
  IconButton,
  Paper,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  Tabs,
  Tab,
  Typography,
  Box,
  Link,
  Modal,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  badge: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  popperPaper: {
    padding: '0.8rem',
    width: '18rem',
    maxHeight: '80vh',
    overflow: 'auto',
  },
  contentBox: {
    padding: '1rem',
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    color: 'black',
    padding: '2rem',
    boxShadow: '24',
    width: '55vw',
    height: '90vh',
    overflow: 'auto',
  },
  imageStyle: {
    width: '50%',
    marginTop: '10px',
    marginBottom: '10px',
    display: 'block',
    margin: '10px auto',
  },
  heading: {
    padding:"1rem",

  },
  mainHeading: {
    fontSize: '2rem', 
    marginBottom: '1.5rem',
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
  },
  List:{
    padding:"0.5rem",
    fontSize:"1.2rem",
  },
  tab: {
    '&:hover': {
      backgroundColor: '#ffffff', // light color on hover
    },
  },

});

export default function HelperIcon() {
  const classes = useStyles();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const renderInstructions = () => {
    switch (selectedTab) {
      case 0:
        return (
          <div>
            <Typography variant="h6" className={classes.heading} style={{fontWeight:"bold",fontSize:"1.5rem"}}>Computer</Typography>
            <ol>
              <li className={classes.List}>At the top right click on the icon </li>
              <img src={require('./pic1.png').default} alt="Step 1" className={classes.imageStyle} />
              <li className={classes.List}>At the top right,click More and then Settings.</li>
              <img src={require('./pic3.png').default} alt="Step 2" className={classes.imageStyle} />
              <li className={classes.List}>Click Privacy and security  and then Site Settings.</li>
              <img src={require('./pic4.png').default} alt="Step 3" className={classes.imageStyle} />
              <li className={classes.List}>Click Location.</li>
              <img src={require('./pic5.png').default} alt="Step 4" className={classes.imageStyle} />
              <li className={classes.List}>Click on your top left location icon and Choose the option you want as your default setting.</li>
              <img src={require('./pic6.png').default} alt="Step 5" className={classes.imageStyle} />
            </ol>
          </div>
        );
      case 1:
        return (
          <div>
            <Typography variant="h6" className={classes.heading}  style={{fontWeight:"bold",fontSize:"1.5rem"}}>Android</Typography>
            <ol>
              <li className={classes.List}>On your Android phone or tablet, open the Chrome app. </li>
              <li className={classes.List}>To the right of the address bar, tap More  Settings.</li>
              <li className={classes.List}>Tap Site settings and then Location. </li>
              <li className={classes.List}>Turn Location on or off.</li>
            </ol>
          </div>
        );
      case 2:
        return (
          <div>
            <Typography variant="h6" className={classes.heading}  style={{fontWeight:"bold",fontSize:"1.5rem"}}>iPhone & iPad</Typography>
            <ol>
              <li  className={classes.List}>On your iPhone or iPad, open the settings app.</li>
              <li  className={classes.List}>Find and tap Chrome.</li>
              <li  className={classes.List}>Tap Location.</li>
              <li  className={classes.List}>Choose the option you want as your default setting.</li>
            </ol>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ height: '4rem', width: '4rem', marginRight: '10px' }}>
      <IconButton onClick={handleToggle} ref={anchorRef}>
        <HelpOutlineIcon sx={{ height: '3rem', width: '3rem' }} />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="top-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'top right',
            }}
          >
            <Paper className={classes.popperPaper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <Link
                    component="button"
                    variant="body2"
                    onClick={handleModalOpen}
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    How to enable location settings
                  </Link>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box className={classes.modalStyle}>
          <Typography variant="h5" className={classes.mainHeading}>How to enable location settings</Typography>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            centered
          >
            <Tab label="Computer" className={classes.tab}/>
            <Tab label="Android" className={classes.tab}/>
            <Tab label="iPhone & iPad" className={classes.tab}/>
          </Tabs>
          <Box className={classes.contentBox}>{renderInstructions()}</Box>
        </Box>
      </Modal>
    </div>
  );
}
