import React from 'react'
import ProfessionalClaimHeader from '../ProfessionalClaimHeader'

const BillingDataHeader = () => {
    return (
        <div>
            <ProfessionalClaimHeader />
        </div>
    )
}

export default BillingDataHeader