export const caregiverDocumentscolumns = ['Name', 'Expiry Date', 'Added By']
export const caregiverDocumentscolumnsColumnDataMapping = {
  Name: 'name',
  'Expiry Date': 'remainingTimeToExpire',
  'Added By': 'addedByFullName',
}
export const caregiverDocumentscolumnsSortingColumn = {
  Name: 'name',
  'Expiry Date': 'remainingTimeToExpire',
  'Added By': 'addedByFullName',
}

