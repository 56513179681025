import React from 'react'
import BillingDataHeader from './BillingDataHeader'
import { Route, Switch } from 'react-router-dom'
import New from './new/New'
import Search from './search/Search'

const BillingDataIndex = () => {
    return (
        <div>
            <BillingDataHeader />
        </div>
    )
}

export default BillingDataIndex