import {useParams} from 'react-router-dom'
import CareGiverProfileTabs from '../ProfileHeader'
import PersonalInformation from './PersonalInformation'

export const CaregiverProfilePersonalInformation = () => {
  const params: { id: string } = useParams()
  return (
    <>
      <CareGiverProfileTabs></CareGiverProfileTabs>
      <PersonalInformation id={params?.id}></PersonalInformation>
    </>
  )
}
