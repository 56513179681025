/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable */
import clsx from 'clsx'
import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {Link} from 'react-router-dom'
import ChatIcon from '../header/ChatIcon.png'
import ClockIcon from '../header/ClockIcon.jpg'
import ClockInModal from './TimeSheets/ClockInModal'
import {RootState} from '../../../../setup'
import {useDispatch, useSelector} from 'react-redux'
import NotificationsIcon from './Notifications/NotificationsIcon'
import HelperIcon from './HelperIcon/HelperIcon'
import TimezoneSelector from '../../../../app/reusable-components/forms/TimeZoneField'
import {setTimeZone} from '../../../../setup/redux/timeZone/reducer'
import axios from 'axios'
import {toast} from 'react-toastify'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const selectAuth = (state: RootState) => state.auth

const Topbar: FC = () => {
  const {config} = useLayout()
  const dispatch = useDispatch()
  const [openClockInModal, setOpenClockInModal] = useState(false)

  const auth = useSelector(selectAuth)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const menuRef = useRef(null)

  const handleToggleMenu = (event) => {
    event.stopPropagation()
    setMenuOpen(!isMenuOpen)
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleTimezoneChange = async (timeZone) => {
    try {
      dispatch(setTimeZone(timeZone))
      const data = {
        timezone: timeZone,
        _id: auth?.user?._id,
      }
      const response = await axios.put('/user/timezone', data)
      const {message, status, success} = response?.data
      if (success && status === 200) {
        toast.success(message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='d-flex align-items-stretch justify-content-between flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}

      {/* Activities */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
      {/* begin::Drawer toggle */}

      {/* <div
          className={clsx('btn btn-icon btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
          id='kt_activities_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen032.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div> */}

      {/* end::Drawer toggle */}
      {/* </div> */}
      {/* Quick links */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
      {/* begin::Menu wrapper */}

      {/*         
        <div
          className={clsx('btn btn-icon btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen025.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div> */}

      {/* <QuickLinks /> */}
      {/* end::Menu wrapper */}
      {/* </div> */}

      {/* CHAT */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
      {/* begin::Menu wrapper */}

      {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          id='kt_drawer_chat_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com012.svg'
            className={toolbarButtonIconSizeClass}
          />

          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div> */}

      {/* end::Menu wrapper */}
      {/* </div> */}

      {/* NOTIFICATIONS */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
      {/* begin::Menu- wrapper */}

      {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen022.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div> */}

      {/* <HeaderNotificationsMenu /> */}
      {/* end::Menu wrapper */}
      {/* </div> */}

      <div className='d-flex align-items-center'>
        <TimezoneSelector onTimezoneChange={(timeZone) => handleTimezoneChange(timeZone)} />
      </div>
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        {auth.user.isCaregiver === true ? (
          <div
            className='mx-4 btn btn-icon btn-active-light-primary btn-custom '
            onClick={() => setOpenClockInModal(true)}
          >
            <img
              src={ClockIcon}
              style={{
                height: '4rem',
                width: '4rem',
                marginRight: '10px',
              }}
              alt='Clock logo'
            />
          </div>
        ) : null}
        <ClockInModal
          title={'Time Tracker'}
          showClockInModal={openClockInModal}
          onCancel={() => setOpenClockInModal(false)}
        ></ClockInModal>
        <NotificationsIcon></NotificationsIcon>
        <HelperIcon></HelperIcon>

        <Link to={`/chat`} className='mx-4 btn btn-icon btn-active-light-primary btn-custom '>
          <img
            src={ChatIcon}
            style={{
              height: '3rem',
              width: '3rem',
              marginRight: '10px',
            }}
            alt='Chat App Logo'
          />
        </Link>
        <div
          style={{position: 'relative'}}
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          onClick={handleToggleMenu}
        >
          <img src={toAbsoluteUrl('/media/avatars/150-26.jpg')} alt='metronic' />

          {isMenuOpen && (
            <div
              ref={menuRef}
              style={{
                position: 'absolute',
                top: '100%',
                right: '0',
                zIndex: '2000',
                marginTop: '5px',
              }}
            >
              <HeaderUserMenu />
            </div>
          )}
        </div>
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/* {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )} */}
    </div>
  )
}

export {Topbar}
