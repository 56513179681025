import { useEffect, useState } from 'react'
import SearchClientLogTable from "./SearchClientLogTable"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { getIncidentReport } from "../store/action"
import FormWrapper from "../../../../../reusable-components/Wrapper/Wrapper"
import DynamicForm from "../../../../../reusable-components/forms/DynamicForm"
import { NEW_CLIENT_LOG_SEARCH, CLIENT_LOG_SEARCH } from "../../CARE_CONSTANTS"
const selectAuth = (state) => state.auth;


const SearchClientLogFields = () => {
    const [showForm, setShowForm] = useState(true)
    const [clientNames, setClientNames] = useState([]);
    const [formData, setFormData] = useState({})
    const [updatedTlog, setUpdatedTlog] = useState([])
    const [queryData, setQueryData] = useState({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1
    })
    const auth = useSelector(selectAuth);
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async (page, pageSize) => {
            const [clientsResult, client_Log_Result] = await Promise.all([
                axios.get(`/clients/get?page=${page}?pageSize=${pageSize}`),
                dispatch(getIncidentReport({page:page, pageSize:pageSize}))
            ])
            const temp_Client_Log = client_Log_Result.payload.data;
            const seen = new Set();

            let options = temp_Client_Log.data.filter((data) => {
                const duplicate = seen.has(data.clientID); seen.add(data.clientID); return !duplicate;
            })

            options = options.map((obj) => {
                let clientObj = clientsResult.data.data.find((clobj) => clobj._id === obj.clientID)
                const updatedElement = {
                    value: clientObj._id,
                    label: clientObj.firstName + ' ' + clientObj.lastName
                }
                return updatedElement
            })


            setClientNames(options);
            const constantsWithUpdatedTlog = CLIENT_LOG_SEARCH.map((field) => {
                if (field.name === 'clientID') {
                    return {
                        ...field,
                        options: options,
                    };
                }
                return field;
            });
            setUpdatedTlog(constantsWithUpdatedTlog)
        }
        fetchData(queryData.pageNumber, queryData.pageSize)
    }, [queryData.pageNumber, queryData.pageSize])

    const handleClientChange = (selectedOption) => {
        setClientNames(selectedOption.value)
    }

    const handleSubmitForm = async (formData) => {
        const tempObj = {
            type: formData.type,
            summary: formData.summary,
            description: formData.description,
            reportDateFrom: formData.reportDateFrom,
            reportDateTo: formData.reportDateTo,
            intensityLevel: formData.intensityLevel,
            clientID: formData.clientID,
        }
        setFormData(tempObj)
        setShowForm(false)
    };
    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Search', variant: 'contained', type: 'submit' }
    ];

    return (
        <FormWrapper
            title='Search Incident report'
            content={
                showForm ?
                    <DynamicForm
                        fields={updatedTlog}
                        data={NEW_CLIENT_LOG_SEARCH}
                        onSubmit={handleSubmitForm}
                        buttons={buttons}
                    />
                    :
                    <SearchClientLogTable formData={formData} setShowForm={setShowForm} />

            }
        />
    )
}

export default SearchClientLogFields