import NursesNotesHeader from "./NursesNotesHeader"
function NursesNotesIndex() {
  return (
    <div>
      <NursesNotesHeader />
    </div>
  )
}

export default NursesNotesIndex
