import { STATES } from '../../../reusable-components/Adress/STATES'
import { COUNTRIES } from '../../../reusable-components/Adress/COUNTRIES'
import * as validator from '../../../reusable-components/Validation/Validations';

export const CONTACTS_FORM_INPUTS = [
    {
        label: "First Name",
        name: "firstName",
        type: "text",
        required: true,
        rowNumber: 1,
        validation: validator.NAME_VALIDATION
    },
    {
        label: "Last Name",
        name: "lastName",
        type: "text",
        required: true,
        rowNumber: 1,
        validation: validator.NAME_VALIDATION
    },
    {
        label: "Relationship to the Individual",
        name: "relaiontoind",
        type: "select",
        required: true,
        rowNumber: 2,
        options: [
            { label: "Advocate", value: "Advocate" },
            { label: "Case Worker", value: "Case Worker" },
            { label: "Parent", value: "Parent" },
            { label: "Employer", value: "Employer" },
            { label: "Day Program", value: "Day Program" },
            { label: "Guardian", value: "Guardian" },
            { label: "Partner", value: "Partner" },
            { label: "Payee", value: "Payee" },
            { label: "Relative", value: "Relative" },
            { label: "Spouse", value: "Spouse" },
            { label: "Other", value: "Other" },
        ]
    },
    {
        label: "Agency",
        name: "agency",
        type: "text",
        required: true,
        rowNumber: 2,
        validation: validator.STREET_VALIDATION
    },
    {
        label: "Comments",
        name: "comments",
        type: "textarea",
        required: false,
        rowNumber: 3
    },
    {
        label: "",
        name: "isGuardian",
        type: "checkboxBoolean",
        options: [
            { label: "Is Guardian", value: true },
        ],
        required: false,
        rowNumber: 5
    },
    {
        sectionHeading: "Guardian Information",
        rowNumber: 5
    },
    {
        label: "Guardian Type",
        name: "guardianType",
        type: "select",
        required: false,
        rowNumber: 6,
        options: [
            { label: "Ad Litem", value: "Ad Litem" },
            { label: "Advocate", value: "Advocate" },
            { label: "Conservator", value: "Conservator" },
            { label: "Limited", value: "Limited" },
            { label: "plenary", value: "plenary" },
            { label: "Other", value: "Other" },
        ]
    },
    {
        label: "Guardian Authority",
        name: "guardianAuthority",
        type: "select",
        required: false,
        rowNumber: 6,
        options: [
            { label: "Financial", value: "Financial" },
            { label: "Legal", value: "Legal" },
            { label: "Medical", value: "Medical" },
            { label: "Residential", value: "Residential" },
            { label: "Vocational", value: "Vocational" }
        ]
    },
    {
        label: "Guardian Establish Start Date",
        name: "guardianEstablishDate",
        type: "date",
        required: false,
        rowNumber: 7
    },
    {
        label: "Guardian Establish End Date",
        name: "guardianEstablishEndDate",
        type: "date",
        required: false,
        rowNumber: 7
    },
    {
        sectionHeading: "Residential Address",
        rowNumber: 8
    },
    {
        addressInfo: true,
        rowNumber: 8
    },
    {
        label: "E-mail",
        name: "email",
        type: "email",
        required: false,
        rowNumber: 9,
        validation: validator.EMAIL_VALIDATION
    },
    {
        mailingInfo: true,
        rowNumber: 10
    },
]

export const INSURANCE_FORM_INPUTS = [
    {
        label: "Medicare Number",
        name: "medicareNumber",
        type: "number",
        required: false,
        rowNumber: 1
    },
    {
        label: "Medicare Date",
        name: "medicareDate",
        type: "date",
        required: false,
        rowNumber: 1
    },
    {
        label: "Med Plan D ID",
        name: "medPlanDId",
        type: "number",
        required: false,
        rowNumber: 2
    },
    {
        label: "Med Plan D Name",
        name: "medPlanDName",
        type: "text",
        required: false,
        rowNumber: 2
    },
    {
        label: "Med Plan D Issuer",
        name: "medPlanDIssuer",
        type: "text",
        required: false,
        rowNumber: 3
    },
    {
        label: "Med Plan D RxBIN",
        name: "medPlanDRxBin",
        type: "text",
        required: false,
        rowNumber: 3
    },
    {
        label: "Med Plan D RxPCN",
        name: "medPlanDRxPcn",
        type: "text",
        required: false,
        rowNumber: 4
    },
    {
        label: "Med Plan D RxGrp",
        name: "medPlanDRxGrp",
        type: "text",
        required: false,
        rowNumber: 4
    },
    {
        label: "Other Benefits",
        name: "otherBenefits",
        type: "textarea",
        required: false,
        rowNumber: 5
    },
    {
        label: "Insurance Company",
        name: "insuranceCompany",
        type: "text",
        required: false,
        rowNumber: 6
    },
    {
        label: "Insurance Group",
        name: "insuranceGroup",
        type: "text",
        required: false,
        rowNumber: 6
    },
    {
        label: "Insurance Policy Number",
        name: "insurancePolicyNumber",
        type: "number",
        required: false,
        rowNumber: 7
    },
    {
        label: "Insurance Policy Holder",
        name: "insurancePolicyHolder",
        type: "text",
        required: false,
        rowNumber: 7
    },
    {
        label: "Files",
        name: "file",
        type: "file",
        required: false,
        rowNumber: 8
    },
]

export const CLIENT_FORM_INPUTS = [
    {
        label: "Image",
        name: "image",
        type: "image",
        required: false,
        rowNumber: 1
    },
    {
        label: "Title",
        name: "title",
        type: "select",
        options: [
            { label: 'Mr', value: 'Mr' },
            { label: 'Miss', value: 'Miss' },
            { label: 'Mrs', value: 'Mrs' },
            { label: 'Ms', value: 'Ms' },
            { label: 'Mx', value: 'Mx' }
        ],
        required: false,
        rowNumber: 1
    },
    {
        label: "Gender",
        name: "gender",
        type: "select",
        options: [
            { label: 'Male', value: 'Male' },
            { label: 'Female', value: 'Female' },
            { label: 'Unknown', value: 'Unknown' },
        ],
        required: false,
        rowNumber: 2
    },
    {
        label: "First Name",
        name: "firstName",
        type: "text",
        required: true,
        rowNumber: 2
    },
    {
        label: "Last Name",
        name: "lastName",
        type: "text",
        required: true,
        rowNumber: 3
    },
    {
        label: "Birth Date",
        name: "birthDate",
        type: "date",
        required: false,
        rowNumber: 3
    },
    {
        label: "SSN",
        name: "socialSecurityNumber",
        type: "number",
        required: false,
        rowNumber: 4
    },
    {
        label: "Medicaid Number",
        name: "medicaidNumber",
        type: "number",
        required: false,
        rowNumber: 4
    },
    {
        label: "E-mail Address",
        name: "email",
        type: "email",
        required: false,
        rowNumber: 5
    },
    {
        label: "Phone Number",
        name: "phoneNumber",
        type: "number",
        required: false,
        rowNumber: 5
    },
    {
        label: "Race",
        name: "race",
        type: "select",
        options: [
            { label: 'American Indian / Alaskan Native', value: 'American Indian / Alaskan Native' },
            { label: 'Asian', value: 'Asian' },
            { label: 'Asian Indian', value: 'Asian Indian' },
            { label: 'Black / African American', value: 'Black / African American' },
            { label: 'Chinese', value: 'Chinese' },
            { label: 'Declined', value: 'Declined' },
            { label: 'Filipino', value: 'Filipino' },
            { label: 'Guamanian or chamorro', value: 'Guamanian or chamorro' },
            { label: 'Japanese', value: 'Japanese' },
            { label: 'Korean', value: 'Korean' },
            { label: 'Multiracial', value: 'Multiracial' },
            { label: 'Native Hawaiian / Other pacific islander', value: 'Native Hawaiian / Other pacific islander' },
            { label: 'Undermined', value: 'Undermined' },
            { label: 'Unknown', value: 'Unknown' },
            { label: 'Vietnamese', value: 'Vietnamese' },
            { label: 'White', value: 'White' },
            { label: 'Other', value: 'Other' },
        ],
        required: false,
        rowNumber: 6
    },
    {
        label: "Ethnicity",
        name: "ethnicity",
        type: "select",
        options: [
            { label: 'Select Ethnicity / Hispanic Origin', value: 'Select Ethnicity / Hispanic Origin' },
            { label: 'Bengali', value: 'Bengali' },
            { label: 'Central American', value: 'Central American' },
            { label: 'Chakma', value: 'Chakma' },
            { label: 'Chinese', value: 'Chinese' },
            { label: 'Cuban', value: 'Cuban' },
            { label: 'Hispanic', value: 'Hispanic' },
            { label: 'Indian', value: 'Indian' },
            { label: 'Magar', value: 'Magar' },
            { label: 'Malays', value: 'Malays' },
            { label: 'Marma', value: 'Marma' },
            { label: 'Mexican', value: 'Mexican' },
            { label: 'Moor', value: 'Moor' },
            { label: 'Newar', value: 'Newar' },
            { label: 'Not Hispanic or Latino', value: 'Not Hispanic or Latino' },
            { label: 'Other Spanish Origin', value: 'Other Spanish Origin' },
            { label: 'Puerto Rican', value: 'Puerto Rican' },
            { label: 'Sinhalese', value: 'Sinhalese' },
            { label: 'South American', value: 'South American' },
            { label: 'Tamang', value: 'Tamang' },
            { label: 'Tamil', value: 'Tamil' },
            { label: 'Tharu', value: 'Tharu' },
            { label: 'Unable to determine', value: 'Unable to determine' }
        ],
        required: false,
        rowNumber: 6
    },
    {
        label: "Status",
        name: "status",
        type: "select",
        options: [
            { label: 'Admitted', value: 'Admitted' },
            { label: 'Pending Admission', value: 'Pending Admission' },
            { label: 'Discharged', value: 'Discharged' },
            { label: 'Deceased', value: 'Deceased' },
            { label: 'Deleted', value: 'Deleted' }
        ],
        required: false,
        rowNumber: 7
    },
    {
        sectionHeading: "Residential Address",
        rowNumber: 8
    },
    {
        label: "Street 1",
        name: "st1",
        type: "text",
        required: true,
        rowNumber: 8
    },
    {
        label: "Street 2",
        name: "st2",
        type: "text",
        required: false,
        rowNumber: 8
    },
    {
        label: "City",
        name: "city",
        type: "text",
        required: true,
        rowNumber: 9
    },
    {
        label: "State",
        name: "state",
        type: "select",
        options: STATES,
        required: true,
        rowNumber: 9
    },
    {
        label: "Zip Code",
        name: "zipCode",
        type: "number",
        required: true,
        rowNumber: 10
    },
    {
        label: "Primary Phone",
        name: "primaryPhone",
        type: "number",
        required: false,
        rowNumber: 10
    },
    {
        label: "Secondary Phone",
        name: "secondaryPhone",
        type: "number",
        required: false,
        rowNumber: 11
    },
    {
        label: "Additional Phone",
        name: "additionalPhone",
        type: "number",
        required: false,
        rowNumber: 11
    },
    {
        sectionHeading: "Mailing Address",
        rowNumber: 12
    },
    {
        label: "Street 1",
        name: "mailingSt1",
        type: "text",
        required: true,
        rowNumber: 12
    },
    {
        label: "Street 2",
        name: "mailingSt2",
        type: "text",
        required: false,
        rowNumber: 12
    },
    {
        label: "City",
        name: "mailingCity",
        type: "text",
        required: true,
        rowNumber: 13
    },
    {
        label: "State",
        name: "mailingState",
        type: "select",
        options: STATES,
        required: true,
        rowNumber: 13
    },
    {
        label: "Zip Code",
        name: "mailingZipCode",
        type: "number",
        required: true,
        rowNumber: 13
    },
    {
        label: "Primary Phone",
        name: "mailingPrimaryPhone",
        type: "number",
        required: false,
        rowNumber: 14
    },
    {
        label: "Secondary Phone",
        name: "mailingSecondaryPhone",
        type: "number",
        required: false,
        rowNumber: 14
    },
    {
        label: "Additional Phone",
        name: "mailingAdditionalPhone",
        type: "number",
        required: false,
        rowNumber: 15
    }

]


export const INSURANCE_NEW_DATA = {
    medicareNumber: '',
    medicareDate: '',
    medPlanDId: '',
    medPlanDName: '',
    medPlanDIssuer: '',
    medPlanDRxBin: '',
    medPlanDRxPcn: '',
    medPlanDRxGrp: '',
    otherBenefits: '',
    insuranceCompany: '',
    insuranceGroup: '',
    insurancePolicyNumber: '',
    insurancePolicyHolder: '',
    file: ''
}

export const CLIENT_LOG_FORM_INPUTS = [
    {
        label: "Type",
        name: "type",
        type: "checkbox",
        options: [
            { label: "Health", value: "Health" },
            { label: "Notes", value: "Notes" },
            { label: "Follow-Up", value: "Follow-Up" },
            { label: "Behavior", value: "Behavior" },
            { label: "Contacts", value: "Contacts" },
            { label: "General", value: "General" }
        ],
        required: true,
        rowNumber: 1
    },
    {
        label: "Summary",
        name: "summary",
        type: "text",
        required: true,
        rowNumber: 2
    },
    {
        label: "Description",
        name: "description",
        type: "textarea",
        required: false,
        rowNumber: 3
    },
    {
        label: "Reported On",
        name: "reportedOn",
        type: "date",
        required: false,
        rowNumber: 4
    },
    {
        label: "Intensity Level",
        name: "intensityLevel",
        type: "select",
        required: false,
        rowNumber: 4,
        options: [
            { label: "Low", value: "Low" },
            { label: "Medium", value: "Medium" },
            { label: "High", value: "High" },
        ]
    },
]
export const CLIENT_LOG_SEARCH = [
    {
        label: "Client",
        name: "clientID",
        type: "select",
        required: false,
        rowNumber: 1,
        options: []
    },
    {
        label: "Reported Date From",
        name: "reportDateFrom",
        type: "date",
        required: false,
        rowNumber: 3
    },
    {
        label: "Reported Date To",
        name: "reportDateTo",
        type: "date",
        required: false,
        rowNumber: 3
    },
    {
        label: "Intensity Level",
        name: "intensityLevel",
        type: "select",
        required: false,
        rowNumber: 1,
        options: [
            { label: "Low", value: "Low" },
            { label: "Medium", value: "Medium" },
            { label: "High", value: "High" },
        ]
    },
    {
        label: "Type",
        name: "type",
        type: "checkbox",
        options: [
            { label: "Health", value: "Health" },
            { label: "Notes", value: "Notes" },
            { label: "Follow-Up", value: "Follow-Up" },
            { label: "Behavior", value: "Behavior" },
            { label: "Contacts", value: "Contacts" },
            { label: "General", value: "General" }
        ],
        required: false,
        rowNumber: 2
    },
    {
        label: "Summary",
        name: "summary",
        type: "text",
        required: false,
        rowNumber: 4
    },
    {
        label: "Description",
        name: "description",
        type: "textarea",
        required: false,
        rowNumber: 5
    },
]

export const HEALTH_DATA_INPUTS = [
    {
        label: "Height (Feet)",
        name: "heightFeet",
        type: "select",
        options: [
            { label: "0", value: "0" },
            { label: "1", value: "1" },
            { label: "2", value: "2" },
            { label: "3", value: "3" },
            { label: "4", value: "4" },
            { label: "5", value: "5" },
            { label: "6", value: "6" },
            { label: "7", value: "7" },
            { label: "8", value: "8" },
            { label: "9", value: "9" },
            { label: "10", value: "10" },
            { label: "11", value: "11" },
        ],
        required: false,
        rowNumber: 1
    },
    {
        label: "Height (Inches)",
        name: "heightInch",
        type: "select",
        options: [
            { label: "0", value: "0" },
            { label: "1", value: "1" },
            { label: "2", value: "2" },
            { label: "3", value: "3" },
            { label: "4", value: "4" },
            { label: "5", value: "5" },
            { label: "6", value: "6" },
            { label: "7", value: "7" },
            { label: "8", value: "8" },
            { label: "9", value: "9" },
            { label: "10", value: "10" },
            { label: "11", value: "11" },
        ],
        required: false,
        rowNumber: 1
    },
    {
        label: "Weight Range From: (lbs)",
        name: "weightRangeFrom",
        type: "number",
        required: false,
        rowNumber: 2
    },
    {
        label: "Weight Range To: (lbs)",
        name: "weightRangeTo",
        type: "number",
        required: false,
        rowNumber: 2
    },
    {
        label: "Temperature From: (F)",
        name: "temperatureFrom",
        type: "number",
        required: false,
        rowNumber: 3
    },
    {
        label: "Temperature To: (F)",
        name: "temperatureTo",
        type: "number",
        required: false,
        rowNumber: 3
    },
    {
        label: "Pulse From: (BPM)",
        name: "pulseFrom",
        type: "number",
        required: false,
        rowNumber: 4
    },
    {
        label: "Pulse To: (BPM)",
        name: "pulseTo",
        type: "number",
        required: false,
        rowNumber: 4
    },
    {
        label: "Oxygen Saturation From: (%)",
        name: "oxygenSaturationFrom",
        type: "number",
        required: false,
        rowNumber: 5
    },
    {
        label: "Oxygen Saturation To: (%)",
        name: "oxygenSaturationTo",
        type: "number",
        required: false,
        rowNumber: 5
    },
    {
        label: "Respiration From: (BPM)",
        name: "respirationFrom",
        type: "number",
        required: false,
        rowNumber: 6
    },
    {
        label: "Respiration To: (BPM)",
        name: "respirationTo",
        type: "number",
        required: false,
        rowNumber: 6
    },
    {
        label: "Blood Pressure Systolic From: (mmHg)",
        name: "bloodSystolicFrom",
        type: "number",
        required: false,
        rowNumber: 7
    },
    {
        label: "Blood Pressure Systolic To: (mmHg)",
        name: "bloodSystolicTo",
        type: "number",
        required: false,
        rowNumber: 7
    },
    {
        label: "Blood Pressure Diastolic From: (mmHg)",
        name: "bloodDiastolicFrom",
        type: "number",
        required: false,
        rowNumber: 8
    },
    {
        label: "Blood Pressure Diastolic To: (mmHg)",
        name: "bloodDiastolicTo",
        type: "number",
        required: false,
        rowNumber: 8
    },
]
export const CLIENT_SEARCH = [
    {
        label: "First Name",
        name: "firstName",
        type: "text",
        required: false,
        rowNumber: 1
    },
    {
        label: "Last Name",
        name: "lastName",
        type: "text",
        required: false,
        rowNumber: 1
    },
    {
        label: "Social Security Number",
        name: "socialSecurityNumber",
        type: "text",
        required: false,
        rowNumber: 1
    },
    {
        label: "Medicaid Number",
        name: "medicaidNumber",
        type: "text",
        required: false,
        rowNumber: 2
    },
    {
        label: "Medicare Number",
        name: "medicareNumber",
        type: "text",
        required: false,
        rowNumber: 2
    },
    {
        label: "Entered By",
        name: "enteredBy",
        type: "text",
        required: false,
        rowNumber: 2
    },
    {
        label: "Status",
        name: "status",
        type: "select",
        options: [
            { label: "Admitted", value: "Admitted" },
            { label: "Pending Admission", value: "Pending Admission" },
            { label: "Discharged", value: "Discharged" },
            { label: "Deceased", value: "Deceased" },
            { label: "Deleted", value: "Deleted" },
        ],
        required: false,
        rowNumber: 3
    },
    {
        label: "Birth Date From",
        name: "birthDateFrom",
        type: "date",
        required: false,
        rowNumber: 3
    },
    {
        label: "Birth Date To",
        name: "birthDateTo",
        type: "date",
        required: false,
        rowNumber: 3
    }
]

export const NEW_CLIENT_SEARCH = {
    firstName: '',
    lastName: '',
    socialSecurityNumber: '',
    medicaidNumber: '',
    medicareNumber: '',
    programSite: '',
    enteredBy: '',
    birthDateFrom: '',
    birthDateTo: '',
    status: '',
}

export const NEW_CONTACT_DATA = {
    "firstName": '',
    "lastName": '',
    "relaiontoind": '',
    "agency": '',
    "comments": '',
    "guardianType": '',
    "guardianAuthority": '',
    "guardianEstablishDate": null,
    "guardianEstablishEndDate": null,
};

export const ALLERGY_INPUTS = [
    {
        label: "Allergy Name",
        name: "allergy",
        type: "asyncSelectICD",
        options: [],
        required: true,
        rowNumber: 1
    },
    {
        label: "Severity",
        name: "severity",
        type: "select",
        options: [
            { label: 'Severe', value: 'Severe' },
            { label: 'Moderate', value: 'Moderate' },
            { label: 'Mild', value: 'Mild' },
            { label: 'Unknown', value: 'Unknown' }
        ],
        required: false,
        rowNumber: 1
    },
    {
        label: "Description",
        name: "description",
        type: "textarea",
        required: false,
        rowNumber: 2
    },
    {
        label: "Allergy State",
        name: "allergyState",
        type: "select",
        options: [
            { label: 'Active', value: 'Active' },
            { label: 'InActive', value: 'InActive' },
        ],
        required: false,
        rowNumber: 3
    },
    {
        label: "Identification Date",
        name: "identificationDate",
        type: "date",
        required: false,
        rowNumber: 3
    },
    {
        label: "Reaction",
        name: "reaction",
        type: "textarea",
        required: false,
        rowNumber: 4
    },
]

export const NEW_ALLERGY = {
    allergy: "",
    allergyState: "",
    description: "",
    severity: "",
    identificationDate: "",
    reaction: "",
}

export const NEW_CLIENT_LOG = {
    type: [],
    summary: '',
    description: '',
    reportedOn: '',
    intensityLevel: '',
}
export const NEW_CLIENT_LOG_SEARCH = {
    type: [],
    summary: '',
    description: '',
    intensityLevel: '',
    reportDateTo: '',
    reportDateFrom: '',
    clientID: '',
}
