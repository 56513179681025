
export const hasPermission = (userPermissionsArray, permission) => {
    if (!permission || typeof permission !== 'string') {
        return false;
    }

    for (const userPermissionObj of userPermissionsArray) {
        const userPermission = userPermissionObj.permissions;

        if (userPermission) {
            const permissionKeys = permission.split('.');
            let currentPermission = userPermission;

            for (const key of permissionKeys) {
                if (!currentPermission[key]) {
                    break;
                }
                currentPermission = currentPermission[key];
            }

            if (typeof currentPermission !== 'object') {
                return true;
            }
        }
    }

    return false;
};
