import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify'
import { addBillingProvider } from '../store/action';
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper';
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm';
import { BILLING_PROVIDER_FORM_INPUTS, NEW_BILLING_PROVIDER } from '../../BILLING_CONSTANTS';


function New() {
    const history = useHistory()
    const dispatch = useDispatch()

    const handleSubmitForm = async (formData) => {
        
    const tempObj = {
        entityType: formData.entityType,
        organizationName: formData.organizationName,
        firstName: formData.firstName,
        lastName: formData.lastName,
        middleName: formData.middleName,
        identityInformation: {
          IDType1: formData.IDType1,
          IDNumber1: Number(formData.IDNumber1),
          IDType2: formData.IDType2,
          IDNumber2: Number(formData.IDNumber2),
          medicaidProviderNumber: Number(formData.medicaidProviderNumber),
          NPINumber: Number(formData.NPINumber),
          providerCommercialNumber: Number(formData.providerCommercialNumber),
          additionalProviderNumber: Number(formData.additionalProviderNumber),
          demonstrationProjectIdentifier: formData.demonstrationProjectIdentifier,
        },
        providerAddress: {
          st1: formData?.addrInfo?.st1,
          st2: formData?.addrInfo?.st2,
          city: formData?.addrInfo?.city,
          state: formData?.addrInfo?.state,
          country: formData?.addrInfo?.country,
          zipCode: Number(formData?.addrInfo?.zipCode),
          phoneNumber: Number(formData?.addrInfo?.primaryPhone),
        },
        contactInformation: {
          name: formData.name,
          email: formData.email,
        },
        financialRouting: {
          accountNumber: Number(formData.accountNumber),
          bankCode: Number(formData.bankCode),
        }
      };
        const response = await dispatch(addBillingProvider(tempObj))
        const { message, success } = response?.payload
        if(!success) {
            return toast.error(message)
        }
        history.push('/admin/billing/billing-provider/list')
        toast.success(message)
    };

    const buttons = [
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];

    return (
        <FormWrapper
            title='New Billing Provider'
            content={
                <DynamicForm
                    fields={BILLING_PROVIDER_FORM_INPUTS}
                    data={NEW_BILLING_PROVIDER}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        />

    )
}

export default New