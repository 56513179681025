
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { PasswordMeterComponent } from '../../../_metronic/assets/ts/components';
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import { Redirect, Switch } from 'react-router-dom'



function InitialPasswordChangeModal() {
    const [password, setPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    const userAuth = (state) => state?.auth;
    const userInfo = useSelector(userAuth)?.user

    const history = useHistory()

    const registrationSchema = Yup.object().shape({
        password: Yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .required('Password confirmation is required')
            .when('password', {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
            }),
    })

    const initialValues = {

        password: '',
        confirmPassword: ''
    }


    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            // setLoading(true)
            axios.put("/users/updatePassword", { email: userInfo.email, password: values.password })
                .then((response) => {
                    setLoading(false)
                    localStorage.clear()
                    window.location.reload()
                    // return (
                    //     <Switch>
                    //         <Redirect to='/auth/login' />
                    //     </Switch>
                    // )
                })
                .catch((error) => {
                    setError(error.response.data.message)
                    setLoading(false)
                    setSubmitting(false)
                    setStatus('Registration process has broken')
                    localStorage.clear()
                    window.location.reload()
                })
        },
    })


    const handleSubmit = (event) => {
        event.preventDefault();
        formik.handleSubmit();
    };

    useEffect(() => {
        PasswordMeterComponent.bootstrap();
    }, []);

    return (
        <div>


            <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', padding: '1rem' }}>

                <h2>Change Your Password</h2>
                <div className='mb-10 fv-row' data-kt-password-meter='true'>
                    <div className='mb-1'>
                        <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                        <div className='position-relative mb-3'>
                            <input
                                type='password'
                                placeholder='Password'
                                autoComplete='off'
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                {...formik.getFieldProps('password')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': formik.touched.password && formik.errors.password,
                                    },
                                    {
                                        'is-valid': formik.touched.password && !formik.errors.password,
                                    }
                                )}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert' className='text-danger'>{formik.errors.password}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* begin::Meter */}
                        <div
                            className="d-flex align-items-center mb-3"
                            data-kt-password-meter-control="highlight"
                        >
                            <div
                                className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                            ></div>
                            <div
                                className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                            ></div>
                            <div
                                className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                            ></div>
                            <div
                                className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"
                            ></div>
                        </div>
                        {/* end::Meter */}
                    </div>
                    <div className="text-muted">
                        Use 8 or more characters with a mix of letters, numbers & symbols.
                    </div>
                </div>
                {/* end::Form group */}

                {/* begin::Form group Confirm password */}
                <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                    <input
                        type='password'
                        placeholder='Password confirmation'
                        autoComplete='off'
                        onChange={formik.handleChange}
                        value={formik.values.confirmPassword}
                        {...formik.getFieldProps('confirmPassword')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                            },
                            {
                                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                            }
                        )}
                    />
                    {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>{formik.errors.confirmPassword}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='text-center'>
                    <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-lg btn-primary w-100 mb-5'
                        disabled={formik.isSubmitting || !formik.isValid}
                        onClick={handleSubmit}
                    >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>

                </div>
            </div>




        </div>
    );
}

export default InitialPasswordChangeModal