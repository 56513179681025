import {useRef, useState} from 'react'
import {Bar} from 'react-chartjs-2'
import jsPDF from 'jspdf'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {Box, Button, FormGroup, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import html2canvas from 'html2canvas'
import Logo from './image.png'
import {MoonLoader} from 'react-spinners'
import { hasPermission } from '../../../../_helper/hasPermission'
import { permissions } from '../../../../_constants/permissions'
import { useSelector } from 'react-redux'
const selectAuth = (state) => state.auth;

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const useStyles = makeStyles(() => ({
  targetLabel: {
    fontWeight: 'bold',
    display: 'block',
  },
  replacementLabel: {
    display: 'block',
  },
  displayFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '600px',
  },
  chartLegend: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontWeight: 'bold !important',
    fontSize: '15px',
  },
}))

const BarChart = ({chartDataArray, clientData, date}) => {
  const [loadingPDF, setLoadingPDF] = useState(false)
  const chartRefs = useRef([])
  const classes = useStyles()
  const auth = useSelector(selectAuth);
  const userPermissions = auth.user.roleId;

  const handleExportPDF = () => {
    const pdf = new jsPDF('landscape')
    const promises = []

    // Load the logo image
    const logoImage = new Image()
    logoImage.src = Logo
    setLoadingPDF(true)

    promises.push(
      new Promise((resolve, reject) => {
        logoImage.onload = () => {
          // Adjust the width and height to resize the image
          const logoWidth = 40 // Set your desired width
          const logoHeight = 10 // Set your desired height
          const pageWidth = pdf.internal.pageSize.getWidth()
          const xPosition = pageWidth - logoWidth - 10 // 10 units from the right edge

          pdf.addImage(
            logoImage,
            'PNG',
            xPosition,
            10, // Adjust the Y position if needed
            logoWidth, // Width of the logo
            logoHeight // Height of the logo
          )
          resolve()
        }
        logoImage.onerror = (error) => reject(error)
      })
    )

    // Ensure the hidden content is fully rendered
    const hiddenContent = document.getElementById('hiddenContent')
    if (!hiddenContent) {
      console.error('Hidden content element not found')
      return
    }
    promises.push(
      html2canvas(hiddenContent)
        .then((canvas) => {
          const canvasData = canvas.toDataURL('image/png')
          pdf.addImage(
            canvasData,
            'PNG',
            10,
            30, // Adjust the Y position to fit below the logo
            pdf.internal.pageSize.getWidth() - 20,
            40 // Adjust the height to fit your content
          )
        })
        .catch((error) => {
          console.error('Error capturing hidden content:', error)
        })
    )

    // Capture charts
    chartRefs.current.forEach((chartRef, index) => {
      if (!chartRef) {
        console.error(`Chart ref at index ${index} not found`)
        return
      }

      promises.push(
        html2canvas(chartRef)
          .then((canvas) => {
            const canvasData = canvas.toDataURL('image/png')

            if (index !== 0) {
              pdf.addPage()
            }

            // Adjust the position and size of the chart in the PDF document
            pdf.addImage(
              canvasData,
              'PNG',
              10,
              10, // Adjust the Y position to fit below the hidden content
              pdf.internal.pageSize.getWidth() - 60,
              pdf.internal.pageSize.getHeight() - 30 // Adjust the height to fit your content
            )
          })
          .catch((error) => {
            console.error(`Error capturing chart at index ${index}:`, error)
          })
      )
    })

    Promise.all(promises)
      .then(() => {
        pdf.save(`${clientData.fullName}'s behaviors-${date}.pdf`)
        setLoadingPDF(false)
      })
      .catch((error) => {
        console.error('Error creating PDF:', error)
        setLoadingPDF(false)
      })
  }

  if (!chartDataArray.length) {
    return (
      <div className='card-body'>
        <h1>No Data Found for this client</h1>
      </div>
    )
  }

  return (
    <div className='card-body'>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Box
          sx={{
            display: 'flex',
            ml: 1.8,
            flexDirection: 'column',
            width: '500px',
            fontWeight: '400',
          }}
        >
          <Typography variant='h5' className={classes.heading}>
            Client Information
          </Typography>
        </Box>

        {loadingPDF ? (
          <div className='overlay mx-8'>
            <MoonLoader color='#9db2fc' size={30} loading={loadingPDF} />
          </div>
        ) : (
          <>
          {
            hasPermission(userPermissions, permissions.behavior_export_export) &&
              <Button
                variant='contained'
                style={{maxHeight: '2.2rem'}}
                autoFocus
                onClick={handleExportPDF}
              >
                Export
              </Button>
          }
          </>
        )}
      </Box>
      <Box id='hiddenContent' style={{display: 'none'}}>
        <Box style={{display: 'flex', justifyContent: 'space-between'}}>
          <img src={Logo} alt='Logo' />
          <Box>
            <p> Client Behavior Sheet </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p className={classes.heading}>Date :</p>
              <p>{date || ''}</p>
            </div>
          </Box>
        </Box>
      </Box>

      {chartDataArray.map((chartData, index) => (
        <div
          key={index}
          ref={(ref) => (chartRefs.current[index] = ref)}
          style={{marginBottom: '5%', marginLeft: '1rem'}}
        >
          {index === 0 && (
            <FormGroup className={classes.displayFlex}>
              <Box className={classes.displayFlex}>
                <Typography variant='p' className={classes.heading}>
                  Client Name:
                </Typography>
                <Box sx={{display: 'flex', justifyContent: 'flex-start', width: '20rem'}}>
                  <Box sx={{display: 'block'}}>
                    <Typography>{clientData.fullName}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.displayFlex}>
                <Typography variant='p' className={classes.heading}>
                  Gender:
                </Typography>
                <Box sx={{display: 'flex', justifyContent: 'flex-start', width: '20rem'}}>
                  <Box sx={{display: 'block'}}>
                    <Typography>{clientData.gender}</Typography>
                  </Box>
                </Box>
              </Box>
            </FormGroup>
          )}
          <FormGroup className={classes.displayFlex}>
            <Box className={classes.displayFlex}>
              <Typography variant='p' className={classes.heading}>
                Target Behavior:
              </Typography>
              <Box sx={{display: 'flex', justifyContent: 'flex-start', width: '20rem'}}>
                <Box sx={{display: 'block'}}>
                  {chartData.legends.slice(0, 1).map((legend) => (
                    <Typography>{legend}</Typography>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box className={classes.displayFlex}>
              <Typography variant='p' className={classes.heading}>
                Replacement Behavior:
              </Typography>
              <Box sx={{display: 'block', justifyContent: 'flex-start', width: '20rem', marginBottom: '1rem'}}>
                {chartData.legends.slice(1).map((legend, legendIndex) => (
                  <Typography key={legendIndex + 1}>{legend}</Typography>
                ))}
              </Box>
            </Box>
          </FormGroup>
          <Bar
            data={{
              labels: chartData.labels,
              datasets: chartData.datasets.map((dataset, datasetIndex) => ({
                label: chartData.legends[datasetIndex],
                backgroundColor: `rgba(${Math.random() * 255},${Math.random() * 255},${
                  Math.random() * 255
                },0.6)`,
                borderColor: `rgba(${Math.random() * 255},${Math.random() * 255},${
                  Math.random() * 255
                },1)`,
                borderWidth: 1,
                data: dataset.values,
              })),
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                  min: 0,
                  max: 5,
                },
              },
              plugins: {
                legend: {
                  display: true,
                },
              },
              barThickness: 20, // Adjust the bar thickness here
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default BarChart
