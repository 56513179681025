import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface Sites {
    _id: String;
    providerName: String;
    providerCode: String;
    siteName: String;
    siteID: Number;
    siteCode: String;
    programCostCenterNumber: Number;
    st1: String;
    st2: String;
    city: String;
    state: String;
    zipCode: String;
    country: String;
    phoneNumber: String;
    secondaryPhone: String;
}

export const getSites = createAsyncThunk(
    'site/get',
    async ({ page, pageSize, sortBy, sortDir, searchTerm  }: { page: number, pageSize: number, sortBy: string, sortDir: string, searchTerm : string }) => {
        try {
            if (page && pageSize) {
                const response = await axios.get(`site/get?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${searchTerm}`)
                return {
                    data: response.data
                }
            }else {
                const response = await axios.get('site/get')
                return {
                    data: response.data
                }
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getSearchSite = createAsyncThunk(
    'site/search',
    async (searchTerm) => {
        try {
            const response = await axios.get(`site/search?searchTerm=${searchTerm}`)
            return {
                data: response.data  
            }
        } catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getOneSites = createAsyncThunk(
    'site/getOne/',
    async (companyid: String | undefined) => {
        try {
            const response = await axios.get(`site/getOne/${companyid}`)
            return {
                data: response.data
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const addSite = createAsyncThunk(
    'site/create',
    async (data: Sites) => {
        try {
            const response = await axios.post('site/create', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const deleteSite = createAsyncThunk(
    'site/delete/',
    async (data: String) => {
        try {
            const response = await axios.delete('site/delete/' + data);
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const updateSite = createAsyncThunk(
    'site/update',
    async (data: Sites) => {
        try {
            const response = await axios.put('site/update', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)