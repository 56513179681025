import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface Program {
    _id: string;
    programName: String;
    programType: String;
    programCode: String;
    primaryContacts: {
        name: String;
        primaryPhone: String;
        secondaryPhone: String
    }
    secondaryContacts: {
        secName: String;
        secPrimaryPhone: String;
        secSecondaryPhone: String
    }
    capacity: Number;
    siteName: String;
    programID: Number;
    individualsAdded: String;
    programCostCenterNumber: Number;
    createDate: String;
    providerCode: String;
    providerName: String;
}

export const getProgram = createAsyncThunk(
    'programs/get',
    async ({  page, pageSize, sortBy, sortDir, searchTerm }: { page?: number, pageSize?: number, sortBy?: string, sortDir?: string, searchTerm?: string }) => {
        try {
            if (page && pageSize) {
                const response = await axios.get(`programs/get?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${searchTerm}`)
                return {
                    data: response.data
                }
            } else {
                const response = await axios.get(`programs/get`)
                return {
                    data: response.data
                }
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getSearchPrograms = createAsyncThunk(
    'programs/search',
    async (searchTerm) => {
        try {
            const response = await axios.get(`programs/search?searchTerm=${searchTerm}`)
            return {
                data: response.data  
            }
        } catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getOneProgram = createAsyncThunk(
    'programs/getOne/',
    async (companyid: String | undefined) => {
        try {
            const response = await axios.get('programs/getOne/' + companyid)
            return {
                data: response.data
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)
export const getClientForEnrollment = createAsyncThunk(
    'programs/getClientForEnrollment/',
    async ({ id, page, pageSize, sortBy, sortDir, searchEnrolledTerm, searchEnrollableTerm } : { id? : string, page?: Number, pageSize?: Number, sortBy?: string, sortDir?: string, searchEnrolledTerm?: string, searchEnrollableTerm?: string } ) => {
        try {
            const response = await axios.get(`/programs/getClientForEnrollment/${id}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`, {
                params: {
                    searchEnrolledTerm, searchEnrollableTerm
                }
            });
            return {
                data: response.data
            };
        } catch (err) {
            console.log(err);
            return err;
        }
    }
);
export const getProgramsForEnrollment = createAsyncThunk(
    'programs/getProgramsForEnrollment/',
    async ({ clientID,  page, pageSize, sortBy, sortDir, searchEnrolledTerm, searchEnrollableTerm } : { clientID? : string, page?: Number, pageSize?: Number, sortBy?: string, sortDir?: string, searchEnrolledTerm?: string, searchEnrollableTerm?: string } ) => {
        try {
            const response = await axios.get(`/programs/getProgramsForEnrollment?clientID=${clientID}&page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchEnrolledTerm=${searchEnrolledTerm}&searchEnrollableTerm=${searchEnrollableTerm}`, {
            });
            return {
                data: response.data
            };
        } catch (err) {
            console.log(err);
            return err;
        }
    }
);

export const deleteClientFromProgram = createAsyncThunk(
    'programs/deleteClient',
    async ({ clientId, programId }: { clientId : string, programId: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`programs/deleteClient/${clientId}`, { _id: programId });
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const addClientToProgram = createAsyncThunk(
    'programs/addClient',
    async ({ clientId, programId } : { clientId : string, programId: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`programs/addClient/${clientId}`, { _id: programId });
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const addProgram = createAsyncThunk(
    'programs/create',
    async (data: Program) => {
        try {
            const response = await axios.post('programs/create', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const deleteProgram = createAsyncThunk(
    'programs/delete/',
    async (data: String) => {
        try {
            const response = await axios.delete('programs/delete/' + data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const updateProgram = createAsyncThunk(
    'programs/update',
    async (data: Program) => {
        try {
            const response = await axios.put('programs/update', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)