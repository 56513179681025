import {  useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../setup'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import { LIST_PROGRAM_ENROLLMENT_COLUMNS_DATA_MAPPING, LIST_PROGRAM_ENROLLMENT_COLUMNS, LIST_PROGRAM_ENROLLMENT_SORTING_COLUMNS } from '../../care_table_constants'
import BackButton from '../../../../../reusable-components/Buttons/BackButton'
import { useParams } from 'react-router-dom'
import { addClientToProgram, deleteClientFromProgram, getProgramsForEnrollment } from '../store/actions'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { permissions } from '../../../../../../_constants/permissions'
import { AppDispatch } from '../../../../../../setup/redux/Store'
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes'
const selectAuth = (state: RootState) => state.auth;


const IndividualProgramList = () => {
    const [enrollableFilteredData, setEnrollableFilteredData] = useState([])
    const [enrolledFilteredData, setEnrolledFilteredData] = useState([])
    const [enrollableSearchTerm, setEnrollableSearchTerm] = useState('')
    const [enrolledSearchTerm, setEnrolledSearchTerm] = useState('')
    const [clientData, setClientData] = useState({
        clientName: '',
        status: '',
        birthDate: ''
    })
    const [deleteProgram, setDeleteProgram] = useState({
        _id: ''
    })
    const [addedProgram, setAddedProgram] = useState({
        _id: ''
    })
    const [queryDataForEnrollableProgram, setQueryDataForEnrollableProgram] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 0,
        sortBy: 'name',
        sortDir: 'desc',
        searchTerm: ''
    })
    const [queryDataForEnrolledProgram, setQueryDataForEnrolledProgram] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 0,
        sortBy: 'name',
        sortDir: 'desc',
        searchTerm: ''
    })
    const dispatch = useDispatch<AppDispatch>()
    const auth = useSelector(selectAuth);
    const parameters: { clientID: string } = useParams()
    const userPermissions = auth.user.roleId;
    useEffect(() => {
        const fetchEnrolledPrograms = async () => {
            try{
                const programData = await dispatch(getProgramsForEnrollment({
                    clientID: parameters.clientID,
                    page: queryDataForEnrolledProgram.pageNumber,
                    pageSize: queryDataForEnrolledProgram.pageSize,
                    sortBy: queryDataForEnrolledProgram.sortBy,
                    sortDir: queryDataForEnrolledProgram.sortDir,
                    searchEnrolledTerm: queryDataForEnrolledProgram.searchTerm
                }));   
                const { enrolled } = programData?.payload?.data;
                setClientData(enrolled.selectedClientsDetails)
                setEnrolledFilteredData(enrolled.data)
                setQueryDataForEnrolledProgram(prevState => ({
                        ...prevState,
                        currentPage: enrolled.currentPage,
                        totalPages: enrolled.totalPages,
                        totalCounts: enrolled.totalCounts,
                    }))
            } 
                catch(err) {
                console.log(err)
            }
        }
        const fetchEnrollablePrograms = async () => {
            try{
                const programData = await dispatch(getProgramsForEnrollment({
                    clientID: parameters.clientID,
                    page: queryDataForEnrollableProgram.pageNumber,
                    pageSize: queryDataForEnrollableProgram.pageSize,
                    sortBy: queryDataForEnrollableProgram.sortBy,
                    sortDir: queryDataForEnrollableProgram.sortDir,
                    searchEnrollableTerm: queryDataForEnrollableProgram.searchTerm
                }));   
                const { enrollable } = programData?.payload?.data;
                        setEnrollableFilteredData(enrollable.data)
                        setClientData(enrollable.selectedClientsDetails)
                        setQueryDataForEnrollableProgram(prevState => ({
                            ...prevState,
                            currentPage: enrollable.currentPage,
                            totalPages: enrollable.totalPages,
                            totalCounts: enrollable.totalCounts,
                        }))
            } catch(err) {
                console.log(err)
            }
        }
        if(parameters.clientID) {
            fetchEnrolledPrograms()
            fetchEnrollablePrograms()
        }
        
    }, [
        addedProgram, deleteProgram,
        queryDataForEnrollableProgram.pageNumber, 
        queryDataForEnrollableProgram.pageSize, 
        queryDataForEnrollableProgram.sortDir, 
        queryDataForEnrollableProgram.sortBy,
        queryDataForEnrollableProgram.searchTerm,
        queryDataForEnrolledProgram.pageSize, 
        queryDataForEnrolledProgram.pageNumber, 
        queryDataForEnrolledProgram.sortBy, 
        queryDataForEnrolledProgram.sortDir, 
        queryDataForEnrolledProgram.searchTerm
    ])
  
        const handleAdd = (row) => {
            dispatch(addClientToProgram({ clientId: parameters.clientID , programId: row._id }))
                .then(() => {
                    setEnrolledFilteredData((prevState) => [...prevState, row]);
                    const tempData = enrollableFilteredData.filter((contact) =>
                        contact._id !== row._id
                    );
                    setEnrollableFilteredData(tempData);
                    setQueryDataForEnrollableProgram((prevState)=> {
                        return {
                          ...prevState,
                          searchTerm : '',
                          pageNumber: 1
                        }
                      })
                })
                .catch((error) => {
                    console.error('Error adding Program:', error);
                });
        
            setAddedProgram({ _id: '' });
        };

        const handleDelete = (row) => {
            dispatch(deleteClientFromProgram({ clientId: parameters.clientID, programId: row._id }))
                .then(() => {
                    setEnrollableFilteredData((prevState) => [...prevState, row]);
                    const tempData = enrolledFilteredData.filter((contact) =>
                        contact._id !== row._id
                    );
                    setEnrolledFilteredData(tempData);
                    setQueryDataForEnrolledProgram((prevState)=> {
                        return {
                          ...prevState,
                          searchTerm : '',
                          pageNumber: 1
                        }
                      })
                })
                .catch((error) => {
                    console.error('Error deleting Prgoram:', error);
                });
        
            setDeleteProgram({ _id: '' });
        };

   const handleAddBox = (row: { _id: string; firstName: string; lastName: string; }) => {
            if (row._id) {
                setAddedProgram(row);
                handleAdd(row);
            }
        }
        const handleDeleteBox = (row: { _id: string; firstName: string; lastName: string; }) => {
            if (row._id) {
                setDeleteProgram(row)
                handleDelete(row)
            }
        }
    return (
        <div>
            <div className=" card d-flex justify-content-center">
                <div className="card-body h-md-100 w-100">
                    <div className='d-flex justify-content-between w-100'>
                        <div className="w-100  h-md-100">
                            <div className="card-title" style={{ display: 'flex', justifyContent: 'space-between', }}>
                                <p className='fs-2 fw-bolder'>Program Details</p>
                                <BackButton />
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="d-flex py-1">
                            <label className="w-50 fw-bolder">Client Name:</label>
                            {
                                clientData &&
                                    <label className="w-50 form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">{clientData?.clientName}</label>
                            }
                        </div>
                        <div className="d-flex py-1">
                            <label className="w-50 fw-bolder">Birth Date:</label>
                            {
                                clientData &&
                                    <label className="w-50 form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">{clientData.birthDate}</label>
                            }

                        </div>
                        <div className="d-flex py-1 mb-4">
                            <label className="w-50 fw-bolder">Individual Status:</label>
                            {
                                clientData &&
                                    <label className="w-50 form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">{clientData.status}</label>
                            }
                        </div>

                        <TableTitle title='Enrollable Program(s)'></TableTitle>
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={enrollableSearchTerm}
                            setSearchTerm={setEnrollableSearchTerm}
                            setQueryData={setQueryDataForEnrollableProgram}
                            margin='normal'
                            inputAdornmentPosition='start'
                        ></TableSearchBar>
                        <DynamicTable
                            columns={LIST_PROGRAM_ENROLLMENT_COLUMNS}
                            data={enrollableFilteredData}
                            canAddBox={hasPermission(userPermissions, permissions.admin_care_enrollment_byIndividual_create)}
                            columnDataMapping={LIST_PROGRAM_ENROLLMENT_COLUMNS_DATA_MAPPING}
                            sortingColumns={LIST_PROGRAM_ENROLLMENT_SORTING_COLUMNS}
                            onAddBox={handleAddBox}
                            title='Are you sure you want to Enroll this Program?'
                            setQueryData={setQueryDataForEnrollableProgram}
                            queryData={queryDataForEnrollableProgram}
                        />

                        <TableTitle title='Enrolled Program(s)'></TableTitle>
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={enrolledSearchTerm}
                            setSearchTerm={setEnrolledSearchTerm}
                            setQueryData={setQueryDataForEnrolledProgram}
                            margin='normal'
                            inputAdornmentPosition='start'
                        ></TableSearchBar>
                        <DynamicTable
                            columns={LIST_PROGRAM_ENROLLMENT_COLUMNS}
                            data={enrolledFilteredData}
                            canDelete={hasPermission(userPermissions, permissions.admin_care_enrollment_byIndividual_delete)}
                            columnDataMapping={LIST_PROGRAM_ENROLLMENT_COLUMNS_DATA_MAPPING}
                            sortingColumns={LIST_PROGRAM_ENROLLMENT_SORTING_COLUMNS}
                            onDelete={handleDeleteBox}
                            title='Are you sure you want to Delete this Program?'
                            setQueryData={setQueryDataForEnrolledProgram}
                            queryData={queryDataForEnrolledProgram}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndividualProgramList