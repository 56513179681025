import {
  Box,
  Button,
  FormControl,
  TextField,
  TextareaAutosize,
  Grid,
  Paper,
  Typography,
  Autocomplete,
  MenuItem
} from '@mui/material'
import { useEffect, useState } from 'react'
import AddClientBehavior from '../component/AddClientBehaviors'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import moment from 'moment'

export default function AddNewEntry() {
  const [addBehaviour, setAddBehviour] = useState(false)
  const [behaviour, setBehaviour] = useState('')
  const [data, setData] = useState({
    client: {},
    behaviors: [],
  })
  const params = useParams()
  const location = useLocation()
  const history = useHistory()

  const getBehaviors = async () => {
    const parsedDate = moment(location.state.date)

    const startOfDay = parsedDate.startOf('day').toISOString()

    const endOfDay = parsedDate.endOf('day').toISOString()

    try {
      const response = await axios.get(`/behaviors?clientId=${params.id}`)
      if (!response.data.length) {
        toast.error('No entry found for this client')
        history.goBack()
        return
      }
      const { data: entryData } = await axios.get(
        `/behavior-entries?clientId=${params.id}&startDate=${startOfDay}&endDate=${endOfDay}`
      )
      response?.data.forEach(({ clientId, behaviorsMapping }) => {
        setData({
          client: clientId,
          behaviors: behaviorsMapping.map(({ targetId, replacementIds, _id }) => {
            const foundIndex = entryData.findIndex((entry) => entry.behaviorId === _id)
            if (foundIndex !== -1) {
              const entry = entryData[foundIndex]
              return {
                isEdit: true,
                id: _id,
                behaviorEntryId: entry._id,
                targetDescription: entry.targetDescription,
                targetRating: entry.targetRating,
                targetBehavior: targetId.name,
                targetBehaviorId: targetId._id,
                replacementBehavior: replacementIds.map((replacement) => {
                  const replacementIndex = entry.replacements.findIndex(
                    (replacementEntry) => replacementEntry.replacementId === replacement._id
                  )
                  if (replacementIndex !== -1) {
                    return {
                      ...replacement,
                      description: entry.replacements[replacementIndex].description,
                      rating: entry.replacements[replacementIndex].rating,
                    }
                  }
                  return replacement
                }),
              }
            }

            return {
              isEdit: false,
              id: _id,
              targetBehavior: targetId.name,
              targetBehaviorId: targetId._id,
              replacementBehavior: replacementIds,
            }
          }),
        })
      })
    } catch (e) {
      toast.error('Something went wrong')
    }
  }

  const handleChange = ({ target: { value, name } }, index) => {
    const dummyData = { ...data }
    dummyData.behaviors[index][name] = value
    setData({ ...dummyData })
  }

  const handleReplacementChange = ({ target: { value, name } }, replacementIndex, index) => {
    const dummyData = { ...data }
    dummyData.behaviors[index].replacementBehavior[replacementIndex][name] = value
    setData({ ...dummyData })
  }

  useEffect(() => {
    getBehaviors()
  }, [])

  const handleSubmit = async (index) => {
    const submissionData = data.behaviors[index]
    try {
      const bodyObj = {
        clientId: data.client._id,
        behaviorId: submissionData.id,
        behaviorEntryId: submissionData.id,
        administeredAt: location.state.date,
        behaviorsMapping: [
          {
            targetId: submissionData.targetBehaviorId,
            replacementIds: submissionData.replacementBehavior.map((replcement) => replcement._id),
          },
        ],
        targetId: submissionData.targetBehaviorId,
        targetDescription: submissionData.targetDescription,
        targetRating: submissionData.targetRating,
        replacements: submissionData.replacementBehavior.map((replcement) => ({
          replacementId: replcement._id,
          description: replcement.description,
          rating: replcement.rating,
        })),
      }
      
      if (submissionData.isEdit) {
        await axios.put(`/behavior-entries/${submissionData.behaviorEntryId}`, bodyObj)
      } else {
        await axios.post(`/behavior-entries`, bodyObj)
      }
      toast.success(`Entry ${submissionData.isEdit ? 'Edited' : 'Added'} Successfuly`)
      getBehaviors()
    } catch (error) {
      console.log({ error })
      toast.error('Something went wrong')
    }
  }

  return (
    <Paper elevation={3} sx={{ p: 4, borderRadius: 2, backgroundColor: '#f9f9f9' }}>
  {addBehaviour ? (
    <AddClientBehavior
      setBehaviour={setBehaviour}
      behaviour={behaviour}
      handleClose={() => {
        setBehaviour('');
        setAddBehviour(false);
      }}
      label='Add Target Behavior'
      title='Target Behavior'
    />
  ) : null}
  <Box sx={{ mb: 4 }}>
    <Typography variant='h4' sx={{ fontSize: '24px', fontWeight: 700, color: '#3f51b5', mb: 2 }}>
      Client Details
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6' sx={{ fontSize: '18px', fontWeight: 600 }}>
          Name:
        </Typography>
        <Typography variant='body1' sx={{ fontSize: '18px', color: '#555' }}>
          {data.client.firstName + ' ' + data.client.lastName}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='h6' sx={{ fontSize: '18px', fontWeight: 600 }}>
          Email:
        </Typography>
        <Typography variant='body1' sx={{ fontSize: '18px', color: '#555' }}>
          {data.client.email}
        </Typography>
      </Grid>
    </Grid>
  </Box>
  <Box>
    {data.behaviors.map((behavior, index) => (
      <Paper key={behavior.id} elevation={2} sx={{ p: 3, mb: 3, borderRadius: 2, backgroundColor: '#fff' }}>
        <Typography variant='h5' sx={{ fontWeight: 600, color: '#3f51b5', mb: 2 }}>
          Behavior {index + 1}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={8} sm={8} md={8}>
          <Box
          sx={{
            mb: 1,
            p: 1,
            border: '1px solid #ccc',
            borderRadius: 2,
            backgroundColor: '#f7f7f7',
          }}
        >
    <Grid container alignItems="center" >
      <Grid item>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            color: '#3f51b5',
            
          }}
        >
          Target Behavior
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          sx={{
            color: '#333',
            fontStyle: 'italic',
            p: 1,
            borderRadius: 1,
            marginLeft:"7rem"
          }}
        >
          {behavior.targetBehavior}
        </Typography>
      </Grid>
    </Grid>
          </Box>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <TextField
              fullWidth
              select
              label='Rating'
              name='targetRating'
              variant='outlined'
              value={behavior.targetRating}
              onChange={(e) => handleChange(e, index)}
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Description'
              name='targetDescription'
              variant='outlined'
              multiline
              rows={3}
              value={behavior.targetDescription}
              onChange={(e) => handleChange(e, index)}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            {behavior.replacementBehavior.map((replacement, replacementIndex) => (
              <Box key={replacementIndex} sx={{ mb: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={8} sm={8} md={8}>
                  <Box
                    sx={{
                      mb: 1,
                      p: 1,
                      border: '1px solid #ccc',
                      borderRadius: 2,
                      backgroundColor: '#f7f7f7',
                    }}
                  >
                  <Grid container alignItems="center" >
                    <Grid item>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          color: '#3f51b5',
                        }}
                      >
                        Replacement Behavior
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{
                          color: '#333',
                          fontStyle: 'italic',
                          p: 1,
                          borderRadius: 1,
                          marginLeft:"4rem"
                        }}
                      >
                        {replacement.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  </Box>
                  </Grid>
                  <Grid item  xs={4} sm={4} md={4}>
                    <TextField
                      fullWidth
                      select
                      label='Rating'
                      name='rating'
                      variant='outlined'
                      value={replacement.rating}
                      onChange={(e) => handleReplacementChange(e, replacementIndex, index)}
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  label='Description'
                  name='description'
                  variant='outlined'
                  multiline
                  rows={3}
                  value={replacement.description}
                  onChange={(e) => handleReplacementChange(e, replacementIndex, index)}
                />
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} textAlign='right'>
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleSubmit(index)}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
    ))}
  </Box>
</Paper>

  );
};

const ratingArray = [1, 2, 3, 4, 5]

const ListItem = ({ title, value, onChange }) => {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'start',
      }}
    >
      <Typography variant='h6' sx={{ fontSize: '14px', mr: 5, fontWeight: 500, minWidth: '200px' }}>
        {title} :
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
        {value.map((v, index) => (
          <Box key={v + index} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='p' sx={{ fontSize: '14px', color: '#000000' }}>
              {v.name}
            </Typography>
            <Box sx={{ display: 'flex', gap: 3, width: '400px' }}>
              <TextareaAutosize
                minRows={2}
                placeholder='Description'
                name='description'
                type='text'
                value={v?.description}
                style={{ width: '200px' }}
                onChange={(e) => onChange(e, index)}
              />

              <FormControl style={{ display: 'flex', width: '100px' }}>
              <Autocomplete
              id="demo-simple-autocomplete"
              options={ratingArray}
              getOptionLabel={(option) => option}
              value={v?.rating || ''}
              onChange={(event, newValue) => {
                onChange({ target: { name: 'rating', value: newValue } }, index);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ratings"
                  size="small"
                  style={{ height: '45px' }}
                />
              )}
            />
              </FormControl>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
