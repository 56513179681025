import { Troubleshoot } from '@mui/icons-material';
import * as validator from '../../reusable-components/Validation/Validations';

export const USER_CONSTANTS = [
    {
        label: "First Name",
        name: "firstName",
        type: "text",
        required: true,
        rowNumber: 1,
        validation: validator.NAME_VALIDATION
    },
    {
        label: "Last Name",
        name: "lastName",
        type: "text",
        required: true,
        rowNumber: 2,
        validation: validator.NAME_VALIDATION
    },
    {
        label: "E-mail Address",
        name: "email",
        type: "email",
        required: true,
        rowNumber: 3,
        validation: validator.EMAIL_VALIDATION
    },
    {
        label: "Role",
        name: "roleId",
        type: "multiSelect",
        options: [],
        required: true,
        rowNumber: 4
    },
    // {
    //     label: "Password",
    //     name: "password",
    //     type: "password",
    //     required: true,
    //     rowNumber: 5,
    //     validation: validator.PASSWORD_VALIDATION

    // }
]
export const USER_EDIT_CONSTANTS = [
    {
        label: "First Name",
        name: "firstName",
        type: "text",
        required: true,
        rowNumber: 1,
        validation: validator.NAME_VALIDATION
    },
    {
        label: "Last Name",
        name: "lastName",
        type: "text",
        required: true,
        rowNumber: 2,
        validation: validator.NAME_VALIDATION
    },
    {
        label: "Role",
        name: "roleId",
        type: "multiSelect",
        options: [],
        required: true,
        rowNumber: 3
    },
]

export const NEW_ROLE = {
    firstName: '',
    lastName: '',
    email: '',
    roleId: [],
    password: '',
}