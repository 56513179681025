import {Logout} from '../../app/modules/auth'

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  
console.log('set', process.env.NODE_ENV)
  if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = process.env.REACT_APP_DEV_API_URL + '/api'
  } else {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL + '/api'
  }

  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }
      const providerCode = localStorage.getItem('providerCode')

      // Add providerCode to query parameters if it exists
      if (providerCode) {
        config.params = {
          ...config.params,
          providerCode: providerCode,
        }
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      if (error.response && error.response.status === 401) {
        // Token expired, log out the user and redirect to the login screen
        store.dispatch(Logout)
        window.location.href = '/login'
      }
      return Promise.reject(error)
    }
  )
}
