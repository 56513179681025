import {Button} from '@mui/material'
import DynamicTable from '../../../reusable-components/tables/DynamicTable'
import TableButton from '../../../reusable-components/tables/TableButton'
import TableSearchBar from '../../../reusable-components/tables/TableSearchBar'
import TableTitle from '../../../reusable-components/tables/TableTitle'
import TableWrapper from '../../../reusable-components/tables/TableWrapper'
import {useEffect, useState} from 'react'
import AddBehaviourDialog from '../component/AddBehaviour'
import axios from 'axios'
import { hasPermission } from '../../../../_helper/hasPermission'
import { permissions } from '../../../../_constants/permissions'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
const selectAuth = (state) => state.auth;

const TARGET_TABLE_HEADER = ['Name']

export const TARGET_DATA_MAPPING = {
  Name: 'name',
}
export const TARGET_DATA_SORTING = {
  Name: 'name',
}

export default function TargetBehaviour() {
  const [searchTerm, setSearchTerm] = useState('')
  const [addBehaviour, setAddBehviour] = useState(false)
  const [behaviour, setBehaviour] = useState('')
  const [data, setData] = useState([])
  const [isUpdate, setIsUpdate] = useState(false)
  const [bool, setBool] = useState(false)
  const [id, setId] = useState('')
  const [filteredData,setFilteredData] = useState([])
  const [queryData, setQueryData] = useState({
      pageNumber: 1,
      pageSize: 5,
      currentPage: 1,
      totalCounts: 1,
      sortBy: 'name',
      sortDir: 'asc'
  })
  const auth = useSelector(selectAuth);
  const userPermissions = auth.user.roleId;

  useEffect(()=> {
    const getDate = async (page, pageSize, sortBy, sortDir) => {
      const targets = await axios.get(`/options?type=TARGET_BEHAVIOR&page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`)
      const {data, currentPage, totalCounts} = targets.data
      const targetData = data?.map((item) => ({id: item._id, name: item.name}))
      setData(targetData)
      setFilteredData(targetData)
      setQueryData((prevState) => {
          return {
              ...prevState,
              currentPage,
              totalCounts,
          };
      });
    }
    getDate(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
  }, [bool, queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir])

  const handleSearchChange = async (event) => {
    event.persist()
    const searchTerm = event.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    try{
        const response = await axios.get(`/options/search?type=TARGET_BEHAVIOR&searchTerm=${searchTerm}`)
        setFilteredData(response.data)
    } catch(err) {
        console.log('Error fetching search result', err)
    }
}
  const handleSubmit = async () => {
    try {
     const response = await axios.post('/options', {
        name: behaviour,
        type: 'TARGET_BEHAVIOR',
      })
      const { message, success } = response.data
      if(!success){
        return toast.error(message)
      }
      toast.success(message)
      handleClose()
      setBool(!bool)
    } catch (e) {
      console.error(e)
    }
  }

  const handleEdit = async () => {
    try {
     const response = await axios.put(`/options/${id}`, {
        name: behaviour,
      })
      const { message, success } = response.data
      if(!success){
        return toast.error(message)
      }
      toast.success(message)
      handleClose()
      setBool(!bool)
    } catch (e) {
      console.error(e)
    }
  }

  const handleUpdate = (e) => {
    setBehaviour(e.name)
    setAddBehviour(true)
    setIsUpdate(true)
    setId(e.id)
  }

  const handleClose = () => {
    setId('')
    setIsUpdate(false)
    setBehaviour('')
    setAddBehviour(false)
  }
  return (
    <>
      <div className='card'>
        {addBehaviour ? (
          <AddBehaviourDialog
            setBehaviour={setBehaviour}
            behaviour={behaviour}
            handleClose={handleClose}
            handleSubmit={isUpdate ? handleEdit : handleSubmit}
            label='Add Target Behavior'
            title='Target Behavior'
          />
        ) : null}
        <div className='card-body'>
          <TableTitle title={'Target Behavior'}></TableTitle>
          <TableWrapper
            searchField={
              hasPermission(userPermissions, permissions.behavior_target_search) && 
                <TableSearchBar
                  label='Search'
                  variant='outlined'
                  size='small'
                  value={searchTerm}
                  handleSearchChange={handleSearchChange}
                  margin='normal'
                  inputAdornmentPosition='start'
                />
            }
            tableButtons={
              hasPermission(userPermissions, permissions.behavior_target_create) && 
                <TableButton
                variant='contained'
                color='primary'
                sx={{float: 'right', marginTop: '1.7rem'}}
                onClick={(e) => {
                  setAddBehviour(true)
                }}
                title='Add New'
              />
            }
            dynamicTable={
              <DynamicTable
                columns={TARGET_TABLE_HEADER}
                data={filteredData}
                columnDataMapping={TARGET_DATA_MAPPING}
                sortingColumns={TARGET_DATA_SORTING}
                canUpdate={hasPermission(userPermissions, permissions.behavior_target_update)}
                onUpdate={handleUpdate}
                title={'Are you sure you want to discontinue those medication?'}
                setQueryData={setQueryData}
                queryData={queryData}
              />
            }
          />
        </div>
      </div>
    </>
  )
}
