import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {Tabs, Tab, useTheme, useMediaQuery} from '@mui/material'

function AdminBillingHeader() {
  const location = useLocation()

  const getActiveTabIndex = () => {
    if (location.pathname.startsWith('/admin/billing/attendance-type')) return 0
    if (location.pathname.startsWith('/admin/billing/description-code')) return 1
    if (location.pathname.startsWith('/admin/billing/funding-source')) return 2
    if (location.pathname.startsWith('/admin/billing/payers')) return 3
    if (location.pathname.startsWith('/admin/billing/billing-provider')) return 4
    return false
  }

  const value = getActiveTabIndex()

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-3 pb-0'>
          <div className='d-flex overflow-auto h-60px'>
            <Tabs
              value={value}
              variant='scrollable'
              scrollButtons={isSmallScreen ? 'auto' : 'on'}
              aria-label='Admin Billing Tabs'
              sx={{
                flexGrow: 1,
                '.MuiTab-root': {
                  textTransform: 'none',
                  minWidth: 150,
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                },
                '.Mui-selected': {
                  color: theme.palette.primary.main,
                },
                '.MuiTabs-indicator': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              <Tab
                sx={{flexGrow: '1'}}
                label='Attendance Type'
                component={Link}
                to='/admin/billing/attendance-type/list'
              />
              <Tab
                sx={{flexGrow: '1'}}
                label='Description Code'
                component={Link}
                to='/admin/billing/description-code/list'
              />
              <Tab
                sx={{flexGrow: '1'}}
                label='Funding Source'
                component={Link}
                to='/admin/billing/funding-source/list'
              />
              <Tab
                sx={{flexGrow: '1'}}
                label='Payers List'
                component={Link}
                to='/admin/billing/payers/list'
              />
              <Tab
                sx={{flexGrow: '1'}}
                label='Billing Provider'
                component={Link}
                to='/admin/billing/billing-provider/list'
              />
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminBillingHeader
