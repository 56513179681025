
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import New from './new/New.tsx'
import Summary from './summary/Summary.tsx'
import Archive from './archive/Archive'
import AttendanceHeader from './AttendanceHeader'
import Search from './search/Search'

function AttendanceIndex() {
    return (
        <div>
            <AttendanceHeader />
        </div>
    )
}

export default AttendanceIndex