import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { TextareaAutosize } from '@mui/material'
import ReactSelect from 'react-select'
import Table from './DataTable'
import { GroupServices } from '../services'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify';

const columns = [{ field: 'name', headerName: 'Name', width: 130 }]

export default function DescriptionDialog({ open, type, groupId, setGroupId, setOpen, setGroupList, creatorId, creatorName }: any) {
  const [selectedOption, setSelectedOption] = React.useState(null)
  const [name, setName] = React.useState('')
  const [members, setMembers] = React.useState([])
  const [description, setDescription] = React.useState('')
  const [removeMember, setRemoveMember] = React.useState([])
  const [excludedMember, setExcludedMember] = React.useState([])

  React.useEffect(() => {
    if (groupId) {
      fetchGroupInfo()
      fetchExcludedMembers()
    } else {
      fetchMember()
    }
    setSelectedOption([
      {
        value: creatorId,
        label: creatorName,
        isDisabled: true,
      }
    ]);
  }, []);

  const fetchMember = async () => {
    try {
      const { data } = await GroupServices.getAllMembers()
      setExcludedMember(
        data.map((member: any) => ({
          value: member._id,
          label: `${member.firstName} ${member.lastName}`,
        })))
    } catch (error) {
      console.log(error)
    }
  }

  const fetchExcludedMembers = async () => {
    try {
      const { data } = await GroupServices.getExcludedMembers(groupId)
      setExcludedMember(
        data.map((member: any) => ({
          value: member._id,
          label: `${member.firstName} ${member.lastName}`,
        }))
      )
    } catch (error) {
      console.log(error)
    }
  }

  const fetchGroupInfo = async () => {
    try {
      const {
        data: { name, members, description },
      } = await GroupServices.get(groupId)
      setName(name)
      setDescription(description)
      setMembers([
        ...members.map((m) => ({ id: m._id, name: `${m.firstName} ${m.lastName}` })),
      ])
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const handleClose = () => {
    if (groupId) {
      setGroupId(null)
    }
    setOpen('')
  }
  const handleSelectChange = (selectedOptions) => {
    const creatorOption = { value: creatorId, label: creatorName, isDisabled: true };

    const updatedSelectedOptions = [
      creatorOption,
      ...selectedOptions.filter((opt) => opt.value !== creatorId),
    ];

    setSelectedOption(updatedSelectedOptions); 
  };
  const handleSubmit = async () => {
    const allUserIds = selectedOption?.map((opt) => opt.value) || [];
    const selectedMemberCount = selectedOption?.length || 0;
    if (selectedMemberCount < 3) {
      toast.error(' Please add at least three members to the group.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (type === 'edit') {
      try {
        await GroupServices.saveGroup(groupId, {
          name,
          description,
          addUserIds: allUserIds,
          removeUserIds: removeMember,
        })
        handleClose()
      } catch (error) {
        console.log(error)
      }
      return
    }

    try {
      await GroupServices.addGroup({
        name,
        description,
        userIds: allUserIds,
      })
      axios.get('groups/list').then(({ data }) => {
        if (data?.length) {
          setGroupList(data)
        }
      })
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm' style={{height:"80%"}}>
       <DialogTitle>
          <h2>{type === 'add' ? 'Add Group' : 'Group Description'}</h2>
        </DialogTitle>
        <DialogContent>
          <div className='container-fluid' style={{height:'34rem'}}>
            <div className='row mb-3'>
              <div className='col-12'>
                <TextField
                  autoFocus
                  margin='dense'
                  id='name'
                  label='Name'
                  value={name}
                  onChange={({ target: { value } }) => setName(value)}
                  type='text'
                  fullWidth
                  variant='outlined'
                  size='medium'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-12'>
                <TextareaAutosize
                  className='form-control'
                  aria-label='Group Description'
                  placeholder='Description'
                  value={description}
                  onChange={({ target: { value } }) => setDescription(value)}
                />
              </div>
            </div>
            <h3>Add Members</h3>
            <div className='row mb-3'>
              <div className='col-12'>
                <ReactSelect
                  isMulti
                  defaultValue={selectedOption}
                  value={selectedOption}
                  onChange={handleSelectChange}
                  options={excludedMember}
                  isOptionDisabled={(option) => option.isDisabled}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    MultiValueRemove: ({ data, innerProps }) => {
                      const firstUser = selectedOption[0]?.value; 
                      return data.value === firstUser ? null : (
                        <div style={{ cursor: 'pointer' }} {...innerProps}>×</div>
                      );
                    }
                  }}
                />
              </div>
            </div>
            {type === 'edit' ? (
              <div className='row mb-3'>
                <div className='col-12'>
                  <h3>Remove Members</h3>
                  <Table columns={columns} data={members} setSelection={setRemoveMember} />
                </div>
              </div>
            ):null}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{type === 'edit' ? 'Save' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
