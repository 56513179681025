import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import ClaimBulkUpload from './claim-bulk-update/ClaimBulkUpload'
import ClaimBulkUploadSearch from './claim-bulk-update-upload-search/ClaimBulkUploadSearch'
import ManualReconciliation from './manual-reconciliation/ManualReconciliation'
import ManualReconciliationUploadSearch from './manual-reconciliation-upload-search/ManualReconciliationUploadSearch'
import ExcelUploadHeader from './ExcelUploadHeader'

function ProfessionalClaimExcelUploadIndex() {
  return (
    <div>
      <ExcelUploadHeader/>
    </div>
  )
}

export default ProfessionalClaimExcelUploadIndex