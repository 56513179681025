import React from 'react'
import {Link} from 'react-router-dom' // If using React Router
import {IconButton, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import './styles.css'

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '-4rem',
    marginRight:"10px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '1rem',
    curser: 'pointer',
  },
}))

const DynamicIcon = ({
  icon,
  svg,
  png,
  title,
  link,
}: {
  icon?: any
  svg?: string
  png?: any
  title?: string
  link?: string
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <IconButton component={Link} to={link} color='primary'>
        <div className='icons'>
          <div className='icon'>
            <div className='bg'></div>
            <div className='glass'>
              <i>
                {icon && React.createElement(icon, {fontSize: '36px'})}
                {svg && <span dangerouslySetInnerHTML={{__html: svg}} />}
                {png && <img src={png} alt={title} style={{width: '60px', height: '60px'}} />}
              </i>
            </div>
          </div>
        </div>
      </IconButton>
      <Typography
        variant='subtitle1'
        fontFamily='Poppins'
        fontWeight={500}
        fontSize={18}
        align='center'
      >
        {title}
      </Typography>
    </div>
  )
}

export default DynamicIcon
