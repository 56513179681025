import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import {getOneScoringMethod, updateScoringMethod} from '../store/action'
import {NEW_METHOD_INPUTS} from '../../GENERAL_CONSTANTS'

const selectAuth = (state) => state.auth

export const EditMethod = () => {
  const {id} = useParams()
  const [data, setData] = useState({})
  const [uiElements, setUIElements] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useSelector(selectAuth)

  const [inputArr, setInputArr] = useState([
    {
      levelName: '',
      levelAcronym: '',
      isReportable: false,
    },
  ])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await dispatch(getOneScoringMethod(id))
        const {data} = result.payload.data
        setInputArr(data?.scoringLevels || [])
        setData(data)
      } catch (error) {
        console.error('Error fetching scoring method data', error)
      }
    }
    fetchData()
  }, [id, dispatch])

  useEffect(() => {
    const inputs = NEW_METHOD_INPUTS
    const newUIElements = [...inputs]

    inputArr.forEach((level, index) => {
      newUIElements.push(
        {
          index,
          label: 'Level Name',
          name: `${index}levelName`,
          value: level.levelName,
          onChange: (e, value) => onChangeLevel(value, index, 'levelName'),
          type: 'text',
          required: true,
        },
        {
          index,
          label: 'Level Acronym',
          name: `${index}levelAcronym`,
          value: level.levelAcronym,
          onChange: (e, value) => onChangeLevel(value, index, 'levelAcronym'),
          type: 'text',
          required: true,
        },
        ...(inputArr.length > 1
          ? [
              {
                index,
                label: 'Delete',
                variant: 'outlined',
                type: 'button',
                onClick: () => onRemoveLevel(index),
              },
            ]
          : [])
      )
    })

    setUIElements(newUIElements)
  }, [inputArr])

  const onChangeLevel = (value, index, field) => {
    const updatedInputArr = [...inputArr]
    updatedInputArr[index][field] = value
    setInputArr(updatedInputArr)
  }

  const onAddAnotherLevelClick = () => {
    const newLevel = {
      levelName: '',
      levelAcronym: '',
      isReportable: false,
    }
    setInputArr((prev) => [...prev, newLevel])
  }

  const onRemoveLevel = (index) => {
    const updatedInputArr = inputArr.filter((_, i) => i !== index)
    setInputArr(updatedInputArr)
  }

  const handleSubmitForm = async (formData) => {
    try {
      const updatedData = {
        ...data,
        methodName: formData.methodName,
        scoringLevels: inputArr,
      }
      const response = await dispatch(updateScoringMethod(updatedData))
      if (response.payload.message === 'Scoring method updated successfully') {
        toast.success('Updated successfully')
        history.push('/admin/general/scoring-method/list')
      } else {
        toast.error('Failed to update')
      }
    } catch (error) {
      toast.error('Error updating scoring method')
    }
  }

  const buttons = [
    {label: 'Cancel', variant: 'contained', type: 'button', onClick: () => history.goBack()},
    {label: 'Save', variant: 'contained', type: 'submit'},
    {
      label: 'Add Level',
      variant: 'contained',
      type: 'button',
      onClick: onAddAnotherLevelClick,
    },
  ]

  return (
    <FormWrapper
      title='Edit Scoring Method'
      content={
        <DynamicForm
          fields={uiElements}
          data={data}
          onSubmit={handleSubmitForm}
          buttons={buttons}
        />
      }
    />
  )
}
