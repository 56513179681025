import * as validator from '../../../reusable-components/Validation/Validations'
export const NEW_PROGRAM_INPUTS = [
  {
    label: 'Program Name',
    name: 'programName',
    type: 'text',
    required: true,
    rowNumber: 1,
    validation: validator.NAME_VALIDATION,
  },
  {
    label: 'Site Name',
    name: 'siteName',
    type: 'select',
    options: [],
    required: true,
    rowNumber: 1,
  },
  {
    label: 'Program Type',
    name: 'programType',
    type: 'select',
    options: [
      {label: 'Adult Day Care', value: 'Adult Day Care'},
      {label: 'Adult Day Training', value: 'Adult Day Training'},
      {label: 'Behavior Therapy', value: 'Behavior Therapy'},
      {label: 'Community Based Day Support', value: 'Community Based Day Support'},
      {label: 'Community Integration Education', value: 'Community Integration Education'},
      {label: 'Day Habilitation', value: 'Day Habilitation'},
      {label: 'Employment Support', value: 'Employment Support'},
      {label: 'Family Support', value: 'Family Support'},
      {label: 'Home Modification', value: 'Home Modification'},
      {label: 'In-Home Supports', value: 'In-Home Supports'},
      {label: 'Nursing', value: 'Nursing'},
      {label: 'Occupational Therapy', value: 'Occupational Therapy'},
      {label: 'Personal Care', value: 'Personal Care'},
      {label: 'Physical Therapy', value: 'Physical Therapy'},
      {label: 'Respite Care', value: 'Respite Care'},
      {label: 'Supported Employment', value: 'Supported Employment'},
      {label: 'Supported Living', value: 'Supported Living'},
      {label: 'Transportation', value: 'Transportation'},
      {label: 'Pre-vocational services', value: 'Pre-vocational services'},
      {label: 'Transitional employment', value: 'Transitional employment'},
      {label: 'Group home', value: 'Group home'},
      {label: 'Supported living', value: 'Supported living'},
      {label: 'Respite care', value: 'Respite care'},
      {label: 'Community integration', value: 'Community integration'},
      {label: 'Behavioral consultation', value: 'Behavioral consultation'},
      {label: 'Crisis intervention', value: 'Crisis intervention'},
      {label: 'Mental health counseling', value: 'Mental health counseling'},
      {label: 'Substance abuse treatment', value: 'Substance abuse treatment'},
      {label: 'Vocational assessment', value: 'Vocational assessment'},
      {label: 'Day habilitation', value: 'Day habilitation'},
      {label: 'Therapeutic recreation', value: 'Therapeutic recreation'},
    ],
    required: true,
    rowNumber: 2,
  },
  {
    label: 'Program ID',
    name: 'programID',
    type: 'number',
    required: true,
    rowNumber: 2,
  },
  {
    label: 'Program Code',
    name: 'programCode',
    type: 'text',
    required: false,
    rowNumber: 3,
  },
  {
    label: 'Program Cost Center Number',
    name: 'programCostCenterNumber',
    type: 'number',
    required: true,
    rowNumber: 3,
  },
  {
    label: 'Capacity',
    name: 'capacity',
    type: 'number',
    required: true,
    rowNumber: 4,
  },
  {
    sectionHeading: 'Primary Contact',
    rowNumber: 5,
  },
  {
    label: 'Name',
    name: 'name',
    type: 'text',
    required: false,
    rowNumber: 6,
    validation: validator.NAME_VALIDATION,
  },
  {
    label: 'Primary Phone',
    name: 'primaryPhone',
    type: 'phoneNumber',
    required: false,
    rowNumber: 6,
    validation: validator.PHONE_NUMBER_VALIDATION,
  },
  {
    label: 'Secondary Phone',
    name: 'secondaryPhone',
    type: 'phoneNumber',
    required: false,
    rowNumber: 7,
    validation: validator.PHONE_NUMBER_VALIDATION,
  },
  {
    sectionHeading: 'Secondary Contact',
    rowNumber: 8,
  },
  {
    label: 'Name',
    name: 'secName',
    type: 'text',
    required: false,
    rowNumber: 9,
    validation: validator.NAME_VALIDATION,
  },
  {
    label: 'Primary Phone',
    name: 'secPrimaryPhone',
    type: 'phoneNumber',
    required: false,
    rowNumber: 9,
    validation: validator.PHONE_NUMBER_VALIDATION,
  },
  {
    label: 'Secondary Phone',
    name: 'secSecondaryPhone',
    type: 'phoneNumber',
    required: false,
    rowNumber: 10,
    validation: validator.PHONE_NUMBER_VALIDATION,
  },
]

export const NEW_METHOD_INPUTS = [
  {
    label: 'Method Name',
    name: 'methodName',
    type: 'text',
    required: true,
    rowNumber: 1,
  },
]

export const SITE_CONSTANT_INPUTS = [
  {
    label: 'Site Name',
    name: 'siteName',
    type: 'text',
    required: true,
    rowNumber: 1,
    validation: validator.NAME_VALIDATION,
  },
  {
    label: 'Site ID',
    name: 'siteID',
    type: 'number',
    required: false,
    rowNumber: 1,
  },
  {
    label: 'Site Code',
    name: 'siteCode',
    type: 'text',
    required: false,
    rowNumber: 2,
  },
  {
    label: 'Program Cost Center Number',
    name: 'programCostCenterNumber',
    type: 'number',
    required: false,
    rowNumber: 2,
  },
  {
    addressInfo: true,
    rowNumber: 4,
  },
  {
    label: 'Accuracy',
    name: 'accuracy',
    type: 'number',
    required: true,
    rowNumber: 7,
  },
  {
    label: 'Secondary Phone',
    name: 'secondaryPhone',
    type: 'phoneNumber',
    required: false,
    rowNumber: 7,
    validation: validator.PHONE_NUMBER_VALIDATION,
  },
]

export const SITE_ADDRESS_INPUT = [
  {
    addressInfo: true,
    rowNumber: 3,
  },
]

export const TYPE_CONSTANT_INPUT = [
  {
    label: 'Name',
    name: 'name',
    type: 'text',
    required: true,
    rowNumber: 1,
    validation: validator.NAME_VALIDATION,
  },
]
export const NEW_SHARED_CONTACT_INPUTS = [
  {
    label: 'First Name',
    name: 'firstName',
    type: 'text',
    required: true,
    rowNumber: 1,
    validation: validator.NAME_VALIDATION,
  },
  {
    label: 'Last Name',
    name: 'lastName',
    type: 'text',
    required: true,
    rowNumber: 1,
    validation: validator.NAME_VALIDATION,
  },
  {
    label: 'Type',
    name: 'type',
    type: 'select',
    options: [],
    required: true,
    rowNumber: 2,
  },
  {
    label: 'Title',
    name: 'title',
    type: 'select',
    options: [
      {label: 'Mr', value: 'Mr'},
      {label: 'Miss', value: 'Miss'},
      {label: 'Mrs', value: 'Mrs'},
      {label: 'Ms', value: 'Ms'},
      {label: 'Mx', value: 'Mx'},
    ],
    required: false,
    rowNumber: 2,
  },
  {
    label: 'Organization Name',
    name: 'organizationName',
    type: 'text',
    required: false,
    rowNumber: 3,
    validation: validator.NAME_VALIDATION,
  },
  {
    label: 'NPI Number',
    name: 'npiNumber',
    type: 'number',
    required: false,
    rowNumber: 3,
  },
  {
    label: 'Speciality',
    name: 'speciality',
    type: 'select',
    options: [
      {label: '- Select Speciality -', value: ''},
      {label: 'Allergy & Immunology', value: 'Allergy & Immunology'},
      {label: 'Anesthesiologist', value: 'Anesthesiologist'},
      {label: 'Audiology', value: 'Audiology'},
      {label: 'Cardiology', value: 'Cardiology'},
      {label: 'Chiropractor', value: 'Chiropractor'},
      {label: 'Colonoscopy', value: 'Colonoscopy'},
      {label: 'Dentist', value: 'Dentist'},
      {label: 'Dermatology', value: 'Dermatology'},
      {label: 'Ear, Nose & Tongue', value: 'Ear, Nose & Tongue'},
      {label: 'Emergency Medicine', value: 'Emergency Medicine'},
      {label: 'Endocrinologist', value: 'Endocrinologist'},
      {
        label: 'Endocrinology, Diabetes & Metabolism',
        value: 'Endocrinology, Diabetes & Metabolism',
      },
      {
        label: 'Endovascular Surgical Neuroradiology',
        value: 'Endovascular Surgical Neuroradiology',
      },
      {label: 'Epidemiologist', value: 'Epidemiologist'},
      {label: 'Family Medicine', value: 'Family Medicine'},
      {label: 'Gastroenterologist', value: 'Gastroenterologist'},
      {label: 'General Medicine', value: 'General Medicine'},
      {label: 'Geriatrics', value: 'Geriatrics'},
      {label: 'Gynecologist', value: 'Gynecologist'},
      {label: 'Hematologist', value: 'Hematologist'},
      {label: 'Hepatology', value: 'Hepatology'},
      {label: 'Infectious Diseases', value: 'Infectious Diseases'},
      {label: 'Internal Medicine', value: 'Internal Medicine'},
      {label: 'Medical Genetics', value: 'Medical Genetics'},
      {label: 'Neonatologist', value: 'Neonatologist'},
      {label: 'Nephrologist', value: 'Nephrologist'},
      {label: 'Neurological Surgeon', value: 'Neurological Surgeon'},
      {label: 'Neurology', value: 'Neurology'},
      {label: 'Nuclear Radiology', value: 'Nuclear Radiology'},
      {label: 'Nutritionist', value: 'Nutritionist'},
      {label: 'Obstetrics', value: 'Obstetrics'},
      {label: 'Occupational Therapist', value: 'Occupational Therapist'},
      {label: 'Oncology', value: 'Oncology'},
      {label: 'Ophthalmology', value: 'Ophthalmology'},
      {label: 'Orthopedics', value: 'Orthopedics'},
      {label: 'Otolaryngology', value: 'Otolaryngology'},
      {label: 'Pain Management', value: 'Pain Management'},
      {label: 'Pathologist', value: 'Pathologist'},
      {label: 'Pediatrics', value: 'Pediatrics'},
      {label: 'Phlebotomy', value: 'Phlebotomy'},
      {label: 'Physiatry', value: 'Physiatry'},
      {label: 'Physical Medicine & Rehabilitation', value: 'Physical Medicine & Rehabilitation'},
      {label: 'Physical Therapy', value: 'Physical Therapy'},
      {label: 'Plastic Surgery', value: 'Plastic Surgery'},
      {label: 'Podiatry', value: 'Podiatry'},
      {label: 'Psychiatry', value: 'Psychiatry'},
      {label: 'Psychology', value: 'Psychology'},
      {label: 'Public Health', value: 'Public Health'},
      {label: 'Pulmonology', value: 'Pulmonology'},
      {label: 'Rheumatology', value: 'Rheumatology'},
      {label: 'Speech Therapy', value: 'Speech Therapy'},
      {label: 'Surgery', value: 'Surgery'},
      {label: 'Urology', value: 'Urology'},
      {label: 'Wound Care', value: 'Wound Care'},
      {label: 'Other', value: 'Other'},
    ],
    required: false,
    rowNumber: 4,
  },
  {
    label: 'E-mail',
    name: 'email',
    type: 'email',
    required: false,
    rowNumber: 4,
    validation: validator.EMAIL_VALIDATION,
  },
  {
    sectionHeading: 'Residential Address',
    rowNumber: 5,
  },
  {
    addressInfo: true,
    rowNumber: 6,
  },
  {
    sectionHeading: 'Mailing Address',
    rowNumber: 8,
  },
  {
    mailingInfo: true,
    rowNumber: 9,
  },
]
export const NEW_SHARED_CONTACT_DATA = {
  title: '',
  firstName: '',
  lastName: '',
  organizationName: '',
  npiNumber: '',
  type: '',
  speciality: '',
  email: '',
  primaryPhone: '',
  secondaryPhone: '',
  additionalPhone: '',
  country: '',
  st1: '',
  st2: '',
  city: '',
  state: '',
  zipCode: '',
  mailingSt1: '',
  mailingSt2: '',
  mailingCity: '',
  mailingState: '',
  mailingZipCode: '',
  mailingCountry: '',
  mailingPrimaryPhone: '',
  mailingSecondaryPhone: '',
  mailingAdditionalPhone: '',
}

export const TYPE_DATA = {
  name: '',
}

export const NEW_SITE_DATA = {
  siteName: '',
  siteID: 0,
  siteCode: '',
  programCostCenterNumber: 0,
  st1: '',
  st2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  phoneNumber: '',
  secondaryPhone: '',
}

export const NEW_PROGRAM_DATA = {
  programName: '',
  programID: 0,
  programType: '',
  programCode: '',
  name: '',
  primaryPhone: 0,
  secondaryPhone: 0,
  secName: '',
  secPrimaryPhone: 0,
  secSecondaryPhone: 0,
  programCostCenterNumber: 0,
  capacity: 0,
  siteName: '',
  individualsAdded: [],
  createDate: '',
}

export const NEW_SCORING_DATA = {
  ScoringMethodName: '',
  ScoringMethodLevel: '',
  ScoringMethodAcronym: '',
}
