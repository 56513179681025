import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import ListPrograms from '../../../../../pages/admin/general/enrollment-by-program/ListPrograms'
import ProgramIndividuals from '../../../../../pages/admin/general/enrollment-by-program/programListing/programIndividuals'
import {useSelector} from 'react-redux'
import {hasPermission} from '../../../../../../_helper/hasPermission'
import {RouteWithPermission} from '../../../../../../_helper/routesWithPermission'
import {Error404} from '../../../../../modules/errors/components/Error404'
import {permissions} from '../../../../../../_constants/permissions'

const EnrollmentBPIndexRoutes: React.FC = () => {
  const auth = useSelector((state: {auth: any}) => state.auth)
  const userPermissions = auth.user.roleId

  const canUpdateEnrollmentByProgram = hasPermission(
    userPermissions,
    permissions.admin_general_enrollement_by_program_update
  )
  const canViewEnrollmentByProgram = hasPermission(userPermissions, permissions.admin_general_enrollement_by_program_view)
  
  const getDefaultPath = () => {
    if (canViewEnrollmentByProgram) return '/admin/general/enrollment-by-program'
    if (canUpdateEnrollmentByProgram) return '/admin/general/enrollment-by-program/:programID'
    return '/error/404'
  }
  return (
    <Switch>
      <Route exact path='/admin/general/enrollment-by-programs'>
        <Redirect to={getDefaultPath()} />
      </Route>
      <RouteWithPermission
        path='/admin/general/enrollment-by-program/:programID'
        component={ProgramIndividuals}
        hasPerm={canViewEnrollmentByProgram && canUpdateEnrollmentByProgram}
      />
      <RouteWithPermission
        path='/admin/general/enrollment-by-program'
        component={ListPrograms}
        hasPerm={canViewEnrollmentByProgram}
      />
      <Route path='/error/404' component={Error404} />
      {/* <Route path='/admin/care/enrollment/programindividual/' component={ProgramIndividuals} /> */}
      {/*  <Route path='/admin/care/enrollment/individualsprogram/*' component={IndividualProgramList} /> */}
    </Switch>
  )
}

export default EnrollmentBPIndexRoutes
