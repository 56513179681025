import React from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

export default function RoleModal({ title, showDeleteModal, setShowDeleteModal, handleConfirmDelete, handleDiscardDelete }) {

    return (
        <Modal
            open={showDeleteModal}
            onClose={handleDiscardDelete}
            aria-labelledby="delete-modal-title"
            aria-describedby="delete-modal-description"
        >
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 600,
                backgroundColor: 'white',
                boxShadow: "none",
                p: 4,
                height: 160,
                textAlign: 'center',
                paddingTop: '18px',
                borderRadius: '6px'

            }}>
                <h2 id="delete-modal-title" style={{ fontWeight: '600', padding:'6px' }}>Confirmation</h2>
                <p id="delete-modal-description" style={{ fontWeight: '500' }}>{title}</p>
                <Button variant="contained" sx={{
                    height: '3.2rem',
                    marginRight: '11px',
                    backgroundColor: '#F1416C',
                    fontWeight: '700',
                    borderRadius:'6px',
                    '&:hover': {
                        backgroundColor: '#cc3f61', // Change the background color on hover
                    },
                }} onClick={handleConfirmDelete}>
                    Confirm
                </Button>
                <Button variant="contained" sx={{
                    height: '3rem',
                    backgroundColor: '#F5F8FA',
                    color: '#7E8299',
                    fontWeight: '700',
                    borderRadius:'6px',
                    '&:hover': {
                        backgroundColor: '#e1e7eb', // Change the background color on hover
                    },
                }} onClick={handleDiscardDelete}>
                    Discard
                </Button>
            </div>
        </Modal>)
}