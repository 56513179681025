import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { Autocomplete, TextField } from '@mui/material';

import { useHistory } from 'react-router-dom'
import { Box } from '@mui/material'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))


export default function CustomizedDialogs({ handleClose, button,setData, handleGeneratePDF,data }) {
    const [selectedClient, setSelectedClient] = React.useState(null)
    const navigate = useHistory('')
    const [date, setDate] = React.useState(new Date())
    const handleChange = ({ target: { value } }) => {
        console.log(value)
        setSelectedClient(value)
    }
    const handleAddMedication = () => {
        handleClose()
        navigate.push(`/mars/individual-medication/${selectedClient}`, { date: date.toISOString() })
    }
    const uniqueClients = data.filter((client, index, self) =>
        index === self.findIndex(c => c?.clientId === client?.clientId)
      );
    const handleAddPdf = async () => {
        handleClose();
        const dateString = date.toISOString();
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1;
        const clientdata = {
            clientId: selectedClient,
            month: month,
            year: year
        };
        const matchingClient = data.find(data => data?.clientId === selectedClient);
        if (matchingClient) {
            const { firstName, lastName } = matchingClient.client;
            const clientName = `${firstName} ${lastName}`;
            if (selectedClient !== null && clientdata.month && clientdata.year) {
                await handleGeneratePDF({ ...clientdata, clientName });
            }
            setSelectedClient(null);
        }
    };

    return (
        <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={true}>
            <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
                Select Client
            </DialogTitle>
            <IconButton
                aria-label='close'
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <FormControl style={{ display: 'flex', width: '100%' }}>
                    <Autocomplete
                    id="client-autocomplete"
                    options={uniqueClients}
                    getOptionLabel={(option) => `${option.client?.firstName} ${option.client?.lastName}`}
                    value={uniqueClients.find(client => client?.clientId === selectedClient) || null}
                    onChange={(event, newValue) => {
                    setSelectedClient(newValue ? newValue.clientId : null);
                    }}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Client"
                        variant="outlined"
                        size="small"
                    />
                    )}
                    size="small"
                />
                </FormControl>
                {
                    button === 'AddMedication' ?
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{ mt: 2 }}
                                label='Date'
                                value={dayjs(date)}
                                onChange={(newValue) => setDate(newValue)}
                                slotProps={{ textField: { size: 'small', style: { width: '350px' } } }}
                            />
                        </LocalizationProvider>
                        :
                        <>
                            <Box className='g-3' style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        sx={{ mt: 2 }}
                                        value={dayjs(date)}
                                        label={'Month'}
                                        views={['month']}
                                        size='small'
                                        fillWidth
                                        onChange={(newValue) => setDate(newValue)}
                                        slotProps={{ textField: { size: 'small', style: { width: '250px' } } }}
                                        renderDay={(day, _value, DayProps) => (
                                            <Box {...DayProps} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {dayjs(day).format('MMM')}
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        sx={{ mt: 2, px: 1 }}
                                        value={dayjs(date)}
                                        label={'Year'}
                                        views={['year']}
                                        fillWidth
                                        onChange={(newValue) => setDate(newValue)}
                                        slotProps={{ textField: { size: 'small', style: { width: '250px' } } }}
                                        renderDay={(day, _value, DayProps) => (
                                            <Box {...DayProps} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {dayjs(day).format('MMM')}
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </>
                }
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={button === "AddMedication" ? handleAddMedication : handleAddPdf}>
                    Next
                </Button>
            </DialogActions>
        </BootstrapDialog>
    )
}
