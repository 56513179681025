import {createSlice} from '@reduxjs/toolkit'
import {getScoringMethod} from '../action'

interface ScoringMethod {
  _id: String
  method_name: String
  level_name: String
  level_acronym: String
}

interface ScoringMethodState {
  scoringMethod: ScoringMethod[] | any
  isLoading: boolean
}

const initialState: ScoringMethodState = {
  scoringMethod: [],
  isLoading: false,
}

export const scoringInitialState = createSlice({
  name: 'scoringMethod',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getScoringMethod.fulfilled, (state, action: any) => {
        state.scoringMethod = action.payload.data
        state.isLoading = false
      })
      .addCase(getScoringMethod.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getScoringMethod.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default scoringInitialState.reducer
