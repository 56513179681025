import React, { useEffect, useState } from 'react'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { CAREGIVER_PROFILE_DOCUMENTS_FORM_INPUTS } from '../../../CAREGIVER_FORM_CONSTANTS'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CareGiverProfileTabs from '../ProfileHeader'

export default function AddCaregiverProfileDocuments() {
    const [uiElementsList, setUiElementsList] = useState([])
    const params : { id: string }= useParams()
    const history = useHistory()
    useEffect(() => {
        const tempElementsList = CAREGIVER_PROFILE_DOCUMENTS_FORM_INPUTS()
        setUiElementsList(tempElementsList)
    }, [])

    const handleSubmitForm = async (formData) => {
        const newFormData = new FormData()
        newFormData.append('file', formData['file'])
        newFormData.append('name', formData['name'])
        newFormData.append('expiryDate', formData['expiryDate']||new Date())
        await axios
            .patch('/caregivers/addDocument/' + params.id, newFormData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((res) => {
                if (res.status == 201) {
                toast.success('Document uploaded successfully')
                history.push(`/caregivers/profile/documents/${params.id}`)
                } 
            })
            .catch((err) => {
                toast.error('Failed to upload document')
            })
    }

    const buttons = [
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ]
    return (
        <>
            <CareGiverProfileTabs />
            <FormWrapper
                title='New Certificate'
                content={
                    <DynamicForm
                        fields={uiElementsList}
                        data={{}}
                        onSubmit={handleSubmitForm}
                        buttons={buttons}
                    />
                }
            ></FormWrapper>
        </>
    )
}
