import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  TextField,
  TextareaAutosize,
} from '@mui/material'
import axios from 'axios'
import {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import {toast} from 'react-toastify'

const NewMessage = ({
  open,
  setOpen,
  users,
  setMessage,
  setMessages,
  messages,
  socket,
  setContacts,
  setSelectedContact
}) => {
  const [options, setOptions] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const [newSelectedContact, setNewSelectedContact] = useState('')

  const auth = useSelector((state: {auth: any}) => state.auth)
  const sender = auth.user

  useEffect(() => {
    setOptions(
      users
        .filter(user => sender._id !== user._id)
        .map(user => ({ label: user.firstName + ' ' + user.lastName, value: user._id }))
    );
  }, [users, sender._id])

  const handleClose = () => {
    setOpen('')
  }

  const sendMessage = async (e) => {
    if (!newMessage) {
      toast.error('Please Enter A Message To Send')
      return
    }
    if (!newSelectedContact) {
      toast.error('Please Select A User')
      return
    }
    if (!sender._id) {
      toast.error('Error Sending A Message')
      return
    }

    if (newMessage.trim() !== '' && newSelectedContact) {
      const response = await axios.post('/messages', {
        text: newMessage,
        senderId: sender._id,
        receiverId: newSelectedContact,
      })
      if (!response?.data?._id) {
        console.log('Something went wrong', response)
        return
      }

      setMessage('')
      const newMessageFormat = {
        id: response.data._id,
        text: response.data.text,
        filePath: response.data.filePath,
        isAttachment: response.data.isAttachment,
        senderId: response.data.senderId,
        receiverId: response.data.receiverId,
        createdAt: response.data.createdAt,
      }
      setMessages([...messages, {...newMessageFormat}])
      if (socket) {
        socket.emit('SEND_NEW_MESSAGE', {to: newSelectedContact, message: newMessageFormat})
        socket.emit('REFRESH_CONVERSATION_LIST', newSelectedContact)
      }
      await axios.get('messages/userChatListing').then(({data}) => {
        if (data?.length) {
          setContacts(data)
        }
      })
      setSelectedContact({userId: newSelectedContact})
      setOpen('')
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Start Chat</DialogTitle>
        <DialogContent 
          style={{
            zIndex: '2', 
            height: '300px', 
            padding: '16px',
            maxWidth: '100%',
            width: '100%',
          }}
        >
          <Autocomplete
            options={options}
            renderInput={(params) => (
              <TextField
                helperText={'Please select a user to start chat'}
                required={true}
                {...params}
                label={'User'}
              />
            )}
            sx={{pt: 1}}
            onChange={(event, newValue) => {
              const selectedValue = newValue ? newValue.value : ''
              setNewSelectedContact(selectedValue)
            }}
          />
          <TextareaAutosize
            name='message'
            minRows={4}
            maxRows={10}
            style={{
              marginBottom: '1rem',
              marginTop: '1rem',
              width: '100%',
              borderRadius: '5px',
              padding: '7px',
              maxWidth: '100%', 
              boxSizing: 'border-box'
            }}
            placeholder='Message...'
            onChange={(e) => setNewMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={sendMessage}>Send</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default NewMessage
