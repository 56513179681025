import React from 'react'
import {Redirect, Route, Switch, BrowserRouter as Router, BrowserRouter} from 'react-router-dom'
import {Login} from '../../../modules/auth/components/Login'
import {Registration} from '../../../modules/auth/components/Registration'
import {ResetPassword} from '../../../modules/auth/components/ResetPassword'
import {ForgotPassword} from '../../../modules/auth/components/ForgotPassword'
import {ProviderCode} from '../../../modules/auth/components/ProviderCode'
import {OTP} from '../../../modules/auth/components/OTP'

const AuthPageRoutes: React.FC = () => {
  return (
    <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
      <Switch>
        <Route path='/auth/set-password' component={ResetPassword} exact={true} />
        <Route path='/auth/forgot-password' component={ForgotPassword} exact={true} />
        <Route path='/auth/registration' component={Registration} exact={true} />
        <Route path='/auth/login' component={ProviderCode} exact={true} />
        <Redirect from='/auth' exact={true} to='/auth/login' />
        <Route path='/auth/otp' component={OTP} exact={true} />
        <Route path='/auth/code' component={ProviderCode} />
        <Redirect to='/code' />
      </Switch>
    </div>
  )
}

export default AuthPageRoutes
