export const ListOfBillingDataColumns = [
    "Billing Provider",
    "TCN Number",
    "Service Date From",
    "Service Date To",
    "Payer",
    "Claim Frequency Type Code",
    "Relation to Individual",
    "Patient Signature",
]
export const ListOfBillingDataSortingColumns = {
    'Billing Provider': 'billingProvider',
    'TCN Number': 'TCNNumber',
    'Service Date From': 'serviceDateFrom',
    'Service Date To': 'serviceDateTo',
    'Payer': 'payer',
    'Claim Frequency Type Code': 'claimFrequencyTypeCode',
    'Relation to Individual': 'relationToIndividual',
    'Patient Signature': 'patientSignature',
}

export const ListOfBillingDateColumnMapping = {
    'Billing Provider': 'billingProvider',
    'TCN Number': 'TCNNumber',
    'Service Date From': 'serviceDateFrom',
    'Service Date To': 'serviceDateTo',
    'Payer': 'payer',
    'Claim Frequency Type Code': 'claimFrequencyTypeCode',
    'Relation to Individual': 'relationToIndividual',
    'Patient Signature': 'patientSignature',
}


export const LIST_FOR_SERV_AUTH_PROGRAM_COLUMNS = [
    'Program Name',
    'Program Code',
    'Program ID',
    'Program Type',
    'Site Name',
    'PCCN',
]
export const LIST_FOR_SERV_AUTH_PROGRAM_SORTING_COLUMNS ={
    "Program Name": 'programName',
    "Program Code": 'programCode',
    "Program ID": 'programID',
    "Program Type": 'programType',
    "Site Name": 'siteNameMod',
    "PCCN": 'programCostCenterNumber',
} 

export const LIST_FOR_SERV_AUTH_PROGRAM_COLUMNS_MAPPING = {
    "Program Name": 'programName',
    "Program Code": 'programCode',
    "Program ID": 'programID',
    "Program Type": 'programType',
    "Site Name": 'siteNameMod',
    "PCCN": 'programCostCenterNumber',
}

export const LIST_FOR_SERV_AUTH_CLIENT_COLUMNS = [
    'First Name',
    'Last Name',
    'Birth Date'
]
export const LIST_FOR_SERV_AUTH_CLIENT_SORTING_COLUMNS = {
    "First Name": 'firstName',
    "Last Name": 'lastName',
    "Birth Date": 'birthDate'
}

export const LIST_FOR_SERV_AUTH_CLIENT_COLUMNS_MAPPING = {
    "First Name": 'firstName',
    "Last Name": 'lastName',
    "Birth Date": 'birthDate'
}


export const LIST_FOR_SERV_AUTH_COLUMNS = [
    'Program',
    'Client Name',
    'Funding Source',
    'Begin Date',
    'End Date',
    'Service Code/Description',
    'Unit of Measure',
    'Unit Rate',
    'Total Units',
    'Remaining Units',
    'Prior Auth',
    'ICD-10 Code',
]
export const LIST_FOR_SERV_AUTH_SORTING_COLUMNS = {
    'Program': 'programName',
    'Client Name': 'clientName',
    'Funding Source': 'fundingSourceName',
    'Begin Date': 'beginDate',
    'End Date': 'endDate',
    'Service Code/Description': 'serviceCodeDesc',
    'Unit of Measure': 'unitMeasure',
    'Unit Rate': 'unitRate',
    'Total Units': 'totalBillable',
    'Remaining Units': 'remainingUnits',
    'Prior Auth': 'priorAuth',
    'ICD-10 Code': 'primaryDiagnosis',
}

export const LIST_FOR_SERV_AUTH_COLUMNS_MAPPING = {
    'Program': 'programName',
    'Client Name': 'clientName',
    'Funding Source': 'fundingSourceName',
    'Begin Date': 'beginDate',
    'End Date': 'endDate',
    'Service Code/Description': 'serviceCodeDesc',
    'Unit of Measure': 'unitMeasure',
    'Unit Rate': 'unitRate',
    'Total Units': 'totalBillable',
    'Remaining Units': 'remainingUnits',
    'Prior Auth': 'priorAuth',
    'ICD-10 Code': 'primaryDiagnosis',
}