import React from 'react'
import {useSelector} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'
import {Tabs, Tab, useMediaQuery, useTheme} from '@mui/material'
import {hasPermission} from '../../../_helper/hasPermission'
import {permissions} from '../../../_constants/permissions'

function MarsDataHeader() {
  const location = useLocation()
  const auth = useSelector((state) => state.auth)
  const userPermissions = auth.user.roleId

  const getActiveTabIndex = () => {
    if (location.pathname.startsWith('/mars/individual-medication')) return 0
    if (location.pathname.startsWith('/mars/discontinued-mediciation')) return 1
    if (location.pathname.startsWith('/mars/missed-medication')) return 2
    return false
  }

  const value = getActiveTabIndex()

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-3 pb-0'>
          <div className='d-flex overflow-auto h-60px'>
            <Tabs
              value={value}
              variant='scrollable'
              scrollButtons={isSmallScreen ? 'auto' : 'on'}
              aria-label='Mars Data Tabs'
              sx={{
                flexGrow: 1,
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                '.MuiTab-root': {
                  textTransform: 'none',
                  minWidth: 150,
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                },
                '.Mui-selected': {
                  color: theme.palette.primary.main,
                },
                '.MuiTabs-indicator': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              {hasPermission(userPermissions, permissions.mars_individual_medication_view) && (
                <Tab
                  label='Individual Medications'
                  component={Link}
                  to='/mars/individual-medication/list'
                />
              )}
              {hasPermission(
                userPermissions,
                permissions.mars_individual_medication_discontinued_medication_view
              ) && (
                <Tab
                  label='Discontinued Medications'
                  component={Link}
                  to='/mars/discontinued-mediciation/list'
                />
              )}
              {hasPermission(
                userPermissions,
                permissions.mars_individual_medication_missed_medication_view
              ) && (
                <Tab
                  label='Missed Medications'
                  component={Link}
                  to='/mars/missed-medication/list'
                />
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarsDataHeader
