import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../setup';
import { deleteBillingProvider, getBillingProvider } from '../store/action';
import { toast } from 'react-toastify'
import TableTitle from '../../../../../reusable-components/tables/TableTitle';
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar';
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable';
import { LIST_OF_BILLING_PROVIDER_COLUMNS, LIST_OF_BILLING_PROVIDER_COLUMN_DATA_MAPPING, LIST_OF_BILLING_PROVIDER_SORTING_COLUMNS } from '../../BILLING_TABLE_CONSTANTS';
import { useHistory } from 'react-router-dom';
import { hasPermission } from '../../../../../../_helper/hasPermission';
import { permissions } from '../../../../../../_constants/permissions';
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper';
import AddButton from '../../../../../reusable-components/Buttons/AddButton';
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes';
const selectAuth = (state: RootState) => state.auth;

const ListProvider = () => {
    const [billingProvider, setBillingProvider] = useState<any[]>([])
    const [filteredData, setFilteredData] = useState<any>([])
    const [searchTerm, setSearchTerm] = useState('')
    const [deletedID, setDeletedID] = useState('')
    const [queryData, setQueryData] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'createdAt',
        sortDir: 'asc',
        searchTerm: ''
    })
    const dispatch = useDispatch()
    const auth = useSelector(selectAuth);
    const userPermissions = auth.user.roleId;
    const history = useHistory()

    useEffect(() => {
        const fetchData = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
            try {
                const billingResult: any = await dispatch(getBillingProvider({ page, pageSize, sortBy: sortBy, sortDir: sortDir, searchTerm: queryData.searchTerm }))
                const { data, currentPage, totalCounts } = billingResult.payload.data;
                setBillingProvider(data)
                setFilteredData(data)
                setQueryData((prevState)=> {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    }
                })
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
    }, [ deletedID, queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm])



    const onDeleteBillingProvider = async (row: { _id: string; }) => {
        try {
            const response: any = await dispatch(deleteBillingProvider(row._id));
            if (response?.payload?.success) {
                toast.success(response.payload.message || 'Success', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                setDeletedID(row._id)
                setQueryData((prevState)=> {
                    return {
                        ...prevState,
                        pageNumber: 1,
                        searchTerm : ''
                    }
                })

            } else {
                toast.error(response.payload?.response?.data?.message || response.payload?.message || 'Error', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        } catch (error) {
            toast.error('Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    }

    const onUpdate = ({ _id }: { _id: string }) => {
        history.push({
            pathname: `/admin/billing/billing-provider/list/${_id}`
        })
    }
    const onClickAddButton = () => {
        history.push('/admin/billing/billing-provider/new')
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <TableTitle title='Billing Provider List'></TableTitle>
                <TableWrapper
                    searchField={
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={searchTerm}
                            setQueryData={setQueryData}
                            setSearchTerm={setSearchTerm}
                            margin='normal'
                            inputAdornmentPosition='start'
                        ></TableSearchBar>
                    }
                    tableButtons={
                        hasPermission(userPermissions, permissions.admin_billing_billingProvider_create) &&
                        <AddButton
                            onClick={onClickAddButton}
                        />
                    }
                    dynamicTable={
                        <DynamicTable
                            columns={LIST_OF_BILLING_PROVIDER_COLUMNS}
                            data={filteredData}
                            canUpdate={hasPermission(userPermissions, permissions.admin_billing_billingProvider_update)}
                            canDelete={hasPermission(userPermissions, permissions.admin_billing_billingProvider_delete)}
                            columnDataMapping={LIST_OF_BILLING_PROVIDER_COLUMN_DATA_MAPPING}
                            sortingColumns={LIST_OF_BILLING_PROVIDER_SORTING_COLUMNS}
                            clickableRow={false}
                            onUpdate={onUpdate}
                            onDelete={onDeleteBillingProvider}
                            title='Are you sure you want to delete the Billing Provider'
                            setQueryData={setQueryData}
                            queryData={queryData}
                        />
                    }
                />
            </div>
        </div>
    )
}

export default ListProvider