import React, { useState } from "react";
import Select from "react-select";

export default function SelectField(props) {
    const [selectedOptions, setSelectedOptions] = useState(props.field.value || []);

    const onChange = (selectedOptions) => {
        const newSelectedValues = selectedOptions.map((option) => option.value);
        setSelectedOptions(newSelectedValues);
    };

    const formatSelectedOptions = () => {
        return selectedOptions.map((option) => ({
            label: option,
            value: option
        }));
    };




    return (
        <div>
            <Select
                {...props}
                value={formatSelectedOptions()}
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={onChange}
                isMulti
            />
        </div>
    );
}
