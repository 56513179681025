import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import New from '../../../../../pages/admin/personalfinance/types-and-categories/new/New'
import List from '../../../../../pages/admin/personalfinance/types-and-categories/list/List'

const TypesAndCategoriesIndexRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path='/admin/personalfinance/types-and-categories/new'>
        <New />
      </Route>
      <Route path='/admin/personalfinance/types-and-categories/list'>
        <List />
      </Route>
      <Redirect
        from='/admin/personalfinance/types-and-categories'
        exact={true}
        to='/admin/personalfinance/types-and-categories/new'
      />
      <Redirect to='/admin/personalfinance/types-and-categories/new' />
    </Switch>
  )
}

export default TypesAndCategoriesIndexRoutes
