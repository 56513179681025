import React, { useRef } from 'react';
import {
  Box
} from "@mui/material";
import RenderPermissionSections from './RenderPermissionSections';
import { DELETED_ROLES } from './roles_table_constants';

const PermissionSectionContainer = ({ allPermissions, permissionRef }) => {
  const filterPermissions = (permissions) => {
    const filteredPermissions = {}

    Object.keys(permissions).forEach((section)=> {
      if(!DELETED_ROLES.includes(section)) {
        if(typeof permissions[section] === 'object' && !Array.isArray(permissions[section])) {
          filteredPermissions[section] = filterPermissions(permissions[section])
        } else {
          filteredPermissions[section] = permissions[section]
        }
      }
    })

    return filteredPermissions
  }

  const filteredPermissions = filterPermissions(allPermissions);
  return (
    <Box sx={{ padding: 2 }}>
      {Object.keys(filteredPermissions).map((subSection) => {
        return (
        <RenderPermissionSections
          key={subSection}
          permissionsSubSecton={{ [subSection]: filteredPermissions[subSection] }}
          permissionRef={permissionRef}
        />
      )
      }
      )}
    </Box>
  );
};

export default PermissionSectionContainer;