import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import New from '../../../../../pages/admin/personalfinance/merchant/new/New'
import List from '../../../../../pages/admin/personalfinance/merchant/list/List'
import ImportFromExcel from '../../../../../pages/admin/personalfinance/merchant/import-from-excel/ImportFromExcel'

const MerchantIndexRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path='/admin/personalfinance/merchant/new'>
        <New />
      </Route>
      <Route path='/admin/personalfinance/merchant/list'>
        <List />
      </Route>
      <Route path='/admin/personalfinance/merchant/import-from-excel'>
        <ImportFromExcel />
      </Route>
      <Redirect from='/admin/personalfinance/merchant' exact={true} to='/admin/personalfinance/merchant/new' />
      <Redirect to='/admin/personalfinance/merchant/new' />
    </Switch>
  )
}

export default MerchantIndexRoutes
