import axios from 'axios'
import * as validator from '../../reusable-components/Validation/Validations'
let clientList = []
let clientLocationList = []
export const ADD_NOTES_FORM_INPUTS = (programsList, scoringMethodList,serviceDescriptionList) => {
  return [
    {
      sectionHeading: 'Details',
      rowNumber: 1,
    },
    {
      label: 'Program',
      name: 'programId',
      type: 'select',
      options: programsList,
      required: true,
      rowNumber: 2,
      onChange: (programId, b) => getClientsUnderAProgram(programId, b),
    },
    {
      label: 'Client',
      name: 'clientId',
      type: 'select',
      options: clientList,
      required: true,
      rowNumber: 2,
    },
    {
      label: 'Note Name',
      name: 'noteName',
      type: 'text',
      required: true,
      rowNumber: 2,
      validation: validator.NAME_VALIDATION,
    },
    // {
    //   label: 'Start Date',
    //   name: 'startDate',
    //   type: 'date',
    //   required: true,
    //   rowNumber: 3,
    // },
    // {
    //   label: 'End Date',
    //   name: 'endDate',
    //   type: 'date',
    //   required: true,
    //   rowNumber: 3,
    // },
    // {
    //   label: 'Target Completion Date',
    //   name: 'targetCompletionDate',
    //   type: 'date',
    //   required: true,
    //   rowNumber: 4,
    // },
    {
      label: 'Location',
      name: 'location',
      type: 'select',
      options: clientLocationList,
      required: true,
      rowNumber: 4,
    },
    {
      sectionHeading: 'Scoring Method',
      rowNumber: 5,
    },
    {
      label: 'Method',
      name: 'scoringMethod',
      type: 'select',
      options: scoringMethodList,
      required: true,
      rowNumber: 5,
    },
    {
      sectionHeading: 'Service Description',
      rowNumber: 5,
    },
    {
      label: 'Service Description',
      name: 'serviceId',
      type: 'select',
      options: serviceDescriptionList,
      required: true,
      rowNumber: 5,
    },
    {
      sectionHeading: 'Other Details',
      rowNumber: 6,
    },
    {
      label: 'Long Term Objectives',
      name: 'longTermObjectives',
      type: 'textarea',
      required: true,
      rowNumber: 7,
      minRows: 4,
    },
    {
      label: 'Goals/Service',
      name: 'goals',
      type: 'textarea',
      required: true,
      rowNumber: 8,
    },
    {
      label: 'Reason For Notes',
      name: 'reasonForNotes',
      type: 'textarea',
      required: true,
      rowNumber: 9,
    },
    {
      label: 'Criteria For Completion',
      name: 'criteriaForCompletion',
      type: 'textarea',
      required: true,
      rowNumber: 10,
    },
    {
      label: 'Materials Required',
      name: 'materialsRequired',
      type: 'textarea',
      required: true,
      rowNumber: 11,
    },
    {
      sectionHeading: 'Tasks',
      rowNumber: 12,
    },
  ]
}

export const ADD_NEW_SCORING_METHOD = () => {
  return [
    {
      label: 'Scoring Method Name',
      name: 'scoringMethodName',
      type: 'text',
      required: true,
      rowNumber: 1,
    },
    {
      sectionHeading: 'Scoring Method Levels',
      rowNumber: 2,
    },
  ]
}

const getClientsUnderAProgram = async (programId, b) => {
  clientList.length = 0;
  clientLocationList.length = 0;
  clientList.forEach((elem, index) => clientList.splice(index))
  axios.get(`/clients/getClientsUnderAProgram/${programId}`).then(({data}) => {
      data?.sites.map((eachSite) => {
        clientLocationList.push({
          label: `${eachSite.siteName.toString().toUpperCase()} (${eachSite.st1} ${
            eachSite.st2
          }, ${eachSite.city}, ${eachSite.state}, ${eachSite.country})`,
          value: {
            address: `${eachSite.siteName.toString().toUpperCase()} (${eachSite.st1} ${
              eachSite.st2
            }, ${eachSite.city}, ${eachSite.state}, ${eachSite.country})`,
            lat: eachSite.lat,
            lng: eachSite.lng,
            typeOfLocation: 'site',
            idOfDoc: eachSite._id,
          },
        })
      })

    data?.clients?.map((eachClient) => {
      clientList.push({
        label: `${eachClient.firstName} ${eachClient.lastName}`,
        value: eachClient._id,
      })
      clientLocationList.push(
        {
        label: `${eachClient.firstName.toString().toUpperCase()} ${eachClient.lastName
          .toString()
          .toUpperCase()} (${eachClient.addrInfo.st1} ${eachClient.addrInfo.st2}, ${
          eachClient.addrInfo.city
        }, ${eachClient.addrInfo.state}, ${eachClient.addrInfo.country})`,
        value: {
          address: `${eachClient.firstName.toString().toUpperCase()} ${eachClient.lastName
            .toString()
            .toUpperCase()} (${eachClient.addrInfo.st1} ${eachClient.addrInfo.st2}, ${
            eachClient.addrInfo.city
          }, ${eachClient.addrInfo?.state}, ${eachClient.addrInfo?.country})`,
          lat: eachClient.addrInfo?.lat,
          lng: eachClient.addrInfo?.lng,
          typeOfLocation: 'client',
          idOfDoc: eachClient._id,
        },
      })
    })
  })
}

export const ADD_NOTE_ENTRY_FORM_INPUTS = (name: string, location: string,clientName: string) => {
  return [
    {
      sectionHeading: 'Data Collection Detail',
      rowNumber: 1,
    },
    {
      label: 'Client Name',
      name: 'name',
      type: 'textLabel',
      required: true,
      rowNumber: 1,
      className: `col-lg-6 col-md-6 col-sm-12 col12`,
      value: clientName
    },
    // {
    //   label: 'Data Collection Date',
    //   name: 'startDate',
    //   type: 'date',
    //   required: true,
    //   rowNumber: 1,
    //   className: `col-lg-6 col-md-6 col-sm-12 col-12`,
    //   placeholder: 'MM-DD-YYYY', 
    // },
    // {
    //   label: 'Begin Time',
    //   name: 'beginTime',
    //   type: 'time',
    //   required: true,
    //   rowNumber: 2,
    //   className: `col-lg-6 col-md-6 col-sm-12 col-12`,
    // },
    // {
    //   label: 'End Time',
    //   name: 'endTime',
    //   type: 'time',
    //   required: true,
    //   rowNumber: 2,
    //   className: `col-lg-6 col-md-6 col-sm-12 col-12`,
    // },
    {
      label: 'Location',
      name: 'location',
      type: 'textLabel',
      required: true,
      rowNumber: 4,
      className: `col-lg-6 col-md-12 col-sm-12 col-12`,
      value: location
    },
    {
      label: 'Entered By',
      name: 'enteredBy',
      type: 'textLabel',
      required: true,
      rowNumber: 4,
       className: `col-lg-6 col-md-12 col-sm-12 col-12`,
      value: name,
    },
    {
      sectionHeading: 'Task Scores',
      rowNumber: 5,
    },
  ]
}

export const EDIT_NOTES_FORM_INPUTS = (programsList,serviceDescriptionList) => {
  return [
    {
      sectionHeading: 'Details',
      rowNumber: 1,
    },
    {
      label: 'Program',
      name: 'programId',
      type: 'select',
      options: programsList,
      required: true,
      rowNumber: 2,
      disabled: true,
    },
    {
      label: 'Client',
      name: 'clientId',
      type: 'select',
      options: clientList,
      required: true,
      rowNumber: 2,
      disabled: true,
    },
    {
      label: 'Note Name',
      name: 'noteName',
      type: 'text',
      required: true,
      rowNumber: 2,
      validation: validator.NAME_VALIDATION,
    },
    // {
    //   label: 'Start Date',
    //   name: 'startDate',
    //   type: 'date',
    //   required: true,
    //   rowNumber: 3,
    // },
    // {
    //   label: 'End Date',
    //   name: 'endDate',
    //   type: 'date',
    //   required: true,
    //   rowNumber: 3,
    // },
    // {
    //   label: 'Target Completion Date',
    //   name: 'targetCompletionDate',
    //   type: 'date',
    //   required: true,
    //   rowNumber: 4,
    // },
    {
      label: 'Location',
      name: 'location',
      type: 'select',
      options: clientLocationList,
      required: true,
      rowNumber: 4,
      disabled: true,
    },
    {
      sectionHeading: 'Service Description',
      rowNumber: 5,
    },
    {
      label: 'Service Description',
      name: 'serviceId',
      type: 'select',
      options: serviceDescriptionList,
      required: true,
      rowNumber: 5,
    },
    {
      sectionHeading: 'Other Details',
      rowNumber: 6,
    },
    {
      label: 'Long Term Objectives',
      name: 'longTermObjectives',
      type: 'textarea',
      required: true,
      rowNumber: 7,
      minRows: 4,
    },
    {
      label: 'Goals/Service',
      name: 'goals',
      type: 'textarea',
      required: true,
      rowNumber: 8,
    },
    {
      label: 'Reason For Notes',
      name: 'reasonForNotes',
      type: 'textarea',
      required: true,
      rowNumber: 9,
    },
    {
      label: 'Criteria For Completion',
      name: 'criteriaForCompletion',
      type: 'textarea',
      required: true,
      rowNumber: 10,
    },
    {
      label: 'Materials Required',
      name: 'materialsRequired',
      type: 'textarea',
      required: true,
      rowNumber: 11,
    },
    {
      sectionHeading: 'Tasks',
      rowNumber: 12,
    },
  ]
}

export const EDIT_NOTE_ENTRY_FORM_INPUTS = (name: string) => {
  return [
    {
      sectionHeading: 'Data Collection Detail',
      rowNumber: 1,
    },
    // {
    //   label: 'Data Collection Date',
    //   name: 'startDate',
    //   type: 'date',
    //   required: true,
    //   rowNumber: 1,
    //   className: `col-lg-3 col-md-6 col-sm-6`,
    // },
    // {
    //   label: 'Begin Time',
    //   name: 'beginTime',
    //   type: 'time',
    //   required: true,
    //   rowNumber: 2,
    //   className: `col-lg-3 col-md-6 col-sm-6`,
    // },
    // {
    //   label: 'End Time',
    //   name: 'endTime',
    //   type: 'time',
    //   required: true,
    //   rowNumber: 3,
    //   className: `col-lg-3 col-md-6 col-sm-6`,
    // },
    {
      label: 'Location',
      name: 'location',
      type: 'text',
      required: true,
      rowNumber: 4,
      className: `col-lg-6 col-md-6 col-sm-6`
    },
    {
      label: 'Entered By:',
      name: 'enteredBy',
      type: 'textLabel',
      required: true,
      rowNumber: 5,
      value: name,
    },
    {
      sectionHeading: 'Task Scores',
      rowNumber: 6,
    },
  ]
}
