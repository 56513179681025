import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import moment from 'moment-timezone';
import { RootState } from '../../../setup';
import { useSelector } from 'react-redux';

const selectTimeZone = (state: RootState) => state.timeZone.timeZone;

const USATimezones = [
  { id: 'America/New_York', name: 'Eastern' },
  { id: 'America/Chicago', name: 'Central' },
  { id: 'America/Denver', name: 'Mountain' },
  { id: 'America/Los_Angeles', name: 'Pacific' },
  { id: 'America/Anchorage', name: 'Alaska' },
  { id: 'America/Phoenix', name: 'Mountain (No DST)' },
  { id: 'America/Adak', name: 'Hawaii-Aleutian' },
  { id: 'Pacific/Honolulu', name: 'Hawaii' }
];

export default function TimezoneSelector({ onTimezoneChange }) {
  const timeZone = useSelector(selectTimeZone);
  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const getTimezoneOffsetLabel = (timezone) => {
    const offset = moment.tz(timezone.id).utcOffset() / 60;
    const offsetSign = offset >= 0 ? '+' : '-';
    return `${timezone.name} (UTC${offsetSign}${Math.abs(offset)})`;
  };

  useEffect(() => {
    const matchedTimezone = USATimezones.find((tz) => tz.id === timeZone);
    if (matchedTimezone) {
      setSelectedTimezone(matchedTimezone);
    }
  }, [timeZone]);

  const handleTimezoneChange = (event, newValue) => {
    setSelectedTimezone(newValue);
    onTimezoneChange(newValue.id);
  };

  return (
    <div className='d-flex align-items-center'>
      <Autocomplete
        value={selectedTimezone}
        onChange={handleTimezoneChange}
        options={USATimezones}
        size='small'
        disableClearable
        getOptionLabel={(option) => getTimezoneOffsetLabel(option)}
        style={{ width: 220, marginLeft: 8 }}
        renderInput={(params) => (
          <TextField {...params} label='Timezone' variant='outlined' />
        )}
      />
    </div>
  );
}
