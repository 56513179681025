import AdminCareHeader from './AdminCareHeader'

function Care() {
    return (
        <div>
            <AdminCareHeader />
        </div>
    )
}

export default Care