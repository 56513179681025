
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import DescriptionCodeHeader from './DescriptionCodeHeader'
import New from './new/New'
import List from './list/List'
import Archive from './archive/Archive'
import AppliedRateHistorySearchProfessional from './applied-rate-history-search-professional/AppliedRateHistorySearchProfessional'

function DescriptionCodeIndex() {
    return (
        <div>
            <DescriptionCodeHeader />
        </div>
    )
}

export default DescriptionCodeIndex