import React, { useEffect, useState } from 'react'
import DynamicForm from '../../../../reusable-components/forms/DynamicForm';
import { ALLERGY_INPUTS } from '../CARE_CONSTANTS';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import HealthProfileMenu from '../HealthProfile/HealthProfileMenu';
import TableTitle from '../../../../reusable-components/tables/TableTitle';
import AdminCareHeader from '../AdminCareHeader';

interface RouteParams {
    clientID: string;
    id: string;
}

const EditAllergy = () => {
    const [editAllergyData, setEditAllergyData] = useState({})
    const history = useHistory()
    const parameters = useParams<RouteParams>()
    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post('/health/getOneAllergy', { clientID: parameters?.clientID, id: parameters?.id })
            const { allergyInfo } = response.data.data
            setEditAllergyData(allergyInfo)
        }
        fetchData()
    }, [])

    const handleSubmitForm = async (formData) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');

        // Create the formatted date string
        const currentDateFormatted = `${year}-${month}-${day}`;
        try {
            const tempObj = {
                clientID: parameters.clientID,
                allergyInfo: formData
            }
            if (formData.identificationDate <= currentDateFormatted) {
                const response = await axios.put('health/updateAllergy', tempObj)
                if(response?.data?.status === 400 && response?.data?.success === false) {
                    toast.error(response?.data?.message)
                } else if (response.data.status === 200 && response.data && response.data.success) {
                    toast.success('Allergy Updated Successfully')
                    history.push(`/admin/care/health/allergy/list/${parameters.clientID}`)
                } else {
                    toast.error('Error Updating Allergy')
                }
            } else {
                toast.error('Identification Date should not be later than today')
            }

        } catch (err) {
            console.log(err)
            toast.error('Error Updating Allergy')
        }
    };

    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' }
    ];
    return (
        <div>
            <AdminCareHeader />
            <HealthProfileMenu />
            <div className="card my-10">
                <div className='card-body'>
                    <div className='mt-5'>
                        <TableTitle title='Edit Allergy Form'></TableTitle>
                        <div className='mt-8'>
                            <DynamicForm
                                fields={ALLERGY_INPUTS}
                                data={editAllergyData}
                                onSubmit={handleSubmitForm}
                                buttons={buttons}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditAllergy