import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import MerchantHeader from './MerchantHeader'
import New from './new/New'
import List from './list/List'
import ImportFromExcel from './import-from-excel/ImportFromExcel'

function MerchantIndex() {
    return (
        <div>
            <MerchantHeader />
        </div>
    )
}

export default MerchantIndex