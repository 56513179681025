import AdminBillingHeader from '../AdminBillingHeader'

function PayersSettingHeader() {
    return (
        <div>
            <AdminBillingHeader />
        </div>
    )
}

export default PayersSettingHeader