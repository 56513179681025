import { Redirect, Route, Switch } from 'react-router-dom'
import ClientLogsHeader from './ClientLogsHeader'
import ClientLogList from './new/List'
import ListOfClientLogs from './list/List'
import SearchClientLogFields from './search/SearchClientLogFields'

const ClientLogsIndex = () => {
    return (
        <div>
            <ClientLogsHeader />
        </div>
    )
}

export default ClientLogsIndex