export const NEW_ADDRESS = {
    st1: "",
    st2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    primaryPhone: "",
}

export const FORM_INPUTS = [
    {
        sectionHeading: 'Residential Address',
        rowNumber: 1,
    },
    {
        addressInfo: true,
        rowNumber: 1,
    },
    {
        mailingInfo: true,
        rowNumber: 1,
    },
]
export const Country = [
    { label: 'United States', value: 'US' },
    { label: 'Pakistan', value: 'PK' }
  ];
