
import React, {Suspense, lazy} from 'react'
import {useSelector} from 'react-redux'
import {Redirect, Route, BrowserRouter as Router, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

import {allRoutes, allroutesHandler} from './allRoutesHandler'
import PermittedRoute from './PermittedRoute'
import AllRoutes from '../allRoutes'

const permissionsAuth = (state) => state?.roles

export function PrivateRoutes() {
  const permissions = useSelector(permissionsAuth).permissions

  allroutesHandler()

  return (
    <Suspense fallback={<FallbackView />}>
      <AllRoutes></AllRoutes>
    </Suspense>
  )
}
