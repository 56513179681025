export const RECORDS_LISTING_COLUMNS = ['Client Name', 'Start Date', 'End Date', 'Frequency', 'Created At'];


export const RECORDS_SORTING_COLUMNS = {
  'Client Name': 'clientName',
  'Start Date': 'startDate',
  'End Date': 'endDate',
  'Frequency': 'frequency',
  'Created At': 'date'
};


export const RECORDS_COLUMNS_DATA_MAPPING = {
  'Client Name': 'clientName',
  'Start Date': 'startDate',
  'End Date': 'endDate',
  'Frequency': 'frequency',
  'Created At': 'date'
};

// export const RECORDS_ENTRIES_COLUMNS = [
//   'Creation Date',
//   'Begin Time',
//   'End Time',
//   'Location',
//   'Created By',
// ]
// export const RECORDS_ENTRIES_SORTING_COLUMNS = {
//   'Creation Date': 'creationDateFormatted',
//   'Begin Time': 'beginTimeFormatted',
//   'End Time': 'endTimeFormatted',
//   Location: 'locationAddress',
//   'Created By': 'createdBy',
// }
// export const RECORDS_ENTRIES_COLUMNS_DATA_MAPPING = {
//   'Creation Date': 'creationDateFormatted',
//   'Begin Time': 'beginTimeFormatted',
//   'End Time': 'endTimeFormatted',
//   Location: 'locationAddress',
//   'Created By': 'createdBy',
// }
