import React, { useEffect, useState } from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {Tabs, Tab, useMediaQuery, useTheme} from '@mui/material'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../_helper/hasPermission'
import { permissions } from '../../../../_constants/permissions'
const selectAuth = (state) => state.auth

function GeneralHeader() {
  const [value, setValue] = useState(0)
  const [availableTabs, setAvailableTabs] = useState([])
  const location = useLocation()
  const history = useHistory()
  const auth = useSelector(selectAuth)
  const userPermissions = auth.user.roleId

  const canViewSharedContact = hasPermission(userPermissions, permissions.admin_billing_sharedContact_view) // Assuming all users can view clients
  const canViewSite = hasPermission(userPermissions, permissions.admin_billing_site_view) // Assuming all users can view contact
  const canViewProgram = hasPermission(userPermissions, permissions.admin_billing_program_view)
  const canViewEnrollment = hasPermission(userPermissions, permissions.admin_general_enrollement_by_program_view) // Assuming all users can view insurance
  const canViewScoringMethod = hasPermission(
    userPermissions,
    permissions.admin_billing_scoring_method_view
  )
  useEffect(() => {
    // Define tabs based on permissions
    const tabs = [
      canViewSharedContact ? { label: 'Shared Contact', path: '/admin/general/shared-contact/list' } : null,
      canViewSite ? { label: 'Site', path: '/admin/general/site/list' } : null,
      canViewProgram ? { label: 'Programs', path: '/admin/general/programs/list' } : null,
      canViewScoringMethod ? { label: 'Scoring Method', path: '/admin/general/scoring-method/list' } : null,
      canViewEnrollment ? { label: 'Enrollment By Program', path: '/admin/general/enrollment-by-program' } : null
    ].filter(Boolean); // Filter out null values
  
    setAvailableTabs(tabs);
  
    const currentPath = window.location.pathname; // Get the current path from window
    let currentTabPath = '';
  
    const currentTabIndex = tabs.findIndex((tab) => {
      if (currentPath.startsWith(tab.path)) {
        currentTabPath = tab.path;
        return true;
      }
      return false;
    });
    if (currentTabIndex !== -1) {
      setValue(currentTabIndex);
    } else if (tabs.length > 0) {
      if (currentPath === '/admin/general/enrollment-by-program') {
        history.push(`${tabs[0].path}`);
      } else {
        history.push(`${tabs[0].path}`);
      }
    }
  }, [canViewSharedContact, canViewSite, canViewProgram, canViewScoringMethod, canViewEnrollment, history]);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(availableTabs[newValue].path);
  };

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-3 pb-0'>
          <div className='d-flex overflow-auto h-60px'>
            <Tabs
              value={value}
              variant='scrollable'
              onChange={handleChange}
              scrollButtons={isSmallScreen ? 'auto' : 'on'}
              aria-label='General Admin Tabs'
              className='w-100'
              sx={{
                flexGrow: 1,
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                display: 'flex',
                justifyContent: 'space-between',

                '.MuiTab-root': {
                  textTransform: 'none',
                  minWidth: 120,
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                },
                '.Mui-selected': {
                  color: theme.palette.primary.main,
                },
                '.MuiTabs-indicator': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              {availableTabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  sx={{flexGrow: 1}}
                  component={Link}
                  to={tab.path}
                />
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralHeader
