import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GetDescriptionCode } from '../../../../admin/billing/description-code/store/action'
import { getFundingSource } from '../../../../admin/billing/funding-source/store/actions'
import { getServiceAuth } from '../../service-authorization/store/actions'
import Table from './Table'
import { getProgram } from '../../../../admin/care/enrollment/store/actions'
import axios from 'axios'
import { SEARCH_BILLING_DATA, SEARCH_BILLING_INPUT_CONSTANTS } from '../../../BILLING_CONSTANTS'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import { toast } from 'react-toastify'

const Search = () => {
    const [searchValues, setSearchValues] = useState({})
    const [showEditingForm, setShowEditingForm] = useState(true)
    const [updatedBillingOptions, setUpdatedBillingOptions] = useState<any[]>([]);
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [descriptionResult, fundingSourceResult, serviceAuthResult, programResult, clientsResult]: any = await Promise.all([
                    dispatch(GetDescriptionCode({})),
                    dispatch(getFundingSource({})),
                    dispatch(getServiceAuth({})),
                    dispatch(getProgram({})),
                    (await axios.get('/clients/get')).data!,
                ])
                const tempDes = descriptionResult?.payload.data;
                const temFun = fundingSourceResult?.payload.data;
                const seen = new Set();
                const tempAuths = serviceAuthResult.payload.data;
                const tempProgram = programResult.payload.data;

                let optionsClient = tempAuths.filter((data: { clientID: string; clientName: string; }) => {
                    const duplicate = seen.has(data.clientID); seen.add(data.clientID); return !duplicate;
                })
                optionsClient = optionsClient.map((obj: { clientID: string }) => {
                    let clientObj = clientsResult.find((clobj: { _id: string }) => clobj._id === obj.clientID)
                    const updatedElement = {
                        value: clientObj._id,
                        label: clientObj.firstName + ' ' + clientObj.lastName
                    }
                    return updatedElement
                })
                const optionsProgram = tempProgram.map((data: { _id: string, programName: string }) => {
                    const updatedElement = {
                        value: data._id,
                        label: data.programName
                    };
                    return updatedElement;
                });
                const updatedServiceDescription = [
                    ...tempDes.map((des: any) => ({ label: des.serviceDescription, value: des._id }))
                ]
                const updatedFundingSource = [
                    ...temFun.map((fun: any) => ({ label: fun.name, value: fun._id }))
                ]
                const constantsWithUpdatedPayer = SEARCH_BILLING_INPUT_CONSTANTS.map((field) => {
                    if (field.name === 'client') {
                        return {
                            ...field,
                            options: optionsClient,
                        };
                    } else if (field.name === 'programSite') {
                        return {
                            ...field,
                            options: optionsProgram,
                        };
                    } else if (field.name === 'serviceDescription') {
                        return {
                            ...field,
                            options: updatedServiceDescription,
                        };
                    } else if (field.name === 'fundingSource') {
                        return {
                            ...field,
                            options: updatedFundingSource,
                        };
                    }
                    return field;
                });
                setUpdatedBillingOptions(constantsWithUpdatedPayer);
            } catch (err) {
                console.log(err)
            }
        }
        fetchData();
    }, [])

    const handleSubmitForm = async (formData: any) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');

        // Create the formatted date string
        const currentDateFormatted = `${year}-${month}-${day}`;
        if(formData.beginServiceDateFrom <= currentDateFormatted && 
            formData.endServiceDateFrom <= currentDateFormatted && 
            formData.beginServiceDateTo >= formData.beginServiceDateFrom &&
           formData.endServiceDateTo >= formData.endServiceDateFrom &&
            formData.serviceDateFrom <= currentDateFormatted && 
            formData.createDateFrom <= currentDateFormatted && 
            formData.serviceDateTo >= formData.serviceDateFrom &&
            formData.createDateTo >= formData.createDateFrom){
            setSearchValues(formData)
        } else {
            if(formData.beginServiceDateFrom > currentDateFormatted){
                toast.error('Service Authorization Begin Date From should not be later than today')
            }else if(formData.beginServiceDateTo < formData.beginServiceDateFrom){
                toast.error('Service Authorization Begin Date To should not be earlier than Begin Date From')
            }else if(formData.endServiceDateFrom > currentDateFormatted){
                toast.error('Service Authorization End Date From should not be later than today')
            }else if(formData.endServiceDateTo < formData.endServiceDateFrom){
                toast.error('Service Authorization End Date To should not be earlier than End Date From')
            }else if(formData.serviceDateFrom > currentDateFormatted){
                toast.error('Service Date From should not be later than today')
            }else if(formData.serviceDateTo < formData.serviceDateFrom){
                toast.error('Service Date To should not be earlier than Service Date From')
            }else if(formData.createDateFrom > currentDateFormatted){
                toast.error('Create Date From should not be later than today')
            }else if(formData.createDateTo < formData.createDateFrom){
                toast.error('Create Date To should not be earlier than Create Date From')
            }
        }
    };
    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' }
    ];
    return (
        <>
            {
                showEditingForm === false ? (
                    <Table searchValues={searchValues} />
                ) : (
                    <FormWrapper
                        title='Search Billing Data'
                        content={
                            <DynamicForm
                                fields={updatedBillingOptions}
                                data={SEARCH_BILLING_DATA}
                                onSubmit={handleSubmitForm}
                                buttons={buttons}
                            />
                        }
                    />

                )
            }
        </>
    )
}

export default Search