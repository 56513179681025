import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tabs, Tab, useMediaQuery, useTheme } from '@mui/material';

function AttendanceHeader() {
    const location = useLocation();

    const getActiveTabIndex = () => {
        if (location.pathname === '/billing/attendance/new') return 0;
        if (location.pathname === '/billing/attendance/search') return 1;
        return false;
    };

    const value = getActiveTabIndex();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body pt-9 pb-0">
                    <p className="text-gray-800 fs-2 fw-bolder">Attendance</p>
                    <div className="d-flex overflow-auto h-55px">
                        <Tabs
                            value={value}
                            onChange={() => {}}
                            variant="scrollable"
                            scrollButtons={isSmallScreen ? 'auto' : 'on'}
                            aria-label="Attendance Tabs"
                            sx={{
                                flexGrow: 1,
                                overflowX: 'auto',
                                whiteSpace: 'nowrap',
                                '.MuiTab-root': {
                                    textTransform: 'none',
                                    minWidth: 120, 
                                    fontSize: '1.1rem', 
                                    fontWeight: 'bold',

                                },
                                '.Mui-selected': {
                                    color: theme.palette.primary.main,
                                },
                                '.MuiTabs-indicator': {
                                    backgroundColor: theme.palette.primary.main,
                                },
                            }}
                        >
                            <Tab
                                label="New"
                                component={Link}
                                to="/billing/attendance/new"
                            />
                            <Tab
                                label="Search"
                                component={Link}
                                to="/billing/attendance/search"
                            />
                            {/* Future Tabs can be added here as needed */}
                            {/* Example:
                            <Tab
                                label="Archive"
                                component={Link}
                                to="/billing/attendance/archive"
                            /> */}
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AttendanceHeader;
