import React, {Suspense, useEffect} from 'react'
import {BrowserRouter, useLocation} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
// import {Routes} from './routing/Routes'
import {Routes} from './allRoutes/Routes'
import AllRoutes from './allRoutes'
import 'react-toastify/dist/ReactToastify.css'
import DisableNumberInputScroll from '../_helper/disableWheelNumberInput/disableNumberInputScroll'
import COGNITO from '../aws-exports'
import {Amplify} from 'aws-amplify'
import {cognitoUserPoolsTokenProvider} from 'aws-amplify/auth/cognito'
import {defaultStorage} from 'aws-amplify/utils'

type Props = {
  basename: string
}

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: COGNITO.USER_POOL_ID,

      userPoolClientId: COGNITO.APP_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: COGNITO.REACT_APP_COGNITO_DOMAIN,
          scopes: ['openid email profile aws.cognito.signin.user.admin launch/patient system/*.*'],
          redirectSignIn: [process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN],
          redirectSignOut: [COGNITO.REDIRECT_SIGNOUT],
          responseType: 'code',
        },
      },
    },
  },
})
cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage)
const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <DisableNumberInputScroll />
      <BrowserRouter basename={basename}>
        <LayoutProvider>
          <AuthInit>
            {/* <AllRoutes /> */}
            <Routes />
            <ToastContainer newestOnTop />
          </AuthInit>
        </LayoutProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
