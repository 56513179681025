import { useParams } from 'react-router-dom'
import CareGiverProfileTabs from '../ProfileHeader'
import CaregiverDocumentsList from './CaregiverProfileDocuments'

export const CaregiverProfileDocuments = () => {
  const params: { id: string } = useParams()
  return (
    <>
      <CareGiverProfileTabs></CareGiverProfileTabs>
      <CaregiverDocumentsList id={params?.id}></CaregiverDocumentsList>
    </>
  )
}
