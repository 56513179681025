import React from 'react'
import AdminBillingHeader from '../AdminBillingHeader'

function FundingSourceHeader() {
    return (
        <div>
            <AdminBillingHeader />
        </div>
    )
}

export default FundingSourceHeader