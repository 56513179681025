import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { GetDescriptionCode } from '../../../../admin/billing/description-code/store/action'
import { getFundingSource } from '../../../../admin/billing/funding-source/store/actions'
import { getServiceAuth } from '../../service-authorization/store/actions'
import axios from 'axios'
import { getProgram } from '../../../../admin/care/enrollment/store/actions'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { BILLING_INPUT_CONSTANTS, NEW_BILLING_DATA } from '../../../BILLING_CONSTANTS'
import { MoonLoader } from 'react-spinners'
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper'
import TableButton from '../../../../../reusable-components/tables/TableButton'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

const New = () => {
    const [updatedBillingOptions, setUpdatedBillingOptions] = useState<any[]>([]);
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const history = useHistory()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [descriptionResult, fundingSourceResult, serviceAuthResult, programResult, clientsResult]: any = await Promise.all([
                    dispatch(GetDescriptionCode({})),
                    dispatch(getFundingSource({})),
                    dispatch(getServiceAuth({})),
                    dispatch(getProgram({})),
                    (await axios.get('/clients/get')).data!,
                ])
                const tempDes = descriptionResult?.payload.data;
                const temFun = fundingSourceResult?.payload.data;
                const seen = new Set();
                const tempAuths = serviceAuthResult.payload.data;
                const tempProgram = programResult.payload.data;

                let optionsClient = tempAuths.filter((data: { clientID: string; clientName: string; }) => {
                    const duplicate = seen.has(data.clientID); seen.add(data.clientID); return !duplicate;
                })
                optionsClient = optionsClient.map((obj: { clientID: string }) => {
                    let clientObj = clientsResult.find((clobj: { _id: string }) => clobj._id === obj.clientID)
                    const updatedElement = {
                        value: clientObj._id,
                        label: clientObj.firstName + ' ' + clientObj.lastName
                    }
                    return updatedElement
                })

                const optionsProgram = tempProgram.map((data: { _id: string, programName: string }) => {
                    const updatedElement = {
                        value: data._id,
                        label: data.programName
                    };
                    return updatedElement;
                });
                const updatedServiceDescription = [
                    ...tempDes.map((des: any) => ({ label: des.serviceDescription, value: des._id }))
                ]
                const updatedFundingSource = [
                    ...temFun.map((fun: any) => ({ label: fun.name, value: fun._id }))
                ]

                const constantsWithUpdatedPayer = BILLING_INPUT_CONSTANTS.map((field) => {
                    if (field.name === 'client') {
                        return {
                            ...field,
                            options: optionsClient,
                        };
                    } else if (field.name === 'programSite') {
                        return {
                            ...field,
                            options: optionsProgram,
                        };
                    } else if (field.name === 'serviceDescription') {
                        return {
                            ...field,
                            options: updatedServiceDescription,
                        };
                    } else if (field.name === 'fundingSource') {
                        return {
                            ...field,
                            options: updatedFundingSource,
                        };
                    }
                    return field;
                });
                setLoading(false)
                setUpdatedBillingOptions(constantsWithUpdatedPayer);
            } catch (err) {
                console.log(err)
            }
        }
        fetchData();
    }, [])

    // const initialValues = {
    //     programSite: '',
    //     client: '',
    //     authorizationID: '',
    //     authorizationNumber: '',
    //     fundingSource: '',
    //     fundingProviderNumber: '',
    //     serviceCoordinatorNumber: '',
    //     serviceDescription: '',
    //     beginServiceDateFrom: '',
    //     beginServiceDateTo: '',
    //     endServiceDateFrom: '',
    //     endServiceDateTo: '',
    // }


    const handleSubmitForm = async (formData: any) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');

        // Create the formatted date string
        const currentDateFormatted = `${year}-${month}-${day}`;
        if(formData.beginServiceDateFrom <= currentDateFormatted && formData.endServiceDateFrom <= currentDateFormatted && formData.beginServiceDateTo >= formData.beginServiceDateFrom && formData.endServiceDateTo >= formData.endServiceDateFrom){
            console.log(formData)
        } else {
            if(formData.beginServiceDateFrom > currentDateFormatted){
                toast.error('Service Authorization Begin Date From should not be later than today')
            }else if(formData.beginServiceDateTo < formData.beginServiceDateFrom){
                toast.error('Service Authorization Begin Date To should not be earlier than Begin Date From')
            }else if(formData.endServiceDateFrom > currentDateFormatted){
                toast.error('Service Authorization End Date From should not be later than today')
            }else if(formData.endServiceDateTo < formData.endServiceDateFrom){
                toast.error('Service Authorization End Date To should not be earlier than End Date From')
            }
        }
        
    };
    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' }
    ];

    return (
        <div className="card">
            <div className="card-body p-7">
                
                <TableWrapper
                    searchField={
                        <h2>Billing Data</h2>
                    }
                    tableButtons={
                        <TableButton
                            variant="contained"
                            color="primary"
                            style={{ float: 'right', }}
                            onClick={() => history.push(`/professionalclaim/billing-data/search`)}
                            title="Advance Search"
                        />
                    }
                    dynamicTable={
                        loading ? <div className="d-flex justify-content-center"><MoonLoader color="#9db2fc" size={80} /></div>
                            :
                            <div className="mt-3">
                                 <DynamicForm
                                fields={updatedBillingOptions}
                                data={NEW_BILLING_DATA}
                                onSubmit={handleSubmitForm}
                                buttons={buttons}
                            />
                            </div>
                           
                    }
                />
            </div>
        </div>
    )
}

export default New