import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import AgingReport from '../../../../../pages/billing/professionalclaim/agency-based-reports/aging-report/AgingReport'
import DeniedClaim from '../../../../../pages/billing/professionalclaim/agency-based-reports/denied-claim/DeniedClaim'
import Reconciliation from '../../../../../pages/billing/professionalclaim/agency-based-reports/reconciliation/Reconciliation'
import ReconciliationServiceLevel from '../../../../../pages/billing/professionalclaim/agency-based-reports/reconciliation-service-level/ReconciliationServiceLevel'
import SubmissionErrors from '../../../../../pages/billing/professionalclaim/agency-based-reports/submission-errors/SubmissionErrors'
import Unclaimed from '../../../../../pages/billing/professionalclaim/agency-based-reports/unclaimed/Unclaimed'
import Utilization from '../../../../../pages/billing/professionalclaim/agency-based-reports/utilization/Utilization'

const AgencyBasedReportsIndexRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path='/professionalclaim/agency-based-reports/aging-report'>
        <AgingReport />
      </Route>
      <Route path='/professionalclaim/agency-based-reports/denied-claim'>
        <DeniedClaim />
      </Route>
      <Route path='/professionalclaim/agency-based-reports/reconciliation'>
        <Reconciliation />
      </Route>
      <Route path='/professionalclaim/agency-based-reports/reconciliation-service-level'>
        <ReconciliationServiceLevel />
      </Route>
      <Route path='/professionalclaim/agency-based-reports/submission-errors'>
        <SubmissionErrors />
      </Route>{' '}
      <Route path='/professionalclaim/agency-based-reports/unclaimed'>
        <Unclaimed />
      </Route>{' '}
      <Route path='/professionalclaim/agency-based-reports/utilization'>
        <Utilization />
      </Route>
      <Redirect
        from='/professionalclaim/agency-based-reports'
        exact={true}
        to='/professionalclaim/agency-based-reports/denied-claim'
      />
      <Redirect to='/professionalclaim/agency-based-reports/' />
    </Switch>
  )
}

export default AgencyBasedReportsIndexRoutes
