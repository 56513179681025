import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import ClientProvider from '../../../pages/behaviour/client'
import TargetProvider from '../../../pages/behaviour/target'
import ReplacementProvider from '../../../pages/behaviour/replacement'
import AddNewClient from '../../../pages/behaviour/client/addNewClient'
import EditClient from '../../../pages/behaviour/client/editClient'
import AddEntry from '../../../pages/behaviour/client/addEntry'
import ExportBehavior from '../../../pages/behaviour/client/exportBehavior'
import { useSelector } from 'react-redux'
import { RouteWithPermission } from '../../../../_helper/routesWithPermission'
import { hasPermission } from '../../../../_helper/hasPermission'
import { permissions } from '../../../../_constants/permissions'
import { Error404 } from '../../../modules/errors/components/Error404'

const BehaviorIndexRouter: React.FC = () => {
  const auth = useSelector((state: { auth: any }) => state.auth);
  const userPermissions = auth.user.roleId;
  const canView = hasPermission(userPermissions, permissions.behavior_view)
  const canViewClient = hasPermission(userPermissions, permissions.behavior_client_behavior_view)
  const canUpdate = hasPermission(userPermissions, permissions.behavior_client_behavior_update)
  const canCreate = hasPermission(userPermissions, permissions.behavior_client_behavior_create)
  const canCreateEntry = hasPermission(userPermissions, permissions.behavior_client_behavior_create_entry)
  const canViewTarget = hasPermission(userPermissions, permissions.behavior_target_view)
  const canViewReplacement = hasPermission(userPermissions, permissions.behavior_replacement_view)
  const canViewExport = hasPermission(userPermissions, permissions.behavior_export_view)

  return (
    <Switch>
      <RouteWithPermission path='/behaviour/edit/:id' component={EditClient} hasPerm={(canViewClient && canUpdate)}/>
      <RouteWithPermission path='/behaviour/entry/:id' component={AddEntry} hasPerm={(canView && canCreateEntry)}/>
      <RouteWithPermission path='/behaviour/add' component={AddNewClient} hasPerm={(canViewClient && canCreate)}/>
      <RouteWithPermission path='/behaviour/client' component={ClientProvider} hasPerm={(canView && canViewClient)}/>
      <RouteWithPermission path='/behaviour/target' component={TargetProvider} hasPerm={(canView && canViewTarget)}/>
      <RouteWithPermission path='/behaviour/replacement' component={ReplacementProvider} hasPerm={(canView && canViewReplacement)}/>
      <RouteWithPermission path='/behaviour/export' component={ExportBehavior} hasPerm={(canView && canViewExport)}/>
      <Route path='/error/404' component={Error404} />
      {/* <Route path='/behaviour/individual-medication/new'>
        <IndividualMedicationForm />
      </Route>
      <Route path='/behaviour/individual-medication/view'>
        <IndividualMedicationViewForm />
      </Route>
      <Route path='/behaviour/individual-medication/:id'>
        <IndividualMedicationDosage />
      </Route>
      <Route path='/behaviour/missed-medication/list'>
        <MissedMedicationListing />
      </Route> */}

      <Redirect to='/behaviour/client' />
    </Switch>
  )
}

export default BehaviorIndexRouter
