import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import {getOneNursesNotes, updateNursesNotes} from '../store/action'
import {NEW_RECORD_INPUTS} from '../../GENERAL_CONSTANTS'
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import axios from 'axios'

const selectAuth = (state) => state.auth

export const EditNotes = () => {
  const {id} = useParams()
  const [formData, setFormData] = useState({})
  const [newData, setNewData] = useState({})
  const dispatch = useDispatch()
  const history = useHistory()
  const [frequency, setFrequency] = useState('')
  const [visitType, setVisitType] = useState('')
  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(dayjs())
  const [selectedClientId, setSelectedClientId] = useState('')
  const [clientFullName, setClientFullName] = useState('')
  const [nextAppt, setNextAppt] = useState(null)
  const [therapy, setTherapy] = useState('')
  const [isExperiencingPain, setIsExperiencingPain] = useState('')
  const [painAssessment, setPainAssessment] = useState([
    {location: '', score: '', duration: '', frequency: '', description: '', isExperiencingPain: ''},
  ])
  const [hasExperiencedFall, setHasExperiencedFall] = useState('')
  const [activity, setActivity] = useState([
    {
      hasExperiencedFall: '',
      details: '',
    },
  ])
  const [pocUpdated, setPocUpdated] = useState('')
  const [poc, setPoc] = useState([
    {
      pocUpdated: '',
      pocDesc: '',
    },
  ])

  const [familyUpdated, setFamilyUpdated] = useState('no')
  const [familyInformed, setFamilyInformed] = useState([
    {
      familyUpdated: '',
      famDesc: '',
    },
  ])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await dispatch(getOneNursesNotes(id))
        const {data} = result.payload.data
        setNewData(data)
        setSelectedClientId(data._id)
        setStartDate(data.startDate)
        setEndDate(data.endDate)
        setClientFullName(data.clientFullName)
        setPainAssessment(data.painAssessment || [])
        setFrequency(data.frequency)
        setVisitType(data.visitType)
        if (
          data.painAssessment &&
          data.painAssessment[0].location === '' &&
          data.painAssessment[0].duration === '' &&
          data.painAssessment[0].score === '' &&
          data.painAssessment[0].frequency === '' &&
          data.painAssessment[0].description === ''
        ) {
          setIsExperiencingPain(false)
        } else {
          setIsExperiencingPain(true)
        }
        setActivity(data.activity || [])
        if (data.activity && data.activity[0].details === '') {
          setHasExperiencedFall(false)
        } else {
          setHasExperiencedFall(true)
        }

        setPoc(data.poc || [])
        if (data.poc && data.poc[0].pocDesc === '') {
          setPocUpdated(false)
        } else {
          setPocUpdated(true)
        }

        setFamilyInformed(data.familyInformed || [])
        if (data.familyInformed && data.familyInformed[0].famDesc === '') {
          setFamilyUpdated(false)
        } else {
          setFamilyUpdated(true)
        }

        setTherapy(data.therapy)
        const tempObj = {
          bp: data.vitalSigns.bp,
          pulse: data.vitalSigns.pulse,
          rr: data.vitalSigns.rr,
          temp: data.vitalSigns.temp,
          pain: data.vitalSigns.pain,
          o2sat: data.vitalSigns.o2sat,
          neuro: data.assessment.neuro,
          skin: data.assessment.skin,
          heent: data.assessment.heent,
          respiratory: data.assessment.respiratory,
          cardiac: data.assessment.cardiac,
          lastBm: data.assessment.gi.lastBm,
          value: data.assessment.gi.weight.value,
          type: data.assessment.gi.weight.type,
          gu: data.assessment.cardiac,
          patientConcerns: data.assessment.patientConcerns,
          notes: data.assessment.notes,
          dme: data.assessment.dme,
          medRec: data.assessment.medRec,
          nextAppt: data.assessment.nextAppt,
        }
        setFormData(tempObj)

        if (data.nextAppt) {
          setNextAppt(new Date(data.assessment.nextAppt))
        }
      } catch (error) {
        console.error('Error fetching data', error)
      }
    }
    fetchData()
  }, [id, dispatch])

  const handleAddPainDetail = () => {
    setPainAssessment([
      ...painAssessment,
      {location: '', score: '', duration: '', frequency: '', description: ''},
    ])
  }

  const handlePainDetailChange = (index, field, value) => {
    const newPainDetails = [...painAssessment]
    newPainDetails[index][field] = value
    setPainAssessment(newPainDetails)
  }

  const handleRemovePainDetail = (index) => {
    const newPainDetails = [...painAssessment]
    newPainDetails.splice(index, 1)
    setPainAssessment(newPainDetails)
  }

  const handleFallDetailChange = (index, field, value) => {
    const newFallDetails = [...activity]
    newFallDetails[index][field] = value
    setActivity(newFallDetails)
  }

  const handlePocDetailChange = (index, field, value) => {
    const newPocDetails = [...poc]
    newPocDetails[index][field] = value
    setPoc(newPocDetails)
  }

  const handlefamilyDetailChange = (index, field, value) => {
    const newFamDetails = [...familyInformed]
    newFamDetails[index][field] = value
    setFamilyInformed(newFamDetails)
  }
  const cleanEmptyFields = (data, initialData = {}) => {
    if (Array.isArray(data)) {
      return data
        .map((item, index) =>
          index === 0 ? cleanEmptyFields(item, initialData[0] || {}) : cleanEmptyFields(item)
        )
        .filter((item, index) => index === 0 || Object.values(item).some((value) => value !== ''))
    } else if (typeof data === 'object' && data !== null) {
      const cleanedData = {}
      for (const key in data) {
        const value = cleanEmptyFields(data[key], initialData[key])
        if (value !== undefined && (value !== '' || initialData[key] !== undefined)) {
          cleanedData[key] = value
        }
      }
      return Object.keys(cleanedData).length > 0 ? cleanedData : initialData
    } else {
      return data
    }
  }

  const handleSubmitForm = async (fData) => {
    const updatedPainAssessment = isExperiencingPain
      ? painAssessment.length > 0
        ? painAssessment
        : [
            {
              location: '',
              score: '',
              duration: '',
              frequency: '',
              description: '',
              isExperiencingPain: true,
            },
          ]
      : [
          {
            location: '',
            score: '',
            duration: '',
            frequency: '',
            description: '',
            isExperiencingPain: false,
          },
        ]
    const updatedActivity = activity.map((fallDetail) => ({
      ...fallDetail,
      hasExperiencedFall: hasExperiencedFall === 'yes',
    }))

    const updatedPoc = poc.map((pocDetail) => ({
      ...pocDetail,
      pocUpdated: pocUpdated === 'yes',
    }))

    const updatedFamily = poc.map((famDetail) => ({
      ...famDetail,
      familyUpdated: familyUpdated === 'yes',
    }))
    const tempObj = {
      frequency,
      startDate,
      endDate,
      _id: selectedClientId,
      clientFullName,
      visitType,
      painAssessment: updatedPainAssessment,
      activity: updatedActivity,
      therapy,
      poc: updatedPoc,
      familyInformed: updatedFamily,
      vitalSigns: {
        bp: fData.bp,
        pulse: fData.pulse,
        rr: fData.rr,
        temp: fData.temp,
        pain: fData.pain,
        o2sat: fData.o2sat,
      },
      assessment: {
        neuro: fData.neuro,
        skin: fData.skin,
        heent: fData.heent,
        respiratory: fData.respiratory,
        cardiac: fData.cardiac,
        gu: fData.gu,
        gi: {
          lastBm: fData.lastBm,
          weight: {
            value: fData.value,
            type: fData.type,
          },
        },

        patientConcerns: fData.patientConcerns,
        notes: fData.notes,
        dme: fData.dme,
        medRec: fData.medRec,
        familyUpdated: fData.familyUpdated,
        nextAppt: fData.nextAppt,
      },
    }

    const cleanedPainAssessment = updatedPainAssessment.filter((item) =>
      Object.values(item).some((value) => value !== '')
    )

    const cleanedData = {
      ...tempObj,
      painAssessment: cleanedPainAssessment,
    }

    try {
      const response = await dispatch(updateNursesNotes(cleanedData))

      if (response.payload.message.includes('successfully')) {
        toast.success('Updated successfully')
        history.push('/records/general/nurses-notes/list')
      } else {
        toast.error('Failed to update')
      }
      if (!isExperiencingPain) {
        setPainAssessment([
          {
            location: '',
            score: '',
            duration: '',
            frequency: '',
            description: '',
            isExperiencingPain: false,
          },
        ])
        setIsExperiencingPain(false) // Reset to default
      }
    } catch (error) {
      toast.error('Error updating scoring method')
    }
  }

  const [displayDateRange, setDisplayDateRange] = useState('')

  useEffect(() => {
    setDisplayDateRange('')
  }, [frequency])

  const buttons = [
    {label: 'Cancel', variant: 'contained', type: 'button', onClick: () => history.goBack()},
    {label: 'Save', variant: 'contained', type: 'submit'},
  ]

  console.log(pocUpdated)
  return (
    <FormWrapper
      title='Edit Note'
      content={
        <>
          {frequency === 'WEEKLY' && (
            <div style={{marginLeft: '15px', color: 'red'}}>
              Note: The start date will automatically adjust to Sunday as the beginning of the week.
            </div>
          )}
          <FormControl
            sx={{mb: 2, ml: 1.5, width: '50%'}}
            color='primary'
            fullWidth
            variant='filled'
            required
          >
            <TextField
              labelId='note-type-select-label'
              value={frequency}
              sx={{background: 'white'}}
              onChange={(e) => {
                setFrequency(e.target.value)
              }}
              label='Note Type'
              label='Note Type'
              name='frequency'
              disabled
            />
          </FormControl>

          {frequency && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            {frequency === 'MONTHLY' ? (
              <>
                <TextField
                  views={['month', 'year']}
                  sx={{ background: 'white', ml: 1 }}
                  label="Selected Range"
                  value={`01-${startDate ? dayjs(startDate).format('MMMM') : ''} - ${
                    endDate ? dayjs(endDate).format('DD-MMMM') : ''
                  }`}
                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                  disabled
                />
              </>
            ) : frequency === 'YEARLY' ? (
              <>
                <TextField
                  views={['year']}
                  sx={{ background: 'white', ml: 1 }}
                  label="Selected Range"
                  value={`${startDate ? dayjs(startDate).format('MMMM') : ''} - ${
                    endDate ? dayjs(endDate).format('MMMM') : ''
                  }`}
                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                  disabled
                />
              </>
            ) : frequency === 'QUARTERLY' ? (
              <>
                <TextField
                  views={['month', 'year']}
                  sx={{ background: 'white', ml: 1 }}
                  label="Selected Range"
                  value={`${startDate ? dayjs(startDate).format('MMMM') : ''} - ${
                    endDate ? dayjs(endDate).format('MMMM') : ''
                  }`}
                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                  disabled
                />
              </>
            ) : frequency === 'DAILY' ? (
              <TextField
                label="Selected Date"
                sx={{ background: 'white', ml: 1 }}
                value={startDate ? dayjs(startDate).format('MM-DD-YYYY'):''}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                disabled
              />
            ) : frequency === 'WEEKLY' ? (
              <TextField
                label="Selected Date"
                sx={{ background: 'white', ml: 1 }}
                value={`${startDate ? dayjs(startDate).format('MM-DD-YY') : ''} to ${
                  endDate ? dayjs(endDate).format('MM-DD-YY') : ''
                }`}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                disabled
              />
            ) : null}
          </LocalizationProvider>
          
          )}

          <FormControl
            sx={{mb: 2, ml: 1.5, width: '50%', background: 'white'}}
            color='primary'
            fullWidth
            variant='filled'
            required
          >
            <TextField value={clientFullName} disabled />
          </FormControl>

          <FormControl
            sx={{mb: 2, ml: 1, width: '25%'}}
            color='primary'
            fullWidth
            variant='filled'
            required
          >
            <TextField
              // labelId='note-type-select-label'
              value={visitType}
              sx={{background: 'white'}}
              onChange={(e) => {
                setVisitType(e.target.value)
              }}
              label='VisitType'
              name='visitType'
              disabled
            />
          </FormControl>

          <p
            style={{
              fontSize: '16px',
              marginLeft: '15px',
              color: '#1976d2',
              fontWeight: 700,
              fontFamily: 'Roboto',
            }}
          >
            Did the patient experience a fall?
          </p>
          <FormControl sx={{width: '100%', mr: '10px'}} required>
            <RadioGroup
              row
              value={hasExperiencedFall === true ? 'yes' : 'no'}
              onChange={(e) => {
                const newValue = e.target.value === 'yes'
                setHasExperiencedFall(newValue)

                setActivity(activity.map((a) => ({...a, hasExperiencedFall: newValue})))
              }}
              sx={{mb: 2, ml: 1.5}}
            >
              <FormControlLabel value='yes' control={<Radio />} label='Yes' />
              <FormControlLabel value='no' control={<Radio />} label='No' />
            </RadioGroup>

            {hasExperiencedFall && activity.length > 0 && (
              <div>
                {activity.map((fallDetail, index) => (
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div key={index} style={{width: '90%'}}>
                      <TextField
                        sx={{width: '98%', mr: '10px', ml: 1}}
                        label='Fall details'
                        name='details'
                        value={fallDetail.details}
                        onChange={(e) => handleFallDetailChange(index, 'details', e.target.value)}
                        fullWidth
                        margin='normal'
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FormControl>
          <p
            style={{
              fontSize: '16px',
              marginLeft: '15px',
              color: '#1976d2',
              fontWeight: 700,
              fontFamily: 'Roboto',
            }}
          >
            Is the patient experiencing Pain?
          </p>
          <RadioGroup
            row
            value={isExperiencingPain ? 'yes' : 'no'}
            onChange={(e) => {
              const newValue = e.target.value === 'yes'
              setIsExperiencingPain(newValue)
              setPainAssessment(painAssessment.map((pa) => ({...pa, isExperiencingPain: newValue})))
            }}
            sx={{mb: 2, ml: 1.5}}
          >
            <FormControlLabel value='yes' control={<Radio />} label='Yes' />
            <FormControlLabel value='no' control={<Radio />} label='No' />
          </RadioGroup>
          {isExperiencingPain && painAssessment.length > 0 && (
            <>
              <div>
                {painAssessment.map((painDetail, index) => (
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div key={index} style={{marginBottom: '10px', width: '90%'}}>
                      <TextField
                        sx={{width: '24%', mr: '10px', ml: 1}}
                        label='Location'
                        name='location'
                        value={painDetail.location}
                        onChange={(e) => handlePainDetailChange(index, 'location', e.target.value)}
                        fullWidth
                        margin='normal'
                      />
                      <TextField
                        type='number'
                        sx={{width: '24%', mr: '10px', ml: 0.5}}
                        label='Pain Score'
                        name='score'
                        value={painDetail.score}
                        onChange={(e) => handlePainDetailChange(index, 'score', e.target.value)}
                        fullWidth
                        margin='normal'
                      />
                      <TextField
                        sx={{width: '24%', mr: '8px', ml: 0.5}}
                        label='Duration'
                        name='duration'
                        value={painDetail.duration}
                        onChange={(e) => handlePainDetailChange(index, 'duration', e.target.value)}
                        fullWidth
                        margin='normal'
                      />
                      <TextField
                        sx={{width: '22%', mr: '10px', ml: 1}}
                        label='Frequency'
                        name='frequency'
                        value={painDetail.frequency}
                        onChange={(e) => handlePainDetailChange(index, 'frequency', e.target.value)}
                        fullWidth
                        margin='normal'
                      />
                      <TextField
                        sx={{width: index > 0 ? '90%' : '98%', ml: 1}}
                        label='Description'
                        name='description'
                        value={painDetail.description}
                        onChange={(e) =>
                          handlePainDetailChange(index, 'description', e.target.value)
                        }
                        fullWidth
                        margin='normal'
                      />
                      {index > 0 && (
                        <Button
                          sx={{mt: 4, ml: 1}}
                          onClick={() => handleRemovePainDetail(index)}
                          variant='contained'
                          color='primary'
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <Button sx={{mb: 4, ml: 1}} onClick={handleAddPainDetail} variant='contained'>
                Add More
              </Button>
            </>
          )}

          <p
            style={{
              fontSize: '16px',
              marginLeft: '15px',
              color: '#1976d2',
              fontWeight: 700,
              fontFamily: 'Roboto',
            }}
          >
            POC updated?
          </p>
          <FormControl sx={{width: '100%', mr: '10px', ml: 1}} required>
            <RadioGroup
              row
              value={pocUpdated === true ? 'yes' : 'no'}
              onChange={(e) => {
                const pocValue = e.target.value === 'yes'
                setPocUpdated(pocValue)

                setPoc(poc.map((p) => ({...p, pocUpdated: pocValue})))
              }}
              sx={{mb: 2, ml: 1.5}}
            >
              <FormControlLabel value='yes' control={<Radio />} label='Yes' />
              <FormControlLabel value='no' control={<Radio />} label='No' />
            </RadioGroup>

            {pocUpdated === true && poc.length > 0 && (
              <div>
                {poc.map((pocDetail, index) => (
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div key={index} style={{width: '90%'}}>
                      <TextField
                        sx={{width: '98%', mr: '10px', ml: 1}}
                        label='POC details'
                        name='pocDesc'
                        value={pocDetail.pocDesc}
                        onChange={(e) => handlePocDetailChange(index, 'pocDesc', e.target.value)}
                        fullWidth
                        margin='normal'
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FormControl>
          <p
            style={{
              fontSize: '16px',
              marginLeft: '15px',
              color: '#1976d2',
              fontWeight: 700,
              fontFamily: 'Roboto',
            }}
          >
            Family updated?
          </p>
          <FormControl sx={{width: '100%', mr: '10px', ml: 1}} required>
            <RadioGroup
              row
              value={familyUpdated === true ? 'yes' : 'no'}
              onChange={(e) => {
                const value = e.target.value === 'yes'
                setFamilyUpdated(value)
                setFamilyInformed(familyInformed.map((f) => ({...f, familyUpdated: value})))
              }}
              sx={{mb: 2, ml: 1.5}}
            >
              <FormControlLabel value='yes' control={<Radio />} label='Yes' />
              <FormControlLabel value='no' control={<Radio />} label='No' />
            </RadioGroup>

            {familyUpdated && familyInformed.length > 0 && (
              <div>
                {familyInformed.map((famDetail, index) => (
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div key={index} style={{width: '90%'}}>
                      <TextField
                        sx={{width: '98%', mr: '10px', ml: 1}}
                        label='Family details'
                        name='famDesc'
                        value={famDetail.famDesc}
                        onChange={(e) => handlefamilyDetailChange(index, 'famDesc', e.target.value)}
                        fullWidth
                        margin='normal'
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FormControl>
          <p
            style={{
              fontSize: '16px',
              marginLeft: '15px',
              color: '#1976d2',
              fontWeight: 700,
              fontFamily: 'Roboto',
            }}
          >
            Therapy
          </p>
          <FormControl
            sx={{mt: 0, mb: 2, ml: 1.5, mr: 1, width: '32%', border: '1px solid lightgrey'}}
            color='primary'
            fullWidth
            variant='filled'
            required
          >
            <InputLabel id='note-type-select-label'>Select Therapy</InputLabel>
            <Select
              labelId='note-type-select-label'
              value={therapy}
              sx={{background: 'white'}}
              onChange={(e) => {
                setTherapy(e.target.value)
              }}
              label='Therapy'
              name='therapy'
            >
              <MenuItem value='pt'>PT</MenuItem>
              <MenuItem value='ot'>OT</MenuItem>
              <MenuItem value='st'>ST</MenuItem>
              <MenuItem value='hha'>HHA</MenuItem>
              <MenuItem value='msw'>MSW</MenuItem>
              <MenuItem value='rn'>RN</MenuItem>
              <MenuItem value='cm'>CM</MenuItem>
            </Select>
          </FormControl>
          <DynamicForm
            fields={NEW_RECORD_INPUTS}
            data={formData}
            onSubmit={handleSubmitForm}
            buttons={buttons}
          />
        </>
      }
    />
  )
}
