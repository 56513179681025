import { useEffect, useState } from 'react'
import axios from 'axios'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import AddClientLogForm from './New'
import {
    INDIVIDUAL_CONTACT_LIST_COLUMNS_DATA_MAPPING,
    INDIVIDUAL_CONTACT_LIST_COLUMNS,
    INDIVIDUAL_CONTACT_LIST_SORTING_COLUMNS,
} from '../../care_table_constants'
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper'
import BackButton from '../../../../../reusable-components/Buttons/BackButton'


const ClientLogList = () => {
    const [selectedIndividual, setSelectedIndividual] = useState({})
    const [clientsListing, setClientsListing] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [showNewForm, setShowNewForm] = useState(false)
    const [queryData, setQueryData] = useState({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'createdAt',
        sortDir: 'asc',
        searchTerm: ''
    })

    const onView = (row) => {
        setSelectedIndividual(row)
        setShowNewForm(true)
    }
    useEffect(() => {
        const fetchClients = async (page, pageSize, sortBy, sortDir) => {
            try {
                const response = await axios.get(`/clients/get?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${queryData.searchTerm}`);
                const { data, currentPage, totalCounts } = response.data;
                setClientsListing(data);
                setFilteredData(data);
                setQueryData((prevState) => {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    };
                });
            } catch (err) {
                console.error('Error fetching contants:', err);
            }
        };
    
        fetchClients(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir);
    
    }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm]);
    

    return (
        <div className='card'>
            <div className='card-body'>
                {
                    !showNewForm && <TableTitle title='Clients List'></TableTitle>
                }
                <TableWrapper
                    searchField={
                        !showNewForm ? (
                            <>
                                <TableSearchBar
                                    label='Search'
                                    variant='outlined'
                                    size='small'
                                    value={searchTerm}
                                    setQueryData={setQueryData}
                                    setSearchTerm={setSearchTerm}
                                    margin='normal'
                                    inputAdornmentPosition='start'
                                ></TableSearchBar>
                            </>
                        ) : (
                            <></>
                        )
                    }
                    tableButtons={
                        !showNewForm && <BackButton />
                    }
                    dynamicTable={
                        !showNewForm ? (
                            <DynamicTable
                                columns={INDIVIDUAL_CONTACT_LIST_COLUMNS}
                                data={filteredData}
                                canUpdate={false}
                                canDelete={false}
                                canView={true}
                                onView={onView}
                                columnDataMapping={INDIVIDUAL_CONTACT_LIST_COLUMNS_DATA_MAPPING}
                                sortingColumns={INDIVIDUAL_CONTACT_LIST_SORTING_COLUMNS}
                                title='Are you sure you want to delete this client?'
                                setQueryData={setQueryData}
                                queryData={queryData}
                            />
                        ) : (
                            <AddClientLogForm selectedIndividual={selectedIndividual} setShowNewForm={setShowNewForm} />
                        )
                    }
                />
            </div>
        </div>
    )
}



export default ClientLogList