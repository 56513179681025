import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  TextField,
  Typography,
} from '@mui/material'
import DynamicTable from '../../../reusable-components/tables/DynamicTable'
import TableSearchBar from '../../../reusable-components/tables/TableSearchBar'
import TableTitle from '../../../reusable-components/tables/TableTitle'
import TableWrapper from '../../../reusable-components/tables/TableWrapper'
import {Fragment, useEffect, useState} from 'react'
import AddClientBehavior from '../component/AddClientBehaviors'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {toast} from 'react-toastify'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const CLIENT_BEHAVIOR_TABLE_HEADER = ['Target Behaviors', 'Replacement Behaviors', 'Status']

export const CLIENT_BEHAVIOR_DATA_MAPPING = {
  'Target Behaviors': 'targetBehavior',
  'Replacement Behaviors': 'replacementBehavior',
  Status: 'status',
}
export const CLIENT_BEHAVIOR_SORTING_COLUMNS = {
  'Target Behaviors': 'targetBehavior',
  'Replacement Behaviors': 'replacementBehavior',
  Status: 'status',
}
export const htmlColumns = ['Replacement Behaviors']

export default function EditClientBehaviour() {
  const [searchTerm, setSearchTerm] = useState('')
  const [addBehaviour, setAddBehaviour] = useState(false)
  const [behaviour, setBehaviour] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [data, setData] = useState({
    client: {},
    behaviors: [],
  })
  const [replacements, setReplacements] = useState([])
  const [targets, setTargets] = useState([])
  const [targetBehaviour, setTargetBehaviour] = useState([null])
  const [replacementBehaviour, setReplacementBehaviour] = useState([[]])
  const [queryData, setQueryData] = useState({
    pageNumber: 1,
    pageSize: 5,
    currentPage: 1,
    totalCounts: 1,
    sortBy: '_id',
    sortDir: 'desc',
    searchTerm: '',
  })
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
  const checkedIcon = <CheckBoxIcon fontSize='small' />
  const params = useParams()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [replacementResponse, targetResponse] = await Promise.all([
          axios.get(`/options/behavior/${params.id}?type=REPLACEMENT_BEHAVIOR`),
          axios.get(`/options/behavior/${params.id}?type=TARGET_BEHAVIOR`),
        ])
        const {data: replacementData} = replacementResponse
        const {data: targetData} = targetResponse
        setReplacements(replacementData?.map((item) => ({value: item?.behavior?._id, label: item?.behavior?.name})))

        setTargets(targetData?.map((item) => ({value: item?.behavior?._id, label: item?.behavior?.name})))
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [filteredData?.length])

  const getBehaviors = async (page, pageSize, sortBy, sortDir, searchTerm) => {
    try {
      if (params.id) {
        const response = await axios.get(
          `/behaviors/${params.id}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${searchTerm}`
        )
        const allBehaviors = []
        const {data, currentPage, totalCounts} = response?.data
        data?.forEach(({_id, clientId, behaviorsMapping}) => {
          const behavior = []
          let replacementId = behaviorsMapping?.replacementIds
            ?.slice(0, 5)
            .map((behavior, index) => {
              return (
                <Fragment key={index}>
                  <p className='mb-0 mt-0'>{behavior.name}</p>
                </Fragment>
              )
            })

          behavior.push({
            targetBehavior: behaviorsMapping.targetId.name,
            replacementBehavior: replacementId,
            status: behaviorsMapping.status,
            mapingId: behaviorsMapping._id,
          })

          allBehaviors.push(...behavior)

          setData({
            id: _id,
            client: clientId,
            behaviors: behavior,
          })
        })

        setFilteredData(allBehaviors)
        setQueryData((prevState) => {
          return {
            ...prevState,
            currentPage,
            totalCounts,
          }
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getBehaviors(
      queryData.pageNumber,
      queryData.pageSize,
      queryData.sortBy,
      queryData.sortDir,
      queryData.searchTerm
    )
  }, [
    queryData.pageNumber,
    queryData.pageSize,
    queryData.sortBy,
    queryData.sortDir,
    queryData.searchTerm,
  ])
  

  const handleUpdate = (e) => {
    setBehaviour(e.name)
    setAddBehaviour(true)
  }

  const handleSubmit = async () => {
    try {
      const filteredTargets = targetBehaviour
        .map((target) => {
          const foundIndex = targets.findIndex((t) => t.label === target)
          if (foundIndex !== -1) {
            return targets[foundIndex].value
          }
          return undefined
        })
        .filter((f) => f !== undefined)

      if (filteredTargets.length) {
        await axios.post(
          `/behaviors/${data.id}/mapping`,
          filteredTargets.map((targets, index) => ({
            targetId: targets,
            replacementIds: replacementBehaviour[index]
              .map((replacement) => {
                const foundIndex = replacements.findIndex((t) => t.label === replacement)
                if (foundIndex !== -1) {
                  return replacements[foundIndex].value
                }
                return undefined
              })
              .filter((f) => f !== undefined),
          }))
        )
      } else {
        toast.warning('Please select target behavior')
      }

      getBehaviors(
        queryData.pageNumber,
        queryData.pageSize,
        queryData.sortBy,
        queryData.sortDir,
        queryData.searchTerm
      )
      setTargetBehaviour([null])
      setReplacementBehaviour([[]])
    } catch (error) {
      console.error(error)
    }
  }

  const handleUpdateStatus = async (row) => {
    try {
      await axios.put(`/behaviors/${data.id}/mapping/${row.mapingId}/status`, {
        status: row.status === 'ACTIVE' ? 'DISCONTINUED' : 'ACTIVE',
      })
      getBehaviors(
        queryData.pageNumber,
        queryData.pageSize,
        queryData.sortBy,
        queryData.sortDir,
        queryData.searchTerm
      )
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <div className='card'>
      {addBehaviour ? (
        <AddClientBehavior
          setBehaviour={setBehaviour}
          behaviour={behaviour}
          handleClose={() => {
            setBehaviour('')
            setAddBehaviour(false)
          }}
          label='Add Target Behavior'
          title='Target Behavior'
        />
      ) : null}
      <div className='card-body'>
        <TableTitle title={' Client Details'} />
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, mt: 2}}>
          <Box sx={{display: 'flex', gap: 5}}>
            <Typography variant='h4' sx={{fontSize: '14px', fontWeight: 600}}>
              Name:
            </Typography>
            <Typography variant='h6' sx={{fontSize: '14px'}}>
              {data.client.firstName + ' ' + data.client.lastName}
            </Typography>
          </Box>
          <Box sx={{display: 'flex', gap: 5}}>
            <Typography variant='h4' sx={{fontSize: '14px', fontWeight: 600}}>
              Email:
            </Typography>
            <Typography variant='h6' sx={{fontSize: '14px'}}>
              {data.client.email}
            </Typography>
          </Box>
        </Box>

        <TableWrapper
          searchField={
            <TableSearchBar
              label='Search'
              variant='outlined'
              size='small'
              value={searchTerm}
              setQueryData={setQueryData}
              setSearchTerm={setSearchTerm}
              margin='normal'
              inputAdornmentPosition='start'
            />
          }
          dynamicTable={
            <>
              <DynamicTable
                columns={CLIENT_BEHAVIOR_TABLE_HEADER}
                data={filteredData}
                columnDataMapping={CLIENT_BEHAVIOR_DATA_MAPPING}
                sortingColumns={CLIENT_BEHAVIOR_SORTING_COLUMNS}
                canUpdateStatus
                htmlColumns={htmlColumns}
                handleUpdateStatus={handleUpdateStatus}
                title={'Are you sure you want to discontinue those medication?'}
                setQueryData={setQueryData}
                queryData={queryData}
              />

              {targetBehaviour.map((target, index) => (
                <Box key={target + index} sx={{paddingTop: '1rem'}}>
                  <Divider variant='middle' />
                  <Box
                    key={target + index}
                    sx={{display: 'flex', justifyContent: 'space-between', gap: 2}}
                  >
                    <FormControl style={{display: 'flex', width: '100%'}}>
                      <Autocomplete
                        id='target-autocomplete'
                        options={targets?.filter((targetOption) => {
                          const existingTargetBehaviors = [
                            ...filteredData.map((beh) => beh.targetBehavior),
                            ...targetBehaviour,
                          ]
                          return !existingTargetBehaviors.includes(targetOption.label)
                        })}
                        getOptionLabel={(option) => option.label}
                        value={
                          targets?.find((target) => target.label === targetBehaviour[index]) || null
                        }
                        onChange={(event, newValue) => {
                          let dummytarget = [...targetBehaviour]
                          dummytarget[index] = newValue ? newValue.label : ''
                          setTargetBehaviour([...dummytarget])
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Target Behavior'
                            variant='outlined'
                            size='small'
                            required
                          />
                        )}
                        size='small'
                      />
                    </FormControl>
                    <FormControl style={{display: 'flex', width: '100%', mt: 2}}>
                      <Autocomplete
                        multiple
                        id={`replacement-autocomplete-${index}`}
                        options={replacements}
                        disableCloseOnSelect
                        forcePopupIcon={true}
                        getOptionLabel={(option) => option.label}
                        value={replacements.filter((replacement) =>
                          replacementBehaviour[index]?.includes(replacement.label)
                        )}
                        onChange={(event, newValue) => {
                          const dummyReplacementBehavior = [...replacementBehaviour]
                          dummyReplacementBehavior[index] = newValue.map((option) => option.label)
                          setReplacementBehaviour([...dummyReplacementBehavior])
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Replacement Behavior'
                            variant='outlined'
                            size='small'
                          />
                        )}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.label}
                          </li>
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip label={option.label} {...getTagProps({index})} />
                          ))
                        }
                        size='small'
                      />
                    </FormControl>
                    <Button
                      variant='contained'
                      size='small'
                      sx={{
                        display:'flex',
                        alignSelf:'center',
                        backgroundColor: '#D9214E',
                        borderColor: '#D9214E',
                        '&:hover': {
                          backgroundColor: '#C21C47',
                        }
                      }}
                      disabled={targetBehaviour.length === 1}
                      onClick={() => {
                        let dummytarget = [...targetBehaviour]
                        let dummyReplacementBehavior = [...replacementBehaviour]
                        dummytarget.splice(index, 1)
                        dummyReplacementBehavior.splice(index, 1)
                        setTargetBehaviour([...dummytarget])
                        setReplacementBehaviour([...dummyReplacementBehavior])
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              ))}
              <Box sx={{mt: 2, display: 'flex', gap: 1}}>
                <Button
                  variant='contained'
                  disabled={targets.length === 0}
                  onClick={() => {
                    let dummytarget = [...targetBehaviour]
                    const dummyReplacementBehavior = [...replacementBehaviour]
                    dummyReplacementBehavior.push([])
                    dummytarget.push('')
                    setReplacementBehaviour([...dummyReplacementBehavior])
                    setTargetBehaviour([...dummytarget])
                  }}
                >
                  Add New
                </Button>
                <Button variant='contained' onClick={handleSubmit}>
                  Save
                </Button>
              </Box>
            </>
          }
        />
      </div>
    </div>
  )
}
