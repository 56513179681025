import React from 'react'

function List() {
  return (
    <div>
    <h3 className=' p-7'>Merchant (List)</h3>
    <div className='card'>
      <div className='card-body'>
        <h1 className='text-center p-7'>Nothing found to display</h1>
      </div>
    </div>
    </div>
  )
}

export default List