
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GetDescriptionCode } from '../../../../admin/billing/description-code/store/action'
import { getAttendanceType } from '../../../../admin/billing/attendance-type/store/action'
import { getServiceAuth } from '../../../professionalclaim/service-authorization/store/actions'
import { getProgram } from '../../../../admin/care/enrollment/store/actions'
import { getAllAttendance } from '../../store/action'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { ATTENDANCE_FIELDS_INPUTS, NEW_ATTENDANCE_TYPE_DATA } from '../../ATTENDANCE_FORM_CONSTANTS'
import { Autocomplete, Button, TextField, Checkbox } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { makeStyles } from '@mui/styles'
import { toast } from 'react-toastify'

const options2 = [
    { label: "Program Name", value: "Program Name" },
    { label: "Client Name", value: "Client Name" },
    { label: "Service Description (Code)", value: "Service Description (Code)" },
    { label: "Service Description", value: "Service Description" },
    { label: "Service Date", value: "Service Date" },
    { label: "Attendance Type", value: "Attendance Type" },
    { label: "Attendance Option", value: "Attendance Option" },
    { label: "Time In", value: "Time In" },
    { label: "Time Out", value: "Time Out" },
    { label: "Billable Time Slot", value: "Billable Time Slot" },
    { label: "Duration (hours)", value: "Duration (hours)" },
    { label: "Duration (minutes)", value: "Duration (minutes)" },
    { label: "Comments (Daily)", value: "Comments (Daily)" },
    { label: "Created Date", value: "Created Date" },
    { label: "Last Updated Date", value: "Last Updated Date" },
]

function Summary() {
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const [attendanceOptions, setAttendanceOptions] = useState<any>([]);
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [attendanceResult, descriptionResult, servAuthResult, programNamesResult, allAttendance]: any = await Promise.all([
                    dispatch(getAttendanceType({})),
                    dispatch(GetDescriptionCode({})),
                    dispatch(getServiceAuth({})),
                    dispatch(getProgram({})),
                    dispatch(getAllAttendance())
                ])
                const tempDes = descriptionResult?.payload.data;
                let tempDesObj: { value: string; label: string }[] = []
                tempDes.map((data: { serviceCode: string; serviceDescription: string; _id: string }) => {
                    tempDesObj.push({ label: data.serviceCode + ' - ' + data.serviceDescription, value: data._id })
                })

                const tempAtt = attendanceResult?.payload?.data;
                let tempAttObj: { value: string; label: string }[] = []
                tempAtt.map((data: { _id: string; name: string; }) => {
                    tempAttObj.push({ label: data.name, value: data._id })
                })

                const tempProgNames = programNamesResult?.payload?.data;
                let tempProgNameObj: { label: any; value: any }[] = []
                tempProgNames.map((data: { programName: any; _id: any }) => {
                    tempProgNameObj.push({ label: data.programName, value: data._id })
                })
                let tempServ = servAuthResult?.payload?.data;
                const seen = new Set();

                const options = tempServ.filter((data: { clientID: string; servfirstName: string; servlastName: string }) => { const duplicate = seen.has(data.clientID); seen.add(data.clientID); return !duplicate; }).map((data: { clientID: string; servfirstName: string; servlastName: string }) => ({ label: data.servfirstName + ' ' + data.servlastName, value: data.clientID }));
                const constantsWithUpdatedAttendance = ATTENDANCE_FIELDS_INPUTS.map((field) => {
                    if (field.name === 'client') {
                        return {
                            ...field,
                            options: options,
                        };
                    } else if (field.name === 'attendanceType') {
                        return {
                            ...field,
                            options: tempAttObj,
                        };
                    } else if (field.name === 'serviceDescription') {
                        return {
                            ...field,
                            options: tempDesObj,
                        };
                    } else if (field.name === 'programName') {
                        return {
                            ...field,
                            options: tempProgNameObj,
                        };
                    }
                    return field;
                });

                setAttendanceOptions(constantsWithUpdatedAttendance)
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchData()

    }, [])
    const useStyles = makeStyles((theme) => ({
        fieldRow: {
            display: "flex",
            float: 'right',
            marginBottom: "12px",
            marginRight: '1rem',
            marginLeft: '1rem',

        },

        fieldContainer: {
            flex: 1,
        },
    }))

    const classes = useStyles();

    const handleAddAllClick = () => {
        const allValues = options2.map((option) => option.value);
        setSelectedOptions(allValues);
    };

    const isAddAllDisabled = selectedOptions.length === options2.length;

    const handleOptionChange = (event: any, newValue: any[]) => {
        const selectedValues = newValue.map((option: { value: any }) => option.value);
        setSelectedOptions(selectedValues);
    };


    const handleSubmitForm = async (formData: any) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');

        // Create the formatted date string
        const currentDateFormatted = `${year}-${month}-${day}`;
        if (formData.startDate <= currentDateFormatted && formData.endDate >= formData.startDate) {
            // const tempObj = {
            //     ...formData,
            //     outputNames: selectedOptions,
            // }
        } else {
            if (formData.startDate > currentDateFormatted) {
                toast.error('Start Date should not be later than today')
            } else if (formData.endDate < formData.startDate) {
                toast.error('End Date should not be earlier than Start Date')
            }
        }

    };
    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' }
    ];

    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    {/* <SummaryTable searchValues={searchValues} setShow={setShow} /> */}
                    <div className={classes.fieldRow}>
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={handleAddAllClick}
                            disabled={isAddAllDisabled}
                        >
                            Add All
                        </Button>
                    </div>
                    <div className={classes.fieldContainer}>
                        <Autocomplete
                            multiple
                            id="output-columns"
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            options={options2}
                            disableCloseOnSelect
                            forcePopupIcon={true}
                            freeSolo={true}
                            getOptionLabel={(option) => option.label}
                            value={selectedOptions.map((value: any) => ({ label: value, value }))}
                            onChange={handleOptionChange}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.label}
                                </li>
                            )}
                            style={{ maxWidth: '100%', marginLeft: '1rem', marginRight: '1rem', marginBottom: '1rem' }}
                            renderInput={(params) => (
                                <TextField {...params} size='small' label="Output Columns" />
                            )}
                        />
                    </div>
                    <DynamicForm
                        fields={attendanceOptions}
                        data={NEW_ATTENDANCE_TYPE_DATA}
                        onSubmit={handleSubmitForm}
                        buttons={buttons}
                    />


                </div>
            </div>
        </>
    )
}

export default Summary