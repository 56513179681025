import React from 'react'
import ProviderHeader from './ProviderHeader'
import { Route, Switch } from 'react-router-dom'
import ServiceProvider from './new/ServiceProvider'
import ListProvider from './list/ListProvider'
import Search from './search/Search'

const ProviderIndex = () => {
    return (
        <div>
            <ProviderHeader />
        </div>
    )
}

export default ProviderIndex