import EnrollmentByProgramHeader from "./EnrollmentByProgramHeader"

function EnrollmentByProgramIndex() {
    return (
        <div>
            <EnrollmentByProgramHeader />
        </div>
    )
}

export default EnrollmentByProgramIndex