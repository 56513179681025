
import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { getServiceAuth } from '../store/actions';
import { filterArray } from '../../../../../../_helper/filterArray';
import { Sort_by } from '../../../../../../_helper/objectSorter';
import axios from 'axios';
import { getFundingSource } from '../../../../admin/billing/funding-source/store/actions';
import { GetDescriptionCode } from '../../../../admin/billing/description-code/store/action';
import { getProgram } from '../../../../admin/care/enrollment/store/actions';

const Table = ({ searchValues }) => {
    const [serviceAuth, setServiceAuth] = useState([])
    const [serviceAuthStatic, setServiceAuthStatic] = useState([])
    const [deleteSPId, setDeleteSPId] = useState('')
    const [deleteSwitch, setDeleteSwitch] = useState(false);
    const [selectedSP, setSelectedSP] = useState({})
    const [exportData, setExportData] = useState([])
    const [showEditingForm, setShowEditingForm] = useState(true)
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [isLoading, setIsLoading] = useState(false);
    const [sortedBy, setSortedBy] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)

            try {
                let [servAuthResult, clients, fundingSources, serviceCodes, programs] = await Promise.all([
                    dispatch(getServiceAuth({})),
                    (await axios.get('/clients/get')).data,
                    dispatch(getFundingSource({})),
                    dispatch(GetDescriptionCode({})),
                    dispatch(getProgram({}))
                ])

                let servAuthsArr = servAuthResult.payload.data
                let servCodeArr = serviceCodes.payload.data
                let fundingSrcArr = fundingSources.payload.data
                let programsArr = programs.payload.data
                servAuthsArr = servAuthsArr.map((element) => {
                    let servObj = servCodeArr.find((obj) => obj._id === element.serviceID);
                    const updatedElement = {
                        ...element,
                        serviceCodeDesc: servObj.serviceCode + "/" + servObj.serviceDescription,
                    };

                    let fundingSrcObj = fundingSrcArr.find((obj) => obj._id === element.fundingSourceID);
                    updatedElement.fundingSourceName = fundingSrcObj.name;

                    let clientObj = clients.find((obj) => obj._id === element.clientID);
                    updatedElement.clientName = clientObj.firstName + " " + clientObj.lastName;

                    let programObj = programsArr.find((obj) => obj._id === element.programID);
                    updatedElement.programName = programObj.programName

                    return updatedElement;
                });
                setServiceAuth(servAuthsArr);
                setServiceAuthStatic(servAuthsArr);
                setExportData(servAuthsArr)
                setIsLoading(false)
            }
            catch (err) {
                console.log(err)
                setIsLoading(false)
            }
        }
        fetchData()
    }, [deleteSwitch, showEditingForm])

    const searchFilterServiceAuth = (value) => {
        const sampleFilter = [
            "programName", "endDate",
            "clientName", "serviceCodeDesc",
            "_id", "unitMeasure", "fundingSourceID",
            "fundingSourceName", "unitRate",
            "beginDate", "totalBillable",
            "remainingUnits", "dataCollectionMethod",
            "priorAuth", "primaryDiagnosis"
        ]
        const filteredArray = filterArray(serviceAuthStatic, sampleFilter);
        let lowerCaseValue = value.toLowerCase()
        let temparr = []
        for (let i = 0; i < filteredArray.length; i++) {
            if (Object.values(filteredArray[i]).filter(item => typeof item === 'string' ? item.toLowerCase().includes(lowerCaseValue) : false).length > 0) {
                temparr.push(filteredArray[i])
            }
        }
        let tempstate = []
        for (let i = 0; i < serviceAuthStatic.length; i++) {
            for (let j = 0; j < temparr.length; j++) {
                if (temparr[j]._id === serviceAuthStatic[i]._id) {
                    tempstate.push(serviceAuthStatic[i])
                }
            }
        }
        setServiceAuth(tempstate)
        setCurrentPage(1)
    }

    const SAPaginationData = useMemo(() => {
        if (serviceAuth.length <= PageSize) {
            setCurrentPage(1)
        }
        let tempdata = serviceAuth
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return tempdata.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, serviceAuth, PageSize, sortedBy, serviceAuthStatic])


    return (
        <div>
            <div className='mb-5 col-mb-10' id='kt_content'>
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                    <div className='container-xxl card'>
                        <div id='kt_content_container' className='mt-4'>
                            <div className='card-body'>
                                <h1 className='mt-6'>Service Authorization List</h1>
                                <div className='w-100 pt-6 pb-3'>
                                    <div className="d-flex w-100 justify-content-between">
                                        <div>
                                            <div className='d-flex align-items-center position-relative overflow-auto my-1'>
                                                <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width={24}
                                                        height={24}
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                    >
                                                        <rect
                                                            opacity='0.5'
                                                            x='17.0365'
                                                            y='15.1223'
                                                            width='8.15546'
                                                            height={2}
                                                            rx={1}
                                                            transform='rotate(45 17.0365 15.1223)'
                                                            fill='black'
                                                        />
                                                        <path
                                                            d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                                            fill='black'
                                                        />
                                                    </svg>
                                                </span>
                                                <input
                                                    type='text'
                                                    data-kt-customer-table-filter='search'
                                                    className='form-control form-control-solid w-250px ps-15'
                                                    onChange={(e) => searchFilterServiceAuth(e.target.value)}
                                                    placeholder='Search Service Provider'
                                                // disabled={isLoading}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <div>
                                                <p style={{ "margin": "revert" }}>Items Per Page</p>
                                            </div>
                                            <div>
                                                <select className='form-control form-control-solid form-select'
                                                    onChange={(e) => setPageSize(parseInt(e.target.value))}
                                                // disabled={isLoading}
                                                >
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                    <option value={150}>150</option>
                                                    <option value={200}>200</option>
                                                    <option value={300}>300</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='stickhyHeaderTable table-responsive' style={{ padding: "0rem 0rem" }}>
                                        <table
                                            className='table table-light table-rounded table-striped border border-2 table-hover fs-6 gy-5 mt-5'
                                            id='kt_individual_table'
                                        >
                                            <thead>
                                                <tr className='text-start table-dark text-gray-400 table-rounded fw-bolder fs-7 text-uppercase gs-0'>

                                                    <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                        if (sortedBy !== "programName") {
                                                            let finalSort = serviceAuth.sort(Sort_by('programName', false, (a) => a.toUpperCase()))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy("programName")
                                                        } else if (sortedBy === "programName") {
                                                            let finalSort = serviceAuth.sort(Sort_by('programName', true, (a) => a.toUpperCase()))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy('')
                                                        }
                                                    }}>
                                                        Program
                                                        <i className={sortedBy === "programName" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                    </th>
                                                    <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                        if (sortedBy !== "clientName") {
                                                            let finalSort = data.sort(Sort_by('clientName', false, (a) => a.toUpperCase()))
                                                            setData(finalSort)
                                                            setSortedBy("clientName")
                                                        } else if (sortedBy === "clientName") {
                                                            let finalSort = data.sort(Sort_by('clientName', true, (a) => a.toUpperCase()))
                                                            setData(finalSort)
                                                            setSortedBy('')
                                                        }
                                                    }}>
                                                        Client Name
                                                        <i className={sortedBy === "clientName" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                    </th>
                                                    <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                        if (sortedBy !== "fundingSource") {
                                                            let finalSort = serviceAuth.sort(Sort_by('fundingSource', false, (a) => a.toUpperCase()))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy("fundingSource")
                                                        } else if (sortedBy === "fundingSource") {
                                                            let finalSort = serviceAuth.sort(Sort_by('fundingSource', true, (a) => a.toUpperCase()))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy('')
                                                        }
                                                    }}>
                                                        Funding Source
                                                        <i className={sortedBy === "fundingSource" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                    </th>
                                                    <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                        if (sortedBy !== "beginDate") {
                                                            let finalSort = serviceAuth.sort(Sort_by('beginDate', false, parseInt))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy("beginDate")
                                                        } else if (sortedBy === "beginDate") {
                                                            let finalSort = serviceAuth.sort(Sort_by('beginDate', true, parseInt))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy('')
                                                        }
                                                    }}>
                                                        Begin Date
                                                        <i className={sortedBy === "beginDate" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                    </th>
                                                    <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                        if (sortedBy !== "endDate") {
                                                            let finalSort = serviceAuth.sort(Sort_by('endDate', false, parseInt))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy("endDate")
                                                        } else if (sortedBy === "endDate") {
                                                            let finalSort = serviceAuth.sort(Sort_by('endDate', true, parseInt))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy('')
                                                        }
                                                    }}>
                                                        End Date
                                                        <i className={sortedBy === "endDate" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                    </th>
                                                    <th className="min-w-250px" style={{ cursor: "pointer" }} onClick={() => {
                                                        if (sortedBy !== "status") {
                                                            let finalSort = serviceAuth.sort(Sort_by('status', false, (a) => a.toUpperCase()))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy("status")
                                                        } else if (sortedBy === "status") {
                                                            let finalSort = serviceAuth.sort(Sort_by('status', true, (a) => a.toUpperCase()))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy('')
                                                        }
                                                    }}>
                                                        Status
                                                        <i className={sortedBy === "status" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                    </th>
                                                    <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                        if (sortedBy !== "unitMeasure") {
                                                            let finalSort = serviceAuth.sort(Sort_by('unitMeasure', false, parseInt))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy("unitMeasure")
                                                        } else if (sortedBy === "unitMeasure") {
                                                            let finalSort = serviceAuth.sort(Sort_by('unitMeasure', true, parseInt))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy('')
                                                        }
                                                    }}>
                                                        Unit of Measure
                                                        <i className={sortedBy === "unitMeasure" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                    </th>
                                                    <th className="min-w-100px" style={{ cursor: "pointer" }} onClick={() => {
                                                        if (sortedBy !== "unitRate") {
                                                            let finalSort = serviceAuth.sort(Sort_by('unitRate', false, parseInt))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy("unitRate")
                                                        } else if (sortedBy === "unitRate") {
                                                            let finalSort = serviceAuth.sort(Sort_by('unitRate', true, parseInt))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy('')
                                                        }
                                                    }}>
                                                        Unit Rate
                                                        <i className={sortedBy === "unitRate" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                    </th>
                                                    <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                        if (sortedBy !== "totalBillable") {
                                                            let finalSort = serviceAuth.sort(Sort_by('totalBillable', false, parseInt))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy("totalBillable")
                                                        } else if (sortedBy === "totalBillable") {
                                                            let finalSort = serviceAuth.sort(Sort_by('totalBillable', true, parseInt))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy('')
                                                        }
                                                    }}>
                                                        Total Units
                                                        <i className={sortedBy === "totalBillable" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                    </th>
                                                    <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                        if (sortedBy !== "remainingUnits") {
                                                            let finalSort = serviceAuth.sort(Sort_by('remainingUnits', false, parseInt))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy("remainingUnits")
                                                        } else if (sortedBy === "remainingUnits") {
                                                            let finalSort = serviceAuth.sort(Sort_by('remainingUnits', true, parseInt))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy('')
                                                        }
                                                    }}>
                                                        Remaining Units
                                                        <i className={sortedBy === "remainingUnits" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                    </th>
                                                    <th className="min-w-200px" style={{ cursor: "pointer" }} onClick={() => {
                                                        if (sortedBy !== "dataCollectionMethod") {
                                                            let finalSort = serviceAuth.sort(Sort_by('dataCollectionMethod', false, (a) => a.toUpperCase()))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy("dataCollectionMethod")
                                                        } else if (sortedBy === "dataCollectionMethod") {
                                                            let finalSort = serviceAuth.sort(Sort_by('dataCollectionMethod', true, (a) => a.toUpperCase()))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy('')
                                                        }
                                                    }}>
                                                        Data Collection Method
                                                        <i className={sortedBy === "dataCollectionMethod" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                    </th>
                                                    <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                        if (sortedBy !== "priorAuth") {
                                                            let finalSort = serviceAuth.sort(Sort_by('priorAuth', false, (a) => a.toUpperCase()))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy("priorAuth")
                                                        } else if (sortedBy === "priorAuth") {
                                                            let finalSort = serviceAuth.sort(Sort_by('priorAuth', true, (a) => a.toUpperCase()))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy('')
                                                        }
                                                    }}>
                                                        Prior Auth
                                                        <i className={sortedBy === "priorAuth" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                    </th>
                                                    <th className="min-w-250px" style={{ cursor: "pointer" }} onClick={() => {
                                                        if (sortedBy !== "primaryDiagnosis") {
                                                            let finalSort = serviceAuth.sort(Sort_by('primaryDiagnosis', false, (a) => a.toUpperCase()))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy("primaryDiagnosis")
                                                        } else if (sortedBy === "primaryDiagnosis") {
                                                            let finalSort = serviceAuth.sort(Sort_by('primaryDiagnosis', true, (a) => a.toUpperCase()))
                                                            setServiceAuth(finalSort)
                                                            setSortedBy('')
                                                        }
                                                    }}>
                                                        ICD-10 Code
                                                        <i className={sortedBy === "primaryDiagnosis" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    SAPaginationData.length > 0 ?
                                                        SAPaginationData.map((data, index) => (
                                                            searchValues.firstName ?  data.clientID === searchValues.firstName &&
                                                            <tr key={index}>
                                                                <td>
                                                                    {data.programName}
                                                                </td>
                                                                <td>
                                                                    {data.clientName}
                                                                </td>
                                                                <td>
                                                                    {data.fundingSourceName}
                                                                </td>
                                                                <td>
                                                                    {data.beginDate}
                                                                </td>
                                                                <td>
                                                                    {data.endDate}
                                                                </td>
                                                                <td>
                                                                    {data.status}
                                                                </td>
                                                                <td>
                                                                    {data.unitMeasure}
                                                                </td>
                                                                <td>
                                                                    {data.unitRate}
                                                                </td>
                                                                <td>
                                                                    {data.totalBillable}
                                                                </td>
                                                                <td>
                                                                    {data.remainingUnits}
                                                                </td>
                                                                <td>
                                                                    {data.dataCollectionMethod}
                                                                </td>
                                                                <td>
                                                                    {data.priorAuth}
                                                                </td>
                                                                <td>
                                                                    {data.primaryDiagnosis}
                                                                </td>
                                                            </tr> : 
                                                            <tr key={index}>
                                                            <td>
                                                                {data.programName}
                                                            </td>
                                                            <td>
                                                                {data.clientName}
                                                            </td>
                                                            <td>
                                                                {data.fundingSourceName}
                                                            </td>
                                                            <td>
                                                                {data.beginDate}
                                                            </td>
                                                            <td>
                                                                {data.endDate}
                                                            </td>
                                                            <td>
                                                                {data.status}
                                                            </td>
                                                            <td>
                                                                {data.unitMeasure}
                                                            </td>
                                                            <td>
                                                                {data.unitRate}
                                                            </td>
                                                            <td>
                                                                {data.totalBillable}
                                                            </td>
                                                            <td>
                                                                {data.remainingUnits}
                                                            </td>
                                                            <td>
                                                                {data.dataCollectionMethod}
                                                            </td>
                                                            <td>
                                                                {data.priorAuth}
                                                            </td>
                                                            <td>
                                                                {data.primaryDiagnosis}
                                                            </td>
                                                        </tr> 
                                                        )
                                                        ) :
                                                        <tr>
                                                            <td align="center" colSpan={12}><span className='text-gray-600 fs-2 fw-bolder'>No Service Authorization Info Has Been Found</span></td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Table