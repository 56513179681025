export const LIST_OF_FUNDING_SOURCE_COLUMNS = [
    'Name',
    'Phone Number',
    'Vendor ID',
    'Payer',
]
export const LIST_OF_FUNDING_SOURCE_SORTING_COLUMNS = {
    "Name": 'name',
    "Phone Number": 'primaryPhone',
    "Vendor ID": 'vendorID',
    "Payer": 'payerNameMod'
}
export const LIST_OF_FUNDING_SOURCE_COLUMN_DATA_MAPPING = {
    "Name": 'name',
    "Phone Number": 'primaryPhone',
    "Vendor ID": 'vendorID',
    "Payer": 'payerNameMod'
}


export const LIST_OF_PAYER_COLUMNS = [
    'Name',
    'Payer ID',
    'Transmission To'
]
export const LIST_OF_PAYER_SORTING_COLUMNS = {
    "Name": 'name',
    "Payer ID": 'payerID',
    "Transmission To": 'transmissionTo',
}
export const LIST_OF_PAYER_COLUMN_DATA_MAPPING = {
    "Name": 'name',
    "Payer ID": 'payerID',
    "Transmission To": 'transmissionTo',
}



export const LIST_OF_BILLING_PROVIDER_COLUMNS = [
    'Organization Name',
    'First Name',
    'Last Name',
    'ID Type',
    'ID Number',
    'Medicaid Provider Number',
    'NPI Number',
    'Provider Commercial Number',
    'Additional Provider Number'
]
export const LIST_OF_BILLING_PROVIDER_SORTING_COLUMNS = {
    'Organization Name': 'organizationName',
    'First Name': 'firstName',
    'Last Name': 'lastName',
    'ID Type': 'IDType1',
    'ID Number': 'IDNumber1',
    'Medicaid Provider Number': 'medicaidProviderNumber',
    'NPI Number': 'NPINumber',
    'Provider Commercial Number': 'providerCommercialNumber',
    'Additional Provider Number': 'additionalProviderNumber'
}
export const LIST_OF_BILLING_PROVIDER_COLUMN_DATA_MAPPING = {
    'Organization Name': 'organizationName',
    'First Name': 'firstName',
    'Last Name': 'lastName',
    'ID Type': 'IDType1',
    'ID Number': 'IDNumber1',
    'Medicaid Provider Number': 'medicaidProviderNumber',
    'NPI Number': 'NPINumber',
    'Provider Commercial Number': 'providerCommercialNumber',
    'Additional Provider Number': 'additionalProviderNumber'
}

export const LIST_OF_LEAVE_RULE_COLUMNS = [
    'Name',
    'Unit Rate',
    'Service Rate',
    'Billing Data',
]
export const LIST_OF_LEAVE_RULE_SORTING_COLUMNS = {
    'Name': 'name',
    'Unit Rate': 'unitRate',
    'Service Rate': 'serviceRate',
    'Billing Data': 'billingData'
}
export const LIST_OF_LEAVE_RULE_COLUMN_DATA_MAPPING = {
    'Name': 'name',
    'Unit Rate': 'unitRate',
    'Service Rate': 'serviceRate',
    'Billing Data': 'billingData'
}


export const LIST_OF_DESCRIPTION_CODE_COLUMNS = [
    'Service Code',
    'Service Description',
    'Attendance Type',
    'Unit of Measure',
    'Unit Rate ($)',
    'Primary Diagnosis Code',
    'Electronic Unit',
]
export const LIST_OF_DESCRIPTION_CODE_SORTING_COLUMNS = {
    'Service Code': 'serviceCode',
    'Service Description': 'serviceDescription',
    'Attendance Type': 'attendanceTypeMod',
    'Unit of Measure': 'unitOfMeasure',
    'Unit Rate ($)': 'unitRate',
    'Primary Diagnosis Code': 'primaryDiagnosis',
    'Electronic Unit': 'electronicUnit',
}
export const LIST_OF_DESCRIPTION_CODE_COLUMN_DATA_MAPPING = {
    'Service Code': 'serviceCode',
    'Service Description': 'serviceDescription',
    'Attendance Type': 'attendanceTypeMod',
    'Unit of Measure': 'unitOfMeasure',
    'Unit Rate ($)': 'unitRate',
    'Primary Diagnosis Code': 'primaryDiagnosis',
    'Electronic Unit': 'electronicUnit',
}


export const htmlColumns = ['Options']
export const LIST_OF_ATTENDANCE_TYPE_COLUMNS = [
    'Type Name',
    "Options"
    // 'Use Time In/Out',
    // 'Duration',
    // 'Billing Date',
]
export const LIST_OF_ATTENDANCE_TYPE_SORTING_COLUMNS = {
    'Type Name': 'name',
    "Options": "options"
    // 'Use Time In/Out': 'time',
    // 'Duration': 'duration',
    // 'Billing Date': 'billingDate',
}
export const LIST_OF_ATTENDANCE_TYPE_COLUMN_DATA_MAPPING = {
    'Type Name': 'name',
    "Options": "options"
    // 'Use Time In/Out': 'time',
    // 'Duration': 'duration',
    // 'Billing Date': 'billingDate',
}