import React, { useState,useEffect } from 'react';
import { 
    Button, Dialog, DialogTitle, DialogContent, DialogActions, 
    TextField, Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow 
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const RateHistoryDialog = ({  formValues, setFormValues, isEnabled  }) => {
    const [open, setOpen] = useState(false);
    const [currentRateHistory, setCurrentRateHistory] = useState({ dateFrom: '', dateTo: '', unitRate: '' });
    const [errors, setErrors] = useState({ dateFrom: '', dateTo: '', unitRate: '' });
    const [localRateHistory, setLocalRateHistory] = useState(formValues.rateHistoryValue || []);


    useEffect(() => {
        setLocalRateHistory(formValues.rateHistoryValue);
    }, [formValues.rateHistoryValue]);


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setCurrentRateHistory({ dateFrom: '', dateTo: '', unitRate: '' });
        setErrors({ dateFrom: '', dateTo: '', unitRate: '' });
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = { dateFrom: '', dateTo: '', unitRate: '' };

        if (!currentRateHistory.dateFrom) {
            newErrors.dateFrom = 'Please fill in the required Date From';
            isValid = false;
        }

        if (!currentRateHistory.dateTo) {
            newErrors.dateTo = 'Please fill in the required Date To';
            isValid = false;
        }
        if (new Date(currentRateHistory.dateFrom) >= new Date(currentRateHistory.dateTo)) {
            newErrors.dateTo = 'Start date should not be greater and equal to End date';
            newErrors.dateFrom = 'Start date should not be greater and equal to End date';
            isValid = false;
        }
        if (!currentRateHistory.unitRate) {
            newErrors.unitRate = 'Please fill in the required Unit Rate';
            isValid = false;
        } else if (parseFloat(currentRateHistory.unitRate) <= 0) {
            newErrors.unitRate = 'Unit Rate must be a positive number';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleAddRateHistory = () => {
        if (validateForm()) {
            const updatedRateHistory = [...localRateHistory, currentRateHistory];
            setLocalRateHistory(updatedRateHistory);
            setFormValues(prevState => ({
                ...prevState,
                rateHistoryValue: updatedRateHistory
            }));
            handleClose();
        }
    };
    const handleDeleteRateHistory = (index) => {
        const updatedRateHistory = localRateHistory.filter((_, i) => i !== index);
        setLocalRateHistory(updatedRateHistory);
        setFormValues(prevState => ({
            ...prevState,
            rateHistoryValue: updatedRateHistory
        }));
    };
    if (!isEnabled) {
        return null;
    }
    return (
        <div>
            <div className='d-flex mt-2 mb-5'>
                <Button
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={handleOpen}
                >
                    Add Rate History Information
                </Button>
            </div>

            <div className='stickhyHeaderTable mb-3' style={{ padding: '0rem 0rem' }}>
    <TableContainer>
        <Table className="table-container">
            <TableHead style={{ backgroundColor: "#2f3347", color: 'white !important' }} >
                <TableRow className="table-header-cell">
                    <TableCell className="table-header-cell">Action</TableCell>
                    <TableCell className="table-header-cell">Date From</TableCell>
                    <TableCell className="table-header-cell">Date To</TableCell>
                    <TableCell className="table-header-cell">Unit Rate ($)</TableCell>
                </TableRow>
            </TableHead>
            <TableBody className='fw-bold text-gray-600'>
                {localRateHistory.length > 0 ? (
                    localRateHistory.map((data, index) => (
                        <TableRow key={index}>
                            <TableCell className="text-gray-800 mb-1">
                                <DeleteOutlineIcon style={{ cursor: "pointer" }} onClick={() => handleDeleteRateHistory(index)} />
                            </TableCell>
                            <TableCell className="px-3">{data.dateFrom}</TableCell>
                            <TableCell className="px-3">{data.dateTo}</TableCell>
                            <TableCell className="px-3">{data.unitRate}</TableCell>
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                        <TableCell colSpan={4} className="text-center text-gray-600">
                            No Rate History
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
            </Table>
        </TableContainer>
    </div>

    <Dialog
    open={open}
    onClose={handleClose}
    maxWidth="md"
    fullWidth
    PaperProps={{
        style: {
        padding: '1.5rem', 
        borderRadius: '12px', 
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
        }
    }}
    >
    <DialogTitle style={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '0.5rem' }}>
        Rate History Information
    </DialogTitle>
    <DialogContent style={{ padding: '0 1rem 1.5rem 1rem' }}>
        <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                value={dayjs(currentRateHistory.dateFrom)}
                format="MM-DD-YYYY"
                maxDate={dayjs()} 
                onChange={(newValue) => {
                    const selectedDate = new Date(`${newValue.month() + 1}/${newValue.date()}/${newValue.year()}`);
                 const today = new Date();       
                 if (selectedDate > today) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    dateFrom: 'Date cannot be in the future',
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    dateFrom: '',
                }));
                const newDate = `${newValue.month() + 1}/${newValue.date()}/${newValue.year()}`;
                setCurrentRateHistory((prevHistory) => ({
                    ...prevHistory,
                    dateFrom: newDate,
                }));
                }
                }}
                slotProps={{
                textField: {
                    label: 'Date From',
                    size: 'small',
                    fullWidth: true,
                    style: {
                    borderRadius: '8px', 
                    },
                    error: !!errors.dateFrom,
                    helperText: errors.dateFrom,
                    required: true,
                }
                }}
            />
            </LocalizationProvider>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-3">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                value={dayjs(currentRateHistory.dateTo, 'MM/DD/YYYY')}
                format="MM-DD-YYYY"
                maxDate={dayjs()} 
                onChange={(newValue) => {
                const selectedDate = new Date(`${newValue.month() + 1}/${newValue.date()}/${newValue.year()}`);
            const today = new Date();
            if (selectedDate > today) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    dateTo: 'Date cannot be in the future',
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    dateTo: '',
                }));
                const newDate = `${newValue.month() + 1}/${newValue.date()}/${newValue.year()}`;
                setCurrentRateHistory((prevHistory) => ({
                    ...prevHistory,
                    dateTo: newDate,
                }));
                 }
                }}
                slotProps={{
                textField: {
                    label: 'End Date',
                    size: 'small',
                    fullWidth: true,
                    style: {
                    borderRadius: '8px', 
                    },
                    error: !!errors.dateTo,
                    helperText: errors.dateTo,
                    required: true,
                }
                }}
            />
            </LocalizationProvider>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-3">
            <TextField
            type="number"
            name="unitRate"
            label="Unit Rate ($)"
            size="small"
            value={currentRateHistory.unitRate}
            onChange={(e) =>
                setCurrentRateHistory((prevHistory) => ({
                ...prevHistory,
                unitRate: e.target.value,
                }))
            }
            placeholder="0.00"
            error={!!errors.unitRate}
            helperText={errors.unitRate}
            required
            fullWidth
            style={{
                borderRadius: '8px', 
            }}
            />
        </div>
        </div>
    </DialogContent>
    <DialogActions style={{ padding: '1rem' }}>
        <Button
        variant="contained"
        color="primary"
        onClick={handleAddRateHistory}
        style={{
            backgroundColor: '#007bff', 
            textTransform: 'none',
            padding: '0.5rem 1.5rem',
            fontSize: '1rem',
            fontWeight: 'bold',
            borderRadius: '8px', 
            marginRight: '1rem',
        }}
        >
        Save
        </Button>
        <Button
        variant="outlined"
        onClick={handleClose}
        style={{
            textTransform: 'none',
            padding: '0.5rem 1.5rem',
            fontSize: '1rem',
            borderRadius: '8px',
            fontWeight: 'bold',
            color: '#6c757d',
            borderColor: '#6c757d',
        }}
        >
        Cancel
        </Button>
    </DialogActions>
    </Dialog>
        </div>
    );
};

export default RateHistoryDialog;