import { useEffect, useState, } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import { LIST_OF_SHARED_CONTACTS_COLUMNS_DATA_MAPPING, LIST_OF_SHARED_CONTACTS_COLUMNS, LIST_OF_SHARED_CONTACTS_SORTING_COLUMNS } from '../../GENERAL_TABLE_CONSTANTS'
import { useHistory } from 'react-router-dom'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { permissions } from '../../../../../../_constants/permissions'
import TableButton from '../../../../../reusable-components/tables/TableButton'
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper'
import AddButton from '../../../../../reusable-components/Buttons/AddButton'
const selectAuth = (state) => state.auth;

const SharedContactList = () => {
    const [sharedContacts, setSharedContacts] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [deletedId, setDeletedId] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [queryData, setQueryData] = useState({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'createdAt',
        sortDir: 'asc',
        searchTerm: ''
    })
    const history = useHistory()
    const auth = useSelector(selectAuth);
    const userPermissions = auth.user.roleId;

    useEffect(() => {
        const fetchData = async (page, pageSize, sortBy, sortDir) => {
            try{
            const response = await axios.get(`/shared/get?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${queryData.searchTerm}`)
            const { data, currentPage, totalCounts } = response.data;
            setSharedContacts(data)
            setFilteredData(data)
            setQueryData((prevState)=> {
                return {
                    ...prevState,
                    currentPage,
                    totalCounts,
                }
            })
            }catch(err){
                console.log(err)
            }
        }
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
    }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, deletedId, queryData.searchTerm])

    const onDelete = (row) => {
        if (row._id) {
            axios.delete("/shared/delete/" + row._id)
                .then(response => {
                    if (response.data && response.data.success) {
                        toast.success(response.data.message || 'Success', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                        setSharedContacts((prevState) =>
                            prevState.filter((contact) => contact._id !== row._id)
                        );
                        setDeletedId(row._id)
                    } else {
                        toast.error(response?.data?.message || 'Error', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                    }
                })
        }
    }

    const onUpdate = ({ _id }) => {
        history.push({
            pathname: `/admin/general/shared-contact/list/${_id}`
        })
    }
    const onClickAddButton = () => {
        history.push('/admin/general/shared-contact/new')
    }
    return (
        <div className='card'>
            <div className='card-body'>
                <>
                    <TableTitle title='Shared Contact List'></TableTitle>
                    <TableWrapper
                        searchField={
                            <TableSearchBar
                                label='Search'
                                variant='outlined'
                                size='small'
                                value={searchTerm}
                                setQueryData={setQueryData}
                                setSearchTerm={setSearchTerm}
                                margin='normal'
                                inputAdornmentPosition='start'
                            />
                        }
                        tableButtons={
                            <>
                                {!!hasPermission(userPermissions, permissions.admin_billing_sharedContact_create) && (
                                    <AddButton
                                        onClick={onClickAddButton}
                                    />
                                )}
                                {!!hasPermission(userPermissions, permissions.admin_billing_sharedContactType_view) && (
                                    <TableButton
                                        variant="contained"
                                        color="primary"
                                        onClick={() => history.push(`/admin/general/shared-contact/type/list`)}
                                        title="Type List"
                                    />
                                )}
                                {!!hasPermission(userPermissions, permissions.admin_billing_sharedContactInfo_view) && (
                                    <TableButton
                                        variant="contained"
                                        color="primary"
                                        onClick={() => history.push(`/admin/general/shared-contact/info`)}
                                        title="Config"
                                    />
                                )}
                            </>
                        }
                        dynamicTable={
                            <DynamicTable
                                columns={LIST_OF_SHARED_CONTACTS_COLUMNS}
                                data={filteredData}
                                canUpdate={hasPermission(userPermissions, permissions.admin_billing_sharedContact_update)}
                                canDelete={hasPermission(userPermissions, permissions.admin_billing_sharedContact_delete)}
                                columnDataMapping={LIST_OF_SHARED_CONTACTS_COLUMNS_DATA_MAPPING}
                                onDelete={onDelete}
                                onUpdate={onUpdate}
                                sortingColumns={LIST_OF_SHARED_CONTACTS_SORTING_COLUMNS}
                                title='Are you sure you want to delete this SharedContact?'
                                setQueryData={setQueryData}
                                queryData={queryData}
                            />
                        }
                    />
                </>
            </div>
        </div>
    )
}

export default SharedContactList