import React from 'react'
import TimesheetsTabs from '../Tabs/TimesheetsTabs'
import SummariesTable from './SummariesTable'

export default function TimesheetsSummaries() {
  return (
    <>
      <TimesheetsTabs></TimesheetsTabs>
      <SummariesTable></SummariesTable>
    </>
  )
}
