import {Redirect, Route, Switch} from 'react-router-dom'
import {RouteWithPermission} from '../../../../../_helper/routesWithPermission'
import CaregiverTimesheets from '../../../../pages/caregiver/TimeSheets/CaregiverTimeSheets/CaregiverTimesheets'
import TimesheetsSummaries from '../../../../pages/caregiver/TimeSheets/TimeSheetsSummaries/TimesheetsSummaries'
import CompanyTimesheet from '../../../../pages/caregiver/TimeSheets/CompanyTimesheets/CompanyTimesheet'
import {useSelector} from 'react-redux'
import {hasPermission} from '../../../../../_helper/hasPermission'
import {permissions} from '../../../../../_constants/permissions'
import {Error404} from '../../../../modules/errors/components/Error404'
 
const TimeSheetIndexRoute = () => {
  const auth = useSelector((state: {auth: any}) => state.auth)
  const userPermissions = auth.user.roleId
 
  const canViewTimeSheets = hasPermission(
    userPermissions,
    permissions.care_giver_time_sheets_timeSheets_view
  )
  const canViewCompanySheets = hasPermission(
    userPermissions,
    permissions.care_giver_time_sheets_companySheet_view
  )
  const canViewSummaries = hasPermission(
    userPermissions,
    permissions.care_giver_time_sheets_summaries_view
  )
  const getDefaultPath = () => {
    if (canViewTimeSheets) return '/caregivers/sheets/timesheets';
    if (canViewSummaries) return '/caregivers/sheets/timesheets-summaries';
    if (canViewCompanySheets) return '/caregivers/sheets/company-timesheets';
    return '/error/404';
  };
  return (
    <Switch>
      <Route exact path='/caregivers/sheets/timesheet'>
        <Redirect to={getDefaultPath()} />
      </Route>
      <RouteWithPermission
        path='/caregivers/sheets/timesheets'
        component={CaregiverTimesheets}
        hasPerm={canViewTimeSheets}
      />
      <RouteWithPermission
        path='/caregivers/sheets/timesheets-summaries'
        component={TimesheetsSummaries}
        hasPerm={canViewSummaries}
      />
      <RouteWithPermission
        path='/caregivers/sheets/company-timesheets'
        component={CompanyTimesheet}
        hasPerm={canViewCompanySheets}
      />
      <Route path='/error/404' component={Error404} />
    </Switch>
  )
}
 
export default TimeSheetIndexRoute