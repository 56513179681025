export const MARS_FORM_CONSTANTS = (clients =[],medicationListing ) => {
  
  return [
    {
      label: 'Client Name',
      name: 'clientId',
      type: 'select',
      options: clients,
      required: true,
      rowNumber: 1,
    },
    {
      label: 'Medication',
      name: 'medicationListingId',
      type: 'select',
      options: medicationListing,  
      required: true,
      rowNumber: 1,
    },
    {
      label: 'Prescribed On',
      name: 'prescribedOn',
      type: 'date',
      required: true,
      rowNumber: 2,
    },
    {
      label: 'Dosage Information',
      name: 'dosageInformation',
      type: 'text',
      required: true,
      rowNumber: 2,
    },
    {
      label: 'Side Effects',
      name: 'sideEffects',
      type: 'text',
      required: false,
      rowNumber: 3,
    },
    {
      label: 'Comments',
      name: 'comment',
      type: 'text',
      required: false,
      rowNumber: 3,
    },
    {
      sectionHeading: 'Timings For Medication',
      rowNumber: 5,
    },
  ]
}
