import React from 'react'
import { Route, Switch, BrowserRouter as Router, BrowserRouter } from 'react-router-dom'
import New from '../../../../../pages/admin/billing/leave-rule/new/New'
import List from '../../../../../pages/admin/billing/leave-rule/list/List'
import Edit from '../../../../../pages/admin/billing/leave-rule/list/LREdit'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { useSelector } from 'react-redux'
import { RouteWithPermission } from '../../../../../../_helper/routesWithPermission'
import { Error404 } from '../../../../../modules/errors/components/Error404'

const LeaveRuleIndexRoutes: React.FC = () => {
    const auth = useSelector((state: { auth: any }) => state.auth);
    const userPermissions = auth.user.roleId;
    const canUpdate = hasPermission(userPermissions, 'ADMIN.ADMIN_BILLING.LEAVE_RULE.UPDATE');
    const canCreate = hasPermission(userPermissions, 'ADMIN.ADMIN_BILLING.LEAVE_RULE.CREATE');
    const canRead = hasPermission(userPermissions, 'ADMIN.ADMIN_BILLING.LEAVE_RULE.VIEW');
    return (
        <Switch>
            <RouteWithPermission path='/admin/billing/leave-rule/new' component={New} hasPerm={false} />
            <RouteWithPermission path='/admin/billing/leave-rule/list/:id' component={Edit} hasPerm={false} />
            <RouteWithPermission path='/admin/billing/leave-rule/list' component={List} hasPerm={false} />
            <Route path='/error/404' component={Error404} />
        </Switch>
    )
}

export default LeaveRuleIndexRoutes
