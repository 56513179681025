import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface leaveRules {
    _id: String;
    name: String;
    monthlyLeaves: String;
    yearlyLeaves: String;
    yearlyConsecutiveLeaves: String;
    calendarYear: String;
    serviceRate: String;
    unitRate: String;
    percentage: String;
    billingData: String;
    billingDataForNo: String;
    noBillingData: String;
    revenueCode: String
}

export const getLeaveRule = createAsyncThunk(
    'leaveRules/get',
    async ({ page, pageSize, sortBy, sortDir  }: { page: number, pageSize: number, sortBy: string, sortDir: string }) => {
        try {
            if (page && pageSize) {
                const response = await axios.get(`leaveRules/get?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`)
                return {
                    data: response.data
                }
            } else {
                const response = await axios.get('leaveRules/get')
                return {
                    data: response.data
                }
            }
        } catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getSearchLeaveRule= createAsyncThunk(
    'leaveRules/search',
    async (searchTerm) => {
        try {
            const response = await axios.get(`leaveRules/search?searchTerm=${searchTerm}`)
            return {
                data: response.data  
            }
        } catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getOneLeaveRule = createAsyncThunk(
    'leaveRules/getOne/',
    async (companyId: string | undefined) => {
        try {
            const response = await axios.get('leaveRules/getOne/' + companyId)
            return {
                data: response.data
            }
        } catch (err) {
            console.log(err)
            return err
        }
    }
)

export const addLeaveRule = createAsyncThunk(
    'leaveRules/create',
    async (data: leaveRules) => {
        try {
            const response = await axios.post('leaveRules/create', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const deleteLeaveRule = createAsyncThunk(
    'leaveRules/delete/',
    async (data: string) => {
        try {
            const response = await axios.delete('leaveRules/delete/' + data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const updateLeaveRule = createAsyncThunk(
    'leaveRules/update',
    async (data: leaveRules) => {
        try {
            const response = await axios.put('leaveRules/update', data)
            return response.data
        }
        catch (err) {
            console.log(err)
        }
    }
)