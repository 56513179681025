import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {useSelector} from 'react-redux'
import DynamicForm from '../../../../reusable-components/forms/DynamicForm'
import {EDIT_NOTES_FORM_INPUTS} from '../../ADD_NOTES_FORM_CONSTANTS'
import {Box, Typography, Button, TextField, FormControl} from '@mui/material'
import {toast} from 'react-toastify'
import {useHistory, useParams} from 'react-router-dom'
import dayjs from 'dayjs'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'


export default function EditNote() {
  const [formData, setFormData] = useState({})
  const [uiElementsList, setUiElementsList] = useState([])
  const [enableButtonText, setEnableButtonText] = useState('')
  const [indexForTask, setIndexForTask] = useState(0)
  const [clickCount, setClickCount] = useState(13)
  const [addClick, setAddClick] = useState(0)
  const [inputArr, setInputArr] = useState([
    {
      taskId: '',
      taskName: '',
      taskDescription: '',
    },
  ])
  const history = useHistory()
  const authUser = useSelector((state: {auth: any}) => state.auth)
  const params: {id: string} = useParams()
  const [frequency, setFrequency] = useState('')
  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(dayjs())

  const onAddAnotherTaskClick = () => {
    setInputArr((prevState) => [...prevState, {taskId: '', taskName: '', taskDescription: ''}])
    setAddClick((prevAddClick) => prevAddClick + 1)
  }

  const buttons = [
    {label: 'Add Task', variant: 'outlined', type: 'button', onClick: onAddAnotherTaskClick},
    {label: 'Cancel', variant: 'outlined', type: 'button'},
    {label: 'Save', variant: 'contained', type: 'submit'},
  ]

  const handleSubmitForm = async (formData) => {
    // // const freq = new Date()
    // const startDate = new Date(formData.startDate)
    // const endDate = new Date(formData.endDate)

    // currentDate.setUTCHours(0)
    // currentDate.setUTCMinutes(0)
    // currentDate.setUTCSeconds(0)
    // currentDate.setUTCMilliseconds(0)

    // if (startDate >= currentDate && endDate >= startDate) {
      await axios
        .patch('/notes/update', {
          noteId: params.id,
          noteName: formData['noteName'],
          programId: formData['programId'],
          clientId: formData['clientId'],
          startDate: startDate,
          frequency:frequency,
          endDate: endDate,
          targetCompletionDate: formData['targetCompletionDate'],
          location: {
            address: formData['location'],
            lat: '31.5245054',
            lng: '74.3033196',
            typeOfLocation: 'client',
            idOfDoc: '65ae6e8b682092bf5446fd8b',
          },
          longTermObjectives: formData['longTermObjectives'],
          goals: formData['goals'],
          reasonForNotes: formData['reasonForNotes'],
          criteriaForCompletion: formData['criteriaForCompletion'],
          materialsRequired: formData['materialsRequired'],
          scoringMethodId: formData['scoringMethod'],
          notesTasks: inputArr,
          serviceId: formData['serviceId'],
        })
        .then((res) => {
          const {message, success} = res?.data
          if (!success) {
            return toast.error(message)
          }
          toast.success(message)
          history.push('/caregivers/notes')
        })
        .catch((e) => {
          toast.error(e.response.data.error ? e.response.data.error : `Couldn't edit note`)
        })
    // } else {
    //   if (endDate < startDate) {
    //     toast.error('End Date should not be earlier than Start Date')
    //   }
    // }
  }

  const onChangeTaskName = (name, value, checked, type, index) => {
    setInputArr((prevState) => {
      const newState = [...prevState]
      newState[index]['taskName'] = value
      return newState
    })
  }

  const onChangeTaskDescription = (name, value, checked, type, index) => {
    setInputArr((prevState) => {
      const newState = [...prevState]
      newState[index]['taskDescription'] = value
      return newState
    })
  }

  const onRemoveTaskButtonClick = (index) => {
    setInputArr((prevInputArr) => {
      const updatedInputArr = [...prevInputArr]
      if (updatedInputArr[index]) {
        updatedInputArr[index].taskName = ''
      }
      return updatedInputArr
    })

    setUiElementsList((prevState) => {
      return prevState.filter((item) => item.index !== index)
    })
  }

  // use effect for fetching data and loading UI
  useEffect(() => {
    fetchData()
  }, [])

  // use effect to add new task row in UI when user clicks add new task button
  useEffect(() => {
    addNotesTaskRow()
  }, [addClick])

  const fetchData = () => {
    axios.get(`/notes/getNoteById/${params.id}`).then((res) => {
      let tempArrForInputArr = []
      let programsList = []
      let clientsList = []
      setStartDate(res.data.startDate)
      setFrequency(res.data.frequency)
      setEndDate(res.data.endDate)
      let clientLocationList = []
      setIndexForTask(res.data.tasks.length - 1)
      // setUserTasks(res.data.tasks)
      let prevFormData = formData
      res.data.tasks.map((eachTask, index) => {
        let taskNameKeyName = `${index}taskName`
        let taskDescriptionKeyName = `${index}taskDescription`
        
        prevFormData[taskNameKeyName] = eachTask.taskName
        prevFormData[taskDescriptionKeyName] = eachTask.taskDescription

        tempArrForInputArr.push({
          taskId: eachTask._id,
          taskName: eachTask.taskName,
          taskDescription: eachTask.taskDescription,
        })
      })
      prevFormData['programId'] = res.data.programId
      prevFormData['clientId'] = res.data?.clientId
      prevFormData['noteName'] = res.data?.noteName
      // prevFormData['startDate'] = res.data?.startDate
      // prevFormData['endDate'] = res.data?.endDate
      // prevFormData['frequency'] = res.data?.frequency,
      prevFormData['targetCompletionDate'] = res.data?.targetCompletionDate
      prevFormData['location'] = res.data?.location?.address
      prevFormData['scoringMethod'] = res.data?.scoringMethod
      prevFormData['longTermObjectives'] = res.data?.longTermObjectives
      prevFormData['goals'] = res.data?.goals
      prevFormData['reasonForNotes'] = res.data?.reasonForNotes
      prevFormData['criteriaForCompletion'] = res.data?.criteriaForCompletion
      prevFormData['materialsRequired'] = res.data?.materialsRequired
      prevFormData['serviceId'] = res.data?.serviceId
      res.data.programs.map((eachProgram) => {
        programsList.push({
          label: eachProgram.programName,
          value: eachProgram._id,
        })
      })
      res?.data?.sites?.map((eachSite) => {
        clientLocationList.push({
          label: `${eachSite.siteName.toString().toUpperCase()} (${eachSite.st1} ${eachSite.st2}, ${
            eachSite.city
          }, ${eachSite.state}, ${eachSite.country})`,
          value: `${eachSite.siteName.toString().toUpperCase()} (${eachSite.st1} ${eachSite.st2}, ${
            eachSite.city
          }, ${eachSite.state}, ${eachSite.country})`,
        })
      })
      res?.data?.clients?.map((eachClients) => {
        clientsList.push({
          label: `${eachClients.firstName} ${eachClients.lastName}`,
          value: eachClients._id,
        })
        clientLocationList.push({
          label: `${eachClients.firstName.toString().toUpperCase()} ${eachClients.lastName
            .toString()
            .toUpperCase()} (${eachClients.addrInfo.st1} ${eachClients.addrInfo.st2}, ${
            eachClients.addrInfo.city
          }, ${eachClients.addrInfo.state}, ${eachClients.addrInfo.country})`,
          value: `${eachClients.firstName.toString().toUpperCase()} ${eachClients.lastName
            .toString()
            .toUpperCase()} (${eachClients.addrInfo.st1} ${eachClients.addrInfo.st2}, ${
            eachClients.addrInfo.city
          }, ${eachClients.addrInfo?.state}, ${eachClients.addrInfo?.country})`,
        })
      })
      let descriptionCodeLists = res.data.services
      let serviceDescriptionList = []
      serviceDescriptionList = descriptionCodeLists.map((description) => ({
        label: description.serviceDescription,
        value: description._id,
      }))
      setEnableButtonText(res.data.isApproved ? 'Disable' : 'Enable')
      let uiElements = EDIT_NOTES_FORM_INPUTS(programsList, serviceDescriptionList)
      setFormData(prevFormData)
      setInputArr(tempArrForInputArr)
      uiElements[2]['options'] = clientsList
      uiElements[7]['options'] = clientLocationList
      let tempClickCount = clickCount + 1
      res.data.tasks.map((eachTask, localIndex) => {
        tempClickCount = tempClickCount + 1
        const taskNameFieldToAdd = {
          index: localIndex,
          label: 'Name',
          name: `${localIndex}taskName`,
          required: true,
          rowNumber: tempClickCount,
          type: 'text',
          onChange: onChangeTaskName,
        }
        const taskDescriptionFieldToAdd = {
          index: localIndex,
          label: 'Description',
          name: `${localIndex}taskDescription`,
          type: 'text',
          required: true,
          rowNumber: tempClickCount,
          onChange: onChangeTaskDescription,
        }

        const deleteButton = {
          index: localIndex,
          label: 'Delete',
          variant: 'outlined',
          type: 'button',
          name: `deleteButton-${localIndex}`,
          required: false,
          minRows: 1,
          onClick: () => onRemoveTaskButtonClick(localIndex),
          rowNumber: tempClickCount,
        }
        uiElements.push(taskNameFieldToAdd)
        uiElements.push(taskDescriptionFieldToAdd)
        if (localIndex != 0) {
          uiElements.push(deleteButton)
        }
      })
      setClickCount(tempClickCount)
      setUiElementsList(uiElements)
    })
  }

  const addNotesTaskRow = () => {
    if (addClick != 0) {
      let tempI = indexForTask + 1
      let tempClickCount = clickCount + 1
      const taskNameFieldToAdd = {
        index: tempI,
        label: 'Name',
        name: `${tempI}taskName`,
        required: true,
        rowNumber: tempClickCount,
        type: 'text',
        onChange: onChangeTaskName,
      }
      const taskDescriptionFieldToAdd = {
        index: tempI,
        label: 'Description',
        name: `${tempI}taskDescription`,
        type: 'text',
        required: true,
        rowNumber: tempClickCount,
        onChange: onChangeTaskDescription,
      }
      const deleteButton = {
        index: tempI,
        label: 'Delete',
        variant: 'outlined',
        type: 'button',
        onClick: () => onRemoveTaskButtonClick(tempI),
        rowNumber: tempClickCount,
      }
      setUiElementsList((prevElements) => [
        ...prevElements,
        taskNameFieldToAdd,
        taskDescriptionFieldToAdd,
        deleteButton,
      ])
      setIndexForTask(tempI)
      setClickCount(tempClickCount)
    }
  }

  const onApproveOrRejectNote = async () => {
    let route = ''
    if (enableButtonText == 'Enable') {
      route = 'notes/approve'
    }
    if (enableButtonText == 'Disable') {
      route = 'notes/reject'
    }
    await axios
      .post(route, {
        noteId: params.id,
        userId: authUser.user._id,
      })
      .then((res) => {
        if (res.status == 201) {
          toast.success(`Note ${enableButtonText} successfully`)
          if (enableButtonText == 'Disable') {
            setEnableButtonText('Enable')
          } else {
            setEnableButtonText('Disable')
          }
        }
      })
      .catch((e) => {
        toast.error(e.response.data.error ? e.response.data.error : `Couldn't edit note`)
      })
  }
 

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1.5rem',
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
            }}
          >
            <Typography fontSize={20} fontWeight={'bold'} fontFamily='helvetica'>
              Edit Note
            </Typography>
            <Button
              variant='contained'
              color='primary'
              type='button'
              style={{marginRight: '0.7rem'}}
              onClick={() => {
                onApproveOrRejectNote()
              }}
            >
              {enableButtonText}
            </Button>
          </Box>

          {frequency === 'WEEKLY' && (
            <div style={{marginLeft: '15px', color: 'red'}}>
              Note: The start date will automatically adjust to Sunday as the beginning of the week.
            </div>
          )}
          <FormControl
            sx={{mb: 2, ml: 1.5, width: '65%'}}
            color='primary'
            fullWidth
            variant='filled'
            required
          >
            <TextField
              // labelId='note-type-select-label'
              value={frequency}
              sx={{background: 'white'}}
              onChange={(e) => {
                setFrequency(e.target.value)
              }}
              label='Note Type'
              name='frequency'
              disabled
            />
          </FormControl>

          {/* {frequency && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {frequency === 'MONTHLY' ? (
                <>
                  <TextField
                    // views={['month', 'year']}
                    sx={{background: 'white', ml: 1}}
                    label='Selected Range'
                    value={`01-${startDate ? dayjs(startDate).format('MMMM') : ''} - ${
                      endDate ? dayjs(endDate).format('DD-MMMM') : ''
                    }`}
                    // renderInput={(params) => <TextField {...params} fullWidth margin='normal' />}
                    disabled
                  />
                </>
              ) : frequency === 'YEARLY' ? (
                <>
                  <TextField
                    // views={['year']}
                    sx={{background: 'white', ml: 1}}
                    label='Selected Range'
                    value={`${startDate ? dayjs(startDate).format('MMMM') : ''} - ${
                      endDate ? dayjs(endDate).format('MMMM') : ''
                    }`}
                    // renderInput={(params) => <TextField {...params} fullWidth margin='normal' />}
                    disabled
                  />
                </>
              ) : frequency === 'QUARTERLY' ? (
                <>
                  <TextField
                    // views={['month', 'year']}
                    sx={{background: 'white', ml: 1}}
                    label='Selected Range'
                    value={`${startDate ? dayjs(startDate).format('MMMM') : ''} - ${
                      endDate ? dayjs(endDate).format('MMMM') : ''
                    }`}
                    // renderInput={(params) => <TextField {...params} fullWidth margin='normal' />}
                    disabled
                  />
                </>
              ) : frequency === 'DAILY' ? (
                <TextField
                  label='Selected Date'
                  sx={{background: 'white', ml: 1}}
                  value={startDate ? dayjs(startDate).format('DD-MM-YYYY') : ''}
                  // renderInput={(params) => <TextField {...params} fullWidth margin='normal' />}
                  disabled
                />
              ) : frequency === 'WEEKLY' ? (
                <TextField
                  label='Selected Date'
                  sx={{background: 'white', ml: 1}}
                  value={`${startDate ? dayjs(startDate).format('DD-MM-YY') : ''} to ${
                    endDate ? dayjs(endDate).format('DD-MM-YY') : ''
                  }`}
                  // renderInput={(params) => <TextField {...params} fullWidth margin='normal' />}
                  disabled
                />
              ) : null}
            </LocalizationProvider>
          )} */}
          <DynamicForm
            fields={uiElementsList}
            data={formData}
            onSubmit={handleSubmitForm}
            buttons={buttons}
          />
        </div>
      </div>
    </>
  )
}
