import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {Tabs, Tab, useMediaQuery, useTheme} from '@mui/material'

function ProfessionalClaimHeader() {
  const location = useLocation()

  const getActiveTabIndex = () => {
    if (location.pathname.startsWith('/billing/professionalclaim/service-authorization/list'))
      return 0
    return false
  }

  const value = getActiveTabIndex()

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-3 pb-0'>
          <div className='d-flex overflow-auto h-60px'>
            {/* <li className='nav-item'>
                                <Link
                                    className={
                                        nav-link text-active-primary me-2  +
                                        (location.pathname.startsWith('/professionalclaim/agency-based-reports') && 'active')
                                    }
                                    to='/professionalclaim/agency-based-reports/aging-report'
                                >
                                    Agency Based Reports
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    className={
                                        nav-link text-active-primary me-2  +
                                        (location.pathname.startsWith('/professionalclaim/claim-template') && 'active')
                                    }
                                    to='/professionalclaim/claim-template/new'
                                >
                                    Claim Template
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    className={
                                        nav-link text-active-primary me-2  +
                                        (location.pathname.startsWith('/professionalclaim/billing-conversion') && 'active')
                                    }
                                    to='/professionalclaim/billing-conversion/isp-billing-unit-calculation-rule'
                                >
                                    Billing Conversion
                                </Link>
                            </li> */}
            {/* <li className='nav-item'>
                                <Link
                                    className={
                                        nav-link text-active-primary me-6  +
                                        (location.pathname.startsWith('/professionalclaim/professional-claim') && 'active')
                                    }
                                    to='/professionalclaim/professional-claim/new'
                                >
                                    Professional Claim
                                </Link>
                            </li> */}
            <Tabs
              value={value}
              variant='scrollable'
              scrollButtons={isSmallScreen ? 'auto' : 'on'}
              aria-label='Professional Claim Tabs'
              sx={{
                flexGrow: 1,
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                '.MuiTab-root': {
                  textTransform: 'none',
                  minWidth: 120,
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                },
                '.Mui-selected': {
                  color: theme.palette.primary.main,
                },
                '.MuiTabs-indicator': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              {/* Service Authorization Tab */}
              <Tab
                label='Service Authorization'
                component={Link}
                to='/billing/professionalclaim/service-authorization/list'
              />
              {/* Uncomment the following Tabs as needed */}
              {/* <Tab
                                label="Agency Based Reports"
                                component={Link}
                                to="/professionalclaim/agency-based-reports/aging-report"
                            />
                            <Tab
                                label="Claim Template"
                                component={Link}
                                to="/professionalclaim/claim-template/new"
                            />
                            <Tab
                                label="Billing Conversion"
                                component={Link}
                                to="/professionalclaim/billing-conversion/isp-billing-unit-calculation-rule"
                            />
                            <Tab
                                label="Professional Claim"
                                component={Link}
                                to="/professionalclaim/professional-claim/new"
                            />
                            <Tab
                                label="Billing Data"
                                component={Link}
                                to="/professionalclaim/billing-data/new"
                            />
                            <Tab
                                label="Excel Upload"
                                component={Link}
                                to="/professionalclaim/excel-upload/new"
                            /> */}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfessionalClaimHeader
