/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable */
export const Sort_by = (field, reverse, primer) => {
    var key = primer ? function (x) { return primer(x[field]); } : function (x) { return x[field]; }
  
    reverse = !reverse ? 1 : -1;
  
    return function(a, b) {
      return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
  }