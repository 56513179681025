import {useDispatch, useSelector} from 'react-redux'
import {Fragment, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {RootState} from '../../../../../setup'
import {QueryData} from '../../../../reusable-components/tables/DynamicTableTypes'
import {deleteNursesNotes, getNursesNotes} from './store/action'
import {toast} from 'react-toastify'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import TableWrapper from '../../../../reusable-components/tables/TableWrapper'
import TableSearchBar from '../../../../reusable-components/tables/TableSearchBar'
import {hasPermission} from '../../../../../_helper/hasPermission'
import {permissions} from '../../../../../_constants/permissions'
import DynamicTable from '../../../../reusable-components/tables/DynamicTable'
import AddButton from '../../../../reusable-components/Buttons/AddButton'
import {
  RECORDS_COLUMNS_DATA_MAPPING,
  RECORDS_LISTING_COLUMNS,
  RECORDS_SORTING_COLUMNS,
} from '../GENERAL_TABLE_CONSTANTS'

const selectAuth = (state: RootState) => state.auth

export const ListNotes = () => {
  const [filteredData, setFilteredData] = useState<any[]>([])
  const [deletedID, setDeletedID] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const dispatch = useDispatch()
  const auth = useSelector(selectAuth)
  const userPermissions = auth.user.roleId
  const history = useHistory()
  const [queryData, setQueryData] = useState<QueryData>({
    pageNumber: 1,
    pageSize: 5,
    currentPage: 1,
    totalCounts: 1,
    sortBy: 'date',
    sortDir: 'asc',
    searchTerm: '',
  })

  useEffect(() => {
    const fetchData = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
      try {
        const result: any = await dispatch(
          getNursesNotes({
            page: page,
            pageSize: pageSize,
            sortBy: sortBy,
            searchTerm: queryData.searchTerm,
          })
        )
        const {data, currentPage, totalCounts} = result?.payload

        const mappedData = data.map((item) => {
          const adjustedStartDate = new Date(item.startDate)
          adjustedStartDate.setDate(adjustedStartDate.getDate() + 1)

          const adjustedEndDate = new Date(item.endDate)
          adjustedEndDate.setDate(adjustedEndDate.getDate() + 1)

          const formattedStartDate = `${(adjustedStartDate.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${adjustedStartDate
            .getDate()
            .toString()
            .padStart(2, '0')}-${adjustedStartDate.getFullYear()}`

            const formattedEndDate = `${(adjustedEndDate.getMonth() + 1)
              .toString()
              .padStart(2, '0')}-${adjustedEndDate
              .getDate()
              .toString()
              .padStart(2, '0')}-${adjustedEndDate.getFullYear()}`
          return {
            _id: item._id,
            startDate:formattedStartDate,
            endDate: formattedEndDate,
            frequency: item.frequency,
            clientName: item.clientFullName || 'N/A',
            date: item.date,
          }
        })

        setFilteredData(mappedData)
        setQueryData((prevState) => {
          return {
            ...prevState,
            currentPage,
            totalCounts,
          }
        })
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    }

    fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir)
  }, [
    deletedID,
    queryData.pageNumber,
    queryData.pageSize,
    queryData.sortBy,
    queryData.sortDir,
    queryData.searchTerm,
  ])

  const DeleteNote = async (row: {_id: string}) => {
    try {
      const response: any = await dispatch(deleteNursesNotes(row._id))
      if (response.payload?.success) {
        toast.success('Deleted')
        setDeletedID(row._id)
        setQueryData((prevState) => ({
          ...prevState,
          searchTerm: '',
          pageNumber: 1,
        }))
      } else {
        toast.error('Error')
      }
    } catch (error) {
      console.error('Error deleting note:', error)
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const onUpdate = ({_id}: {_id: string}) => {
    history.push({
      pathname: `/records/general/nurses-notes/edit/${_id}`,
    })
  }

  const onClickAddButton = () => {
    history.push('/records/general/nurses-notes/new')
  }

  const onView = (row: any) => {
    history.push(`/records/general/nurses-notes/${row['_id']}`)
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div>
          <TableTitle title='Nurses/Therapy-Notes' />
          <TableWrapper
            searchField={
              <TableSearchBar
                label='Search'
                variant='outlined'
                size='small'
                value={searchTerm}
                setQueryData={setQueryData}
                setSearchTerm={setSearchTerm}
                margin='normal'
                inputAdornmentPosition='start'
              />
            }
            tableButtons={
              !!hasPermission(userPermissions, permissions.admin_billing_program_create) && (
                <AddButton onClick={onClickAddButton} />
              )
            }
            dynamicTable={
              <DynamicTable
                htmlColumns={[]}
                columns={RECORDS_LISTING_COLUMNS}
                data={filteredData}
                canUpdate={hasPermission(userPermissions, permissions.admin_billing_program_update)}
                canDelete={hasPermission(userPermissions, permissions.admin_billing_program_delete)}
                columnDataMapping={RECORDS_COLUMNS_DATA_MAPPING}
                sortingColumns={RECORDS_SORTING_COLUMNS}
                clickableRow={false}
                onUpdate={onUpdate}
                onDelete={DeleteNote}
                title='Are you sure you want to delete the note'
                setQueryData={setQueryData}
                queryData={queryData}
              />
            }
          />
        </div>
      </div>
    </div>
  )
}
