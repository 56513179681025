import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, MenuItem } from '@mui/material';
import axios from 'axios';
import {useHistory} from 'react-router-dom'
import { toast } from 'react-toastify';

const selectAuth = (state) => state.auth

const MissedMedicationDialog = ({ open, endDate, selectedClient, setEndDate, startDate, setStartDate, setSelectedClient, onClose }) => {
    const [clientNames, setClientNames] = useState([]);
    const [client, setClient] = useState(selectedClient)
    const [tempStartDate, settempStartDate] = useState(startDate)
    const [tempEndDate, setTempEndDate] = useState(endDate)


    const history = useHistory()
    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get('/clients/get')
                setClientNames(response.data)
                if (response.data?.length)
                    setClient(() => response.data[0]._id)
            } catch (error) {
                console.log(error)
            }
        })()
    }, [setClient, setClientNames]);


    const handleSave = () => {
        const startDate = new Date(tempStartDate);
        const endDate = new Date(tempEndDate);
        const oneMonth = 1000 * 60 * 60 * 24 * 30;
        const dateDifference = endDate - startDate;
        if(startDate > endDate) {
            toast.error("Start date should not be greater than end date.");
        } else {
            if (dateDifference > oneMonth) {
            toast.error("Start date and End date should not be more than one month.");
            } else {
                setSelectedClient(client);
                setStartDate(tempStartDate);
                setEndDate(tempEndDate);
                onClose();
            }
        }
    };
    const handleClose = () => {
        if (!open) { 
            onClose();
        }
    };
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Select Client and date range</DialogTitle>
            <DialogContent>
                <TextField
                    select
                    label="Client Name"
                    value={client}
                    onChange={(e) => {
                        setClient(e.target.value)
                    }}
                    fullWidth
                    style={{ marginTop: '16px', marginBottom: '16px' }}
                    InputLabelProps={{ shrink: true }}
                >
                    {clientNames.map((client) => (
                        <MenuItem key={client._id} value={client._id}>
                            {`${client.firstName} ${client.lastName}`}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label="Start Date"
                    type="date"
                    value={tempStartDate}
                    onChange={(e) => settempStartDate(e.target.value)}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="End Date"
                    type="date"
                    value={tempEndDate}
                    onChange={(e) => setTempEndDate(e.target.value)}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                    InputLabelProps={{ shrink: true }}
                />
                <Button variant="contained" color="primary" onClick={handleSave}>
                    Save
                </Button>
                <Button
          variant='contained'
          color='primary'
          style={{marginLeft: '8px'}}
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
                
            </DialogContent>
        </Dialog>
    );
};

export default MissedMedicationDialog;
