import React, { useState } from 'react'
import BarChart from './BarChart'
import AddBehaviorEntry from '../component/AddBehaviorEntry'
import moment from 'moment'
import axios from 'axios'
import { toast } from 'react-toastify'

const ExportBehavior = () => {
  const [selectClient, setSelectClient] = useState(true)
  const [date, setDate] = useState(new Date)
  const [clientData, setClientData] = useState({})

  const [chartData, setChartData] = useState([])

  const getBehaviors = async (client, date, clientData) => {
    const parsedDate = moment(date)

    const clientFoundIndex = clientData.findIndex(c => c._id === client)
    console.log(clientFoundIndex)

    if (clientFoundIndex !== -1) {
      const {firstName, lastName, gender, } = clientData[clientFoundIndex]
      const tempData = {
        fullName: firstName + " " + lastName,
        gender,
      }
      setClientData(tempData)
    }

    setDate(parsedDate.startOf("day").format("MM/YYYY"))

    const startOfDay = parsedDate.startOf('month').toISOString()

    const endOfDay = parsedDate.endOf('month').toISOString()

    try {
      const response = await axios.get(`/behaviors?clientId=${client}`)
      if (!response.data.length) {
        toast.error('No entry found for this client')
        return
      }
      const { data: entryData } = await axios.get(
        `/behavior-entries?clientId=${client}&startDate=${startOfDay}&endDate=${endOfDay}`
      )

      const res = response.data[0].behaviorsMapping
      const result = {}
      entryData.forEach((entry) => {
        if (result.hasOwnProperty(entry.behaviorId)) {
          result[entry.behaviorId].push(entry)
          return
        }

        result[entry.behaviorId] = [entry]
      })

      setChartData(
        Object.keys(result).map((key) => {
          const entryD = result[key]
          let singleTargetBehaviorData = {
            labels: [],
            legends: [],
            datasets: [],
          }
          entryD.forEach((entry, index) => {
            const foundTargetBehavior = res.findIndex(
              (behavior) => entry.behaviorId === behavior._id
            )

            if (foundTargetBehavior !== -1) {
              const tBehavior = res[foundTargetBehavior]
              if (index === 0) {
                singleTargetBehaviorData.legends = [
                  tBehavior.targetId.name,
                  ...tBehavior.replacementIds.map(({ name }) => name),
                ]
              }
              singleTargetBehaviorData.labels.push(
                moment(entry.administeredAt).startOf('day').format('DD/MM/YYYY')
              )
              singleTargetBehaviorData.datasets.push({
                values: [
                  parseInt(entry.targetRating),
                  ...entry.replacements.map(({ rating }) => parseInt(rating || '0')),
                ],
              })
            }
          })

          const dataSetValuesLength = singleTargetBehaviorData?.legends?.length || 0

          singleTargetBehaviorData.datasets = Array.from(
            { length: dataSetValuesLength },
            (_, index) => {
              const values = singleTargetBehaviorData.datasets.map(
                (obj) => obj.values[index] ?? null
              )
              return { values }
            }
          )
          return singleTargetBehaviorData
        })
      )

      setSelectClient(false)
    } catch (e) {
      console.log(e)
      toast.error('Something went wrong')
    }
  }

  return (
    <>
      {selectClient ? (
        <AddBehaviorEntry handleClose={() => setSelectClient(false)} callback={getBehaviors} month={true} />
      ) : (
        <div className='card'>
          <BarChart chartDataArray={chartData} date={date} clientData={clientData} />
        </div>
      )}
    </>
  )
}

export default ExportBehavior
