import React from 'react'
import AgencyBasedReportsCard from './agency-based-reports/card/AgencyBasedReportsCard'
import BillingConversionCard from './billing-conversion/card/BillingConversionCard'
import ClaimTemplateCard from './claim-template/card/ClaimTemplateCard'
import ExcelUploadCard from './excel-upload/card/ExcelUploadCard'
import ProfessionalClaimCard from './professional-claim/card/ProfessionalClaimCard'
import ServiceAuthorizationCard from './service-authorization/card/ServiceAuthorizationCard'
import ProfessionalClaimHeader from './ProfessionalClaimHeader'
import BillingDataCard from './billing-data/card/BillingDataCard'

function ProfessionalClaim() {
    return (
        <div>
            <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-xxl">
                    <ProfessionalClaimHeader />
                    {/* <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
                        <div className="col-xl-12 col-md-12">
                            <AgencyBasedReportsCard />
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">

                         <div className="col-xl-6 col-md-6">
                            <ExcelUploadCard />
                        </div> 

                        <div className="col-xl-6 col-md-6">
                            <ServiceAuthorizationCard />
                        </div>
                        <div className="col-md-6">
                            <ClaimTemplateCard />
                        </div>

                    </div>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">

                   
                         <div className="col-md-6">
                            <BillingConversionCard />
                        </div> 
                        <div className="col-md-6">
                            <ProfessionalClaimCard />
                        </div>
                        <div className="col-md-6">
                            <BillingDataCard />
                        </div>

                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default ProfessionalClaim