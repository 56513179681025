import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import AdminCareHeader from '../AdminCareHeader'

function IndividualIntakeHeader() {
    const location = useLocation()
    return (
            <AdminCareHeader />
    )
}

export default IndividualIntakeHeader