import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {hasPermission} from '../../../../../_helper/hasPermission'
import {permissions} from '../../../../../_constants/permissions'
import {RootState} from '../../../../../setup'
import {useSelector} from 'react-redux'

const CareGiverProfileTabs = () => {
  const [value, setValue] = useState(0)
  const [availableTabs, setAvailableTabs] = useState<{label: string; path: string}[]>([])
  const history = useHistory()
  const params: {id: string} = useParams()
  const auth = useSelector((state: RootState) => state.auth)
  const userPermissions = auth?.user?.roleId
  const path = history?.location?.pathname

  const canViewProfileInfo = hasPermission(
    userPermissions,
    permissions.care_giver_profile_personalInformation_view
  )
  const canViewDocuments = hasPermission(
    userPermissions,
    permissions.care_giver_profile_documents_view
  )
  const canViewJobDetails = hasPermission(
    userPermissions,
    permissions.care_giver_profile_jobDetails_view
  )

  // Effect for updating the available tabs based on permissions
  useEffect(() => {
    // Update availableTabs with permission-based conditional rendering
    const tabs = [
      canViewJobDetails ? {label: 'Job Details', path: 'profile/jobDetails'} : null,
      canViewProfileInfo
        ? {label: 'Personal Information', path: 'profile/personalInformation'}
        : null,
      canViewDocuments ? {label: 'Documents', path: 'profile/documents'} : null,
    ].filter(Boolean) as {label: string; path: string}[]

    setAvailableTabs(tabs)

    // Handle initial redirect based on current path or available tabs
    const currentTabIndex = tabs.findIndex((tab) => path.includes(tab.path))
    if (currentTabIndex !== -1) {
      setValue(currentTabIndex)
    } else if (tabs.length > 0) {
      // Redirect to the first available tab if no valid tab is found
      history.push(`/caregivers/${tabs[0].path}/${params?.id}`)
    }
  }, [canViewJobDetails, canViewProfileInfo, canViewDocuments, path, history, params?.id])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    history.push(`/caregivers/${availableTabs[newValue].path}/${params?.id}`)
  }

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-3 pb-0'>
          <div className='d-flex overflow-auto h-60px'>
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{
                '.MuiTab-root': {
                  textTransform: 'none',
                  minWidth: 150,
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                },
              }}
            >
              {availableTabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  onClick={() => history.push(`/caregivers/${tab.path}/${params?.id}`)}
                />
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CareGiverProfileTabs
