import { createSlice } from "@reduxjs/toolkit";
import { getServiceAuth } from "../actions";

interface ServiceAuth {
    programName: string;
    firstName: string;
    lastName: string;
    IDType: string;
    fundingSource: string;
    authorizationNumber: string;
    accountingNumber: string;
    beginDate: string;
    endDate: string;
    rateHistory: string;
    rateHistoryValue: Array<Object>;
    servfirstName: string;
    servlastName: string;
    organizationAgency: string;
    serviceCoordinatorNumber: string;
    phoneNumber: string;
    extension: string;
    comments: string;
    clientID: string;
    status: string;
    serviceDescription: string;
    serviceCode: string;
    unitMeasure: string;
    unitRate: number;
    claimType: string;
    primaryDiagnosis: string;
    procedureModifier: string[];
    diagnosisPointer: string[];
    procedureQualifier: string;
    unitOfMeasure: string;
    roundingAlgorithm: string;
    allowedIncrement: string;
    serviceID: string;
    totalBillable: number;
    totalAmount: number;
    priorAuth: string;
    dataCollectionMethod: string;
    attendanceType: string;
    remainingUnits: number;
}

interface ServiceAuthState {
    serviceAuths: ServiceAuth[] | any;
    isLoading: boolean;
}

const initialState: ServiceAuthState = {
    serviceAuths: [],
    isLoading: false
}

export const serviceAuthInitialSlice = createSlice({
    name: 'serviceAuth',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
        .addCase(getServiceAuth.fulfilled, (state, action: any) => {
            state.serviceAuths = action.payload.data;
            state.isLoading = false;
        })
        .addCase(getServiceAuth.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getServiceAuth.rejected, (state, action) => {
            state.isLoading = false;
        })
    }
})

export default serviceAuthInitialSlice.reducer