/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable */
import {FC, useState} from 'react'
// import {shallowEqual, useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
// import {UserModel} from '../../../../app/modules/auth/models/UserModel'
// import {RootState} from '../../../../setup'
// import {Languages} from './Languages'
// import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
// import {useDispatch} from 'react-redux'
import {toAbsoluteUrl} from '../../../helpers'
import {Logout} from '../../../../app/modules/auth'
import {Modal, Button} from 'react-bootstrap'
import axios from 'axios'
import {useSelector} from 'react-redux'

const HeaderUserMenu: FC = () => {
  const selectAuth = (state: {auth: any}) => state.auth
  const auth = useSelector(selectAuth)

  const [isOpen, setIsOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState<any>(null)
  const history = useHistory()

  // const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  // const dispatch = useDispatch()
  // const logout = () => {

  // }

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)
  const handleImageChange = (e: any) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }

    setSelectedImage(img)
  }

  const handleSubmit = async (e: {preventDefault: () => void}) => {
    e.preventDefault()

    let imagefilename = Date.now() + '-' + Math.round(Math.random() * 1e9)
    let formData = new FormData()
    formData.append('email', auth.user.email)
    formData.append('profileImg', selectedImage.data)
  }

  return (
    <div>
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Profile Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form action='' onSubmit={handleSubmit}>
              <input type='file' onChange={handleImageChange} />
              {selectedImage && (
                <img
                  src={selectedImage.preview}
                  alt='Profile Picture'
                  style={{width: '50%', marginTop: '1rem', marginRight: '50%'}}
                />
              )}

              {selectedImage && (
                <Button
                  type='submit'
                  onClick={closeModal}
                  variant='success'
                  style={{marginTop: '20px'}}
                >
                  Submit
                </Button>
              )}
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <div
        style={{
          display: 'flex',
          
          flexDirection: 'column',
          borderRadius: '0.375rem',
          backgroundColor: 'white',
          padding: '1rem',
          fontSize: '1.25rem',
          width: '275px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        }}
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
              <img alt='Logo' src={toAbsoluteUrl('/media/avatars/150-26.jpg')} />
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {/* {user.first_name} {user.first_name} */}
                {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
              </div>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                {/* {user.email} */}
              </a>
            </div>
          </div>
        </div>

        {/* <div className='menu-item px-5'>
          <a href='/dashboard' className='menu-link px-5'>
            Dashboard
          </a>
        </div>

        <div className='separator my-2'></div> */}

        {/* <Languages /> */}

        {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}

        {/* <div className='menu-item px-5'>
          <a className='menu-link px-5' onClick={openModal}>
            Upload Profile Picture
          </a>
        </div>
*/}
        <div className='separator my-2'></div>

        <div  className='menu-item px-5'>
          <a  className='menu-link px-5' onClick={() => history.push('/updatePassword')}>
            Change Password
          </a>
        </div>

        <div className='separator my-2'></div>
        <div className='menu-item px-5'>
          <a className='menu-link px-5' onClick={() => history.push('/mfa')}>
            MFA
          </a>
        </div>

        <div className='separator my-2'></div>

        <div className='menu-item px-5'>
          <a onClick={() => Logout()} className='menu-link px-5'>
            Sign Out
          </a>
        </div>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
