import { STATES } from '../../../reusable-components/Adress/STATES'
import { COUNTRIES } from '../../../reusable-components/Adress/COUNTRIES'
import * as validator from '../../../reusable-components/Validation/Validations'

export const BILLING_PROVIDER_FORM_INPUTS = [
    {
        label: "Entity Type",
        name: "entityType",
        type: "select",
        options: [
            { label: "Person", value: "Person" },
            { label: "Non Person", value: "Non Person" },
        ],
        required: true,
        rowNumber: 1
    },
    {
        label: "Organization Name",
        name: "organizationName",
        type: "text",
        required: true,
        rowNumber: 2,
        validation: validator.NAME_VALIDATION
    },
    {
        label: "First Name",
        name: "firstName",
        type: "text",
        required: true,
        rowNumber: 2,
        validation: validator.NAME_VALIDATION
    },
    {
        label: "Last Name",
        name: "lastName",
        type: "text",
        required: true,
        rowNumber: 3,
        validation: validator.NAME_VALIDATION
    },
    {
        sectionHeading: "Identification Information",
        rowNumber: 4
    },
    {
        label: "ID Type",
        name: "IDType1",
        type: "select",
        options: [
            { label: "Social Security Number(SSN)", value: "Social Security Number(SSN)" },
            { label: "Employer's Identifications Number(EID)", value: "Employer's Identifications Number(EID)" },
        ],
        required: true,
        rowNumber: 5
    },
    {
        label: "ID Number",
        name: "IDNumber1",
        type: "number",
        required: true,
        rowNumber: 5
    },
    {
        label: "ID Type",
        name: "IDType2",
        type: "select",
        options: [
            { label: "State License Number", value: "State License Number" },
            { label: "Provider UPIN Number", value: "Provider UPIN Number" },
        ],
        required: false,
        rowNumber: 6
    },
    {
        label: "ID Number",
        name: "IDNumber2",
        type: "number",
        required: false,
        rowNumber: 6
    },
    {
        label: "Medicaid Number Provider",
        name: "medicaidProviderNumber",
        type: "number",
        required: false,
        rowNumber: 7
    },
    {
        label: "NPI Number",
        name: "NPINumber",
        type: "number",
        required: false,
        rowNumber: 7
    },
    {
        label: "Provider Commercial Number",
        name: "providerCommercialNumber",
        type: "number",
        required: false,
        rowNumber: 8
    },
    {
        label: "Additional Provider Number",
        name: "additionalProviderNumber",
        type: "number",
        required: false,
        rowNumber: 8
    },
    {
        label: "Demonstration Project Identifier",
        name: "demonstrationProjectIdentifier",
        type: "text",
        required: false,
        rowNumber: 9
    },
    {
        sectionHeading: "Provider Address",
        rowNumber: 10
    },
    
    {
        addressInfo: true,
        rowNumber: 11
    },
    {
        sectionHeading: "Contact Information",
        rowNumber: 14
    },
    {
        label: "Contact Name",
        name: "name",
        type: "text",
        required: false,
        rowNumber: 15,
        validation: validator.NAME_VALIDATION
    },
    {
        label: "E-mail Address",
        name: "email",
        type: "email",
        required: false,
        rowNumber: 15,
        validation: validator.EMAIL_VALIDATION
    },
    {
        sectionHeading: "Financial Routing",
        rowNumber: 20
    },
    {
        label: "Provider Account Number",
        name: "accountNumber",
        type: "number",
        required: false,
        rowNumber: 21
    },
    {
        label: "Bank Code Number",
        name: "bankCode",
        type: "number",
        required: false,
        rowNumber: 21
    },
]

export const PAYER_FORM_INPUTS = [
    {
        label: "Name",
        name: "name",
        type: "text",
        required: true,
        rowNumber: 1,
        validation: validator.NAME_VALIDATION
    },
    {
        label: "Type",
        name: "transmissionTo",
        type: "select",
        options: [
            { label: 'Dental', value: 'Dental' },
            { label: 'EDI', value: 'EDI' },
            { label: 'EDI Delayed', value: 'EDI Delayed' },
            { label: 'NON EDI', value: 'NON EDI' },
            { label: 'NON EDI ADV PAYER', value: 'NON EDI ADV PAYER' },
        ],
        required: true,
        rowNumber: 2
    },
    {
        label: "Payer Code",
        name: "payerID",
        type: "text",
        required: true,
        rowNumber: 3
    }

]


export const FUNDING_SOURCE_FORM_INPUTS = [
    {
        sectionHeading: "Details",
        rowNumber: 0
    },
    {
        label: "Name",
        name: "name",
        type: "text",
        required: true,
        rowNumber: 1,
        validation: validator.NAME_VALIDATION
    },
    {
        label: "Funding Provider Number",
        name: "fundingProvider",
        type: "numberOnly",
        required: false,
        rowNumber: 2
    },
    {
        label: "Vendor ID",
        name: "vendorID",
        type: "numberOnly",
        required: false,
        rowNumber: 2
    },
    {
        addressInfo: true,
        rowNumber: 3
    },
    {
        sectionHeading: "Contact Information",
        rowNumber: 6
    },
    {
        label: "Title",
        name: "title",
        type: "text",
        required: true,
        rowNumber: 7
    },
    {
        label: "First Name",
        name: "firstName",
        type: "text",
        required: true,
        rowNumber: 7,
        validation: validator.NAME_VALIDATION
    },
    {
        label: "Last Name",
        name: "lastName",
        type: "text",
        required: true,
        rowNumber: 8,
        validation: validator.NAME_VALIDATION
    },
    {
        label: "E-mail",
        name: "email",
        type: "email",
        required: false,
        rowNumber: 8,
        validation: validator.EMAIL_VALIDATION
    },
    {
        sectionHeading: "Contact Information",
        rowNumber: 9
    },
    {
        label: "Payer",
        name: "payer",
        type: "select",
        options: [],
        required: true,
        rowNumber: 10
    },
    {
        label: "Payer Type",
        name: "payerType",
        type: "select",
        options: [
            { label: "Medicaid", value: "Medicaid" },
            { label: "Medicare", value: "Medicare" },
            { label: "Tricare", value: "Tricare" },
            { label: "Chapva", value: "Chapva" },
            { label: "Group Health Plan", value: "Group Health Plan" },
            { label: "Feca blk Lung", value: "Feca blk Lung" },
            { label: "Other", value: "Other" }
        ],
        required: false,
        rowNumber: 10
    }
]

export const NEW_BILLING_PROVIDER = {
    entityType: '',
    organizationName: '',
    firstName: '',
    lastName: '',
    middleName: '',
    IDType1: '',
    IDNumber1: 0,
    IDType2: '',
    IDNumber2: 0,
    medicaidProviderNumber: 0,
    NPINumber: 0,
    providerCommercialNumber: 0,
    additionalProviderNumber: '',
    demonstrationProjectIdentifier: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipCode: 0,
    name: '',
    phoneNumber: 0,
    faxNumber: '',
    email: '',
    addName: '',
    addPhoneNumber: 0,
    addFaxNumber: '',
    addEmail: '',
    accountNumber: '',
    bankCode: '',
}

export const NEW_FUNDING_SOURCE = {
    name: '',
    fundingProvider: 0,
    vendorID: 0,
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    title: '',
    firstName: '',
    lastName: '',
    phoneNumber: 0,
    faxNumber: 0,
    payer: '',
    payerType: '',
    email: '',
}

export const NEW_PAYER_SOURCE = {
    name: '',
    transmissionTo: '',
    payerID: '',
}


export const LEAVERULES_FORM_INPUTS = [
    {
        label: "Name",
        name: "Name",
        type: "text",
        required: true,
        rowNumber: 1
    },
    {
        label: "Monthly Maximum Leave Days",
        name: "monthlyLeaves",
        type: "number",
        required: false,
        rowNumber: 1
    },
    {
        label: "Yearly Maximum Leave Days",
        name: "yearlyLeaves",
        type: "number",
        required: false,
        rowNumber: 1
    },
    {
        label: "Maximum Consecutive Leave Days Per Year",
        name: "yearlyConsecutiveLeaves",
        type: "number",
        required: false,
        rowNumber: 1
    },
    {
        label: "Calculate Leave Days using",
        name: "calendarYear",
        type: "radio",
        options: [
            { label: "Calender Year", value: "Calender Year" },
            { label: "Fiscal Year", value: "Fiscal Year" }
        ],
        required: true,
        rowNumber: 1
    },
    {
        label: "Rate Source",
        name: "serviceRate",
        type: "radio",
        options: [
            { label: "Service Rate", value: "Service Rate" },
            { label: "Unit Rate History", value: "Unit Rate History" },
            { label: "Define Rate Below", value: "Define Rate Below" },
            { label: "Percentage of Service Rate", value: "Percentage of Service Rate" },
            { label: "Define Rate in Service", value: "Define Rate in Service" },
        ],
        required: true,
        rowNumber: 1
    },
    {
        label: "Unit Rate ($)",
        name: "unitRate",
        type: "number",
        required: true,
        rowNumber: 1
    },
    {
        label: "Percentage (%)",
        name: "percentage",
        type: "number",
        required: true,
        rowNumber: 1
    },
    {
        label: "Billing Data Grouping Type",
        name: "billingData",
        type: "radio",
        options: [
            { label: "Per Day", value: "Per Day" },
            { label: "Group Per Continuous Option", value: "Group Per Continuous Option" },
            { label: "Group Per Option", value: "Group Per Option" },
            { label: "Group Per Revenue Code", value: "Group Per Revenue Code" },
            { label: "Group Per Continuous Revenue Code", value: "Group Per Continuous Revenue Code" },
        ],
        required: true,
        rowNumber: 1
    },
    {
        label: "Billing for Non Covered Days",
        name: "billingDataForNon",
        type: "radio",
        options: [
            { label: "No Billing Data", value: "Per Day" },
            { label: "Billing Data with non-billable units", value: "Billing Data with non-billable units" },
            { label: "Billing Data with non-billable units and Revenue Code", value: "Billing Data with non-billable units and Revenue Code" },
            { label: "Billing Data with billable units and Revenue Code", value: "Billing Data with billable units and Revenue Code" },
        ],
        required: true,
        rowNumber: 1
    },
    {
        label: "Revenue Code",
        name: "revenueCode",
        type: "select",
        options: [
            { label: "Revenue Code", value: "Revenue Code" }
        ],
        required: true,
        rowNumber: 1
    },
    {
        label: "Billing Data Grouping for Non Covered Days",
        name: "noBillingData",
        type: "radio",
        options: [
            { label: "Per Day", value: "Per Day" },
            { label: "Group Per Continuous Option", value: "Group Per Continuous Option" },
            { label: "Group Per Option", value: "Group Per Option" },
        ],
        required: true,
        rowNumber: 1
    },
]