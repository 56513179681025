import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

import axios from 'axios'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box } from '@mui/material'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

const selectAuth = (state) => state.auth

export default function CustomizedDialogs({ handleClose, button, setData, handleGeneratePDF }) {
    const auth = useSelector(selectAuth)
    const [clientData, setClientData] = React.useState([])
    const [selectedClient, setSelectedClient] = React.useState(null)
    const navigate = useHistory()
    const [date, setDate] = React.useState(new Date())

    React.useEffect(() => {
        (async () => {
            try {
                const response = await axios.get('/clients/get')
                setClientData(response.data)
            } catch (error) {
                console.log(error)
            }
        })()
    }, [])

    const handleChange = ({ target: { value } }) => {
        setSelectedClient(value)
    }

    const handleAddMedication = () => {
        handleClose()
        navigate.push(`/brhaviour/client/${selectedClient}`, { date: date.toISOString() })
    }
    const handleAddPdf = async () => {
        handleClose();
        const dateString = date.toISOString();
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1;
        const data = {
            clientId: selectedClient,
            month: month,
            year: year
        };
        const matchingClient = clientData.find(data => data._id === selectedClient);
        if (matchingClient) {
            const { firstName, lastName } = matchingClient;
            const clientName = `${firstName} ${lastName}`;
            if (selectedClient !== null && data.month && data.year) {
                await handleGeneratePDF({ ...data, clientName });
            }
            setSelectedClient(null);
        }
    };

    return (
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={true}>
        <DialogTitle sx={{m: 0, p: 2}} id='customized-dialog-title'>
          Select Client
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <FormControl style={{display: 'flex', width: '100%'}}>
            <InputLabel id='demo-simple-select-label'>Client</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={selectedClient}
              label='Age'
              size='small'
              onChange={handleChange}
            >
              {clientData.map((client) => (
                <MenuItem value={client._id}>{client?.firstName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={button === 'AddMedication' ? handleAddMedication : handleAddPdf}
          >
            Next
          </Button>
        </DialogActions>
      </BootstrapDialog>
    )
}
