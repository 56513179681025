import { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../setup'
import { useHistory } from 'react-router-dom'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../reusable-components/tables/TableSearchBar'
import AdminCareHeader from '../AdminCareHeader'
import DynamicTable from '../../../../reusable-components/tables/DynamicTable'
import { INDIVIDUAL_CONTACT_LIST_COLUMNS_DATA_MAPPING, INDIVIDUAL_CONTACT_LIST_COLUMNS, INDIVIDUAL_CONTACT_LIST_SORTING_COLUMNS } from '../care_table_constants'
import { hasPermission } from '../../../../../_helper/hasPermission'
import { permissions } from '../../../../../_constants/permissions'
import { QueryData } from '../../../../reusable-components/tables/DynamicTableTypes'

const selectAuth = (state: RootState) => state.auth

const List = () => {
    const history = useHistory()
    const [clientsListing, setClientsListing] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [queryData, setQueryData] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: '_id',
        sortDir: 'desc',
        searchTerm: ''
    })

    const auth = useSelector(selectAuth)
    const userPermissions = auth.user.roleId;

    useEffect(() => {
        const fetchContants = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
            try {
                const response = await axios.get(`/clients/get?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${queryData.searchTerm}`);
                const { data, currentPage, totalCounts } = response.data;
                setClientsListing(data);
                setFilteredData(data);
                setQueryData((prevState: any) => {
                    return {
                        ...prevState,
                        currentPage,
                        totalCounts,
                    };
                });
            } catch (err) {
                console.error('Error fetching contants:', err);
            }
        };
    
        fetchContants(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir);
    
    }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm]);
    

    const onView = ({ _id }: { _id: string }) => {
        if (hasPermission(userPermissions, permissions.admin_care_healthData_view)) {
            history.push({
                pathname: `/admin/care/health/health-data/${_id}`
            })
        }
        else if (hasPermission(userPermissions, permissions.admin_care_allergy_view)) {
            history.push({
                pathname: `/admin/care/health/allergy/list/${_id}`
            })
        }
        else if (hasPermission(userPermissions, permissions.admin_care_diagnosis_view)) {
            history.push({
                pathname: `/admin/care/health/diagnosis/list/${_id}`
            })
        }
    }

    return (
        <>
            <AdminCareHeader />
            <div className='card'>
                <div className='card-body'>
                    <TableTitle title='Clients List'></TableTitle>
                    <TableSearchBar
                        label='Search'
                        variant='outlined'
                        size='small'
                        value={searchTerm}
                        setQueryData={setQueryData}
                        setSearchTerm={setSearchTerm}
                        margin='normal'
                        inputAdornmentPosition='start'
                    ></TableSearchBar>
                    <DynamicTable
                        columns={INDIVIDUAL_CONTACT_LIST_COLUMNS}
                        data={filteredData}
                        canView={hasPermission(userPermissions, permissions.admin_care_healthProfile_view)}
                        canDelete={false}
                        columnDataMapping={INDIVIDUAL_CONTACT_LIST_COLUMNS_DATA_MAPPING}
                        sortingColumns={INDIVIDUAL_CONTACT_LIST_SORTING_COLUMNS}
                        onView={onView}
                        setQueryData={setQueryData}
                        queryData={queryData}
                    />
                </div>
            </div>
        </>
    )
}

export default List