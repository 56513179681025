/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable */ import React, {useState} from 'react'
import OtpInput from 'react-otp-input'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
// import { useUser } from '../../../../_helper/userHooks/userHook'
import axios from 'axios'
import {storeTokenInLocalStorage} from '../../../../_helper/userHooks/common'
import {useDispatch} from 'react-redux'
import io from 'socket.io-client'

import {signIn, getCurrentUser} from 'aws-amplify/auth'
import {Try} from '@mui/icons-material'
import {toast} from 'react-toastify'
// import {verifyLoginMFA} from '../redux/AuthCRUD'
// import * as auth from '../redux/AuthRedux'
// import { login } from '../redux/AuthCRUD'
// import { setRoles } from '../redux/rolesSlice

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

// const initialValues = {
//     email: 'trumemodev@mail.com',
//     password: 'TrumemoDev1*',
// }
const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
export function OTP() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const location = useLocation()

  const history = useHistory()

  const signInWithCognito = async (values) => {
    try {
      const {nextStep} = await signIn({
        username: values.email,
        password: values.password,
      })

      if (nextStep.signInStep === 'DONE') {
        const currentUser = await getCurrentUser()
        // localStorage.setItem("isSSO", false);
        // getUserProfile(currentUser.userId);
        return
      }

      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') {
        history.push('/otp?type=LOGIN')
        return
      }

      if (nextStep.signInStep === 'CONFIRM_SIGN_UP') {
        history.push('/otp?type=SIGNUP', {
          state: {
            email: values.email,
            password: values.password,
            returnPath: '/login',
          },
        })
        return
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || error?.message || 'Something went wrong')
      console.log(error?.response?.data?.error || error?.message || 'Something went wrong')
    } finally {
      setLoading(false)
    }
    //   message.warning(nextStep.signInStep);
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      signInWithCognito({email: values.email, password: values.password})
      return
      // const config = {
      //   header: {
      //     'Content-Type': 'application/json',
      //   },
      // }

      // try {
      //   const response = await axios.post('/users/login', values, config)
      //   if (!response?.data?.token) {
      //     console.log('Something went wrong during signing in: ', response)
      //     setError(response.data.message)
      //     return
      //   }
      //   if (response?.data?.user?.isActive == false) {
      //     console.log('Verify your email')
      //     setError('Verify your email')
      //     return
      //   }
      //   singInWithCognito()

      //   storeTokenInLocalStorage(response.data.token)

      //   window.location.reload()
      // } catch (error) {
      //   console.log('error', error)
      //   setError(error.response.data.message)
      //   setLoading(false)
      //   setSubmitting(false)
      //   setStatus('The login detail is incorrect')
      // } finally {
      //   setLoading(false)
      // }
      // axios.post("/users/login", values, config)
      //     .then((res) => {
      //         if (!res?.data?.token) {
      //             console.log('Something went wrong during signing in: ', res);
      //             return
      //         }
      //         storeTokenInLocalStorage(res.data.token)
      //         window.location.reload();
      //     })
      //     .catch((error) => {
      //         console.log("error", error)
      //         setError(error.response.data.message)
      //         setLoading(false)
      //         setSubmitting(false)
      //         setStatus('The login detail is incorrect')
      //     })
      //     .finally(() => {
      //         setLoading(false)
      //     })
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>OTP Validation</h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
                    New Here?{' '}
                    <Link to='/auth/registration' className='link-primary fw-bolder'>
                        Create an Account
                    </Link>
                </div> */}
        <OTPInputField />
      </div>

      {/* <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Sign In</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div> */}
      {/* end::Action */}
    </form>
  )
}

const OTPInputField = () => {
  const [otp, setOtp] = useState('')
  //   let token = searchParams.get('token')
  const location: any = useLocation()
  const history = useHistory()
  const params = location.search.split('=')

  if (params.length < 2) {
    history.goBack()
    return
  }

  const type = params[1]
  const handleChange = async (otp) => {
    setOtp(otp)
    const {email, password} = location.state
    if (otp.length === 6) {
      switch (type) {
        case 'LOGIN':
          if (!email?.length || !password?.length) {
            history.push('/auth/login')
            return
          }
          // const response = await verifyLoginMFA(otp)
          // if (!response.data.status) {
          //   toast.error(response.data.message)
          //   return
          // }
          // storeTokenInLocalStorage(response.data.token)
          // window.location.reload()
          return
          break
        case 'SIGNUP': {
          if (!email?.length || !password?.length) {
            history.push('/auth/registration')
            return
          }
          const response = await axios.post('/users/confirmOTP', {
            email,
            otp,
            type,
          })
          if (!response.data.status) {
            console.log(response)
            toast.error(response.data.message)
            return
          }
          break
        }
        default:
          history.push('/auth/login')
          return
      }

      history.push('/auth/login')

      //   const response = await axios.post('/users/login', {email, password})
      //   if (!response?.data?.token) {
      //     toast.error(response.data.message)
      //     return
      //   }

      //   storeTokenInLocalStorage(response.data.token)
      //   window.location.reload()
    }
  }

  return (
    <OtpInput
      value={otp}
      onChange={handleChange}
      numInputs={6}
      renderInput={(props) => <input {...props} />}
      inputStyle={{
        width: '3rem',
        height: '3rem',
        margin: '0 0.5rem',
        fontSize: '1rem',
        borderRadius: 4,
        border: '1px solid rgba(0,0,0,0.3)',
      }}
    />
  )
}
