
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import BillingProviderHeader from './BillingProviderHeader'
import New from './new/New'
// import Archive from './archive/Archive'
// import Search from './search/Search'
import ListProvider from './list/ListProvider'

function BillingProviderIndex() {
    return (
        <div>
            <BillingProviderHeader />
        </div>
    )
}

export default BillingProviderIndex