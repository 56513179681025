import { createSlice } from "@reduxjs/toolkit";
import { addBillingProvider } from "../action";


interface BillingProvider {
    entityType: String;
    organizationName: String;
    firstName: String;
    lastName: String;
    middleName: String;
    identityInformation: {
        IDType1: String;
        IDNumber1: Number;
        IDType2: String;
        IDNumber2: Number;
        medicaidProviderNumber: Number;
        NPINumber: Number;
        providerCommercialNumber: Number;
        additionalProviderNumber: Number;
        demonstrationProjectIdentifier: String;
    },
    providerAddress: {
        street1: String;
        street2: String;
        city: String;
        state: String;
        zipCode: Number;
    },
    contactInformation: {
        name: String;
        phoneNumber: Number;
        faxNumber: Number;
        email: String;
    },
    additionalContact: {
        addName: String,
        addPhoneNumber: Number,
        addFaxNumber: String,
        addEmail: Number,
    },
    financialRouting: {
        accountNumber: Number;
        bankCode: Number;
    }
}

interface BillingProviderState {
    billing: BillingProvider[] | any;
    isLoading: boolean
}

const initialState: BillingProviderState = {
    billing: [],
    isLoading: false
}

export const billingInitialSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(addBillingProvider.fulfilled, (state, action) => {
                state.billing = action.payload
                state.isLoading = false
            })
            .addCase(addBillingProvider.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(addBillingProvider.rejected, (state, action) => {
                state.isLoading = false
            })
    }

})

export default billingInitialSlice.reducer