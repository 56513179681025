import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {EDIT_NOTE_ENTRY_FORM_INPUTS} from '../../ADD_NOTES_FORM_CONSTANTS'
import DynamicForm from '../../../../reusable-components/forms/DynamicForm'
import {toast} from 'react-toastify'
import dayjs from 'dayjs'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {FormControl, TextField} from '@mui/material'

export default function EditNoteEntry() {
  const params: {id: string} = useParams()
  const history = useHistory()
  const [uiElements, setUIElements] = useState([])
  const [clickCount, setClickCount] = useState(7)
  const [scoringMethod, setScoringMethod] = useState([])
  const [updateRolesFields, setUpdateRolesFields] = useState(false)
  const [userTasks, setUserTasks] = useState([])
  const [inputArr, setInputArr] = useState([
    {
      taskId: '',
      taskName: '',
      taskDescription: '',
      score: '',
      description: '',
    },
  ])
  const [formData, setFormData] = useState({})
  const [frequency, setFrequency] = useState('')
  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(dayjs())
  const buttons = [
    {label: 'Cancel', variant: 'outlined', type: 'button'},
    {label: 'Save', variant: 'contained', type: 'submit'},
  ]

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    axios.get(`/notesEntry/getById/${params.id}`).then((res) => {
      let tempArrForInputArr = []
      let tempArrayForScoringMethod = []
      let prevFormData = formData

      res.data.taskList.map((eachTask, localIndex) => {
        let taskNameKeyName = `${localIndex}taskName`
        let taskDescription = `${localIndex}taskDescription`
        let score = `${localIndex}score`
        let description = `${localIndex}description`
        prevFormData[taskNameKeyName] = eachTask.taskName
        prevFormData[taskDescription] = eachTask.taskDescription
        prevFormData[score] = res.data.tasks[localIndex]['score']
        prevFormData[description] = res.data.tasks[localIndex]['description']
        tempArrForInputArr.push({
          taskName: eachTask.taskName,
          taskDescription: eachTask.taskDescription,
          score: res.data.tasks[localIndex]['score'],
          description: res.data.tasks[localIndex]['description'],
          taskId: eachTask._id,
        })
      })

      res.data.scoringMethod[0].scoringLevels.map((obj) => {
        tempArrayForScoringMethod.push({
          label: obj.levelAcronym,
          value: obj._id,
        })
      })
      setStartDate(res.data.beginTime)
      setFrequency(res.data.frequency)
      setEndDate(res.data.endTime)
      // prevFormData['startDate'] = res?.data?.creationDate
      // prevFormData['beginTime'] = res?.data?.beginTime
      // prevFormData['endTime'] = res?.data?.endTime
      prevFormData['location'] = res?.data?.location?.address
      prevFormData['enteredBy'] = `${res?.data?.user[0]?.firstName} ${res?.data?.user[0]?.lastName}`

      setUIElements(
        EDIT_NOTE_ENTRY_FORM_INPUTS(
          `${res?.data?.user[0]?.firstName.toUpperCase()} ${res?.data?.user[0]?.lastName.toUpperCase()}`
        )
      )
      setUserTasks(res.data.taskList)
      setScoringMethod(tempArrayForScoringMethod)
      setInputArr(tempArrForInputArr)
      setFormData(prevFormData)
      setUpdateRolesFields((prevstate) => {
        return !prevstate
      })
    })
  }

  const onChangeTaskDescription = (name, value, checked, type, index) => {
    let prevState = inputArr
    prevState[index]['description'] = value
    setInputArr(prevState)
  }

  const onChangeScoringMethod = (value, index) => {
    let prevState = inputArr
    prevState[index]['score'] = value
    setInputArr(prevState)
  }

  useEffect(() => {
    loadDataAndUI()
  }, [updateRolesFields])

  const loadDataAndUI = () => {
    let tempClickCount = clickCount
    let tempUIArray = uiElements
    userTasks.map((eachTask, taskIndex) => {
      tempClickCount = tempClickCount + 1
      const taskNameFieldToAdd = {
        index: taskIndex,
        label: 'Name',
        name: `${taskIndex}taskName`,
        type: 'text',
        required: true,
        rowNumber: tempClickCount,
        disabled: true,
        sx: {
          width: '90%',
        },
        value: '',
      }
      const taskDescriptionFieldToAdd = {
        index: taskIndex,
        label: 'Description',
        name: `${taskIndex}taskDescription`,
        type: 'textarea',
        required: true,
        rowNumber: tempClickCount,
        disabled: true,
        className: `col-12 col-sm-6 col-md-6 false false false col-lg-3 col-md-3 col-sm-3 false`,
        textAreaClassName: 'notes-entry-task-description-method-div',
      }
      const scoringMethodFieldToAdd = {
        index: taskIndex,
        label: 'Search Level',
        name: `${taskIndex}score`,
        type: 'select',
        options: scoringMethod,
        required: true,
        rowNumber: tempClickCount,
        onChange: onChangeScoringMethod,
        className: `col-12 col-sm-6 col-md-6 false false false col-lg-3 col-md-3 col-sm-3 false`,
        sx: {
          marginLeft: '3rem',
          width: '80%',
        },
      }
      const descriptionFieldToAdd = {
        index: taskIndex,
        label: 'Description',
        name: `${taskIndex}description`,
        type: 'textarea',
        required: true,
        rowNumber: tempClickCount,
        onChange: onChangeTaskDescription,
        className: `col-12 col-sm-6 col-md-6 false false false col-lg-3 col-md-3 col-sm-3 false`,
        textAreaClassName: 'notes-entry-task-remarks-method-div',
      }

      tempUIArray.push(
        taskNameFieldToAdd,
        taskDescriptionFieldToAdd,
        scoringMethodFieldToAdd,
        descriptionFieldToAdd
      )
    })
    setClickCount(tempClickCount)
    setUIElements(tempUIArray)
  }

  const handleSubmitForm = async (formData) => {
    let arrayOfTasks = inputArr
    arrayOfTasks.forEach((eachElem) => {
      delete eachElem['taskDescription']
      delete eachElem['taskName']
    })
    await axios
      .patch('/notesEntry/update/' + params.id, {
        // creationDate: formData['startDate'],
        // beginTime: formData['beginTime'],
        // endTime: formData['endTime'],
        beginTime: startDate,
        frequency: frequency,
        endTime: endDate,
        location: {
          address: formData['location'],
          lat: '31.5245054',
          lng: '74.3033196',
          typeOfLocation: 'client',
          idOfDoc: '65ae6e8b682092bf5446fd8b',
        },
        tasks: arrayOfTasks,
      })
      .then((res) => {
        if (res.status == 201) {
          toast.success('Note entry updated successfully')
          history.goBack()
        }
      })
      .catch((e) => toast.error("Couldn't update note entry"))
  }

  return (
    <>
      <div className='card'>
        <div className='card-body'>

        <FormControl
            sx={{mb: 2, ml: 1.5, width: '30%'}}
            color='primary'
            fullWidth
            variant='filled'
            required
          >
            <TextField
              // labelId='note-type-select-label'
              value={frequency}
              sx={{background: 'white'}}
              onChange={(e) => {
                setFrequency(e.target.value)
              }}
              label='Note Type'
              name='frequency'
              disabled
            />
          </FormControl>
          {frequency && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {frequency === 'MONTHLY' ? (
                <>
                  <TextField
                    // views={['month', 'year']}
                    sx={{background: 'white', ml: 1}}
                    label='Selected Range'
                    value={`01-${startDate ? dayjs(startDate).format('MMMM') : ''} - ${
                      endDate ? dayjs(endDate).format('DD-MMMM') : ''
                    }`}
                    // renderInput={(params) => <TextField {...params} fullWidth margin='normal' />}
                    disabled
                  />
                </>
              ) : frequency === 'YEARLY' ? (
                <>
                  <TextField
                    // views={['year']}
                    sx={{background: 'white', ml: 1}}
                    label='Selected Range'
                    value={`${startDate ? dayjs(startDate).format('MMMM') : ''} - ${
                      endDate ? dayjs(endDate).format('MMMM') : ''
                    }`}
                    // renderInput={(params) => <TextField {...params} fullWidth margin='normal' />}
                    disabled
                  />
                </>
              ) : frequency === 'QUARTERLY' ? (
                <>
                  <TextField
                    // views={['month', 'year']}
                    sx={{background: 'white', ml: 1}}
                    label='Selected Range'
                    value={`${startDate ? dayjs(startDate).format('MMMM') : ''} - ${
                      endDate ? dayjs(endDate).format('MMMM') : ''
                    }`}
                    // renderInput={(params) => <TextField {...params} fullWidth margin='normal' />}
                    disabled
                  />
                </>
              ) : frequency === 'DAILY' ? (
                <TextField
                  label='Selected Date'
                  sx={{background: 'white', ml: 1}}
                  value={startDate ? dayjs(startDate).format('DD-MM-YYYY') : ''}
                  // renderInput={(params) => <TextField {...params} fullWidth margin='normal' />}
                  disabled
                />
              ) : frequency === 'WEEKLY' ? (
                <TextField
                  label='Selected Date'
                  sx={{background: 'white', ml: 1}}
                  value={`${startDate ? dayjs(startDate).format('DD-MM-YY') : ''} to ${
                    endDate ? dayjs(endDate).format('DD-MM-YY') : ''
                  }`}
                  // renderInput={(params) => <TextField {...params} fullWidth margin='normal' />}
                  disabled
                />
              ) : null}
            </LocalizationProvider>
          )}
          <DynamicForm
            fields={uiElements}
            data={formData}
            onSubmit={handleSubmitForm}
            buttons={buttons}
          />
        </div>
      </div>
    </>
  )
}
