import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import UserList from '../../../../../pages/admin/general/change-password/user-list/UserList'

const ChangePasswordIndexRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path='/admin/general/change-password/user-list'>
        <UserList />
      </Route>
      <Redirect
        from='/admin/general/change-password'
        exact={true}
        to='/admin/general/change-password/user-list'
      />
      <Redirect to='/admin/general/change-password/user-list' />
    </Switch>
  )
}

export default ChangePasswordIndexRoutes
