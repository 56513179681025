import React from 'react'
import { Button } from '@mui/material'

const AddButton: React.FC<{
    onClick?: () => void
}> = ({ onClick }) => {
    return (
        <Button
            variant="contained"
            color='primary'
            sx={{ height: '2.3rem', minWidth: '86px', float: 'right', marginRight: '5px' }}
            onClick={onClick}
        >
            Add New
        </Button>
    )
}

export default AddButton