import { useEffect, useState } from 'react'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper';
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm';
import { getServiceAuth } from '../../service-authorization/store/actions';
import { useDispatch } from 'react-redux';
import { NEW_PROFESSIONAL_CLAIM_DATA, SEARCH_PROFESSIONAL_CLAIM_INPUTS } from '../../../BILLING_CONSTANTS';
import axios from 'axios';

const SearchProfessionalClaim = () => {
    const [updatedProfessionalClaimOptions, setUpdatedProfessionalClaim] = useState([]);
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [serviceAuthResult, clientsResult] = await Promise.all([
                    dispatch(getServiceAuth({})),
                    (await axios.get('/clients/get')),
                ])
                const tempAuths = serviceAuthResult.payload.data;
                const seen = new Set();
                let optionsClient = tempAuths.filter((data) => {
                    const duplicate = seen.has(data.clientID); seen.add(data.clientID); return !duplicate;
                })
                optionsClient = optionsClient.map((obj) => {
                    let clientObj = clientsResult.data.find((clobj) => clobj._id === obj.clientID)
                    const updatedElement = {
                        value: clientObj._id,
                        label: clientObj.firstName + ' ' + clientObj.lastName
                    }
                    return updatedElement
                })
                const constantsWithUpdatedProfessionalClaim = SEARCH_PROFESSIONAL_CLAIM_INPUTS.map((field) => {
                    if (field.name === 'individualSearch') {
                        return {
                            ...field,
                            options: optionsClient,
                        };
                    }
                    return field;
                });
                setUpdatedProfessionalClaim(constantsWithUpdatedProfessionalClaim)
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [])

    const handleSubmitForm = async (formData) => {
        console.log(formData)
    };

    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];

    return (
        <FormWrapper
            title='Claim Search'
            content={
                <DynamicForm
                    fields={updatedProfessionalClaimOptions}
                    data={NEW_PROFESSIONAL_CLAIM_DATA}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        />
    )
}

export default SearchProfessionalClaim