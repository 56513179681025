import {useDispatch, useSelector} from 'react-redux'

import {Fragment, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {RootState} from '../../../../../setup'
import {QueryData} from '../../../../reusable-components/tables/DynamicTableTypes'
import {deleteScoringMethod, getScoringMethod} from './store/action'
import {toast} from 'react-toastify'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import TableWrapper from '../../../../reusable-components/tables/TableWrapper'
import TableSearchBar from '../../../../reusable-components/tables/TableSearchBar'
import {hasPermission} from '../../../../../_helper/hasPermission'
import {permissions} from '../../../../../_constants/permissions'
import DynamicTable from '../../../../reusable-components/tables/DynamicTable'
import AddButton from '../../../../reusable-components/Buttons/AddButton'
import {
  htmlColumns,
  LIST_OF_SM_COLUMN_DATA_MAPPING,
  LIST_OF_SM_COLUMNS,
  LIST_OF_SM_SORTING_COLUMNS,
} from '../GENERAL_TABLE_CONSTANTS'

const selectAuth = (state: RootState) => state.auth

export const ListMethod = () => {
  const [method, setMethod] = useState<any[]>([])
  const [filteredData, setFilteredData] = useState<any>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [deletedID, setDeletedID] = useState('')
  const [queryData, setQueryData] = useState<QueryData>({
    pageNumber: 1,
    pageSize: 5,
    currentPage: 1,
    totalCounts: 1,
    sortBy: 'createdAt',
    sortDir: 'asc',
    searchTerm: '',
  })
  const dispatch = useDispatch()
  const auth = useSelector(selectAuth)
  const userPermissions = auth.user.roleId
  const history = useHistory()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result: any = await dispatch(getScoringMethod({}))
        const { data } = result.payload.data
        setMethod(data)
        const newData = data.map((item) => {
          return {
            ...item,
            levelName: (
              <Fragment>
                {item.scoringLevels.slice(0, 5).map((option, index) => (
                  <Fragment key={index}>
                    <p className='mb-0 mt-0'>
                      {option.levelName} {option.levelAcronym} <br />
                    </p>
                  </Fragment>
                ))}
              </Fragment>
            ),
          }
        })

        setFilteredData(newData)
      } catch (err) {}
    }

    fetchData()
  }, [
    deletedID,
    queryData.pageNumber,
    queryData.pageSize,
    queryData.sortBy,
    queryData.sortDir,
    queryData.searchTerm,
  ])

  const DeleteProgram = async (row: {_id: any}) => {
    try {
      const response: any = await dispatch(deleteScoringMethod(row._id))
      if (response.payload?.success) {
        toast.success('Deleted')
        setDeletedID(row._id)
        setQueryData((prevState) => ({
          ...prevState,
          searchTerm: '',
          pageNumber: 1,
        }))
      } else {
        toast.error('Error')
      }
    } catch (error) {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const onUpdate = ({_id}: {_id: string}) => {
    history.push({
      pathname: `/admin/general/scoring-method/edit/${_id}`,
    })
  }
  const onClickAddButton = () => {
    history.push('/admin/general/scoring-method/new')
  }
  return (
    <div className='card'>
      <div className='card-body'>
        <div>
          <TableTitle title='Scoring Method'></TableTitle>
          <TableWrapper
            searchField={
              <TableSearchBar
                label='Search'
                variant='outlined'
                size='small'
                value={searchTerm}
                setQueryData={setQueryData}
                setSearchTerm={setSearchTerm}
                margin='normal'
                inputAdornmentPosition='start'
              ></TableSearchBar>
            }
            tableButtons={
              !!hasPermission(userPermissions, permissions.admin_billing_scoring_method_create) && (
                <AddButton onClick={onClickAddButton} />
              )
            }
            dynamicTable={
              <DynamicTable
                htmlColumns={htmlColumns}
                columns={LIST_OF_SM_COLUMNS}
                data={filteredData}
                canUpdate={hasPermission(userPermissions, permissions.admin_billing_scoring_method_update)}
                canDelete={hasPermission(userPermissions, permissions.admin_billing_scoring_method_delete)}
                columnDataMapping={LIST_OF_SM_COLUMN_DATA_MAPPING}
                sortingColumns={LIST_OF_SM_SORTING_COLUMNS}
                clickableRow={false}
                onUpdate={onUpdate}
                onDelete={DeleteProgram}
                title='Are you sure you want to delete the method'
                setQueryData={setQueryData}
                queryData={queryData}
              />
            }
          />
        </div>
      </div>
    </div>
  )
}
