import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const CryptoJS = require('crypto-js');

const secretKey = 'J4k3H6g7D9b2N5';



interface ServiceProvider {
    _id: String;
    serviceProviderCode: String;
    serviceProviderType: String;
    serviceProviderName: String;
    firstName: String;
    lastName: String;
    businessName: String;
    ownerOrgID: Number;
    email: String;
    // truMemoProvider : String;
    authNumber: boolean;
    physicalAddr: {
        st1: String;
        st2: String;
        city: String;
        state: String;
        country: String;
        zipCode: Number;
    };
    mailingInfo: {
        mailingSt1: String;
        mailingSt2: String;
        mailingCity: String;
        mailingState: String;
        mailingCountry: String;
        mailingZipCode: Number;
    };
    taxonomyCode: String;
    taxonomyCodeDescription: String;
}

export const getServiceProvider = createAsyncThunk(
    'serviceProvider/get',
    async () => {
      try {
        const response = await axios.get('serviceProvider/get');
        const encryptedDataArray = response.data;
  
        const decryptedDataArray = encryptedDataArray.map((encryptedObj: any) => {
          const bytes = CryptoJS.AES.decrypt(encryptedObj.encryptedData, secretKey);
          const decryptedObj = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          return decryptedObj;
        });
  
        console.log(decryptedDataArray);
        return {
          data: decryptedDataArray,
        };
      } catch (err) {
        console.log(err);
        return err;
      }
    }
  );

  


// export const getServiceProvider = createAsyncThunk(
//     'serviceProvider/get/',
//     async (companyid: string | undefined) => {
//       try {
//         const response = await axios.get('serviceProvider/get/' + companyid);

//         // Assuming the encrypted data is received as 'encryptedData' in the response data
//         const encryptedData = response.data.encryptedData;

//         const decryptedBytes = AES.decrypt(encryptedData, secretKey);
//         const decryptedData = decryptedBytes.toString(enc.Utf8);

//         // Use the decryptedData as needed
//         console.log('Decrypted Data:', decryptedData);

//         return {
//           data: decryptedData, // Update the data property with the decrypted data
//         };
//       } catch (err) {
//         console.log(err);
//         throw err;
//       }
//     }
//   );

export const addServiceProvider = createAsyncThunk(
    'serviceProvider/create',
    async (data: ServiceProvider) => {
        try {
            const response = await axios.post('serviceProvider/create', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }

)

export const deleteServiceProvider = createAsyncThunk(
    'serviceProvider/delete/',
    async (data: string) => {
        try {
            const response = await axios.delete('serviceProvider/delete/' + data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)


export const updateServiceProvider = createAsyncThunk(
    'serviceProvider/update',
    async (data: ServiceProvider) => {
        try {
            const response = await axios.put('serviceProvider/update', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)
