import * as XLSX from 'xlsx'

const ExportXLSX = ({headerProp, filename, data, title}) => {
  const exportToXLSX = () => {
    const headers = headerProp.map((header) => ({
      label: header.label,
      key: header.key,
    }))

    const formattedData = data.map((item) => {
      const formattedItem = {}
      headers.forEach((header) => {
        formattedItem[header.key] = item[header.key]
      })
      return formattedItem
    })

    const worksheet = XLSX.utils.json_to_sheet(formattedData, {
      header: headers.map((h) => h.key),
      skipHeader: true,
    })

    XLSX.utils.sheet_add_aoa(worksheet, [headers.map((header) => header.label)], {origin: 'A1'})

    // Set column widths
    const columnWidths = headerProp.map((header) => ({
      wch: header.width || 10,
    }))
    worksheet['!cols'] = columnWidths

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, `${filename}.xlsx`)
  }

  return (
    <button className='btn btn-sm btn-success' disabled={data.length === 0} onClick={exportToXLSX}>
      {title} &nbsp;
      <i className='bi fa-sm bi-file-earmark-excel-fill'></i>
    </button>
  )
}

export default ExportXLSX
