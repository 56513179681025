import {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import {NEW_METHOD_INPUTS} from '../../GENERAL_CONSTANTS'
import {toast} from 'react-toastify'
import {addScoringMethod} from '../store/action'

const selectAuth = (state) => state.auth

export const NewMethod = () => {
  const [site, setSite] = useState([])
  const [levels, setLevels] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()
  const auth = useSelector(selectAuth)
  const [uiElements, setUIElements] = useState([])
  const [indexForScoringMethod, setIndexForScoringMethod] = useState(0)
  const [clickCount, setClickCount] = useState(3)

  const [inputArr, setInputArr] = useState([
    {
      levelName: '',
      levelAcronym: '',
      // isReportable: false,
    },
  ])

  useEffect(() => {
    const inputs = NEW_METHOD_INPUTS
    setUIElements(inputs)
    setUIElements((prevState) => [
      ...prevState,
      inputs,
      {
        index: indexForScoringMethod,
        rowNumber: clickCount,
        // error: error[i],
        // helperText: error[i] ? 'Please select a different role' : '',
        label: 'Level Name',
        name: `${indexForScoringMethod}levelName`,
        value: inputArr[indexForScoringMethod]['levelName'],
        onChange: onChangeLevelName,
        type: 'text',
        required: true,
      },
      {
        index: indexForScoringMethod,
        rowNumber: clickCount,
        label: 'Level Acronym',
        name: `${indexForScoringMethod}levelAcronym`,
        value: inputArr[indexForScoringMethod]['levelAcronym'],
        onChange: onChangeLevelAcronym,
        type: 'text',
        required: true,
      },
    ])
  }, [])

  const onAddAnotherLevelClick = async () => {
    setClickCount(clickCount + 1)
    let prevInputArr = inputArr
    prevInputArr.push({
      levelName: '',
      levelAcronym: '',
    })
    setInputArr(prevInputArr)
    setIndexForScoringMethod(indexForScoringMethod + 1)
  }
  const onChangeLevelAcronym = (name, value, checked, type, index) => {
    let prevInputArr = inputArr
    prevInputArr[index]['levelAcronym'] = value
    setInputArr(prevInputArr)
  }

  const onRemoveAnotherLevelButtonClick = (index) => {
    let prevInputArr = inputArr
    prevInputArr[index].levelName = ''
    setUIElements((prevState) => {
      return prevState.filter((item) => {
        return !item.index || (item.index && item.index != index)
      })
    })
  }

  const onChangeLevelName = (name, value, checked, type, index) => {
    let prevInputArr = inputArr
    prevInputArr[index]['levelName'] = value
    setInputArr(prevInputArr)
  }

  const handleSubmitForm = async (formData) => {
    let scoringLevels = []
    inputArr.map((eachElem) => {
      if (eachElem.levelName !== '') {
        scoringLevels.push(eachElem)
      }
    })
    const tempObj = {
      methodName: formData.methodName,

      scoringLevels: scoringLevels,
    }
    await dispatch(addScoringMethod(tempObj))
      .then((data) => {
        toast.success('Scoring method created successfully')
        history.push('/admin/general/scoring-method/list')
      })
      .catch((err) => {
        toast.error('Error while creating scoring method')
      })
  }
  useEffect(() => {
    addNewScoringMethodRow()
  }, [indexForScoringMethod])
  const addNewScoringMethodRow = () => {
    if (indexForScoringMethod != 0) {
      const levelNameFieldToAdd = {
        index: indexForScoringMethod,
        rowNumber: clickCount,
        label: 'Level Name',
        name: `${indexForScoringMethod}levelName`,
        value: inputArr[indexForScoringMethod]['levelName'],
        onChange: onChangeLevelName,
        type: 'text',
        required: true,
      }
      const levelAcronymFieldToAdd = {
        index: indexForScoringMethod,
        rowNumber: clickCount,
        label: 'Level Acronym',
        name: `${indexForScoringMethod}levelAcronym`,
        value: inputArr[indexForScoringMethod]['levelAcronym'],
        onChange: onChangeLevelAcronym,
        type: 'text',
        required: true,
      }
      const deleteButton = {
        index: indexForScoringMethod,
        label: 'Delete',
        variant: 'outlined',
        type: 'button',
        onClick: () => onRemoveAnotherLevelButtonClick(indexForScoringMethod),
        rowNumber: clickCount,
      }
      setUIElements((prevElements) => [
        ...prevElements,
        levelNameFieldToAdd,
        levelAcronymFieldToAdd,
        deleteButton,
      ])
    }
  }

  const buttons = [
    {
      label: 'Add Level',
      variant: 'contained',
      type: 'button',
      onClick: onAddAnotherLevelClick,
    },
    {label: 'Cancel', variant: 'contained', type: 'button'},
    {label: 'Save', variant: 'contained', type: 'submit'}
  ]

  return (
    <FormWrapper
      title='Add New Method'
      content={
        <>
          <DynamicForm
            fields={uiElements}
            data={site}
            onSubmit={handleSubmitForm}
            buttons={buttons}
          />
        </>
      }
    />
  )
}
