import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NewProgram from '../../../../../pages/admin/general/programs/newProgram/New'
import ListProgram from '../../../../../pages/admin/general/programs/listProgram/List'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { RouteWithPermission } from '../../../../../../_helper/routesWithPermission'
import EditProgram from '../../../../../pages/admin/general/programs/listProgram/Edit'
import { Error404 } from '../../../../../modules/errors/components/Error404'

const ProgramIndexRoutes: React.FC = () => {
    const auth = useSelector((state: { auth: any }) => state.auth);
    const userPermissions = auth.user.roleId;
    const canUpdate = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.PROGRAM.UPDATE');
    const canCreate = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.PROGRAM.CREATE');
    const canRead = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.PROGRAM.VIEW');

    return (
        <Switch>
            <RouteWithPermission path='/admin/general/programs/new' component={NewProgram} hasPerm={canCreate} />
            <RouteWithPermission path='/admin/general/programs/list/:id' component={EditProgram} hasPerm={canUpdate} />
            <RouteWithPermission path='/admin/general/programs/list' component={ListProgram} hasPerm={canRead} />
            <Route path='/error/404' component={Error404} />
        </Switch>
    )
}

export default ProgramIndexRoutes
