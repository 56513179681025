
import React, { Fragment, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Pagination from '../../../../../../_helper/paginationHook/Pagination'
import { deleteServiceProvider, getServiceProvider } from '../store/action'
import SPRow from './SPRow';
import { filterArray } from '../../../../../../_helper/filterArray';
import { RootState } from '../../../../../../setup';
import { Sort_by } from '../../../../../../_helper/objectSorter';
import SPEdit from './SPEdit';
import { MoonLoader } from 'react-spinners';
const selectAuth = (state: RootState) => state.auth;

// const headers = [
//     { label: "Form ID", key: "_id" },
//     { label: "First Name", key: "firstName" },
//     { label: "Last Name", key: "lastName" },
//     { label: "Service Provider Code", key: "serviceProviderCode" },
//     { label: "Service Provider Type", key: "serviceProviderType" },
//     { label: "Service Provider Name", key: "serviceProviderName" },
//     { label: "Business Name", key: "businessName" },
//     { label: "Owner Org ID", key: "ownerOrgID" },
//     { label: "Email", key: "email" },

// ]

const ListProvider = () => {
    const [serviceProvider, setServiceProvider] = useState<object[]>([])
    const [serviceProviderStatic, setServiceProviderStatic] = useState<any[]>([])
    const [deleteSPId, setDeleteSPId] = useState('')
    const [deleteSwitch, setDeleteSwitch] = useState(false);
    const [selectedSP, setSelectedSP] = useState({})
    const [exportData, setExportData] = useState([])
    const [showEditingForm, setShowEditingForm] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sortedBy, setSortedBy] = useState('')


    const dispatch = useDispatch()
    const auth = useSelector(selectAuth);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const result: any = await dispatch(getServiceProvider())
                let tempObj: any = result.payload.data;
                setServiceProvider(tempObj)
                setServiceProviderStatic(tempObj)
                setExportData(tempObj)
                setIsLoading(false)
            }
            catch (err) {
                console.log(err)
                setIsLoading(false)
            }
        }
        fetchData()
    }, [deleteSwitch, showEditingForm])

    const onDeleteSP = async () => {
        // const response: any = await dispatch(deleteServiceProvider(deleteSPId));
        setDeleteSwitch(!deleteSwitch);
    };

    const PaginationRef = useRef(null)

    useEffect(() => {
        let PaginationElement: any = PaginationRef.current
        if (PaginationElement !== null) {
            let numberOfPages = PaginationElement.getElementsByTagName("li").length - 2
            if (numberOfPages > 0) {
                if (numberOfPages < currentPage) {
                    let tempPage = currentPage - 1
                    setCurrentPage(tempPage)
                }
            }
        }
    }, [serviceProvider])

    const searchFilterSP = (value: string) => {
        const sampleFilter = [
            "firstName",
            "lastName",
            "serviceProviderCode",
            "_id",
            "serviceProviderType",
            "serviceProviderName",
            "businessName",
            "ownerOrgID"
        ]
        const filteredArray = filterArray(serviceProviderStatic, sampleFilter);
        let lowerCaseValue = value.toLowerCase()
        let temparr: any = []
        for (let i = 0; i < filteredArray.length; i++) {
            if (Object.values(filteredArray[i]).filter(item => typeof item === 'string' ? item.toLowerCase().includes(lowerCaseValue) : false).length > 0) {
                temparr.push(filteredArray[i])
            }
        }
        let tempstate: any = []
        for (let i = 0; i < serviceProviderStatic.length; i++) {
            for (let j = 0; j < temparr.length; j++) {
                if (temparr[j]._id === serviceProviderStatic[i]._id) {
                    tempstate.push(serviceProviderStatic[i])
                }
            }
        }
        setServiceProvider(tempstate)
        setCurrentPage(1)
    }

    const SPPaginationData = useMemo(() => {
        if (serviceProvider.length <= PageSize) {
            setCurrentPage(1)
        }
        let tempdata = serviceProvider
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return tempdata.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, serviceProvider, PageSize, sortedBy, serviceProviderStatic])

    return (
        <div>
            <div className='mb-5 col-mb-10' id='kt_content'>
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                    {
                        showEditingForm === false ? (
                            <SPEdit selectedSP={selectedSP} setShowEditingForm={setShowEditingForm} />
                        ) : (
                            <div className='container-xxl card'>
                                <div id='kt_content_container' className='mt-4'>
                                    <div className='card-body'>
                                        <h1 className='mt-6'>Service Provider List</h1>
                                        <div className='w-100 pt-6 pb-3'>
                                            <div className="d-flex w-100 justify-content-between">
                                                <div>
                                                    <div className='d-flex align-items-center position-relative overflow-auto my-1'>
                                                        <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                                                            <svg
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                width={24}
                                                                height={24}
                                                                viewBox='0 0 24 24'
                                                                fill='none'
                                                            >
                                                                <rect
                                                                    opacity='0.5'
                                                                    x='17.0365'
                                                                    y='15.1223'
                                                                    width='8.15546'
                                                                    height={2}
                                                                    rx={1}
                                                                    transform='rotate(45 17.0365 15.1223)'
                                                                    fill='black'
                                                                />
                                                                <path
                                                                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                                                    fill='black'
                                                                />
                                                            </svg>
                                                        </span>
                                                        <input
                                                            type='text'
                                                            data-kt-customer-table-filter='search'
                                                            className='form-control form-control-solid w-250px ps-15'
                                                            onChange={(e) => searchFilterSP(e.target.value)}
                                                            placeholder='Search Service Provider'
                                                            disabled={isLoading}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div>
                                                        <p style={{ "margin": "revert" }}>Items Per Page</p>
                                                    </div>
                                                    <div>
                                                        <select className='form-control form-control-solid form-select'
                                                            onChange={(e) => setPageSize(parseInt(e.target.value))}
                                                            disabled={isLoading}
                                                        >
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                            <option value={150}>150</option>
                                                            <option value={200}>200</option>
                                                            <option value={300}>300</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            isLoading ? (
                                                <div className="overlay d-flex justify-content-center">
                                                    <MoonLoader
                                                        color="#9db2fc"
                                                        size={80}
                                                        loading={isLoading}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className='stickhyHeaderTable table-responsive' style={{ padding: "0rem 0rem" }}>
                                                        <table
                                                            className='table table-light table-rounded table-striped border border-2 table-hover fs-6 gy-5 mt-5'
                                                            id='kt_individual_table'
                                                        >
                                                            <thead>
                                                                <tr className='text-start table-dark text-gray-400 table-rounded fw-bolder fs-7 text-uppercase gs-0'>
                                                                    <th className="px-3 min-w-100px" style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            if (sortedBy !== "firstName") {

                                                                                let finalSort = [...serviceProvider].sort(Sort_by('firstName', false, (a: any) => a.toUpperCase()))
                                                                                setServiceProvider(finalSort)
                                                                                setSortedBy("firstName")
                                                                            } else if (sortedBy === "firstName") {
                                                                                let finalSort = [...serviceProvider].sort(Sort_by('firstName', true, (a: any) => a.toUpperCase()))
                                                                                setServiceProvider(finalSort)
                                                                                setSortedBy('')
                                                                            }
                                                                        }}
                                                                    >First Name
                                                                        <i className={sortedBy === "firstName" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                                    </th>
                                                                    <th className="px-3 min-w-100px" style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            if (sortedBy !== "lastName") {

                                                                                let finalSort = [...serviceProvider].sort(Sort_by('lastName', false, (a: any) => a.toUpperCase()))
                                                                                setServiceProvider(finalSort)
                                                                                setSortedBy("lastName")
                                                                            } else if (sortedBy === "lastName") {
                                                                                let finalSort = [...serviceProvider].sort(Sort_by('lastName', true, (a: any) => a.toUpperCase()))
                                                                                setServiceProvider(finalSort)
                                                                                setSortedBy('')
                                                                            }
                                                                        }}
                                                                    >Last Name
                                                                        <i className={sortedBy === "lastName" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                                    </th>
                                                                    <th className="px-3 min-w-100px" style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            if (sortedBy !== "serviceProviderCode") {

                                                                                let finalSort = [...serviceProvider].sort(Sort_by('serviceProviderCode', false, (a: any) => a.toUpperCase()))
                                                                                setServiceProvider(finalSort)
                                                                                setSortedBy("serviceProviderCode")
                                                                            } else if (sortedBy === "serviceProviderCode") {
                                                                                let finalSort = [...serviceProvider].sort(Sort_by('serviceProviderCode', true, (a: any) => a.toUpperCase()))
                                                                                setServiceProvider(finalSort)
                                                                                setSortedBy('')
                                                                            }
                                                                        }}
                                                                    >
                                                                        Service Provider Code
                                                                        <i className={sortedBy === "serviceProviderCode" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                                    </th>
                                                                    <th className="px-3 min-w-100px" style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            if (sortedBy !== "serviceProviderType") {

                                                                                let finalSort = [...serviceProvider].sort(Sort_by('serviceProviderType', false, (a: any) => a.toUpperCase()))
                                                                                setServiceProvider(finalSort)
                                                                                setSortedBy("serviceProviderType")
                                                                            } else if (sortedBy === "serviceProviderType") {
                                                                                let finalSort = [...serviceProvider].sort(Sort_by('serviceProviderType', true, (a: any) => a.toUpperCase()))
                                                                                setServiceProvider(finalSort)
                                                                                setSortedBy('')
                                                                            }
                                                                        }}
                                                                    >Service Provider Type
                                                                        <i className={sortedBy === "serviceProviderType" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                                    </th>
                                                                    <th className="px-3 min-w-100px" style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            if (sortedBy !== "serviceProviderName") {

                                                                                let finalSort = [...serviceProvider].sort(Sort_by('serviceProviderName', false, (a: any) => a.toUpperCase()))
                                                                                setServiceProvider(finalSort)
                                                                                setSortedBy("serviceProviderName")
                                                                            } else if (sortedBy === "serviceProviderName") {
                                                                                let finalSort = [...serviceProvider].sort(Sort_by('serviceProviderName', true, (a: any) => a.toUpperCase()))
                                                                                setServiceProvider(finalSort)
                                                                                setSortedBy('')
                                                                            }
                                                                        }}
                                                                    >
                                                                        Service Provider Name
                                                                        <i className={sortedBy === "serviceProviderName" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                                    </th>
                                                                    <th className="px-3 min-w-100px" style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            if (sortedBy !== "businessName") {

                                                                                let finalSort = [...serviceProvider].sort(Sort_by('businessName', false, (a: any) => a.toUpperCase()))
                                                                                setServiceProvider(finalSort)
                                                                                setSortedBy("businessName")
                                                                            } else if (sortedBy === "businessName") {
                                                                                let finalSort = [...serviceProvider].sort(Sort_by('businessName', true, (a: any) => a.toUpperCase()))
                                                                                setServiceProvider(finalSort)
                                                                                setSortedBy('')
                                                                            }
                                                                        }}
                                                                    >Business Name
                                                                        <i className={sortedBy === "businessName" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                                    </th>
                                                                    <th className="px-3 min-w-100px" style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            if (sortedBy !== "ownerOrgID") {

                                                                                let finalSort = [...serviceProvider].sort(Sort_by('ownerOrgID', false, parseInt))
                                                                                setServiceProvider(finalSort)
                                                                                setSortedBy("ownerOrgID")
                                                                            } else if (sortedBy === "ownerOrgID") {
                                                                                let finalSort = [...serviceProvider].sort(Sort_by('ownerOrgID', true, parseInt))
                                                                                setServiceProvider(finalSort)
                                                                                setSortedBy('')
                                                                            }
                                                                        }}
                                                                    >Owner Organization ID
                                                                        <i className={sortedBy === "ownerOrgID" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                                    </th>
                                                                    <th className="px-3 min-w-100px" style={{ cursor: "pointer" }}>
                                                                        Actions
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='fw-bold text-gray-600'>
                                                                {
                                                                    SPPaginationData.length > 0 ?
                                                                        SPPaginationData.map((data, index) => (
                                                                            <Fragment key={index}>
                                                                                <SPRow data={data} setDeleteSPId={setDeleteSPId} setSelectedSP={setSelectedSP} setShowEditingForm={setShowEditingForm} />
                                                                            </Fragment>
                                                                        )) :
                                                                        <tr>
                                                                            <td align="center" colSpan={12}><span className='text-gray-600 fs-2 fw-bolder'>No Service Provider Have Been Found</span></td>
                                                                        </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="d-flex justify-content-center" ref={PaginationRef}>
                                                        <Pagination
                                                            className='pagination-bar'
                                                            currentPage={currentPage}
                                                            totalCount={serviceProvider.length}
                                                            pageSize={PageSize}
                                                            onPageChange={(page: SetStateAction<number>) => setCurrentPage(page)}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                                <div>
                                    <div className='d-flex justify-content-start pb-6 mx-9'>
                                        <div className='mr-2'>
                                            {/* <ExportCSV
                                                headerProp={headers} filename={"Service_Provider_Details"} data={exportData} title={"Export to CSV"}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                </div>
                <div className='modal fade' id="kt_modal_delete_individual" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered mw-600px mh-100px">
                        <div className="modal-content">
                            <div className="modal-header d-flex justify-content-center">
                                <h2 className="fw-bolder">ARE YOU SURE YOU WANT TO DELETE THIS CLIENT?</h2>
                            </div>
                            <div className='modal-body scroll-y mx-lg-5'>
                                <div className='d-flex justify-content-center'>
                                    <button className='btn btn-danger me-3'
                                        data-bs-dismiss="modal" aria-label="Close" onClick={onDeleteSP}>Confirm
                                    </button>
                                    <button className='btn btn-light me-3' data-bs-dismiss="modal"
                                        aria-label="Close">Discard
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListProvider