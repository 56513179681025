import React, { SetStateAction, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getServiceAuth } from '../../service-authorization/store/actions';
import Edit from './Edit';
import { filterArray } from '../../../../../../_helper/filterArray';
import Pagination from '../../../../../../_helper/paginationHook/Pagination';
import { Sort_by } from '../../../../../../_helper/objectSorter';

const selectAuth = (state: { auth: any }) => state.auth;

const Table = ({ searchValues }: any) => {
    const [servAuths, setServAuths] = useState([])
    const [servStatic, setServStatic] = useState<any[]>([]);
    const [selectedValue, setSelectedValue] = useState({})
    const [showEditingForm, setShowEditingForm] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [sortedBy, setSortedBy] = useState('');
    const [PageSize, setPageSize] = useState(50);
    const auth = useSelector(selectAuth);
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result: any = await dispatch(getServiceAuth({}));
                let tempObj: any = JSON.stringify(result.payload.data)
                tempObj = JSON.parse(tempObj)
                for (let i = 0; i < tempObj.length; i++) {
                    tempObj[i].serviceCodeDesc = tempObj[i].service[0].serviceDescription + '/' + tempObj[i].service[0].serviceCode;
                    tempObj[i].unitMeasure = tempObj[i].service[0].unitMeasure;
                    tempObj[i].unitRate = tempObj[i].service[0].unitRate;
                    tempObj[i].totalBillable = tempObj[i].service[0].totalBillable;
                    tempObj[i].remainingUnits = tempObj[i].service[0].remainingUnits;
                    tempObj[i].dataCollectionMethod = tempObj[i].service[0].dataCollectionMethod;
                    tempObj[i].priorAuth = tempObj[i].service[0].priorAuth;
                    tempObj[i].primaryDiagnosis = tempObj[i].service[0].primaryDiagnosis;
                }
                setServAuths(tempObj)
                setServStatic(tempObj)
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [])

    const PaginationRef = useRef(null)

    useEffect(() => {
        let PaginationElement: any = PaginationRef.current
        if (PaginationElement !== null) {
            let numberOfPages = PaginationElement.getElementsByTagName("li").length - 2
            if (numberOfPages > 0) {
                if (numberOfPages < currentPage) {
                    let tempPage = currentPage - 1
                    setCurrentPage(tempPage)
                }
            }
        }
    }, [servAuths])
    console.log(servStatic)

    const searchFilterBillingData = (value: string) => {
        const sampleFilter = [
            "programName", "endDate",
            "firstName", "serviceCodeDesc",
            "_id", "unitMeasure",
            "fundingSource", "unitRate",
            "beginDate", "totalBillable",
            "remainingUnits", "dataCollectionMethod",
            "priorAuth", "primaryDiagnosis"
        ]
        const filteredArray = filterArray(servStatic, sampleFilter);
        let lowerCaseValue = value.toLowerCase()
        let temparr: any = []
        for (let i = 0; i < filteredArray.length; i++) {
            if (Object.values(filteredArray[i]).filter(item => typeof item === 'string' ? item.toLowerCase().includes(lowerCaseValue) : false).length > 0) {
                temparr.push(filteredArray[i])
            }
        }
        let tempstate: any = []
        for (let i = 0; i < servStatic.length; i++) {
            for (let j = 0; j < temparr.length; j++) {
                if (temparr[j]._id === servStatic[i]._id) {
                    tempstate.push(servStatic[i])
                }
            }
        }
        setServAuths(tempstate)
        setCurrentPage(1)
    }
    // const BDPaginationData = useMemo(() => {
    //     if (servAuths.length <= PageSize) {
    //         setCurrentPage(1)
    //     }
    //     let tempdata = servAuths
    //     const firstPageIndex = (currentPage - 1) * PageSize;
    //     const lastPageIndex = firstPageIndex + PageSize;
    //     return tempdata.slice(firstPageIndex, lastPageIndex);
    // }, [currentPage, servAuths, PageSize, servStatic])
    return (
        <div>
            <div className='mb-5 col-mb-10' id='kt_content'>
                {
                    showEditingForm ?
                        <Edit selectedValue={selectedValue} setShowEditingForm={setShowEditingForm} />
                        :
                        <div className='post d-flex flex-column-fluid' id='kt_post'>
                            <div className='container-xxl card'>
                                <div id='kt_content_container' className='mt-4'>
                                    <div className='card-body'>
                                        <h1 className='mt-4'>Search Billing Data</h1>
                                        <div className='w-100 pt-6 pb-3'>
                                            <div className="d-flex w-100 justify-content-between">
                                                <div>
                                                    <div className='d-flex align-items-center position-relative overflow-auto my-1'>
                                                        <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                                                            <svg
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                width={24}
                                                                height={24}
                                                                viewBox='0 0 24 24'
                                                                fill='none'
                                                            >
                                                                <rect
                                                                    opacity='0.5'
                                                                    x='17.0365'
                                                                    y='15.1223'
                                                                    width='8.15546'
                                                                    height={2}
                                                                    rx={1}
                                                                    transform='rotate(45 17.0365 15.1223)'
                                                                    fill='black'
                                                                />
                                                                <path
                                                                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                                                    fill='black'
                                                                />
                                                            </svg>
                                                        </span>
                                                        <input
                                                            type='text'
                                                            data-kt-customer-table-filter='search'
                                                            className='form-control form-control-solid w-250px ps-15'
                                                            onChange={(e) => searchFilterBillingData(e.target.value)}
                                                            placeholder='Search Billing Data'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div>
                                                        <p style={{ "margin": "revert" }}>Items Per Page</p>
                                                    </div>
                                                    <div>
                                                        <select className='form-control form-control-solid form-select'
                                                            onChange={(e) => setPageSize(parseInt(e.target.value))}>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                            <option value={150}>150</option>
                                                            <option value={200}>200</option>
                                                            <option value={300}>300</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='stickhyHeaderTable table-responsive' style={{ padding: "0rem 0rem" }}>
                                            <table
                                                className='table table-light table-rounded table-striped border border-2 table-hover fs-6 gy-5 mt-5'
                                                id='kt_individual_table'
                                            >
                                                <thead>
                                                    <tr className='text-start table-dark text-gray-400 table-rounded fw-bolder fs-7 text-uppercase gs-0'>
                                                        <th className="px-3 min-w-100px" style={{ cursor: "pointer" }}
                                                        >Action
                                                        </th>
                                                        <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                            if (sortedBy !== "programName") {
                                                                let finalSort = servAuths.sort(Sort_by('programName', false, (a: any) => a.toUpperCase()))
                                                                setServAuths(finalSort)
                                                                setSortedBy("programName")
                                                            } else if (sortedBy === "programName") {
                                                                let finalSort = servAuths.sort(Sort_by('programName', true, (a: any) => a.toUpperCase()))
                                                                setServAuths(finalSort)
                                                                setSortedBy('')
                                                            }
                                                        }}>
                                                            Program
                                                            <i className={sortedBy === "programName" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                        </th>
                                                        <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                            if (sortedBy !== "firstName") {
                                                                let finalSort = servAuths.sort(Sort_by('firstName', false, (a: any) => a.toUpperCase()))
                                                                setServAuths(finalSort)
                                                                setSortedBy("firstName")
                                                            } else if (sortedBy === "firstName") {
                                                                let finalSort = servAuths.sort(Sort_by('firstName', true, (a: any) => a.toUpperCase()))
                                                                setServAuths(finalSort)
                                                                setSortedBy('')
                                                            }
                                                        }}>
                                                            Client Name
                                                            <i className={sortedBy === "firstName" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                        </th>
                                                        <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                            if (sortedBy !== "fundingSource") {
                                                                let finalSort = servAuths.sort(Sort_by('fundingSource', false, (a: any) => a.toUpperCase()))
                                                                setServAuths(finalSort)
                                                                setSortedBy("fundingSource")
                                                            } else if (sortedBy === "fundingSource") {
                                                                let finalSort = servAuths.sort(Sort_by('fundingSource', true, (a: any) => a.toUpperCase()))
                                                                setServAuths(finalSort)
                                                                setSortedBy('')
                                                            }
                                                        }}>
                                                            Funding Source
                                                            <i className={sortedBy === "fundingSource" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                        </th>
                                                        <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                            if (sortedBy !== "beginDate") {
                                                                let finalSort = servAuths.sort(Sort_by('beginDate', false, parseInt))
                                                                setServAuths(finalSort)
                                                                setSortedBy("beginDate")
                                                            } else if (sortedBy === "beginDate") {
                                                                let finalSort = servAuths.sort(Sort_by('beginDate', true, parseInt))
                                                                setServAuths(finalSort)
                                                                setSortedBy('')
                                                            }
                                                        }}>
                                                            Begin Date
                                                            <i className={sortedBy === "beginDate" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                        </th>
                                                        <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                            if (sortedBy !== "endDate") {
                                                                let finalSort = servAuths.sort(Sort_by('endDate', false, parseInt))
                                                                setServAuths(finalSort)
                                                                setSortedBy("endDate")
                                                            } else if (sortedBy === "endDate") {
                                                                let finalSort = servAuths.sort(Sort_by('endDate', true, parseInt))
                                                                setServAuths(finalSort)
                                                                setSortedBy('')
                                                            }
                                                        }}>
                                                            End Date
                                                            <i className={sortedBy === "endDate" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                        </th>
                                                        <th className="min-w-250px" style={{ cursor: "pointer" }} onClick={() => {
                                                            if (sortedBy !== "serviceCodeDesc") {
                                                                let finalSort = servAuths.sort(Sort_by('serviceCodeDesc', false, (a: any) => a.toUpperCase()))
                                                                setServAuths(finalSort)
                                                                setSortedBy("serviceCodeDesc")
                                                            } else if (sortedBy === "serviceCodeDesc") {
                                                                let finalSort = servAuths.sort(Sort_by('serviceCodeDesc', true, (a: any) => a.toUpperCase()))
                                                                setServAuths(finalSort)
                                                                setSortedBy('')
                                                            }
                                                        }}>
                                                            Service Code/Description
                                                            <i className={sortedBy === "serviceCodeDesc" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                        </th>
                                                        <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                            if (sortedBy !== "unitMeasure") {
                                                                let finalSort = servAuths.sort(Sort_by('unitMeasure', false, parseInt))
                                                                setServAuths(finalSort)
                                                                setSortedBy("unitMeasure")
                                                            } else if (sortedBy === "unitMeasure") {
                                                                let finalSort = servAuths.sort(Sort_by('unitMeasure', true, parseInt))
                                                                setServAuths(finalSort)
                                                                setSortedBy('')
                                                            }
                                                        }}>
                                                            Unit of Measure
                                                            <i className={sortedBy === "unitMeasure" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                        </th>
                                                        <th className="min-w-100px" style={{ cursor: "pointer" }} onClick={() => {
                                                            if (sortedBy !== "unitRate") {
                                                                let finalSort = servAuths.sort(Sort_by('unitRate', false, parseInt))
                                                                setServAuths(finalSort)
                                                                setSortedBy("unitRate")
                                                            } else if (sortedBy === "unitRate") {
                                                                let finalSort = servAuths.sort(Sort_by('unitRate', true, parseInt))
                                                                setServAuths(finalSort)
                                                                setSortedBy('')
                                                            }
                                                        }}>
                                                            Unit Rate
                                                            <i className={sortedBy === "unitRate" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                        </th>
                                                        <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                            if (sortedBy !== "totalBillable") {
                                                                let finalSort = servAuths.sort(Sort_by('totalBillable', false, parseInt))
                                                                setServAuths(finalSort)
                                                                setSortedBy("totalBillable")
                                                            } else if (sortedBy === "totalBillable") {
                                                                let finalSort = servAuths.sort(Sort_by('totalBillable', true, parseInt))
                                                                setServAuths(finalSort)
                                                                setSortedBy('')
                                                            }
                                                        }}>
                                                            Total Units
                                                            <i className={sortedBy === "totalBillable" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                        </th>
                                                        <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                            if (sortedBy !== "remainingUnits") {
                                                                let finalSort = servAuths.sort(Sort_by('remainingUnits', false, parseInt))
                                                                setServAuths(finalSort)
                                                                setSortedBy("remainingUnits")
                                                            } else if (sortedBy === "remainingUnits") {
                                                                let finalSort = servAuths.sort(Sort_by('remainingUnits', true, parseInt))
                                                                setServAuths(finalSort)
                                                                setSortedBy('')
                                                            }
                                                        }}>
                                                            Remaining Units
                                                            <i className={sortedBy === "remainingUnits" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                        </th>
                                                        <th className="min-w-200px" style={{ cursor: "pointer" }} onClick={() => {
                                                            if (sortedBy !== "dataCollectionMethod") {
                                                                let finalSort = servAuths.sort(Sort_by('dataCollectionMethod', false, (a: any) => a.toUpperCase()))
                                                                setServAuths(finalSort)
                                                                setSortedBy("dataCollectionMethod")
                                                            } else if (sortedBy === "dataCollectionMethod") {
                                                                let finalSort = servAuths.sort(Sort_by('dataCollectionMethod', true, (a: any) => a.toUpperCase()))
                                                                setServAuths(finalSort)
                                                                setSortedBy('')
                                                            }
                                                        }}>
                                                            Data Collection Method
                                                            <i className={sortedBy === "dataCollectionMethod" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                        </th>
                                                        <th className="min-w-150px" style={{ cursor: "pointer" }} onClick={() => {
                                                            if (sortedBy !== "priorAuth") {
                                                                let finalSort = servAuths.sort(Sort_by('priorAuth', false, (a: any) => a.toUpperCase()))
                                                                setServAuths(finalSort)
                                                                setSortedBy("priorAuth")
                                                            } else if (sortedBy === "priorAuth") {
                                                                let finalSort = servAuths.sort(Sort_by('priorAuth', true, (a: any) => a.toUpperCase()))
                                                                setServAuths(finalSort)
                                                                setSortedBy('')
                                                            }
                                                        }}>
                                                            Prior Auth
                                                            <i className={sortedBy === "priorAuth" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                        </th>
                                                        <th className="min-w-250px" style={{ cursor: "pointer" }} onClick={() => {
                                                            if (sortedBy !== "primaryDiagnosis") {
                                                                let finalSort = servAuths.sort(Sort_by('primaryDiagnosis', false, (a: any) => a.toUpperCase()))
                                                                setServAuths(finalSort)
                                                                setSortedBy("primaryDiagnosis")
                                                            } else if (sortedBy === "primaryDiagnosis") {
                                                                let finalSort = servAuths.sort(Sort_by('primaryDiagnosis', true, (a: any) => a.toUpperCase()))
                                                                setServAuths(finalSort)
                                                                setSortedBy('')
                                                            }
                                                        }}>
                                                            ICD-10 Code
                                                            <i className={sortedBy === "primaryDiagnosis" ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"} />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className='fw-bold text-gray-600 '>
                                                    {/* {
                                                        servAuths.map((serviceAuth: any, index) => (
                                                            serviceAuth.clientID === searchValues.client && (
                                                                <Fragment key={index}>
                                                                    <tr>
                                                                        <td>
                                                                            <span className="btn btn-icon btn-bg-light text-hover-primary btn-active-color-primary btn-sm me-1"
                                                                                onClick={() => {
                                                                                    setSelectedValue(serviceAuth)
                                                                                    setShowEditingForm(true)
                                                                                }}
                                                                            >
                                                                                <span className='svg-icon svg-icon-3'>
                                                                                    <svg
                                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                                        width={24}
                                                                                        height={24}
                                                                                        viewBox='0 0 24 24'
                                                                                        fill='none'
                                                                                    >
                                                                                        <path
                                                                                            opacity='0.3'
                                                                                            d='M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z'
                                                                                            fill='black'
                                                                                        />
                                                                                        <path
                                                                                            d='M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z'
                                                                                            fill='black'
                                                                                        />
                                                                                    </svg>
                                                                                </span>
                                                                            </span>
                                                                            <button className='btn btn-icon text-hover-primary btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                                data-bs-toggle="modal" data-bs-target="#kt_modal_delete_SA"
                                                                                onClick={() => {
                                                                                    // setDeleteSA(serviceAuth._id)
                                                                                }}
                                                                                style={{ cursor: "pointer" }}
                                                                            >
                                                                                <span className='svg-icon svg-icon-3'>
                                                                                    <svg
                                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                                        width={24}
                                                                                        height={24}
                                                                                        viewBox='0 0 24 24'
                                                                                        fill='none'
                                                                                    >
                                                                                        <path
                                                                                            d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                                                                                            fill='black'
                                                                                        />
                                                                                        <path
                                                                                            opacity='0.5'
                                                                                            d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                                                                                            fill='black'
                                                                                        />
                                                                                        <path
                                                                                            opacity='0.5'
                                                                                            d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                                                                                            fill='black'
                                                                                        />
                                                                                    </svg>
                                                                                </span>
                                                                            </button>
                                                                        </td>

                                                                        <td>
                                                                            <span className="text-gray-800 text-hover-primary mb-1">
                                                                                {serviceAuth.programName}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="text-gray-800 text-hover-primary mb-1">
                                                                                {serviceAuth.firstName} {serviceAuth.lastName}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="text-gray-800 text-hover-primary mb-1">
                                                                                {serviceAuth.fundingSource}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="text-gray-800 text-hover-primary mb-1">
                                                                                {serviceAuth.beginDate}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="text-gray-800 text-hover-primary mb-1">
                                                                                {serviceAuth.endDate}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="text-gray-800 text-hover-primary mb-1">
                                                                                {serviceAuth.serviceCodeDesc}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="text-gray-800 text-hover-primary mb-1">
                                                                                {serviceAuth.unitMeasure}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="text-gray-800 text-hover-primary mb-1">
                                                                                {serviceAuth.unitRate}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="text-gray-800 text-hover-primary mb-1">
                                                                                {serviceAuth.totalBillable}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="text-gray-800 text-hover-primary mb-1">
                                                                                {serviceAuth.remainingUnits}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="text-gray-800 text-hover-primary mb-1">
                                                                                {serviceAuth.dataCollectionMethod}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="text-gray-800 text-hover-primary mb-1">
                                                                                {serviceAuth.priorAuth}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="text-gray-800 text-hover-primary mb-1">
                                                                                {serviceAuth.primaryDiagnosis}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        ))
                                                    } */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className='my-4'>
                                    <div className="d-flex justify-content-center" ref={PaginationRef}>
                                        <Pagination
                                            className='pagination-bar'
                                            currentPage={currentPage}
                                            totalCount={servAuths.length}
                                            pageSize={PageSize}
                                            onPageChange={(page: SetStateAction<number>) => setCurrentPage(page)}
                                        />
                                    </div>
                                    {/* <div className='d-flex justify-content-start pb-6'>
                                        <div className='mr-2'>
                                            <ExportCSV
                                                headerProp={headers} filename={"Clients_Details"} data={exportData} title={"Export to CSV"}
                                            />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                }

            </div>
            <div className='modal fade' id="kt_modal_delete_SA" tabIndex={-1} aria-hidden="true">
                {/*begin::Modal dialog*/}
                <div className="modal-dialog modal-dialog-centered mw-600px mh-100px">
                    {/*begin::Modal content*/}
                    <div className="modal-content">
                        {/*begin::Modal header*/}
                        <div className="modal-header d-flex justify-content-center">
                            {/*begin::Modal title*/}
                            <h2 className="fw-bolder">ARE YOU SURE YOU WANT TO DELETE THIS SERVICE AUTH ENTRY?</h2>
                            {/*end::Modal title*/}
                        </div>
                        {/*end::Modal header*/}
                        {/*begin::Modal body*/}
                        <div className='modal-body scroll-y mx-lg-5'>
                            <div className='d-flex justify-content-center'>
                                <button className='btn btn-danger me-3'
                                    data-bs-dismiss="modal" aria-label="Close">Confirm
                                </button>
                                <button className='btn btn-light me-3' data-bs-dismiss="modal"
                                    aria-label="Close">Discard
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Table