import React, {useEffect, useState} from 'react'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import TableButton from '../../../../../reusable-components/tables/TableButton'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import {
  caregiverDocumentscolumns,
  caregiverDocumentscolumnsColumnDataMapping,
  caregiverDocumentscolumnsSortingColumn,
} from '../../../CAREGIVER_DOCUMENTS_CONSTANTS'
import axios from 'axios'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { permissions } from '../../../../../../_constants/permissions'
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper'
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes'
import { RootState } from '../../../../../../setup'
const selectAuth = (state: RootState) => state.auth

export default function CaregiverDocumentsList(props) {
  const [filteredData, setFilteredData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [queryData, setQueryData] = useState<QueryData>({
      pageNumber: 1,
      pageSize: 5,
      currentPage: 1,
      totalCounts: 1,
      sortBy: '_id',
      searchTerm: '',
      sortDir: 'desc'
  })
  const history = useHistory()
  const auth = useSelector(selectAuth)
  const userPermissions = auth.user.roleId;

  useEffect(() => {
    try{
      const id = props.id
      const fetchData = async (page: number, pageSize : number, sortBy: string, sortDir: string, searchTerm : string) => {
        const response = await axios.get(`caregivers/getById/${id}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${searchTerm}`)
        const { data, currentPage, totalCounts } = response.data;
            setFilteredData(data)
            setQueryData((prevState)=> {
                return {
                    ...prevState,
                    currentPage,
                    totalCounts,
                }
            })
      }
      fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm)
    } catch(err) {
      console.error(err)
      toast.error(err)
    }
   
   
  }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm, props.id])

  const onAddButtonClick = () => {
    history.push('/caregivers/profile/documents/new/' + props.id)
  }

  const onDelete = (row) => {
    axios
      .patch('/caregivers/deleteDocument/', {
        caregiverId: props.id,
        docId: row._id,
        filePath: row.filePath,
      })
      .then((res) => {
        if (res.status == 201) {
          toast.success('Document deleted successfully')
          setFilteredData((prevState) => prevState.filter((doc) => doc._id !== row._id))
        }
      })
      .catch((err) => {
        toast.error('Failed to delete document')
      })
  }
  // const id = props.id
  return (
    <div className='card'>
      <div className='card-body'>
        <TableTitle title='Certificates'></TableTitle>
        <TableWrapper 
            searchField={
        <TableSearchBar
          label='Search'
          variant='outlined'
          size='small'
          value={searchTerm}
          setQueryData={setQueryData}
          setSearchTerm={setSearchTerm}
          margin='normal'
          inputAdornmentPosition='start'
        ></TableSearchBar>
            }
            tableButtons={
            !!hasPermission(userPermissions, permissions.care_giver_profile_documents_create) &&
            <TableButton
                variant={'contained'}
                color={'primary'}
                title='Add New'
                onClick={onAddButtonClick}
       />
            }
            dynamicTable={
            <DynamicTable
                canDelete={hasPermission(userPermissions, permissions.care_giver_profile_documents_delete)}
                onDelete={onDelete}
                columns={caregiverDocumentscolumns}
                data={filteredData}
                columnDataMapping={caregiverDocumentscolumnsColumnDataMapping}
                sortingColumns={caregiverDocumentscolumnsSortingColumn}
                setQueryData={setQueryData}
                queryData={queryData}
            />
            }
        />    
      </div>
    </div>
  )
}
