import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOneLeaveRule, updateLeaveRule } from '../store/action';
import { TextField, Button, Grid, Radio, RadioGroup, FormControlLabel, Autocomplete, Box } from '@mui/material';
import TableTitle from '../../../../../reusable-components/tables/TableTitle';
import { toast } from 'react-toastify';
import * as validator from '../../../../../reusable-components/Validation/Validations'
const selectAuth = (state) => state.auth;

const LREdit = () => {
    const parameters = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const auth = useSelector(selectAuth);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formValues, setFormValues] = useState({
        name: '',
        monthlyLeaves: '',
        yearlyLeaves: '',
        yearlyConsecutiveLeaves: '',
        calendarYear: '',
        serviceRate: '',
        unitRate: '',
        percentage: '',
        billingData: '',
        billingDataForNon: '',
        noBillingData: '',
        revenueCode: '',
    })

    useEffect(() => {
        const fetchData = async () => {
            if (parameters.id) {
                const response = await dispatch(getOneLeaveRule(parameters.id))
                const tempLR = response.payload.data
                const tempData = {
                    _id: tempLR._id,
                    name: tempLR.name,
                    monthlyLeaves: tempLR.monthlyLeaves,
                    yearlyLeaves: tempLR.yearlyLeaves,
                    yearlyConsecutiveLeaves: tempLR.yearlyConsecutiveLeaves,
                    calendarYear: tempLR.calendarYear,
                    serviceRate: tempLR.serviceRate,
                    unitRate: tempLR.unitRate,
                    percentage: tempLR.percentage,
                    billingData: tempLR.billingData,
                    billingDataForNon: tempLR.billingDataForNon,
                    noBillingData: tempLR.noBillingData,
                    revenueCode: tempLR.revenueCode,
                };
                setFormValues(tempData);
            }
        }
        fetchData()

    }, [])

    const handleFormChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: checked
            }));
        } else {
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (values) => {
        setIsSubmitted(true);
        const validations = [
            { field: 'name', validation: validator.NAME_VALIDATION }
        ]
        for (const { field, validation } of validations) {
            if (values[field] !== undefined && values[field] !== null && values[field] !== '') {
                if (!validation.pattern.test(values[field])) {
                    toast.error('Form Validation Failed');
                    return;
                }
            }
        }

        if (values.serviceRate === 'Define Rate Below') {
            values.unitRate = values.unitRate
        } else if (values.serviceRate === 'Percentage of Service Rate') {
            values.percentage = values.percentage
        } else {
            values.percentage = ''
            values.unitRate = ''
        }
        const response = await dispatch(updateLeaveRule(values))
        if (response.payload.status == 200 && response.payload.success === true) {
            toast.success('Leave Rule Updated Successfully')
            history.push('/admin/billing/leave-rule/list')
        } else {
            toast.error('Error while updating Leave Rule')
        }
    }

    return (
        <div className='card'>
            <form className='card-body' onSubmit={(e) => {
                e.preventDefault()
                handleSubmit(formValues)
            }}>
                <TableTitle title='Edit Leave Rules'></TableTitle>
                <Grid container sx={{ marginTop: '1rem' }} spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="name"
                            label="Rule Name"
                            size='small'
                            value={formValues.name}
                            required={true}
                            onChange={(e) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        name: e.target.value
                                    }
                                })
                            }}
                            error={Boolean(formValues.name && !validator.NAME_VALIDATION.pattern.test(formValues.name))}
                            helperText={formValues.name && !validator.NAME_VALIDATION.pattern.test(formValues.name) ? validator.NAME_VALIDATION.message : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="monthlyLeaves"
                            label="Monthly Maximum Leave Days"
                            type="number"
                            size='small'
                            value={formValues.monthlyLeaves}
                            onChange={(e) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        monthlyLeaves: e.target.value
                                    }
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="yearlyLeaves"
                            label="Yearly Maximum Leave Days"
                            type="number"
                            size='small'
                            value={formValues.yearlyLeaves}
                            onChange={(e) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        yearlyLeaves: e.target.value
                                    }
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="yearlyConsecutiveLeaves"
                            label="Maximum Consecutive Leave Days Per Year"
                            type="number"
                            size='small'
                            value={formValues.yearlyConsecutiveLeaves}
                            onChange={(e) => {
                                setFormValues((prevState) => {
                                    return {
                                        ...prevState,
                                        yearlyConsecutiveLeaves: e.target.value
                                    }
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <label className='text-danger mx-1'>*</label>
                        <label className=' fw-bold fs-6 mb-2'>Calculate Leave Days using</label>
                        <RadioGroup
                            name="calendarYear"
                            aria-label="calendarYear"
                            value={formValues.calendarYear || ''}
                            onChange={handleFormChange}
                        >
                            <div className='mx-8'>
                                <FormControlLabel
                                    value='Calendar Year'
                                    control={<Radio className='form-check-input' />}
                                    label='Calendar Year'
                                />
                            </div>
                            <div className='mx-8'>
                                <FormControlLabel
                                    value='Fiscal Year'
                                    control={<Radio className='form-check-input' />}
                                    label='Fiscal Year'
                                />
                            </div>
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <label className='text-danger mx-1'>*</label>
                        <label className=' fw-bold fs-6 mb-2'>Rate Source</label>
                        <RadioGroup
                            name="serviceRate"
                            aria-label="serviceRate"
                            value={formValues.serviceRate || ''}
                            onChange={handleFormChange}
                        >
                            <div className='mx-8'>
                                <FormControlLabel
                                    value='Service Rate'
                                    control={<Radio className='form-check-input' />}
                                    label='Service Rate'
                                />
                                {formValues.serviceRate === 'Service Rate' && (
                                    <div
                                        className='px-2 rounded'
                                        style={{ backgroundColor: "LightBlue" }}
                                    >
                                        <p style={{ marginBottom: '0.4rem' }}>Note: 'Default Unit Rate ($)' specified in the Service form will be used to generate billing data.</p>
                                    </div>
                                )}
                            </div>

                            <div className='mx-8'>
                                <FormControlLabel
                                    value='Unit Rate History'
                                    control={<Radio className='form-check-input' />}
                                    label='Unit Rate History'
                                />
                                {formValues.serviceRate === 'Unit Rate History' && (
                                    <div
                                        className='px-2 rounded'
                                        style={{ backgroundColor: "LightBlue" }}
                                    >
                                        <p style={{ marginBottom: '0.4rem' }}>
                                            Note: Rate History defined in Service Form or Service Description/Code will be used to generate billing data. If no Rate History is found, then 'Default Unit Rate ($)' of the Service form will be used.
                                        </p>
                                    </div>
                                )}
                            </div>

                            <div className='mx-8'>
                                <FormControlLabel
                                    value='Define Rate Below'
                                    control={<Radio className='form-check-input' />}
                                    label='Define Rate Below'
                                />
                                {formValues.serviceRate === 'Define Rate Below' ?
                                    <div
                                        className='px-2 rounded'
                                        style={{ backgroundColor: "LightBlue" }}>
                                        <p style={{ marginBottom: '0.4rem' }}>
                                            Note: The 'Unit Rate ($)' defined below will be used to generate billing data.
                                        </p>
                                    </div>
                                    : ""
                                }
                            </div>

                            <div className='mx-8'>
                                <FormControlLabel
                                    value='Percentage of Service Rate'
                                    control={<Radio className='form-check-input' />}
                                    label='Percentage of Service Rate'
                                />
                                {formValues.serviceRate === 'Percentage of Service Rate' ?
                                    <div
                                        className='px-2 rounded'
                                        style={{ backgroundColor: "LightBlue" }}>
                                        <p style={{ marginBottom: '0.4rem' }}>
                                            Note: 'Percentage (%)' of the 'Default Unit Rate ($)' from the Service form that will be used to generate billing data.
                                        </p>
                                    </div>
                                    : ""
                                }
                            </div>

                            <div className='mx-8'>
                                <FormControlLabel
                                    value='Define Rate Service'
                                    control={<Radio className='form-check-input' />}
                                    label='Define Rate In Service'
                                />
                                {formValues.serviceRate === 'Define Rate Service' ?
                                    <div
                                        className='px-2 rounded'
                                        style={{ backgroundColor: "LightBlue" }}>
                                        <p style={{ marginBottom: '0.4rem' }}>
                                            Note: New rate for this leave rule can be defined in 'Unit Rate for ICF Leave Rule' section of the Service Form.
                                        </p>
                                    </div>
                                    : ""
                                }
                            </div>
                        </RadioGroup>
                    </Grid>

                    {formValues.serviceRate === 'Define Rate Below' && (
                        <Grid item xs={12} sm={6}>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 '>
                                <label className='text-danger mx-1'>*</label>
                                <label className=' fw-bold fs-62'>Unit Rate ($)</label>
                                <TextField
                                    fullWidth
                                    type='number'
                                    name='unitRate'
                                    size='small'
                                    className='mx-6'
                                    value={formValues.unitRate}
                                    onChange={(e) => {
                                        setFormValues((prevState) => {
                                            return {
                                                ...prevState,
                                                unitRate: e.target.value,
                                                percentage: ''
                                            }
                                        })
                                    }}
                                // error={formik.touched.unitRate && Boolean(formik.errors.unitRate)}
                                // helperText={formik.touched.unitRate && formik.errors.unitRate}
                                />
                            </div>
                        </Grid>
                    )}

                    {formValues.serviceRate === 'Percentage of Service Rate' && (
                        <Grid item xs={12} sm={6}>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                                <label className='text-danger mx-1'>*</label>
                                <label className=' fw-bold fs-6'>Percentage (%)</label>
                                <TextField
                                    fullWidth
                                    type='number'
                                    name='percentage'
                                    size='small'
                                    className='mx-6'
                                    value={formValues.percentage}
                                    onChange={(e) => {
                                        setFormValues((prevState) => {
                                            return {
                                                ...prevState,
                                                percentage: e.target.value,
                                                unitRate: ''
                                            }
                                        })
                                    }}
                                // error={formik.touched.percentage && Boolean(formik.errors.percentage)}
                                // helperText={formik.touched.percentage && formik.errors.percentage}
                                />
                            </div>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                            <label className='text-danger mx-1'>*</label>
                            <label className=' fw-bold fs-6'>Billing Data Grouping Type</label>
                            <RadioGroup
                                name="billingData"
                                aria-label="billingData"
                                value={formValues.billingData || ''}
                                onChange={handleFormChange}
                            >
                                <div className='mx-8 '>
                                    <FormControlLabel
                                        value='Per Day'
                                        control={<Radio className='form-check-input' />}
                                        label='Per Day'
                                    />
                                    {formValues.billingData === 'Per Day' ?
                                        <div
                                            className='px-2 rounded'
                                            style={{ backgroundColor: "LightBlue" }}>
                                            <p style={{ marginBottom: '0.4rem' }}>
                                                Note: Billing Data will be generated for each day.
                                            </p>
                                        </div>
                                        : ""
                                    }
                                </div>

                                <div className='mx-8'>
                                    <FormControlLabel
                                        value='Group Per Continuous Option'
                                        control={<Radio className='form-check-input' />}
                                        label='Group Per Continuous Option'
                                    />
                                    {formValues.billingData === 'Group Per Continuous Option' ?
                                        <div
                                            className='px-2 rounded'
                                            style={{ backgroundColor: "LightBlue" }}>
                                            <p style={{ marginBottom: '0.4rem' }}>
                                                Note: Consecutive Attendance data having the same option will be grouped in a single billing data.
                                            </p>
                                        </div>
                                        : ""
                                    }
                                </div>

                                <div className='mx-8'>
                                    <FormControlLabel
                                        value='Group Per Option'
                                        control={<Radio className='form-check-input' />}
                                        label='Group Per Option'
                                    />
                                    {formValues.billingData === 'Group Per Option' ?
                                        <div
                                            className='px-2 rounded'
                                            style={{ backgroundColor: "LightBlue" }}>
                                            <p style={{ marginBottom: '0.4rem' }}>
                                                Note: Attendance data within the searched date range will be grouped by Attendance option in a single billing data.
                                            </p>
                                        </div>
                                        : ""
                                    }
                                </div>

                                <div className='mx-8'>
                                    <FormControlLabel
                                        value='Group Per Revenue Code'
                                        control={<Radio className='form-check-input' />}
                                        label='Group Per Revenue Code'
                                    />
                                    {formValues.billingData === 'Group Per Revenue Code' ?
                                        <div
                                            className='px-2 rounded'
                                            style={{ backgroundColor: "LightBlue" }}>
                                            <p style={{ marginBottom: '0.4rem' }}>
                                                Note: Attendance data within the searched date range will be grouped by Revenue Code in a single billing data.
                                            </p>
                                        </div>
                                        : ""
                                    }
                                </div>

                                <div className='mx-8'>
                                    <FormControlLabel
                                        value='Group Per Continuous Revenue Code'
                                        control={<Radio className='form-check-input' />}
                                        label='Group Per Continuous Revenue Code'
                                    />
                                    {formValues.billingData === 'Group Per Continuous Revenue Code' ?
                                        <div
                                            className='px-2 rounded'
                                            style={{ backgroundColor: "LightBlue" }}>
                                            <p style={{ marginBottom: '0.4rem' }}>
                                                Note: Consecutive Attendance data having the same Revenue Code will be grouped in a single billing data.
                                            </p>
                                        </div>
                                        : ""
                                    }
                                </div>
                            </RadioGroup>
                        </div>
                    </Grid>


                    <Grid item xs={12}>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                            <label className='text-danger mx-1'>*</label>
                            <label className='fw-bold fs-6 mb-2'>Billing for Non Covered Days</label>
                            <RadioGroup
                                name="noBillingData"
                                aria-label="noBillingData"
                                value={formValues.noBillingData || ''}
                                onChange={handleFormChange}
                            >
                                <div className='mx-8'>
                                    <FormControlLabel
                                        value='No Billing Data'
                                        control={<Radio className='form-check-input' />}
                                        label='No Billing Data'
                                        onClick={() => {
                                            setFormValues((prevState) => {
                                                return {
                                                    ...prevState,
                                                    revenueCode: '',
                                                    billingDataForNon: '',
                                                }
                                            })
                                        }}
                                    />
                                </div>

                                <div className='mx-8'>
                                    <FormControlLabel
                                        value='Billing Data with non-billable units'
                                        control={<Radio className='form-check-input' />}
                                        label='Billing Data with non-billable units'
                                        onClick={() => {
                                            setFormValues((prevState) => {
                                                return {
                                                    ...prevState,
                                                    revenueCode: '',
                                                }
                                            })
                                        }}
                                    />
                                </div>

                                <div className='mx-8'>
                                    <FormControlLabel
                                        value='Billing Data with non-billable units and Revenue Code'
                                        control={<Radio className='form-check-input' />}
                                        label='Billing Data with non-billable units and Revenue Code'
                                    />
                                </div>

                                <div className='mx-8'>
                                    <FormControlLabel
                                        value='Billing Data with billable units and Revenue Code'
                                        control={<Radio className='form-check-input' />}
                                        label='Billing Data with billable units and Revenue Code'
                                    />
                                </div>
                            </RadioGroup>
                        </div>
                    </Grid>

                    {formValues.noBillingData === 'Billing Data with non-billable units and Revenue Code' ||
                        formValues.noBillingData === 'Billing Data with billable units and Revenue Code' ? (
                        <Grid item xs={12}>
                            <label className='fw-bold fs-6 mx-2 mb-2'>Revenue Code</label>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                                <Autocomplete
                                    id="revenueCode"
                                    options={['Revenue Code']}
                                    size='small'
                                    freeSolo={true}
                                    className='mx-6'
                                    forcePopupIcon={true}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    value={formValues.revenueCode || ''}
                                    onChange={(event, newValue) => {
                                        handleFormChange({
                                            target: {
                                                name: "revenueCode",
                                                value: newValue ? newValue : ''
                                            }
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            autoComplete="off"
                                            name="revenueCode"
                                            label="- Select Revenue Code -"
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                    ) : null}

                    {formValues.noBillingData === 'Billing Data with non-billable units' ||
                        formValues.noBillingData === 'Billing Data with non-billable units and Revenue Code' ||
                        formValues.noBillingData === 'Billing Data with billable units and Revenue Code' ? (
                        <Grid item xs={12}>
                            <label className='fw-bold fs-6 mx-2 mb-2'>Billing Data Grouping for Non Covered Days</label>
                            <RadioGroup
                                aria-label="billingDataForNon"
                                name="billingDataForNon"
                                value={formValues.billingDataForNon || ''}
                                onChange={handleFormChange}
                            >
                                <div className='mx-8'>
                                    <FormControlLabel
                                        value="Per Day"
                                        control={<Radio className='form-check-input' />}
                                        label="Per Day"
                                    />
                                    {formValues.billingDataForNon === 'Per Day' ? (
                                        <div
                                            className='px-2 rounded'
                                            style={{ backgroundColor: "LightBlue" }}
                                        >
                                            <p style={{ marginBottom: '0.4rem' }}>
                                                Note: Billing Data will be generated for each day.
                                            </p>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className='mx-8'>
                                    <FormControlLabel
                                        value="Group Per Continuous Option"
                                        control={<Radio className='form-check-input' />}
                                        label="Group Per Continuous Option"
                                    />
                                    {formValues.billingDataForNon === 'Group Per Continuous Option' ? (
                                        <div
                                            className='px-2 rounded'
                                            style={{ backgroundColor: "LightBlue" }}
                                        >
                                            <p style={{ marginBottom: '0.4rem' }}>
                                                Note: Consecutive Attendance data having the same option will be grouped in a single billing data.
                                            </p>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className='mx-8'>
                                    <FormControlLabel
                                        value="Group Per Option"
                                        control={<Radio className='form-check-input' />}
                                        label="Group Per Option"
                                    />
                                    {formValues.billingDataForNon === 'Group Per Option' ? (
                                        <div
                                            className='px-2 rounded'
                                            style={{ backgroundColor: "LightBlue" }}
                                        >
                                            <p style={{ marginBottom: '0.4rem' }}>
                                                Note: Attendance data within the searched date range will be grouped by Attendance option in a single billing data.
                                            </p>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </RadioGroup>
                        </Grid>
                    ) : null}
                </Grid>

                <Box sx={{ marginTop: '24px', }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        borderBottomLeftRadius: '4px',
                        borderBottomRightRadius: '4px',
                        padding: '6px 0',
                        paddingRight: '2.5rem',
                    }} >
                        <Button
                            type='button'
                            variant='outlined'
                            sx={{
                                color: '#000',
                                backgroundColor: '#f2f2f2',
                                '&:hover': {
                                    backgroundColor: '#f2f2f2',
                                },
                            }}
                            onClick={() => history.push('/admin/billing/leave-rule/list')}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            sx={{
                                marginLeft: '10px',
                                backgroundColor: '#28a745',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#218838',
                                },
                            }}
                            variant='outlined'
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </form>
        </div>

    )
}

export default LREdit