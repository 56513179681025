import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import AgingReport from './aging-report/AgingReport'
import DeniedClaim from './denied-claim/DeniedClaim'
import Reconciliation from './reconciliation/Reconciliation'
import ReconciliationServiceLevel from './reconciliation-service-level/ReconciliationServiceLevel'
import SubmissionErrors from './submission-errors/SubmissionErrors'
import Unclaimed from './unclaimed/Unclaimed'
import Utilization from './utilization/Utilization'
import AgencyBasedReportsHeader from './AgencyBasedReportsHeader'

function AgencyBasedReportsIndex() {
  return (
    <div>
      <AgencyBasedReportsHeader/>
    </div>
  )
}

export default AgencyBasedReportsIndex