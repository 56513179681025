
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getServiceProvider } from '../store/action';

interface SP {
    ownerOrgID: String,
    serviceProviderName: String,
    serviceProviderCode: String,
    status: String,
    client: String,
}

const SearchTable = ({
    searchValues,
    setShowEditingForm,
    showEditingForm }:
    {
        searchValues: SP;
        setShowEditingForm: (value: boolean) => void;
        showEditingForm: boolean
    }) => {
    const [serviceProvider, setServiceProvider] = useState<object[]>([])
    const dispatch = useDispatch()
    console.log(serviceProvider)
    console.log(searchValues)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result: any = await dispatch(getServiceProvider())
                let tempObj: any = result.payload.data;
                setServiceProvider(tempObj)
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [])

    return (
        <div>
            <div className='mb-5 col-mb-10' id='kt_content'>
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                    <div className='container-xxl card'>
                        <div id='kt_content_container' className='mt-4'>
                            <div className='card-body'>
                                <div>
                                    <div className='stickhyHeaderTable table-responsive' style={{ padding: "0rem 0rem" }}>
                                        <table
                                            className='table table-light table-rounded table-striped border border-2 table-hover fs-6 gy-5 mt-5'
                                            id='kt_individual_table'
                                        >
                                            <thead>
                                                <tr className='text-start table-dark text-gray-400 table-rounded fw-bolder fs-7 text-uppercase gs-0'>
                                                    <th className="px-3 min-w-100px" style={{ cursor: "pointer" }}
                                                    >First Name
                                                    </th>
                                                    <th className="px-3 min-w-100px" style={{ cursor: "pointer" }}
                                                    >Last Name
                                                    </th>
                                                    <th className="px-3 min-w-100px" style={{ cursor: "pointer" }}
                                                    >Service Provider Name
                                                    </th>
                                                    <th className="px-3 min-w-100px" style={{ cursor: "pointer" }}
                                                    >Service Provider Code
                                                    </th>
                                                    <th className="px-3 min-w-100px" style={{ cursor: "pointer" }}
                                                    >Service Provider Type
                                                    </th>
                                                    <th className="px-3 min-w-100px" style={{ cursor: "pointer" }}
                                                    >Business Name
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className='fw-bold text-gray-600'>
                                                {
                                                    serviceProvider.map((data: any, index) => (
                                                        data._id === searchValues.client ||
                                                            data.serviceProviderCode === searchValues.serviceProviderCode ||
                                                            data.serviceProviderName === searchValues.serviceProviderName ?
                                                            (
                                                                <tr key={index} >
                                                                    <td>{data.firstName}</td>
                                                                    <td>{data.lastName}</td>
                                                                    <td>{data.serviceProviderName}</td>
                                                                    <td>{data.serviceProviderCode}</td>
                                                                    <td>{data.serviceProviderType}</td>
                                                                    <td>{data.businessName}</td>
                                                                </tr>
                                                            ) : null

                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchTable