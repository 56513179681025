import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NewProgram from './newProgram/New'
import ListProgram from './listProgram/List'
import ProgramHeader from './programHeader'

const ProgramIndex = () => {
    return (
        <div>
            <ProgramHeader />
        </div>
    )
}

export default ProgramIndex