import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import {  getOneBillingProvider, updateBillingProvider } from '../store/action';
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper';
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm';
import { BILLING_PROVIDER_FORM_INPUTS } from '../../BILLING_CONSTANTS';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';


const Edit = () => {
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({})
    const parameters = useParams()
    const history = useHistory()

    useEffect(() => {
        const fetchData = async () => {
            if (parameters.id) {
                const response = await dispatch(getOneBillingProvider(parameters.id))
                const tempBP = response.payload.data
                const temObj = {
                    _id: tempBP._id,
                    entityType: tempBP.entityType,
                    organizationName: tempBP.organizationName,
                    firstName: tempBP.firstName,
                    lastName: tempBP.lastName,
                    middleName: tempBP.middleName,
                    IDType1: tempBP.identityInformation.IDType1,
                    IDNumber1: tempBP.identityInformation.IDNumber1,
                    IDType2: tempBP.identityInformation.IDType2,
                    IDNumber2: tempBP.identityInformation.IDNumber2,
                    medicaidProviderNumber: tempBP.identityInformation.medicaidProviderNumber,
                    NPINumber: tempBP.identityInformation.NPINumber,
                    providerCommercialNumber: tempBP.identityInformation.providerCommercialNumber,
                    additionalProviderNumber: tempBP.identityInformation.additionalProviderNumber,
                    demonstrationProjectIdentifier: tempBP.identityInformation.demonstrationProjectIdentifier,
                    addrInfo: {
                        st1: tempBP.providerAddress.st1,
                        st2: tempBP.providerAddress.st2,
                        city: tempBP.providerAddress.city,
                        state: tempBP.providerAddress.state,
                        zipCode: tempBP.providerAddress.zipCode,
                        country: tempBP.providerAddress.country,
                        primaryPhone: tempBP.providerAddress.phoneNumber,
                    },
                    name: tempBP.contactInformation.name,
                    email: tempBP.contactInformation.email,
                    accountNumber: tempBP.financialRouting.accountNumber,
                    bankCode: tempBP.financialRouting.bankCode,
                }
                setFormData(temObj)
            }
        }
        fetchData()
    }, [])



    const handleSubmitForm = async (value) => {
        const tempObj = {
            _id: formData._id,
            entityType: value.entityType,
            organizationName: value.organizationName,
            firstName: value.firstName,
            lastName: value.lastName,
            middleName: value.middleName,
            identityInformation: {
              IDType1: value.IDType1,
              IDNumber1: Number(value.IDNumber1),
              IDType2: value.IDType2,
              IDNumber2: Number(value.IDNumber2),
              medicaidProviderNumber: Number(value.medicaidProviderNumber),
              NPINumber: Number(value.NPINumber),
              providerCommercialNumber: Number(value.providerCommercialNumber),
              additionalProviderNumber: Number(value.additionalProviderNumber),
              demonstrationProjectIdentifier: value.demonstrationProjectIdentifier,
            },
            providerAddress: {
              st1: value?.addrInfo?.st1,
              st2: value?.addrInfo?.st2,
              city: value?.addrInfo?.city,
              state: value?.addrInfo?.state,
              country: value?.addrInfo?.country,
              zipCode: Number(value?.addrInfo?.zipCode),
              phoneNumber: Number(value?.addrInfo?.primaryPhone),
            },
            contactInformation: {
                name: value.name,
                email: value.email,
            },
            financialRouting: {
                accountNumber: Number(value.accountNumber),
                bankCode: Number(value.bankCode),
            }
        }
        const response = await dispatch(updateBillingProvider(tempObj))
        const { message, success } = response?.payload
        if(!success) {
            return toast.error(message)
        }
        history.push('/admin/billing/billing-provider/list')
        toast.success(message)
    }

    const buttons = [
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];

    return (
        <FormWrapper
            title="Edit Billing Provider"
            content={
                <DynamicForm
                    fields={BILLING_PROVIDER_FORM_INPUTS}
                    data={formData}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        ></FormWrapper>
    )
}

export default Edit