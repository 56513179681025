import { useState, useEffect } from 'react'
import axios from 'axios'
import { addNewUser } from './store'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import FormWrapper from '../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../reusable-components/forms/DynamicForm'
import { NEW_ROLE, USER_CONSTANTS } from './USER_CONSTANT'
import { makeStyles } from '@mui/styles'


const AddUser = ({ history }) => {
    const dispatch = useDispatch()
    const [updatedUserConstant, setUpdatedUserConstant] = useState([])

    useEffect(() => {
        axios.get('/roleManagement/get').then((roles) => {
            const updatedRoleOptions = [
                ...roles.data.map((role) => ({ label: role.name, value: role._id })),
            ];
            const userConstantsWithUpdatedRoles = USER_CONSTANTS.map((field) => {
                if (field.name === 'roleId') {
                    return {
                        ...field,
                        options: updatedRoleOptions,
                    };
                }
                return field;
            });
            setUpdatedUserConstant(userConstantsWithUpdatedRoles)
        })
    }, [])



    const handleSubmitForm = async (formData) => {
        const ids = formData.roleId.map(data => data.value)
        const tempObj = {
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            password: formData.password,
            roleId: ids,
        }
        const response = await dispatch(addNewUser(tempObj))
        if (response?.payload.success) {
            toast.success(response?.payload.message || 'success', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            history.push('/users')
        } else {
            toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    };

    const useStyles = makeStyles((theme) => ({
        card: {
            width: '70%',
            marginLeft: '10rem'
        }
    }))
    const classes = useStyles();

    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];
    return (
        <div className={classes.card}>
            <FormWrapper
                title='Add User'
                content={
                    <DynamicForm
                        fields={updatedUserConstant}
                        data={NEW_ROLE}
                        onSubmit={handleSubmitForm}
                        buttons={buttons}
                    />
                }
            />
        </div>
    )
}

export default AddUser
