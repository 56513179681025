import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';


export default function Table({columns,data,setSelection}) {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick 
        onRowSelectionModelChange ={itm => setSelection(itm)}
      />
    </div>
  );
}
