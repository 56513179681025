import {TextField, Box, Typography} from '@mui/material'
import {useLocation} from 'react-router-dom'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
const selectTimeZone = (state) => state.timeZone.timeZone

export default function View() {
  const {
    state: {data},
  } = useLocation()
  const timeZone = useSelector(selectTimeZone)

  return (
    <Box
      sx={{
        maxWidth: '100%',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'white',
        borderRadius: '8px',
        padding: '1rem',
      }}
    >
      <div
        style={{
          flex: 1,
          marginRight: '8px',
        }}
      >
        <Typography
          variant='h3'
          sx={{paddingTop: '1.5rem', paddingBottom: '1.5rem', paddingLeft: '1rem'}}
          fontSize={20}
          fontWeight={'bold'}
        >
          Individual Medication
        </Typography>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '12px',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            gap: 15,
          }}
        >
          <TextField
            variant='outlined'
            size='small'
            style={{
              marginBottom: '0.1rem',
              width: '100%',
            }}
            inputProps={{
              style: {
                WebkitTextFillColor: '#000000b0',
              },
            }}
            disabled
            placeholder='Full Name'
            value={data.clientFullName||""}
          />
          <TextField
            variant='outlined'
            size='small'
            style={{
              marginBottom: '0.1rem',
              width: '100%',
            }}
            inputProps={{
              style: {
                WebkitTextFillColor: '#000000b0',
              },
            }}
            disabled
            placeholder='Medication Name'
            value={data.medicationListingName||""}
          />
        </Box>
      </div>
      <div
        style={{
          flex: 1,
          marginRight: '8px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '12px',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            gap: 15,
          }}
        >
          <TextField
            variant='outlined'
            size='small'
            style={{
              marginBottom: '0.1rem',
              width: '100%',
            }}
            inputProps={{
              style: {
                WebkitTextFillColor: '#000000b0',
              },
            }}
            disabled
            placeholder='Prescribed On'
            value={data.prescribedOn||""}
          />
          <TextField
            variant='outlined'
            size='small'
            style={{
              marginBottom: '0.1rem',
              width: '100%',
            }}
            inputProps={{
              style: {
                WebkitTextFillColor: '#000000b0',
              },
            }}
            disabled
            placeholder='Dosage Information'
            value={data.dosageInformation||""}
          />
        </Box>
      </div>
      <div
        style={{
          flex: 1,
          marginRight: '8px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '12px',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            gap: 15,
          }}
        >
          <TextField
            variant='outlined'
            size='small'
            style={{
              marginBottom: '0.1rem',
              width: '100%',
            }}
            inputProps={{
              style: {
                WebkitTextFillColor: '#000000b0',
              },
            }}
            disabled
            placeholder='Side Effects'
            value={data.sideEffects||""}
          />
          <TextField
            variant='outlined'
            size='small'
            style={{
              marginBottom: '0.1rem',
              width: '100%',
            }}
            inputProps={{
              style: {
                WebkitTextFillColor: '#000000b0',
              },
            }}
            disabled
            placeholder='Comment'
            value={data.comment}
          />
        </Box>
      </div>

      <Box style={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            marginBottom: '0.5rem',
            marginLeft: '1rem',
          }}
          color='primary'
        >
          Dosage Timings
        </Typography>
        <div
          style={{
            flex: 1,
            marginRight: '8px',
          }}
        >
          {data?.dosageTimings?.map((timing,index) => (
            <Box
            key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '12px',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                gap: 15,
              }}
            >
              <TextField
                variant='outlined'
                size='small'
                style={{
                  marginBottom: '0.1rem',
                  width: '100%',
                }}
                inputProps={{
                  style: {
                    WebkitTextFillColor: '#000000b0',
                  },
                }}
                disabled
                placeholder='Time'
                value={moment.tz(timing.time, 'UTC').tz(timeZone).format('hh:mm A') ||""}
              />
              <TextField
                variant='outlined'
                size='small'
                style={{
                  marginBottom: '0.1rem',
                  width: '100%',
                }}
                inputProps={{
                  style: {
                    WebkitTextFillColor: '#000000b0',
                  },
                }}
                disabled
                placeholder='Dosage'
                value={timing.dosage||""}
              />
              <TextField
                variant='outlined'
                size='small'
                style={{
                  marginBottom: '0.1rem',
                  width: '100%',
                }}
                inputProps={{
                  style: {
                    WebkitTextFillColor: '#000000b0',
                  },
                }}
                disabled
                placeholder='Comment'
                value={timing.comment||""}
              />
            </Box>
          ))}
        </div>
      </Box>
    </Box>
  )
}
