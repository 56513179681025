import * as React from 'react';
import Card from '@mui/material/Card';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ImageIcon from '@mui/icons-material/Image';
import DownloadIcon from '@mui/icons-material/Download';
import { IconButton, Typography } from '@mui/material';

export default function PdfViewer({ name, filePath, mimeType = 'application/pdf' }) {

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = filePath;
        link.download = name; // Set the downloaded file's name
        link.target = '_blank';
        link.click();
    };

    const renderIcon = () => {
        switch (mimeType) {
            case 'application/pdf':
                return <PictureAsPdfIcon color='primary' style={{ fontSize: '40px' }} />;
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return <InsertDriveFileIcon color='primary' style={{ fontSize: '40px' }} />;
            case 'image/jpeg':
            case 'image/png':
            case 'image/gif':
                return <ImageIcon color='primary' style={{ fontSize: '40px' }} />;
            default:
                return <InsertDriveFileIcon color='primary' style={{ fontSize: '40px'}} />;
        }
    };

    return (
        <Card
            sx={{
                display: 'flex',
                gap: 1,
                justifyContent: 'center',
                alignItems: 'center',
                height: '50px',
            }}
        >
            {renderIcon()}
            <Typography>{name}</Typography>
            <IconButton onClick={handleDownload}>
                <DownloadIcon />
            </IconButton>
        </Card>
    );
}
