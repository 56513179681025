import TimesheetsTabs from '../Tabs/TimesheetsTabs'
import CaregiverTimeSheetsDynamicTable from './Table'

export default function CaregiverTimesheets() {
  return (
    <>
      <TimesheetsTabs></TimesheetsTabs>
      <CaregiverTimeSheetsDynamicTable></CaregiverTimeSheetsDynamicTable>
    </>
  )
}
