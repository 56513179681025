import React from 'react'
import { Route, Switch } from 'react-router-dom'
import New from '../../../../../pages/admin/general/site/new/New'
import List from '../../../../../pages/admin/general/site/list/List'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { RouteWithPermission } from '../../../../../../_helper/routesWithPermission'
import EditSite from '../../../../../pages/admin/general/site/list/EditSite'
import { Error404 } from '../../../../../modules/errors/components/Error404'

const SiteIndexRoutes: React.FC = () => {
    const auth = useSelector((state: { auth: any }) => state.auth);
    const userPermissions = auth.user.roleId;
    const canUpdate = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.SITE.UPDATE');
    const canCreate = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.SITE.CREATE');
    const canRead = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.SITE.VIEW');
    return (
        <Switch>
            <RouteWithPermission path='/admin/general/site/new' component={New} hasPerm={canCreate} />
            <RouteWithPermission path='/admin/general/site/list/:id' component={EditSite} hasPerm={canUpdate} />
            <RouteWithPermission path='/admin/general/site/list' component={List} hasPerm={canRead} />
            <Route path='/error/404' component={Error404} />
        </Switch>
    )
}

export default SiteIndexRoutes