import { useState } from "react";
import PermissionSection from "./PermissionSection";


const RenderPermissionSections = ({ permissionsSubSecton, permissionRef }) => {
    const [permissions, setPermissions] = useState(permissionsSubSecton);
    function handleCheckboxChange(event, combinedSectionName, subSection) {
        const isChecked = event.target.checked;
        if (combinedSectionName) {
            setPermissions((prevPermissions) => {
                const updatedData = { ...prevPermissions };
                let current = updatedData;
                const keys = combinedSectionName.split(".");
                for (const key of keys) {
                    current = current[key];
                }
                current[subSection] = isChecked;
                console.log({ permissionRef })
                permissionRef.current = { ...permissionRef.current, ...updatedData }
                return { ...updatedData };
            });
        }
    };

    return (
        <PermissionSection
            permissions={permissions}
            setPermissions={setPermissions}
            handleCheckboxChange={handleCheckboxChange}
            sectionName="PERMISSIONS"
            level={0}
            allPermissions={permissionRef}
        />
    );
};

export default RenderPermissionSections;