import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface AttendanceType {
    _id: String;
    name: String;
    time: String;
    duration: String;
    bundleRules: String;
    bundling: String;
    targetService: String;
    leaveRules: String;
    ISPBillable: String;
    ispProgram: String;
    ISPData: String;
    totalUnits: String;
    billingDate: String;
    dataRangeGroup: String;
    directBilling: String;
    options: String;
    bundlingForm: String;
}
interface DeleteAttendanceTypeResponse {
    success: boolean;
    message?: string;
}

export const getAttendanceType = createAsyncThunk(
    'att/get/',
    async ({ page, pageSize, sortBy, sortDir, searchTerm }: { page?: number, pageSize?: number, sortBy?: string, sortDir?: string, searchTerm?: string  }) => {
        try {
            if(page && pageSize) {
                const response = await axios.get(`att/get?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${searchTerm}`)
                return {
                    data: response.data
                }
            } else {
                const response = await axios.get(`att/get`)
                return {
                    data: response.data
                }
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getOneAttendanceType = createAsyncThunk(
    'att/getOne/',
    async(attId: string | undefined) => {
        try{
            const response = await axios.get('att/getOne/' + attId)
            return {
                data: response.data
            }
        } catch (err) {
            console.log(err)
            return err
        }
    }
)

export const addAttendanceType = createAsyncThunk(
    'att/create',
    async (data: AttendanceType) => {
        try {
            const response = await axios.post('att/create', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }

)

export const deleteAttendanceType = createAsyncThunk<DeleteAttendanceTypeResponse, string>(
    'att/delete/',
    async (data: string) => {
        const response = await axios.delete(`att/delete/${data}`);
        return response.data as DeleteAttendanceTypeResponse;
    }
);

export const updateAttendanceType = createAsyncThunk(
    'att/update',
    async (data: AttendanceType) => {
        try {
            const response = await axios.put('att/update', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)