import {useEffect, useState} from 'react'
import { useSelector} from 'react-redux'
import SearchTable from './SearchTable'
import '../../../../../../_helper/cssfiles/reactSelectField.css'
import axios from 'axios'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import {Autocomplete, TextField, Button, Box} from '@mui/material'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'

const selectAuth = (state: {auth: any}) => state.auth

// const registrationSchema = Yup.object().shape({
//   client: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
//   startDate: Yup.date().required('Start Date is a required field'),
//   endDate: Yup.date()
//     .required()
//     .test(
//       'date-range',
//       'The difference between start and end dates cannot be longer than 2 months',
//       function (endDate: any) {
//         const {startDate} = this.parent
//         const startDateObj: any = new Date(startDate)
//         const endDateObj = new Date(endDate)
//         const diffInMonths =
//           (endDateObj.getFullYear() - startDateObj.getFullYear()) * 12 +
//           (endDateObj.getMonth() - startDateObj.getMonth())
//         return diffInMonths <= 1
//       }
//     )
//     .test('currentDate', 'The end date cannot be later than today', function (endDate) {
//       const currentDateObj = new Date() // Get the current date
//       const endDateObj = endDate ? new Date(endDate) : null // Explicitly specify type as Date
//       return endDateObj ? endDateObj <= currentDateObj : false
//     })
//     .test('endDate', 'End Date cannot be less than Begin Date', function (endDate) {
//       const {startDate} = this.parent
//       if (!endDate || !startDate) {
//         return true // allow empty values to pass validation
//       }
//       const end = new Date(endDate)
//       const begin = new Date(startDate)
//       return end >= begin
//     }),
//   attendanceType: Yup.string().required('Attendance Type is Required'),
//   serviceDescription: Yup.string().required('Service Description is Required'),
//   programNames: Yup.string().required('Program Name is Required'),
// })

interface selectOptionType {
  label: string,
  value: string
}
interface attType {
  client: selectOptionType,
  startDate: string,
  endDate: string
  attendanceType: selectOptionType,
  serviceDescription: selectOptionType,
  programNames: selectOptionType,
  status: selectOptionType,
}

function Search() {
  const [attendanceType, setAttendanceType] = useState<{value: string; label: string}[]>([])
  const [serviceDescription, setServiceDescription] = useState<
    {label: string; value: string; attendanceType: string}[]
  >([])
  const [programNames, setProgramNames] = useState<
    {
      label: string
      value: string
    }[]
  >([])
  const [showEditingForm, setShowEditingForm] = useState(true)
  const auth = useSelector(selectAuth)
  const history = useHistory()
  const [searchValues, setSearchValues] = useState<attType>({
    startDate: '',
    endDate: '',
    client: {
      label: '',
      value: ''
  },
  attendanceType: {
      label: '',
      value: ''
  },
  serviceDescription: {
      label: '',
      value: ''
  },
  programNames: {
      label: '',
      value: ''
  },
  status: {
      label: 'Approved',
      value: 'Approved'
  },
  })
  const [clientNames, setClientNames] = useState([])
  const currentDate = new Date()
  const formattedDate = `${currentDate.getFullYear().toString().padStart(2, '0')}-${(
    currentDate.getMonth() + 1
  )
    .toString()
    .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`

  const [formValues, setFormValues] = useState<attType>({
    startDate: '',
    endDate: formattedDate,
    attendanceType: {
      label: '',
      value: ''
  },
  serviceDescription: {
      label: '',
      value: ''
  },
  programNames: {
      label: '',
      value: ''
  },
  status: {
      label: 'Approved',
      value: 'Approved'
  },
    client: {
      label: '',
      value: ''
  },
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('/attendance/getAttendanceFields')
        const {programNamesResult, attendanceTypeResult, descriptionResult, serviceAuthsResult} =
          response.data!
        setAttendanceType(attendanceTypeResult)
        setServiceDescription(descriptionResult)
        setProgramNames(programNamesResult)
        setClientNames(serviceAuthsResult)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  // const handleFormChange = (e: {target: {name: string; value: string | number}}, newValue) => {
  //   const {label, value} = newValue
  //   setFormValues((prevValues) => ({
  //     ...prevValues,
  //     [label]: value,
  //   }))
  // }
  const handleSubmit = (values) => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const day = currentDate.getDate().toString().padStart(2, '0')
    // Create the formatted date string
    const currentDateFormatted = `${year}-${month}-${day}`
    if (values.startDate <= currentDateFormatted && values.endDate >= values.startDate) {
      const tempObj = {
        startDate: values.startDate || '',
        endDate: values.endDate || '',
        attendanceType: values.attendanceType || '',
        serviceDescription: values.serviceDescription.value || '',
        programNames: values.programNames.value || '',
        client: values.client.value || '',
        status: values.status.value || ''
      }
      setSearchValues(tempObj)
      setShowEditingForm(false)
    } else {
      if (values.startDate > currentDateFormatted) {
        toast.error('Start Date should not be later than today')
      } else if (values.endDate < values.startDate) {
        toast.error('End Date should not be earlier than Start Date')
      }
    }
  }

  return (
    <>
      {showEditingForm === false ? (
        <SearchTable
          searchValues={searchValues}
          setShowEditingForm={setShowEditingForm}
          showEditingForm={showEditingForm}
        />
      ) : (
        <div className='card'>
          <form
            className='card-body'
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit(formValues)
            }}
          >
            <TableTitle title='Attendance Data(Search)'></TableTitle>
            <div className='row mt-4 g-3'>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                <Autocomplete
                  options={attendanceType}
                  size='small'
                  forcePopupIcon={true}
                  value={formValues.attendanceType}
                  onChange={(event, newValue) => {
                    setFormValues({
                      ...formValues,
                      attendanceType: newValue,
                    })
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant='outlined' required label='Attendance Type' />
                  )}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                <Autocomplete
                  id='serviceDescription'
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  size='small'
                  forcePopupIcon={true}
                  value={formValues.serviceDescription}
                  options={serviceDescription
                    ?.filter((des) => des.attendanceType == formValues.attendanceType.value)
                    .map((des) => ({label: des.label, value: des.value}))}
                  disabled={!formValues.attendanceType}
                  onChange={(e, newValue) => {
                    setFormValues({
                      ...formValues,
                      serviceDescription: newValue,
                    })
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant='outlined' label='Description Code' required />
                  )}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3'>
                <Autocomplete
                  id='programNames'
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  size='small'
                  forcePopupIcon={true}
                  options={programNames}
                  value={formValues.programNames}
                  disabled={!formValues.serviceDescription}
                  onChange={(e: {target: any}, newValue) => {
                    setFormValues({
                      ...formValues,
                      programNames: newValue,
                    })
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant='outlined' label='Program Name' required />
                  )}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3'>
                <Autocomplete
                  id='status'
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  size='small'
                  forcePopupIcon={true}
                  options={[
                    {label: 'Approved', value: 'Approved'},
                    {label: 'Discontinued', value: 'Discontinued'},
                  ]}
                  value={formValues.status}
                  onChange={(e: {target: any}, newValue) => {
                    setFormValues({...formValues, status: newValue})
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant='outlined' label='Status' required />
                  )}
                />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(formValues.startDate, 'YYYY-MM-DD')}
                    format='YYYY-MM-DD'
                    onChange={(newValue) => {
                      if (newValue) {
                        const newDate = newValue.format('YYYY-MM-DD');
                      setFormValues({...formValues, startDate: newDate})
                      }
                    }}
                    slotProps={{
                      textField: {label: 'Start Date', size: 'small', style: {width: '100%'}},
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(formValues.endDate)}
                    format='YYYY-MM-DD'
                    onChange={(newValue) => {
                      if (newValue) {
                        const newDate = newValue.format('YYYY-MM-DD');
                        setFormValues({...formValues, endDate: newDate})
                      }
                    }}
                    slotProps={{
                      textField: {label: 'End Date', size: 'small', style: {width: '100%'}},
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3'>
                <Autocomplete
                  id='client'
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  size='small'
                  freeSolo={true}
                  forcePopupIcon={true}
                  options={clientNames}
                  value={formValues.client || ''}
                  onChange={(e: {target: any}, newValue) => {
                    setFormValues({...formValues, client: newValue ? newValue : ''})
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant='outlined' label='Client' />
                  )}
                />
              </div>
            </div>
            <Box style={{marginTop: '24px'}}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  borderBottomLeftRadius: '4px',
                  borderBottomRightRadius: '4px',
                  padding: '10px 0',
                }}
              >
                <Button
                  type='button'
                  sx={{padding: '4px', margin: '4px'}}
                  variant='contained'
                  style={{
                    color: '#000',
                    backgroundColor: '#fff'
                  }}
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  sx={{padding: '4px', margin: '4px'}}
                  variant='contained'
                  style={{
                    marginLeft: '6px',
                    backgroundColor: '#28a745',
                    color: '#fff'
                  }}
                >
                  Search
                </Button>
              </Box>
            </Box>
          </form>
        </div>
      )}
    </>
  )
}

export default Search
