import React, {useEffect, useState} from 'react'
import {ADD_NOTE_ENTRY_FORM_INPUTS} from '../../ADD_NOTES_FORM_CONSTANTS'
import DynamicForm from '../../../../reusable-components/forms/DynamicForm'
import {useHistory, useParams} from 'react-router-dom'
import axios from 'axios'
import {useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import '../../Style.css'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import {TextField} from '@mui/material'
import FormWrapper from '../../../../reusable-components/Wrapper/Wrapper'

export default function NewNoteEntries() {
  const authUser = useSelector((state: {auth: any}) => state.auth)
  const history = useHistory()
  const params: {id: string} = useParams()
  const [frequency, setFrequency] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [displayDateRange, setDisplayDateRange] = useState('')
  const [endDate, setEndDate] = useState(dayjs())
  const [uiElements, setUIElements] = useState(ADD_NOTE_ENTRY_FORM_INPUTS('', '', ''))
  const [userAPIResponse, setUserAPIResponse] = useState({
    firstName: '',
    lastName: '',
  })
  const [noteAPIResponse, setNoteAPIResponse] = useState({
    scoringMethod: {scoringLevels: []},
    tasks: [],
    location: {
      address: '',
    },
    clientId: '',
    clients: [],
  })

  const [taskAndScoreList, setTaskAndScoreList] = useState([])
  const [formData, setFormData] = useState({
    // startDate: startDate,
    beginTime: startDate,
    endTime: endDate,
    location: '',
    enteredBy: '',
  })

  const buttons = [
    {label: 'Cancel', variant: 'outlined', type: 'button'},
    {label: 'Save', variant: 'contained', type: 'submit'},
  ]
  // use effect to fetch data
  useEffect(() => {
    fetchData()
  }, [])

  // useeffect to set UI and data in UI
  useEffect(() => {
    setUIAndData()
  }, [noteAPIResponse])

  const fetchData = () => {
    let axiosArray = []
    let newPromise
    newPromise = axios({
      method: 'get',
      url: `/user/getOne/${authUser.user._id}`,
    })
    axiosArray.push(newPromise)
    newPromise = axios({
      method: 'get',
      url: `/notes/getNoteById/${params.id}`,
    })
    axiosArray.push(newPromise)

    axios
      .all(axiosArray)
      .then(
        axios.spread(async (...responses) => {
          let localList = []
          responses[1].data.tasks.map((eachTask) => {
            localList.push({
              taskId: eachTask._id,
              score: '',
              description: '',
            })
          })

          setTaskAndScoreList(localList)
          setUserAPIResponse(responses[0].data)
          setNoteAPIResponse(responses[1].data)

          setFrequency(responses[1].data.frequency)
        })
      )
      .catch((error) => console.error(error))
  }

  const setUIAndData = () => {
    let scoringMethodLevels = []
    let uiArray = []
    let lastRow = 7
    let i = 0
    const nameOfUser = `${userAPIResponse.firstName
      .toString()
      .toUpperCase()} ${userAPIResponse.lastName.toString().toUpperCase()}`
    const location = `${noteAPIResponse?.location?.address.toString().toUpperCase()}` || ''
    const clientId = noteAPIResponse.clientId
    const client = noteAPIResponse.clients?.find((client) => client._id === clientId)

    const clientName = client
      ? `${client.firstName.toUpperCase()} ${client.lastName.toUpperCase()}`
      : ''
    uiArray = ADD_NOTE_ENTRY_FORM_INPUTS(nameOfUser, location, clientName)
    if (noteAPIResponse.scoringMethod.scoringLevels) {
      noteAPIResponse.scoringMethod?.scoringLevels.map((eachLevel) => {
        scoringMethodLevels.push({
          label: eachLevel.levelAcronym,
          value: eachLevel._id,
        })
      })
    }
    if (noteAPIResponse.tasks.length > 0) {
      noteAPIResponse.tasks.map((eachTask, indexForTask) => {
        uiArray.push(
          {
            label: 'Name:',
            name: `${i}Name`,
            type: 'text',
            required: true,
            rowNumber: lastRow,
            // value: ``,
            value: `${eachTask.taskName}`,
            disabled: true,
            sx: {
              width: '100%',
              marginTop: '0.5rem',
            },
            className: `col-lg-6 col-sm-12 col-md-6 col-12 false false false col-lg-3 col-md-3 col-sm-12 false`,
          },
          {
            index: indexForTask,
            label: 'Score',
            name: `${i}Score`,
            type: 'select',
            options: scoringMethodLevels,
            required: true,
            rowNumber: lastRow,
            value: taskAndScoreList[indexForTask]['score'],
            onChange: onChangeScore,
            className: `col-lg-6 col-sm-12 col-md-6 col-12 false false false col-lg-3 col-md-3 col-sm-12 false`,
            sx: {
              // marginLeft: '3rem',
              width: '100%',
              marginTop: '0.5rem',
            },
          },
          {
            label: 'Description:',
            name: `${i}Description`,
            required: true,
            rowNumber: lastRow,
            value: eachTask.taskDescription,
            type: 'textarea',
            disabled: true,
            className: `col-lg-6 col-sm-12 col-md-6 col-12 false false false col-lg-3 col-md-3 col-sm-12 false`,
            // value: ``,
            // textAreaClassName: 'notes-entry-task-description-method-div',
            sx: {
              resize: 'none',
            },
          },
          {
            index: indexForTask,
            label: 'Description',
            name: `${i}Description`,
            type: 'textarea',
            required: false,
            rowNumber: lastRow,
            value: taskAndScoreList[indexForTask]['description'],
            onChange: onChangeDescription,
            className: `col-lg-6 col-sm-12 col-md-6 col-12 false false false col-lg-3 col-md-3 col-sm-12 false`,
            // textAreaClassName: 'notes-entry-task-remarks-method-div',
          }
        )
        i++
        lastRow++
      })
    }
    setUIElements(uiArray)
  }

  const onChangeScore = (value, index) => {
    setTaskAndScoreList((prevInputArr) => {
      const newState = [...prevInputArr]
      newState[index]['score'] = value
      return newState
    })
  }

  const onChangeDescription = (name, value, checked, type, index) => {
    setTaskAndScoreList((prevInputArr) => {
      const newState = [...prevInputArr]
      newState[index]['description'] = value
      return newState
    })
  }

  const handleSubmitForm = async (formData) => {
    await axios
      .post('/notesEntry/create', {
        noteId: params.id,
        // creationDate: startDate,
        beginTime: startDate,
        endTime: endDate,
        frequency,

        location: {
          address: formData['location'],
          lat: '31.5245054',
          lng: '74.3033196',
          typeOfLocation: 'client',
          idOfDoc: '65ae6e8b682092bf5446fd8b',
        },
        tasks: taskAndScoreList,
      })
      .then((res) => {
        const {message, success} = res?.data
        if (!success) {
          return toast.error(message)
        }
        toast.success(message)
        history.push('/caregivers/notes')
      })
      .catch((e) => toast.error("Couldn't add note entry"))
  }
  const maxDate = dayjs().subtract(1, 'day')
  const minDate = dayjs().subtract(5, 'year')

  const handleStartDateChange = (newStartDate) => {
    if (!newStartDate) return

    if (newStartDate.isAfter(maxDate)) {
      newStartDate = maxDate
    } else if (newStartDate.isBefore(minDate)) {
      newStartDate = minDate
    }

    let calculatedStartDate
    switch (frequency) {
      case 'DAILY':
        calculatedStartDate = newStartDate
        break
      case 'WEEKLY':
        calculatedStartDate = newStartDate.startOf('week')
        break
      case 'MONTHLY':
        calculatedStartDate = newStartDate.startOf('month')
        break
      case 'QUARTERLY':
        calculatedStartDate = newStartDate.startOf('month')
        break
      case 'YEARLY':
        calculatedStartDate = newStartDate.startOf('year')
        break
      default:
        calculatedStartDate = null
    }

    if (calculatedStartDate && frequency !== 'WEEKLY' && frequency !== "DAILY") {
      calculatedStartDate = calculatedStartDate.add(1, 'day')
    }

    setStartDate(calculatedStartDate)

    let calculatedEndDate
    switch (frequency) {
      case 'DAILY':
        calculatedEndDate = calculatedStartDate
        break
      case 'WEEKLY':
        calculatedEndDate = calculatedStartDate.add(5, 'days').endOf('day')
        break
      case 'MONTHLY':
        calculatedEndDate = calculatedStartDate.endOf('month')
        setDisplayDateRange(
          `01-${calculatedStartDate.format('MMMM')} - ${calculatedEndDate.format('DD-MMMM')}`
        )
        break
      case 'QUARTERLY':
        calculatedEndDate = calculatedStartDate.add(2, 'months').endOf('month')
        setDisplayDateRange(
          `${calculatedStartDate.format('MMMM')} - ${calculatedEndDate.format('MMMM')}`
        )
        break
      case 'YEARLY':
        calculatedEndDate = calculatedStartDate.endOf('year')
        setDisplayDateRange(
          `${calculatedStartDate.format('MMMM')} - ${calculatedEndDate.format('MMMM')}`
        )
        break
      default:
        calculatedEndDate = null
    }

    setEndDate(calculatedEndDate)
  }
  useEffect(() => {
    setDisplayDateRange('')
  }, [frequency])
  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <FormWrapper
            content={
              <>
                {frequency && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {frequency === 'MONTHLY' ? (
                      <>
                        <DatePicker
                          views={['month', 'year']}
                          sx={{background: 'white', ml: 1.5, mb: 2}}
                          label='Select Month'
                          maxDate={dayjs()}
                          minDate={dayjs().subtract(5, 'year')}
                          value={startDate}
                          onChange={handleStartDateChange}
                          // renderInput={(params:any) => <TextField {...params} fullWidth margin='normal' />}
                        />
                        {displayDateRange && (
                          <TextField
                            label='Selected Range'
                            sx={{width: '15%', mt: '0', ml: 1}}
                            value={displayDateRange}
                            fullWidth
                            margin='normal'
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        )}
                      </>
                    ) : frequency === 'YEARLY' ? (
                      <>
                        <DatePicker
                          views={['year']}
                          sx={{background: 'white', ml: 1.5, mb: 2}}
                          label='Select Year'
                          maxDate={dayjs()}
                          minDate={dayjs().subtract(5, 'year')}
                          value={startDate}
                          onChange={handleStartDateChange}
                          // renderInput={(params:any) => <TextField {...params} fullWidth margin='normal' />}
                        />
                        {displayDateRange && (
                          <TextField
                            label='Selected Range'
                            sx={{width: '15%', mt: '0', ml: 1}}
                            value={displayDateRange}
                            fullWidth
                            margin='normal'
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        )}
                      </>
                    ) : frequency === 'QUARTERLY' ? (
                      <>
                        <DatePicker
                          views={['month', 'year']}
                          sx={{background: 'white', ml: 1.5, mb: 2}}
                          label='Select Month'
                          maxDate={dayjs()}
                          minDate={dayjs().subtract(5, 'year')}
                          value={startDate}
                          onChange={handleStartDateChange}
                          // renderInput={(params:any) => <TextField {...params} fullWidth margin='normal' />}
                        />
                        {displayDateRange && (
                          <TextField
                            label='Selected Range'
                            sx={{width: '10%', mt: '0', ml: 1}}
                            value={displayDateRange}
                            fullWidth
                            margin='normal'
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <DatePicker
                          label='Start Date'
                          sx={{background: 'white', ml: 1.5, mb: 2}}
                          value={startDate}
                          maxDate={dayjs()}
                          minDate={dayjs().subtract(5, 'year')}
                          onChange={handleStartDateChange}
                          // renderInput={(params:any) => <TextField {...params} fullWidth margin='normal' />}
                        />

                        <>
                          {displayDateRange && frequency !== 'DAILY' && frequency !== 'WEEKLY' && (
                            <TextField
                              label='Selected Range'
                              sx={{width: '15%', mt: '0', ml: 1}}
                              value={displayDateRange}
                              fullWidth
                              margin='normal'
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          )}
                        </>
                      </>
                    )}

                    {frequency !== 'DAILY' &&
                      frequency !== 'WEEKLY' &&
                      frequency !== 'QUARTERLY' &&
                      frequency !== 'MONTHLY' &&
                      frequency !== 'YEARLY' && (
                        <TextField
                          sx={{mb: 2, ml: 1, background: 'white'}}
                          label='End Date'
                          value={endDate ? endDate.format('MM-DD-YYYY') : ''}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      )}
                  </LocalizationProvider>
                )}

                <DynamicForm
                  fields={uiElements}
                  data={formData}
                  onSubmit={handleSubmitForm}
                  buttons={buttons}
                />
              </>
            }
          />
        </div>
      </div>
    </>
  )
}
