import axios from "axios"

export const GroupServices = {
    get: (id:string)=>{
        return axios.get(`groups/${id}`)
    },
    getExcludedMembers: (id:string)=>{
        return axios.get(`groups/${id}/excludedUsers`)
    },
    saveGroup: (id:string,data:any)=>{
        return axios.put(`groups/${id}`,data)
    },
    addGroup: (data:any)=>{
        return axios.post(`groups`,data)
    },
    getAllMembers :()=>{
        return axios.get(`user/users`)
    },
    postAttactmentMessageContact : (data:any) =>{
        return axios
        .post('messages/attachment',data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        
    },
    postAttactmentMessageGroup : (data:any) =>{
        return axios
        .post('groupMessages/attachment',data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        
    }
}