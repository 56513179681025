export const BILLING_INPUT_CONSTANTS = [
    {
        label: "Program",
        name: "programSite",
        type: "select",
        options: [],
        required: false,
        rowNumber: 1
    },
    {
        label: "Client",
        name: "client",
        type: "select",
        options: [],
        required: false,
        rowNumber: 1
    },
    {
        label: "Authorization ID",
        name: "authorizationID",
        type: "number",
        required: false,
        rowNumber: 2,
    },
    {
        label: "Authorization Number",
        name: "authorizationNumber",
        type: "number",
        required: false,
        rowNumber: 2
    },
    {
        label: "Funding Source",
        name: "fundingSource",
        type: "select",
        options: [],
        required: false,
        rowNumber: 3
    },
    {
        label: "Funding Provider Number",
        name: "fundingProviderNumber",
        type: "number",
        required: false,
        rowNumber: 3
    },
    {
        label: "Service Coordinator Number",
        name: "serviceCoordinatorNumber",
        type: "number",
        required: false,
        rowNumber: 4
    },
    {
        label: "Service Description (Code)",
        name: "serviceDescription",
        type: "select",
        options: [],
        required: false,
        rowNumber: 4
    },
    {
        label: "Service Authorization Begin Date From",
        name: "beginServiceDateFrom",
        type: "date",
        required: false,
        rowNumber: 5
    },
    {
        label: "Service Authorization Begin Date To",
        name: "beginServiceDateTo",
        type: "date",
        required: false,
        rowNumber: 5
    },
    {
        label: "Service Authorization End Date From",
        name: "endServiceDateFrom",
        type: "date",
        required: false,
        rowNumber: 6
    },
    {
        label: "Service Authorization End Date To",
        name: "endServiceDateTo",
        type: "date",
        required: false,
        rowNumber: 6
    },

]
export const SEARCH_BILLING_INPUT_CONSTANTS = [
    {
        label: "Program",
        name: "programSite",
        type: "select",
        options: [],
        required: false,
        rowNumber: 1
    },
    {
        label: "Client",
        name: "client",
        type: "select",
        options: [],
        required: false,
        rowNumber: 1
    },
    {
        label: "Authorization ID",
        name: "authorizationID",
        type: "number",
        required: false,
        rowNumber: 2
    },
    {
        label: "Authorization Number",
        name: "authorizationNumber",
        type: "number",
        required: false,
        rowNumber: 2
    },
    {
        label: "Funding Source",
        name: "fundingSource",
        type: "select",
        options: [],
        required: false,
        rowNumber: 3
    },
    {
        label: "Funding Provider Number",
        name: "fundingProviderNumber",
        type: "number",
        required: false,
        rowNumber: 3
    },
    {
        label: "Service Coordinator First Name",
        name: "serviceCoordinatorFirstName",
        type: "text",
        required: false,
        rowNumber: 4
    },
    {
        label: "Service Coordinator Last Name",
        name: "serviceCoordinatorLastName",
        type: "text",
        required: false,
        rowNumber: 4
    },
    {
        label: "Service Coordinator Number",
        name: "serviceCoordinatorNumber",
        type: "number",
        required: false,
        rowNumber: 5
    },
    {
        label: "Service Description (Code)",
        name: "serviceDescription",
        type: "select",
        options: [],
        required: false,
        rowNumber: 5
    },
    {
        label: "Service Authorization Begin Date From",
        name: "beginServiceDateFrom",
        type: "date",
        required: false,
        rowNumber: 6
    },
    {
        label: "Service Authorization Begin Date To",
        name: "beginServiceDateTo",
        type: "date",
        required: false,
        rowNumber: 6
    },
    {
        label: "Service Authorization End Date From",
        name: "endServiceDateFrom",
        type: "date",
        required: false,
        rowNumber: 7
    },
    {
        label: "Service Authorization End Date To",
        name: "endServiceDateTo",
        type: "date",
        required: false,
        rowNumber: 7
    },
    {
        label: "Billing Data ID",
        name: "billingDataID",
        type: "number",
        required: false,
        rowNumber: 8
    },
    {
        label: "Service Date From",
        name: "serviceDateFrom",
        type: "date",
        required: false,
        rowNumber: 8
    },
    {
        label: "Service Date To",
        name: "serviceDateTo",
        type: "date",
        required: false,
        rowNumber: 9
    },
    {
        label: "Create Date From",
        name: "createDateFrom",
        type: "date",
        required: false,
        rowNumber: 9
    },
    {
        label: "Create Date To",
        name: "createDateTo",
        type: "date",
        required: false,
        rowNumber: 10
    },
    {
        label: "Method of Data Collection",
        name: "dataCollection",
        type: "select",
        options: [
            { label: 'Billing Data Input', value: 'Billing Data Input' },
            { label: 'Attendance', value: 'Attendance' },
            { label: 'ISP Data', value: 'ISP Data' },
            { label: 'Case Note', value: 'Case Note' },
        ],
        required: false,
        rowNumber: 10
    },

]

export const SEARCH_SERVICE_AUTH_INPUT_CONSTANTS = [
    {
        label: "Program",
        name: "programName",
        type: "select",
        options: [],
        required: false,
        rowNumber: 1
    },
    {
        label: "Client",
        name: "firstName",
        type: "select",
        options: [],
        required: false,
        rowNumber: 1
    },
    {
        label: "Authorization ID",
        name: "authorizationID",
        type: "number",
        required: false,
        rowNumber: 2
    },
    {
        label: "Authorization Number",
        name: "authorizationNumber",
        type: "number",
        required: false,
        rowNumber: 2
    },
    {
        label: "Funding Source",
        name: "fundingSource",
        type: "text",
        required: false,
        rowNumber: 3
    },
    {
        label: "Funding Provider Number",
        name: "fundingProviderNumber",
        type: "number",
        required: false,
        rowNumber: 3
    },
    {
        label: "Service Coordinator First Name",
        name: "servfirstName",
        type: "text",
        required: false,
        rowNumber: 4
    },
    {
        label: "Service Coordinator Last Name",
        name: "servlastName",
        type: "text",
        required: false,
        rowNumber: 4
    },
    {
        label: "Service Coordinator Number",
        name: "serviceCoordinatorNumber",
        type: "number",
        required: false,
        rowNumber: 5
    },
    {
        label: "Service Coordinator Agency",
        name: "organizationAgency",
        type: "text",
        required: false,
        rowNumber: 5
    },
    {
        label: "Service Authorization Begin Date From",
        name: "beginServiceDateFrom",
        type: "date",
        required: false,
        rowNumber: 6
    },
    {
        label: "Service Authorization Begin Date To",
        name: "beginServiceDateTo",
        type: "date",
        required: false,
        rowNumber: 6
    },
    {
        label: "Service Authorization End Date From",
        name: "endServiceDateFrom",
        type: "date",
        required: false,
        rowNumber: 7
    },
    {
        label: "Service Authorization End Date To",
        name: "endServiceDateTo",
        type: "date",
        required: false,
        rowNumber: 7
    },
    {
        label: "Service Description (Code)",
        name: "serviceDescription",
        type: "select",
        options: [],
        required: false,
        rowNumber: 8
    },
    {
        label: "Service Code",
        name: "serviceCode",
        type: "number",
        required: false,
        rowNumber: 8
    },
    {
        label: "ISP Program Name",
        name: "ispProgramName",
        type: "text",
        required: false,
        rowNumber: 9
    },
    {
        label: "ISP Program ID",
        name: "ispProgramID",
        type: "number",
        required: false,
        rowNumber: 9
    },
    {
        label: "Status",
        name: "status",
        type: "select",
        options: [
            { label: 'In Prep', value: 'In Prep', },
            { label: 'Approved', value: 'Approved', },
            { label: 'Disconnected', value: 'Disconnected', },
            { label: 'Pending Approval', value: 'Pending Approval', },
            { label: 'Deleted', value: 'Deleted', }
        ],
        required: false,
        rowNumber: 10
    },
    {
        label: "Method of Data Collection",
        name: "dataCollection",
        type: "select",
        options: [
            { label: 'Billing Data Input', value: 'Billing Data Input' },
            { label: 'Attendance', value: 'Attendance' },
            { label: 'ISP Data', value: 'ISP Data' },
            { label: 'Case Note', value: 'Case Note' },
        ],
        required: false,
        rowNumber: 10
    },
    {
        label: "Other Insurance Indicator",
        name: "insurance",
        type: "radio",
        options: [
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" }
        ],
        required: false,
        rowNumber: 11
    },
]
export const NEW_SERV_AUTH_SEARCH_DATA = {
    firstName: '',
    programName: '',
    authorizationID: '',
    authorizationNumber: '',
    fundingSource: '',
    fundingProviderNumber: '',
    servfirstName: '',
    servlastName: '',
    serviceCoordinatorNumber: '',
    organizationAgency: '',
    beginServiceDateFrom: '',
    beginServiceDateTo: '',
    endServiceDateFrom: '',
    endServiceDateTo: '',
    serviceCode: '',
    serviceDescription: '',
    ispProgramName: '',
    ispProgramID: '',
    status: [],
    dataCollection: [],
    insurance: '',
}
export const NEW_BILLING_DATA = {
    programSite: '',
    client: '',
    authorizationID: '',
    authorizationNumber: '',
    fundingSource: '',
    fundingProviderNumber: '',
    serviceCoordinatorNumber: '',
    serviceDescription: '',
    beginServiceDateFrom: '',
    beginServiceDateTo: '',
    endServiceDateFrom: '',
    endServiceDateTo: '',
}
export const SEARCH_BILLING_DATA = {
    programSite: '',
    client: '',
    authorizationID: '',
    billingDataID: '',
    authorizationNumber: '',
    fundingSource: '',
    fundingProviderNumber: '',
    serviceCoordinatorFirstName: '',
    serviceCoordinatorLastName: '',
    serviceCoordinatorNumber: '',
    serviceDescription: '',
    beginServiceDateFrom: '',
    beginServiceDateTo: '',
    endServiceDateFrom: '',
    endServiceDateTo: '',
    serviceDateFrom: '',
    serviceDateTo: '',
    createDateFrom: '',
    createDateTo: '',
    dataCollection: [],
}


export const SEARCH_PROFESSIONAL_CLAIM_INPUTS = [
    {
        label: "Client",
        name: "individualSearch",
        type: "select",
        options: [],
        required: false,
        rowNumber: 1
    },
    {
        label: "Entered By",
        name: "enteredBy",
        type: "text",
        required: false,
        rowNumber: 1
    },
    {
        label: "Individual Medicaid Number",
        name: "individualMedicaidNumber",
        type: "number",
        required: false,
        rowNumber: 2
    },
    {
        label: "Authorization ID",
        name: "authorizationID",
        type: "number",
        required: false,
        rowNumber: 2
    },
    {
        label: "Funding Source",
        name: "fundingSource",
        type: "text",
        required: false,
        rowNumber: 3
    },
    {
        label: "Service Description",
        name: "serviceDescription",
        type: "text",
        required: false,
        rowNumber: 3
    },
    {
        label: "Billing Data ID",
        name: "billingData",
        type: "number",
        required: false,
        rowNumber: 4
    },
    {
        label: "Claim ID",
        name: "claimID",
        type: "number",
        required: false,
        rowNumber: 4
    },
    {
        label: "NPI Number",
        name: "NPINumber",
        type: "number",
        required: false,
        rowNumber: 5
    },
    {
        label: "Billing Provider Name",
        name: "billingProviderName",
        type: "text",
        required: false,
        rowNumber: 5
    },
    {
        label: "Billing Provider Organization Name",
        name: "billingProviderOrganization",
        type: "text",
        required: false,
        rowNumber: 6
    },
    {
        label: "First Name",
        name: "firstName",
        type: "text",
        required: false,
        rowNumber: 6
    },
    {
        label: "Last Name",
        name: "lastName",
        type: "text",
        required: false,
        rowNumber: 7
    },
    {
        label: "Medicaid Provider Number",
        name: "medicaidProviderNumber",
        type: "number",
        required: false,
        rowNumber: 7
    },
    {
        label: "Service Date From",
        name: "serviceDateFrom",
        type: "date",
        required: false,
        rowNumber: 8
    },
    {
        label: "Service Date To",
        name: "serviceDateTo",
        type: "date",
        required: false,
        rowNumber: 8
    },
    {
        label: "Claim Create Date From",
        name: "claimDateFrom",
        type: "date",
        required: false,
        rowNumber: 9
    },
    {
        label: "Claim Create Date To",
        name: "claimDateTo",
        type: "date",
        required: false,
        rowNumber: 9
    },
    {
        label: "Payer",
        name: "payer",
        type: "multiSelect",
        options: [
            { label: 'FL MMIS: Florida Medicaid - 77027 (Prof.)', value: 'FL MMIS: Florida Medicaid - 77027 (Prof.)' },
            { label: 'THERAP-MANUAL: Manual Billing - 99 (Prof. & Inst.)', value: 'THERAP-MANUAL: Manual Billing - 99 (Prof. & Inst.)' },
        ],
        required: false,
        rowNumber: 10
    },
    {
        label: "Status",
        name: "status",
        type: "multiSelect",
        options: [
            { label: 'Billable', value: 'Billable', },
            { label: 'Queued', value: 'Queued', },
            { label: 'Sent', value: 'Sent', },
            { label: 'Pending', value: 'Pending', },
            { label: 'Denied', value: 'Denied', },
            { label: 'Paid', value: 'Paid', },
            { label: 'Paid Adjusted', value: 'Paid Adjusted', },
            { label: 'Voided', value: 'Voided', },
            { label: 'Rejected', value: 'Rejected', },
            { label: 'Deleted', value: 'Deleted', }
        ],
        required: false,
        rowNumber: 10
    },
    {
        label: "Claim Frequency Type Code",
        name: "claimFrequencyTypeCode",
        type: "multiSelect",
        options: [
            { label: '1-Original (Admit thru Discharge Claim)', value: '1-Original (Admit thru Discharge Claim)' },
            { label: '2-Interim - First Claim', value: '2-Interim - First Claim' },
            { label: '3-Interim - Continuing Claim', value: '3-Interim - Continuing Claim' },
            { label: '4-Interim - Last Claim', value: '4-Interim - Last Claim' },
            { label: '6-Corrected (Adjustment of Prior Claim)', value: '6-Corrected (Adjustment of Prior Claim)' },
            { label: '7-Replacement (Replacement of Prior Claim)', value: '7-Replacement (Replacement of Prior Claim)' },
            { label: '8-Void (Void/Cancel of Prior Claim)', value: '8-Void (Void/Cancel of Prior Claim)' },
        ],
        required: false,
        rowNumber: 11
    },
    {
        label: "Other Insurance Indicator",
        name: "group",
        type: "radio",
        options: [
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" }
        ],
        required: false,
        rowNumber: 12
    },
]

export const NEW_PROFESSIONAL_CLAIM_DATA = {
    individualSearch: '',
    enteredBy: '',
    individualMedicaidNumber: '',
    authorizationID: '',
    fundingSource: '',
    serviceDescription: '',
    billingData: '',
    claimID: '',
    NPINumber: '',
    billingProviderName: '',
    billingProviderOrganization: '',
    firstName: '',
    lastName: '',
    medicaidProviderNumber: '',
    serviceDateFrom: '',
    serviceDateTo: '',
    ClaimDateFrom: '',
    ClaimDateTo: '',
    payer: [],
    status: [],
    claimFrequencyTypeCode: [],
    group: '',
}