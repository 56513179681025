import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link, useLocation, useHistory} from 'react-router-dom'
import {Tabs, Tab, useMediaQuery, useTheme} from '@mui/material'
import {hasPermission} from '../../../../_helper/hasPermission'
import {permissions} from '../../../../_constants/permissions'

const selectAuth = (state) => state.auth

function AdminCareHeader() {
  const [value, setValue] = useState(0)
  const [availableTabs, setAvailableTabs] = useState([])
  const location = useLocation()
  const history = useHistory()
  const path = location.pathname
  const auth = useSelector(selectAuth)
  const userPermissions = auth.user.roleId

  // Define permission-based tab visibility
  const canViewClients = hasPermission(userPermissions, permissions.admin_care_view) // Assuming all users can view clients
  const canViewContact = hasPermission(userPermissions, permissions.admin_care_contact_view) // Assuming all users can view contact
  const canViewEnrollment = hasPermission(userPermissions, permissions.admin_care_enrollment_view)
  const canViewInsurance = hasPermission(userPermissions, permissions.admin_care_insurance_view) // Assuming all users can view insurance
  const canViewHealthInfo = hasPermission(
    userPermissions,
    permissions.admin_care_healthProfile_view
  ) // Assuming all users can view health info
  const canViewIncidentReport = hasPermission(
    userPermissions,
    permissions.admin_care_clientLog_view
  ) // Assuming all users can view incident reports

  // Effect for updating the available tabs based on permissions
  useEffect(() => {
    // Define tabs based on permissions
    const tabs = [
      canViewClients ? {label: 'Clients', path: '/admin/care/individual-intake'} : null,
      canViewContact ? {label: 'Contact', path: '/admin/care/contact'} : null,
      canViewEnrollment ? {label: 'Enrollment', path: '/admin/care/enrollment'} : null,
      canViewInsurance ? {label: 'Insurance', path: '/admin/care/insurance'} : null,
      canViewHealthInfo ? {label: 'Health Info', path: '/admin/care/health'} : null,
      canViewIncidentReport
        ? {label: 'Incident Report', path: '/admin/care/incident-report'}
        : null,
    ].filter(Boolean)
    setAvailableTabs(tabs)
    const currentPath = window.location.pathname // Get the current path
    let currentTabPath = ''

    const currentTabIndex = tabs.findIndex((tab) => {
      if (currentPath.startsWith(tab.path)) {
        currentTabPath = tab.path
        return true // Return true to mark the matching tab
      }
      return false // Return false for non-matching tabs
    })
    if (currentTabIndex !== -1) {
      setValue(currentTabIndex)
    } else if (tabs.length > 0) {
      if (canViewEnrollment && path == '/admin/care/enrollment') {
        history.push(`${tabs[0].path}/by-client`)
      } else  {
        history.push(`${tabs[0].path}/list`)
      }
    }
  }, [
    canViewClients,
    canViewContact,
    canViewEnrollment,
    canViewInsurance,
    canViewHealthInfo,
    canViewIncidentReport,
    path,
    history,
  ])

  // Handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue)
    history.push(availableTabs[newValue].path)
  }

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-3 pb-0'>
          <div className='d-flex overflow-auto h-60px'>
            <Tabs
              value={value}
              onChange={handleChange}
              variant='scrollable'
              scrollButtons={isSmallScreen ? 'auto' : 'on'}
              orientation='horizontal'
              aria-label='Admin Care Tabs'
              className='w-100'
              sx={{
                flexGrow: 1,
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                display: 'flex',
                justifyContent: 'space-between',

                '.MuiTab-root': {
                  textTransform: 'none',
                  minWidth: 120,
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                },
                '.Mui-selected': {
                  color: theme.palette.primary.main,
                },
                '.MuiTabs-indicator': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              {availableTabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  sx={{flexGrow: 1}}
                  component={Link}
                  to={tab.path}
                />
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminCareHeader
