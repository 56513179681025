import React from 'react'
import { Button } from '@mui/material'
import { useHistory } from 'react-router-dom'

const BackButton = () => {
    const history = useHistory()
    return (
        <Button
            variant="outlined"
            type='button'
            sx={{ height: '2.3rem', minWidth: '86px', float: 'right', marginRight: '5px' }}
            onClick={() => history.goBack()}
        >Back</Button>
    )
}

export default BackButton