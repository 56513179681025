import React, { useState } from 'react'
import { resetPassword } from '../redux/AuthCRUD'
import { TextField, Button, Box } from "@mui/material";
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom';

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const isInvitedUser = searchParams.get('isInvitedUser');

  const handleSubmit = async (e) => {
    if (confirmPassword?.length && password !== confirmPassword) {
      toast.error("Password Mismatch")
    } else {
      await resetPassword(email, password, token, isInvitedUser)
        .then(() => {
          setLoading(false);
          toast.success("Password Reset Successfully")
        })
        .catch(() => {
          setLoading(false);
        })
    }
  }

  return (
    <>
      <div
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        id='kt_login_password_reset_form'
      >
        <form onSubmit={handleSubmit}>
          <TextField
            label="New Password"
            type="password"
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Box mt={2} />
          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Box mt={2} />
          <div style={{ textAlign: "center" }}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </>
  )
};