import {TextField} from '@mui/material'

const NumberField = ({field, handleInputChange, formData , disabled}) => {
  return (
    <TextField
      sx={field.sx || {}}
      defaultValue={field.defaultValue ? field.defaultValue : undefined}
      label={field.label}
      variant='outlined'
      type='text'
      size='small'
      name={field.name}
      value={field.value ? field.value : formData[field.name] || ''}
      disabled={field?.disabled || disabled}
      onChange={(e) => {
        const phoneNumber = e?.target?.value?.replace(/[^0-9]/g, '').slice(0, 50)
        handleInputChange(e.target.name, phoneNumber, '', e.target.type)
      }}
      required={field.required || false}
      className='textField'
      error={Boolean(
        field.validation &&
          formData[field.name] &&
          !field.validation.pattern.test(formData[field.name])
      )}
      helperText={
        field.validation &&
        formData[field.name] &&
        !field.validation.pattern.test(formData[field.name])
          ? field.validation.message
          : ''
      }
    />
  )
}

export default NumberField
