export const LIST_OF_SHARED_CONTACTS_COLUMNS_DATA_MAPPING = {
  Name: 'name',
  Type: 'typeMod',
  'Organization Name': 'organizationName',
  Speciality: 'speciality',
  'NPI Number': 'npiNumber',
  'Mailing Address': 'mailingaddress',
  'Primary Phone': 'primaryPhone',
  Email: 'email',
}
export const LIST_OF_SHARED_CONTACTS_SORTING_COLUMNS = {
  Name: 'name',
  Type: 'typeMod',
  'Organization Name': 'organizationName',
  Speciality: 'speciality',
  'NPI Number': 'npiNumber',
  'Mailing Address': 'mailingaddress',
  'Primary Phone': 'primaryPhone',
  Email: 'email',
}
export const LIST_OF_SHARED_CONTACTS_COLUMNS = [
  'Name',
  'Type',
  'Organization Name',
  'Speciality',
  'NPI Number',
  'Mailing Address',
  'Primary Phone',
  'Email',
]

export const LIST_OF_CLIENT_COLUMNS_DATA_MAPPING = {
  'Client Name': 'name',
  'Birth Date': 'birthDate',
  'Phone Number': 'phoneNumber',
  Address: 'mailingaddress',
}
export const LIST_OF_CLIENT_SORTING_COLUMNS = {
  'Client Name': 'name',
  'Birth Date': 'birthDate',
  'Phone Number': 'phoneNumber',
  Address: 'mailingaddress',
}
export const ADDED_CLIENTS_LIST_COLUMNS = ['Client Name', 'Birth Date', 'Phone Number', 'Address']

export const LIST_OF_SITE_COLUMNS = [
  'Site Name',
  'Site Code',
  'Site ID',
  'PROGRAM COST CENTER NUMBER',
]
export const LIST_OF_SITE_SORTING_COLUMNS = {
  'Site Name': 'siteName',
  'Site Code': 'siteCode',
  'Site ID': 'siteID',
  'PROGRAM COST CENTER NUMBER': 'programCostCenterNumber',
}
export const LIST_OF_SITE_COLUMN_DATA_MAPPING = {
  'Site Name': 'siteName',
  'Site Code': 'siteCode',
  'Site ID': 'siteID',
  'PROGRAM COST CENTER NUMBER': 'programCostCenterNumber',
}

export const LIST_OF_PROGRAM_COLUMNS = [
  'Program Name',
  'Program Type',
  'PCCN',
  'Site Name',
  'Program Code',
  'Program ID',
]
export const LIST_OF_PROGRAM_SORTING_COLUMNS = {
  'Program Name': 'programName',
  'Program Type': 'programType',
  PCCN: 'programCostCenterNumber',
  'Site Name': 'siteName',
  'Program Code': 'programCode',
  'Program ID': 'programID',
}
export const LIST_OF_PROGRAM_COLUMN_DATA_MAPPING = {
  'Program Name': 'programName',
  'Program Code': 'programCode',
  'Program ID': 'programID',
  PCCN: 'programCostCenterNumber',
  'Program Type': 'programType',
  'Site Name': 'siteNameMod',
}

export const LIST_OF_SC_COLUMNS = ['Name', 'Date Created']
export const LIST_OF_SC_SORTING_COLUMNS = {
  Name: 'name',
  'Date Created': 'createDate',
}
export const LIST_OF_SC_COLUMN_DATA_MAPPING = {
  Name: 'name',
  'Date Created': 'createDate',
}
export const htmlColumns = ['Level Name/Acronym']
export const LIST_OF_SM_COLUMNS = ['Method Name', 'Level Name/Acronym',]
export const LIST_OF_SM_SORTING_COLUMNS = {
  'Method Name': 'methodName',
  'Level Name/Acronym': 'levelName',
}
export const LIST_OF_SM_COLUMN_DATA_MAPPING = {
  'Method Name': 'methodName',
  'Level Name/Acronym': 'levelName',
}
