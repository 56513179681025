import { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { NEW_SHARED_CONTACT_INPUTS } from '../../GENERAL_CONSTANTS'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import { useHistory, useParams } from 'react-router-dom'

const SharedContactEditForm = () => {
    const [formData, setFormData] = useState({})
    const [sharedTypes, setSharedTypes] = useState([])
    const parameter = useParams()
    const history = useHistory()

    useEffect(() => {
        const fetchData = async () => {
            const [SharedContact, SharedContactType] = await Promise.all([
                axios.get(`/shared/getOne?id=${parameter.id}`),
                axios.get("/sharedtype/get")
            ])
            const { data }= SharedContact
            const updatedTypeOptions = [
                ...SharedContactType.data.data.map((type) => ({ label: type.name, value: type._id })),
            ];
            
            const constantsWithUpdatedSharedContacts = NEW_SHARED_CONTACT_INPUTS.map((field) => {
                if (field.name === 'type') {
                    return {
                        ...field,
                        options: updatedTypeOptions,
                    };
                }
                return field;
            });

            const tempObj = {
                _id: data._id,
                title: data.title,
                firstName: data.firstName,
                lastName: data.lastName,
                organizationName: data.organizationName,
                npiNumber: data.npiNumber,
                type: data.type,
                speciality: data.speciality,
                email: data.email,
                addrInfo: {
                    primaryPhone: data.addrInfo.primaryPhone,
                    country: data.addrInfo.country,
                    st1: data.addrInfo.st1,
                    st2: data.addrInfo.st2,
                    city: data.addrInfo.city,
                    state: data.addrInfo.state,
                    zipCode: data.addrInfo.zipCode,
                },
                mailingInfo: {
                    st1: data.mailingInfo.mailingSt1,
                    st2: data.mailingInfo.mailingSt2,
                    city: data.mailingInfo.mailingCity,
                    state: data.mailingInfo.mailingState,
                    zipCode: data.mailingInfo.mailingZipCode,
                    country: data.mailingInfo.mailingCountry,
                    primaryPhone: data.mailingInfo.mailingPrimaryPhone,
                }
            }
            setFormData(tempObj)
            setSharedTypes(constantsWithUpdatedSharedContacts)
        }
        if (parameter.id) {
            fetchData()
        } else {
            history.push('/admin/general/shared-contact/list')
        }
    }, [])


    const handleSubmitForm = async (formData) => {
        const tempObj = {
            _id: formData._id,
            title: formData.title,
            firstName: formData.firstName,
            lastName: formData.lastName,
            organizationName: formData.organizationName,
            npiNumber: Number(formData.npiNumber),
            type: formData.type,
            speciality: formData.speciality,
            email: formData.email,
            addrInfo: {
                primaryPhone: Number(formData?.addrInfo?.primaryPhone),
                country: formData?.addrInfo?.country,
                st1: formData?.addrInfo?.st1,
                st2: formData?.addrInfo?.st2,
                city: formData?.addrInfo?.city,
                state: formData?.addrInfo?.state,
                zipCode: Number(formData?.addrInfo?.zipCode)
            },
            mailingInfo: {
                mailingSt1: formData?.mailingInfo?.st1,
                mailingSt2: formData?.mailingInfo?.st2,
                mailingCity: formData?.mailingInfo?.city,
                mailingState: formData?.mailingInfo?.state,
                mailingZipCode: Number(formData?.mailingInfo?.zipCode),
                mailingCountry: formData?.mailingInfo?.country,
                mailingPrimaryPhone: Number(formData?.mailingInfo?.primaryPhone),
            }
        };
        try {
            const response = await axios.put("/shared/update", tempObj);
            if(response?.data?.status === 400 && response?.data?.success === false) {
                toast.error(response?.data?.message)
            } else if (response.data && response.data.success) {
                toast.success(response.data.message || 'Success', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                history.push({
                    pathname: `/admin/general/shared-contact/list`
                })
            } else {
                toast.error(response?.data?.message || 'Error', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    const buttons = [
        { label: 'Cancel', variant: 'outlined', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];


    console.log(sharedTypes , NEW_SHARED_CONTACT_INPUTS,formData)

    return (
        <FormWrapper
            title='Edit Shared Contact'
            content={
                <DynamicForm
                    fields={sharedTypes}
                    data={formData}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        />
    )
}

export default SharedContactEditForm