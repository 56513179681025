import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import SearchClaimTemplate from './search/SearchClaimTemplate'
import NewClaimTemplate from './new/NewClaimTemplate'
import ClaimTemplateHeader from './ClaimTemplateHeader'

function ClaimTemplateIndex() {
  return (
    <div>
      <ClaimTemplateHeader/>
    </div>
  )
}

export default ClaimTemplateIndex