export const NOTES_LISTING_COLUMNS = [
  'Name',
  'Client Name',
  'Program Name',
  'Service Description',
  'Frequency',
  'Status',
]
export const NOTES_SORTING_COLUMNS = {
  'Name': 'noteName',
  'Client Name': 'clientName',
  'Program Name': 'programName',
  'Service Description':'serviceName',
  'Frequency': 'frequency',
  'Status': 'isApproved',
}
export const NOTES_COLUMNS_DATA_MAPPING = {
  'Name': 'noteName',
  'Client Name': 'clientName',
  'Program Name': 'programName',
  'Service Description':'serviceName',
  'Frequency': 'frequency',
  'Status': 'isApproved',
}

export const NOTES_ENTRIES_COLUMNS = [
  // 'Creation Date',
  'Begin Date',
  'End Date',
  'Location',
  'Created By',
]
export const NOTES_ENTRIES_SORTING_COLUMNS = {
  // 'Creation Date': 'creationDateFormatted',
  'Begin Date': 'beginTime',
  'End Date': 'endTime',
  'Location':'locationAddress',
  'Created By': 'createdBy',
}
export const NOTES_ENTRIES_COLUMNS_DATA_MAPPING = {
  // 'Creation Date': 'creationDateFormatted',
  'Begin Date': 'beginTime',
  'End Date': 'endTime',
  'Location':'locationAddress',
  'Created By': 'createdBy',
}
