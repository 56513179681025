import { permissions } from "../../../_constants/permissions";
import NoteNew from './assets/noteNew.png'
import NotesList from './assets/NotesList.png'
import ClientBehavior from './assets/behaviour.png'
import BehaviorExport from './assets/BehaviorExport.png'
import ClientList from './assets/search-client.png'
import CareGiver from './assets/assistant.png'
import CareGiverNew from './assets/hand.png'
import LogsNew from './assets/add-properties.png'
import Enrollment from './assets/sign-up.png'
import MyAvaiablity from './assets/schedules.png'
import Schedules from './assets/calender.png'
import TimeOff from './assets/coffee-time.png'
import CompanyTimeSheet from './assets/companyTimeSheet.png'
import TimeSheets from './assets/timesheet.png'
import AttendanceNew from './assets/fingerprint.png'
import AttendanceSearch from './assets/attendanceSearch.png'
import AuthorizationNew from './assets/locksmith.png'
import AuthorizationList from './assets/authorization.png'
import MedicationEntry from './assets/medication.png'
import MedicationList from './assets/medicationList.png'
import PersonAddIcon from '@mui/icons-material/PersonAdd';


export const iconsData = [
    { png: ClientList, title: 'Clients List', link: '/admin/care/individual-intake/list', permission: permissions.admin_care_view },
    { icon: PersonAddIcon, title: 'New Client', link: '/admin/care/individual-intake/new', permission: permissions.admin_care_create },
    {
        svg: `<svg fill="#ffffff" width="60px" height="60px" viewBox="0 0 32 32" 
        version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier"> <title>logs</title>
         <path d="M0 24q0 0.832 0.576 1.44t1.44 0.576h1.984q0 2.496 1.76 4.224t4.256 1.76h6.688q-2.144-1.504-3.456-4h-3.232q-0.832 0-1.44-0.576t-0.576-1.408v-20q0-0.832 0.576-1.408t1.44-0.608h16q0.8 0 1.408
          0.608t0.576 1.408v7.232q2.496 1.312 4 3.456v-10.688q0-2.496-1.76-4.256t-4.224-1.76h-16q-2.496 0-4.256 1.76t-1.76 4.256h-1.984q-0.832 0-1.44 0.576t-0.576
           1.408 0.576 1.44 1.44 0.576h1.984v4h-1.984q-0.832 0-1.44 0.576t-0.576 1.408 0.576 1.44 1.44 0.576h1.984v4h-1.984q-0.832 0-1.44 0.576t-0.576 1.408zM10.016 24h2.080q0-0.064-0.032-0.416t-0.064-0.576
            0.064-0.544 0.032-0.448h-2.080v1.984zM10.016 20h2.464q0.288-1.088 0.768-1.984h-3.232v1.984zM10.016 16h4.576q0.992-1.216 2.112-1.984h-6.688v1.984zM10.016 12h16v-1.984h-16v1.984zM10.016 8h16v-1.984h-16v1.984zM14.016
             23.008q0 1.824 0.704 3.488t1.92 2.88 2.88 1.92 3.488 0.704 3.488-0.704 2.88-1.92 1.92-2.88 0.704-3.488-0.704-3.488-1.92-2.88-2.88-1.92-3.488-0.704-3.488 0.704-2.88 1.92-1.92 2.88-0.704 3.488zM18.016 23.008q0-2.080 1.44-3.52t3.552-1.472
              3.52 1.472 1.472 3.52q0 2.080-1.472 3.52t-3.52 1.472-3.552-1.472-1.44-3.52zM22.016 23.008q0 0.416 0.288 0.704t0.704 0.288h1.984q0.416 0 0.704-0.288t0.32-0.704-0.32-0.704-0.704-0.288h-0.992v-0.992q0-0.416-0.288-0.704t-0.704-0.32-0.704 0.32-0.288 0.704v1.984z">
              </path> 
              </g>
        </svg>`, title: 'Incident Report List', link: '/admin/care/incident-report/list', permission: permissions.admin_care_clientLog_view
    },
    { png: LogsNew, title: 'Incident Report New', link: '/admin/care/incident-report/new', permission: permissions.admin_care_clientLog_create },
    { png: Enrollment, title: 'Enrollment by Clients', link: '/admin/care/enrollment/by-client', permission: permissions.admin_care_enrollment_view },
    { png: CareGiver, title: 'List Caregiver', link: '/caregivers', permission: permissions.care_giver_view },
    { png: CareGiverNew, title: 'New Caregiver', link: '/caregivers/new', permission: permissions.care_giver_create },
    { png: MyAvaiablity, title: 'Caregiver Availability', link: '/caregivers/mySchedules', permission: permissions.care_giver_schedules_myAvailability_view },
    { png: Schedules, title: 'Caregiver Schedules', link: '/caregivers/allSchedules', permission: permissions.care_giver_schedules_view },
    { png: TimeOff, title: 'Caregiver Time Off', link: '/caregivers/timeOffs', permission: permissions.care_giver_schedules_view }, // Will have create permmission for timeOff
    { png: TimeSheets, title: 'Caregiver Timesheet', link: '/caregivers/timesheets' , permission: permissions.care_giver_time_sheets_timeSheets_view},
    { png: CompanyTimeSheet, title: 'All Timesheets', link: '/caregivers/company-timesheets', permission: permissions.care_giver_time_sheets_companySheet_view },
    { png: AttendanceNew, title: 'New Attendance', link: '/billing/attendance/new', permission: permissions.billing_attendance_new_view },
    { png: AttendanceSearch, title: 'Search Attendance', link: '/billing/attendance/search' , permission: permissions.billing_attendance_search_view},
    { png: AuthorizationNew, title: 'Service Authorization New', link: '/billing/professionalclaim/service-authorization/new', permission: permissions.billing_professionalClaim_serviceAuthorization_create },
    { png: AuthorizationList, title: 'Service Authorization List', link: '/billing/professionalclaim/service-authorization/list' , permission: permissions.billing_professionalClaim_serviceAuthorization_view},
    { png: MedicationEntry, title: 'Medication Entry (MARS)', link: '/mars/individual-medication/new', permission: permissions.mars_individual_medication_continued_medication_create },
    { png: MedicationList, title: 'Medication List (MARS)', link: '/mars/individual-medication/list', permission: permissions.mars_individual_medication_continued_medication_view },
    { png: NotesList, title: 'Notes List', link: '/caregivers/notes', permission: permissions.care_giver_notes_view },
    { png: NoteNew, title: 'Notes New', link: '/caregivers/notes/new', permission: permissions.care_giver_notes_note_create },
    { png: ClientBehavior, title: 'Client Behavior', link: '/behaviour/client', permission : permissions.behavior_target_view },
    { png: BehaviorExport, title: 'Export Behavior', link: '/behaviour/export', permission: permissions.behavior_export_view},
];