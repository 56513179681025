import React from 'react'
import ExportXLSX from '../../../_helper/exportCSVHelper/ExportToXlsx'
interface Header {
  label: string
  key: string
  width?: number
}

interface TableExportToXlsxButtonProps {
  headers: Header[]
  exportData: any[]
  title: string
  fileName: string
}
const TableExportToXlsxButton: React.FC<TableExportToXlsxButtonProps> = ({
  title,
  headers,
  exportData,
  fileName,
}) => {
  return (
    <div className='d-flex justify-content-start pb-6'>
      <div className='mr-2'>
        <ExportXLSX headerProp={headers} filename={fileName} data={exportData} title={title} />
      </div>
    </div>
  )
}

export default TableExportToXlsxButton
