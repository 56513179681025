import React, {useState} from 'react'
import axios from 'axios'
import * as XLSX from 'xlsx'
import {toast} from 'react-toastify'
import {styled} from '@mui/material/styles'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
const UploadComponent = () => {
  const [isUploading, setIsUploading] = useState(false)
  const handleUpload = async (type: 'client' | 'contact', event: any) => {
    // setIsUploading(true)
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = async (event: any) => {
        const data = new Uint8Array(event.target.result)
        const workbook: any = XLSX.read(data, {type: 'array'})
        if (!workbook?.SheetNames.length) {
          toast.error('No page found in the file')
          return
        }
        const clientSheet = workbook.SheetNames[0]

        const finalData = XLSX.utils
          .sheet_to_json(workbook.Sheets[clientSheet])
          .map((client: any) => {
            const contacts = XLSX.utils.sheet_to_json(workbook.Sheets['client_contacts'])

            const filteredContacts = contacts.filter(
              (contact: any) => contact.client_Id === client.Client_ID
            )
            client.contacts = filteredContacts

            // const sites = XLSX.utils.sheet_to_json(workbook.Sheets['client_sites'])

            // let filteredSites = sites.filter((site: any) => site.Client_ID === client.Client_ID)
            let sharedContacts = XLSX.utils.sheet_to_json(workbook.Sheets['client_shared_contacts'])
            const sharedContactClients = XLSX.utils.sheet_to_json(
              workbook.Sheets['shared_contacts_added_clients']
            )
            sharedContacts = sharedContacts.filter(
              (contact: any) => contact.Client_ID === client.Client_ID
            )

            sharedContacts = sharedContacts.map((sharedContact: any) => {
              sharedContact.clientAdded = sharedContactClients.filter(
                (sharedContactClient: any) =>
                  sharedContactClient.Contact_ID === sharedContact.Contact_ID
              )

              return sharedContact
            })

            client.sharedContacts = sharedContacts

            // const newFilteredSites = filteredSites.map((site: any) =>
            //   programs.filter((program: any) => site.siteID === program.siteId)
            // )
            // console.log(filteredSites)
            // client.sites = filteredSites?.length ? newFilteredSites : []

            // const filteredPrograms = programs.filter(
            //   (program: any) => program.Client_ID === client.Client_ID
            // )
            // client.programs = filteredPrograms

            return client
          })
        try {
          const response = await axios.post('clients/import', {
            data: finalData,
            fileName: file.name,
          })
          if (response.data.success) {
            toast.success(response.data.message)
            return
          }
          toast.error(response.data.message)
        } catch (err) {
          toast.error(err?.message || 'Something went wrong')
        } finally {
          setIsUploading(false)
        }
      }

      reader.readAsArrayBuffer(file)
    }
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <InputFileUpload
          disabled={isUploading}
          message='Upload Clients'
          handleUpload={(event) => handleUpload('client', event)}
        />
        <DownloadButton file={''} label={'Download Clients Template'} />
      </div>
      {/* <Divider style={{marginTop: 20, marginBottom: 20}} />
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <InputFileUpload
          disabled={isUploading}
          message='Upload Contacts'
          handleUpload={(event) => handleUpload('contact', event)}
        />
        <DownloadButton file={ContactTemplate} label={'Download Contacts Template'} />
      </div> */}
    </div>
  )
}

export default UploadComponent

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

function InputFileUpload({message, disabled, handleUpload}) {
  return (
    <Button
      style={{width: '100px !important'}}
      component='label'
      role={undefined}
      variant='contained'
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      disabled={disabled}
    >
      {message}
      <VisuallyHiddenInput type='file' onChange={handleUpload} />
    </Button>
  )
}

const DownloadButton = ({label, file}) => {
  return (
    <Button variant='contained' color='primary' component='label'>
      <a href={file} style={{color: 'white'}} target='_blank' download>
        {label}
      </a>
    </Button>
  )
}
