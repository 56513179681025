import React from 'react'
import {Route, Switch} from 'react-router-dom'
import ServiceProvider from '../../../../../pages/admin/service-directory/service-provider/new/ServiceProvider'
import ListProvider from '../../../../../pages/admin/service-directory/service-provider/list/ListProvider'
import Search from '../../../../../pages/admin/service-directory/service-provider/search/Search'

const ProviderIndexRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path='/admin/service-directory/service-provider/new'>
        <ServiceProvider />
      </Route>
      <Route path='/admin/service-directory/service-provider/list'>
        <ListProvider />
      </Route>
      <Route path='/admin/service-directory/service-provider/search'>
        <Search />
      </Route>
    </Switch>
  )
}

export default ProviderIndexRoutes
