import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import ChangePasswordHeader from './ChangePasswordHeader'
import UserList from './user-list/UserList'

function ChangePasswordIndex() {
    return (
        <div>
            <ChangePasswordHeader />
        </div>
    )
}

export default ChangePasswordIndex