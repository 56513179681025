import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { addFundingSource } from '../store/actions'
import { getPayer } from '../../payers-setting/store/actions'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FUNDING_SOURCE_FORM_INPUTS, NEW_FUNDING_SOURCE } from '../../BILLING_CONSTANTS'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'

const New = () => {
    const [payerName, setPayerName] = useState([])
    const [updatedFundingSource, setUpdatedFundingSource] = useState([])
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            const result = await dispatch(getPayer({}))
            const tempObj = result?.payload?.data?.data;
            const updatedPayerOptions = [
                ...tempObj.map((payer) => ({ label: payer.name, value: payer._id })),
            ];
            setPayerName(updatedPayerOptions)
            const constantsWithUpdatedPayer = FUNDING_SOURCE_FORM_INPUTS.map((field) => {
                if (field.name === 'payer') {
                    return {
                        ...field,
                        options: updatedPayerOptions,
                    };
                }
                return field;
            });
            setUpdatedFundingSource(constantsWithUpdatedPayer)
        }
        fetchData()
    }, [ dispatch])

    const handleSubmitForm = async (formData) => {
        const tempObj = {
            name: formData.name,
            fundingProvider: Number(formData.fundingProvider),
            vendorID: Number(formData.vendorID),
            addrInfo: {
                st1: formData?.addrInfo?.st1,
                st2: formData?.addrInfo?.st2,
                city: formData?.addrInfo?.city,
                state: formData?.addrInfo?.state,
                country: formData?.addrInfo?.country,
                zipCode: Number(formData?.addrInfo?.zipCode),
                primaryPhone: Number(formData?.addrInfo?.primaryPhone),
            },
            title: formData.title,
            firstName: formData.firstName,
            lastName: formData.lastName,
            payer: formData.payer,
            payerType: formData.payerType,
            email: formData.email,
        }
        const response = await dispatch(addFundingSource(tempObj))
        if(response?.payload?.status === 400 && response?.payload?.success === false) {
            toast.error(response?.payload?.message)
        } else if (response?.payload.success) {
            toast.success(response?.payload.message || 'success', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            history.push('/admin/billing/funding-source/list')
        } else {
            toast.error(response?.payload?.response?.data?.message || response?.payload?.message || 'Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    };
    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' }
    ];

    return (
        <FormWrapper
            title="Funding Source"
            content={
                <DynamicForm
                    fields={updatedFundingSource}
                    data={NEW_FUNDING_SOURCE}
                    onSubmit={handleSubmitForm}
                    buttons={buttons}
                />
            }
        />
    )
}

export default New