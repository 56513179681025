import React from 'react'
import { Route, Switch } from 'react-router-dom'
import New from '../../../../../pages/billing/professionalclaim/billing-data/new/New'
import Search from '../../../../../pages/billing/professionalclaim/billing-data/search/Search'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { RouteWithPermission } from '../../../../../../_helper/routesWithPermission'
import { Error404 } from '../../../../../modules/errors/components/Error404'

const BillingDataIndexRoutes: React.FC = () => {
    const auth = useSelector((state: { auth: any }) => state.auth);
    const userPermissions = auth.user.roleId;
    const canCreate = hasPermission(userPermissions, 'BILLING.PROFESSIONAL_CLAIM.BILLING_DATA.CREATE');
    const canSearch = hasPermission(userPermissions, 'BILLING.PROFESSIONAL_CLAIM.BILLING_DATA.SEARCH');

    return (
        <Switch>
            <RouteWithPermission path='/professionalclaim/billing-data/new' component={New} hasPerm={false} />
            <RouteWithPermission path='/professionalclaim/billing-data/search' component={Search} hasPerm={false} />
            <Route path='/error/404' component={Error404} />
        </Switch>
    )
}

export default BillingDataIndexRoutes
