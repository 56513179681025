import Select from "react-select";
import { useField } from "formik";
import React from "react";
const SelectField = (props) => {
    const [state, { setValue }] = useField(props.field.name);

    // value is an array now
    const onChange = (value) => {
        setValue(value);
    };

    return (
        <div>
            <Select
                {...props}
                value={state?.value}
                className="react-select-container"
                classNamePrefix="react-select"
                isMulti
                onChange={onChange}

            />
        </div>
    );
}
export default SelectField 