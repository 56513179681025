import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface Contact {
    clientID: string;
    firstName: string;
    lastName: string;
    agency: string;
    comments: string;
    relationtoind: string;
    isGuardian: Boolean;
    isAddress: Boolean;
    isMailing: Boolean;
    guardianInfo: {
        guardianAuthority: string;
        guardianEstablishDate: string;
        guardianEstablishEndDate: string;
        guardianType: string;
    },
    addressInfo: {
        st1: string;
        st2: string;
        primaryPhone: string;
        secondaryPhone: string;
        additionalPhone: string;
        faxNumber: string;
        email: string;
        webaddress: string;
        zipCode: string;
        state: string;
        city: string;
    },
    mailingInfo: {
        mailingSt1: string;
        mailingSt2: string;
        mailingPrimaryPhone: string;
        mailingSecondaryPhone: string;
        mailingAdditionalPhone: string;
        mailingFaxNumber: string;
        mailingZipCode: string;
        mailingState: string;
        mailingCity: string;
    }
}

export const getContact = createAsyncThunk(
    'contacts/get/',
    async () => {
        try {
            const response = await axios.get('contacts/get')
            return {
                data: response.data
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getClientContacts = createAsyncThunk(
    'contacts/getClientContacts/',
    async ({ clientID, page, pageSize, sortBy, sortDir, searchTerm }: { clientID: string | undefined, page: number, pageSize: number, sortBy: string, sortDir: string, searchTerm: string }) => {
        try {
            const response = await axios.get(`contacts/getClientContacts/${clientID}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${searchTerm}`)
            return {
                data: response.data
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const addContact = createAsyncThunk(
    'contacts/create',
    async (data: Contact) => {
        try {
            const response = await axios.post('contacts/create', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const deleteContact = createAsyncThunk(
    'contacts/delete/',
    async (data: string) => {
        try {
            const response = await axios.delete('contacts/delete/' + data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const updateContact = createAsyncThunk(
    'contacts/update',
    async (data: Contact) => {
        try {
            const response = await axios.put('contacts/update', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

