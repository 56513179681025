import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import InstitutionHeader from './InstitutionHeader'
import New from './new/New'
import List from './list/List'
import ImportFromExcel from './import-from-excel/ImportFromExcel'

function InstitutionIndex() {
    return (
        <div>
            <InstitutionHeader />
        </div>
    )
}

export default InstitutionIndex