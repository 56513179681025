import React, { useEffect, useRef, useState } from 'react';
import PermissionSectionContainer from './PermissionSectionContainer';
import { TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import RoleModal from './RoleModal';
import { toast } from 'react-toastify'
import TableWrapper from '../../reusable-components/tables/TableWrapper';

function Roles() {
    const history = useHistory();
    const [allPermissions, setAllPermissions] = useState({});
    const [title, setTitle] = useState("");
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showMessages, setShowMessages] = useState(false);
    const { id } = useParams();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const roleNameRef = useRef(null);
    const [isRoleNameEmpty, setIsRoleNameEmpty] = useState(false);
    const permissionRef = useRef({})
    const handleDiscardDelete = () => {
        setShowDeleteModal(false);
    };

    const handleConfirmDelete = () => {
        setShowDeleteModal(false);
        setAllPermissions({});
        setTitle('')
        roleNameRef.current.value = "";
        history.push('/roles')
    };

    useEffect(() => {
        if (id === 'new') {
            setTitle("Create Role")
            axios.get('/settings?type=PERMISSIONS').then(({ data }) => {
                if (data?.length && data[0]?.data) {
                    setAllPermissions(data[0].data)
                    permissionRef.current = data[0].data
                } else {
                    setErrorMessage("Error while fetching permissions");
                    setShowMessages(true)
                }
            })
        } else if (id) {
            setTitle("Edit Role")
            axios.get(`/roleManagement/get/${id}`).then(({ data }) => {
                if (data?.permissions) {
                    setAllPermissions(data.permissions);
                    roleNameRef.current.value = data.name;
                    permissionRef.current = data.permissions
                }
            })
        }


    }, [id])

    const handleSave = async (event) => {
        event.preventDefault();
        if (!roleNameRef.current.value) {
            return;
        }

        const data = {
            name: roleNameRef.current.value,
            permissions: permissionRef.current
        };

        try {
            if (id === 'new') {
                const response = await axios.post('roleManagement/create', data);
                if (response.status === 201) {
                    toast.success("Role created successfully!");
                    setTimeout(() => {
                        history.push('/roles');
                    }, 1500);
                }
            } else if (id) {
                const response = await axios.put(`roleManagement/update/${id}`, data);
                if (response.status === 200) {
                    toast.success("Role updated successfully!");
                    setTimeout(() => {
                        history.push('/roles');
                    }, 1500);
                }
            }
        } catch (err) {
            const errorMessage = err.response?.data.message || 'An error occurred';
            toast.error(errorMessage);
        }
    };

    useEffect(() => {
        const delayInMilliseconds = 1500;
        const delayTimeout = setTimeout(() => {
            setShowMessages(null);
            setErrorMessage('');
            setSuccessMessage('');
        }, delayInMilliseconds);

        return () => clearTimeout(delayTimeout);
    }, [errorMessage, successMessage]);

    const handleCancel = () => {
        setShowDeleteModal(true);

    }

    return (
        <div className='card'>
            <div className='card-body'>
                <form onSubmit={handleSave}>
                    <Typography fontSize={20} fontWeight={'bold'} fontFamily="helvetica">{title}</Typography>
                    <TableWrapper
                        searchField={
                            <TextField
                                required
                                sx={{ marginTop: '1.6rem' }}
                                size="small"
                                label="Role Name"
                                variant="outlined"
                                inputRef={roleNameRef}
                                error={isRoleNameEmpty}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        }
                        tableButtons={
                            <>
                                <Button variant="outlined" color="primary" onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button variant="contained" sx={{ margin: '3px' }} color="primary" type="submit">
                                    Save
                                </Button>
                            </>
                        }
                        dynamicTable={
                            <>
                                <PermissionSectionContainer allPermissions={allPermissions} permissionRef={permissionRef} />
                                <RoleModal title='Are you sure you want to discard the changes?' showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} handleConfirmDelete={handleConfirmDelete} handleDiscardDelete={handleDiscardDelete} />
                            </>
                        }
                    />
                </form>
            </div>
        </div>
    );
}

export default Roles