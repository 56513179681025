import { Link, useLocation } from 'react-router-dom'
import AdminCareHeader from '../AdminCareHeader'

const ClientLogsHeader = () => {
    const location = useLocation()
    return (
        <div>
            <AdminCareHeader />
        </div>
    )
}

export default ClientLogsHeader