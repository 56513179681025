export const title = [
    { label: 'Mr', value: 'Mr' },
    { label: 'Miss', value: 'Miss' },
    { label: 'Mrs', value: 'Mrs' },
    { label: 'Ms', value: 'Ms' },
    { label: 'MX', value: 'MX' },
  ];
  
  export const gender = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Unknown', value: 'Unknown' },
  ];

  export const Country = [
    { label: 'United States', value: 'US' },
    { label: 'Pakistan', value: 'PK' }
];  
  export const race = [
    { label: 'American Indian/Alaskan Native', value: 'American Indian/Alaskan Native' },
    { label: 'Asian', value: 'Asian' },
    { label: 'Asian Indian', value: 'Asian Indian' },
    { label: 'Black/African American', value: 'Black/African American' },
    { label: 'Chinese', value: 'Chinese' },
    { label: 'Declined', value: 'Declined' },
    { label: 'Filipino', value: 'Filipino' },
    { label: 'Guamanian or Chamorro', value: 'Guamanian or Chamorro' },
    { label: 'Japanese', value: 'Japanese' },
    { label: 'Korean', value: 'Korean' },
    { label: 'Multiracial', value: 'Multiracial' },
    { label: 'Native Hawaiian/Other Pacific Islander', value: 'Native Hawaiian/Other Pacific Islander' },
    { label: 'Samoan', value: 'Samoan' },
    { label: 'Undetermined', value: 'Undetermined' },
    { label: 'Unknown', value: 'Unknown' },
    { label: 'Vietnamese', value: 'Vietnamese' },
    { label: 'White', value: 'White' },
    { label: 'Other', value: 'Other' },
  ];
  
  export const ethnicity = [
    { label: 'Bengali', value: 'Bengali' },
    { label: 'Central American', value: 'Central American' },
    { label: 'Chakma', value: 'Chakma' },
    { label: 'Chinese', value: 'Chinese' },
    { label: 'Cuban', value: 'Cuban' },
    { label: 'Hispanic', value: 'Hispanic' },
    { label: 'Indian', value: 'Indian' },
    { label: 'Magar', value: 'Magar' },
    { label: 'Malays', value: 'Malays' },
    { label: 'Marma', value: 'Marma' },
    { label: 'Mexican', value: 'Mexican' },
    { label: 'Moor', value: 'Moor' },
    { label: 'Newar', value: 'Newar' },
    { label: 'Not Hispanic or Latino', value: 'Not Hispanic or Latino' },
    { label: 'Other Spanish Origin', value: 'Other Spanish Origin' },
    { label: 'Puerto Rican', value: 'Puerto Rican' },
    { label: 'Sinhalese', value: 'Sinhalese' },
    { label: 'South American', value: 'South American' },
    { label: 'Tamang', value: 'Tamang' },
    { label: 'Tamil', value: 'Tamil' },
    { label: 'Tharu', value: 'Tharu' },
    { label: 'Unable to determine', value: 'Unable to determine' },
  ];
  
  export const states = [
    { label: 'Alabama', value: 'Alabama' },
    { label: 'Alaska', value: 'Alaska' },
    { label: 'Arizona', value: 'Arizona' },
    { label: 'Arkansas', value: 'Arkansas' },
    { label: 'California', value: 'California' },
    { label: 'Colorado', value: 'Colorado' },
    { label: 'Connecticut', value: 'Connecticut' },
    { label: 'Delaware', value: 'Delaware' },
    { label: 'District Of Columbia', value: 'District Of Columbia' },
    { label: 'Florida', value: 'Florida' },
    { label: 'Georgia', value: 'Georgia' },
    { label: 'Hawaii', value: 'Hawaii' },
    { label: 'Idaho', value: 'Idaho' },
    { label: 'Illinois', value: 'Illinois' },
    { label: 'Indiana', value: 'Indiana' },
    { label: 'Iowa', value: 'Iowa' },
    { label: 'Kansas', value: 'Kansas' },
    { label: 'Kentucky', value: 'Kentucky' },
    { label: 'Louisiana', value: 'Louisiana' },
    { label: 'Maine', value: 'Maine' },
    { label: 'Maryland', value: 'Maryland' },
    { label: 'Massachusetts', value: 'Massachusetts' },
    { label: 'Michigan', value: 'Michigan' },
    { label: 'Minnesota', value: 'Minnesota' },
    { label: 'Mississippi', value: 'Mississippi' },
    { label: 'Missouri', value: 'Missouri' },
    { label: 'Montana', value: 'Montana' },
    { label: 'Nebraska', value: 'Nebraska' },
    { label: 'Nevada', value: 'Nevada' },
    { label: 'New Hampshire', value: 'New Hampshire' },
    { label: 'New Jersey', value: 'New Jersey' },
    { label: 'New Mexico', value: 'New Mexico' },
    { label: 'New York', value: 'New York' },
    { label: 'North Carolina', value: 'North Carolina' },
    { label: 'North Dakota', value: 'North Dakota' },
    { label: 'Ohio', value: 'Ohio' },
    { label: 'Oklahoma', value: 'Oklahoma' },
    { label: 'Oregon', value: 'Oregon' },
    { label: 'Pennsylvania', value: 'Pennsylvania' },
    { label: 'Rhode Island', value: 'Rhode Island' },
    { label: 'South Carolina', value: 'South Carolina' },
    { label: 'South Dakota', value: 'South Dakota' },
    { label: 'Tennessee', value: 'Tennessee' },
    { label: 'Texas', value: 'Texas' },
    { label: 'Utah', value: 'Utah' },
    { label: 'Vermont', value: 'Vermont' },
    { label: 'Virginia', value: 'Virginia' },
    { label: 'Washington', value: 'Washington' },
    { label: 'West Virginia', value: 'West Virginia' },
    { label: 'Wisconsin', value: 'Wisconsin' },
    { label: 'Wyoming', value: 'Wyoming' },
  ];
  
  export const country = [
    { label: 'Afghanistan', value: 'Afghanistan' },
    { label: 'Aland Islands', value: 'Aland Islands' },
    { label: 'Albania', value: 'Albania' },
    { label: 'Algeria', value: 'Algeria' },
    { label: 'American Samoa', value: 'American Samoa' },
    { label: 'Andorra', value: 'Andorra' },
    { label: 'Angola', value: 'Angola' },
    { label: 'Anguilla', value: 'Anguilla' },
    { label: 'Antarctica', value: 'Antarctica' },
    { label: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
    { label: 'Argentina', value: 'Argentina' },
    { label: 'Armenia', value: 'Armenia' },
    { label: 'Aruba', value: 'Aruba' },
    { label: 'Australia', value: 'Australia' },
    { label: 'Austria', value: 'Austria' },
    { label: 'Azerbaijan', value: 'Azerbaijan' },
    { label: 'Bahamas', value: 'Bahamas' },
    { label: 'Bahrain', value: 'Bahrain' },
    { label: 'Bangladesh', value: 'Bangladesh' },
    { label: 'Barbados', value: 'Barbados' },
    { label: 'Belarus', value: 'Belarus' },
    { label: 'Belgium', value: 'Belgium' },
    { label: 'Belize', value: 'Belize' },
    { label: 'Benin', value: 'Benin' },
    { label: 'Bermuda', value: 'Bermuda' },
    { label: 'Bhutan', value: 'Bhutan' },
    { label: 'Bolivia', value: 'Bolivia' },
    { label: 'Bonaire, Sint Eustatius and Saba', value: 'Bonaire, Sint Eustatius and Saba' },
    { label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
    { label: 'Botswana', value: 'Botswana' },
    { label: 'Bouvet Island', value: 'Bouvet Island' },
    { label: 'Brazil', value: 'Brazil' },
    { label: 'British Indian Ocean Territory', value: 'British Indian Ocean Territory' },
    { label: 'Brunei Darussalam', value: 'Brunei Darussalam' },
    { label: 'Bulgaria', value: 'Bulgaria' },
    { label: 'Burkina Faso', value: 'Burkina Faso' },
    { label: 'Burundi', value: 'Burundi' },
    { label: 'Cambodia', value: 'Cambodia' },
    { label: 'Cameroon', value: 'Cameroon' },
    { label: 'Canada', value: 'Canada' },
    { label: 'Cape Verde', value: 'Cape Verde' },
    { label: 'Cayman Islands', value: 'Cayman Islands' },
    { label: 'Central African Republic', value: 'Central African Republic' },
    { label: 'Chad', value: 'Chad' },
    { label: 'Chile', value: 'Chile' },
    { label: 'China', value: 'China' },
    { label: 'Christmas Island', value: 'Christmas Island' },
    { label: 'Cocos (Keeling) Islands', value: 'Cocos (Keeling) Islands' },
    { label: 'Colombia', value: 'Colombia' },
    { label: 'Comoros', value: 'Comoros' },
    { label: 'Congo', value: 'Congo' },
    { label: 'Congo, Democratic Republic of the Congo', value: 'Congo, Democratic Republic of the Congo' },
    { label: 'Cook Islands', value: 'Cook Islands' },
    { label: 'Costa Rica', value: 'Costa Rica' },
    { label: 'Croatia', value: 'Croatia' },
    { label: 'Cuba', value: 'Cuba' },
    { label: 'Curacao', value: 'Curacao' },
    { label: 'Cyprus', value: 'Cyprus' },
    { label: 'Czech Republic', value: 'Czech Republic' },
    { label: 'Denmark', value: 'Denmark' },
    { label: 'Djibouti', value: 'Djibouti' },
    { label: 'Dominica', value: 'Dominica' },
    { label: 'Dominican Republic', value: 'Dominican Republic' },
    { label: 'Ecuador', value: 'Ecuador' },
    { label: 'Egypt', value: 'Egypt' },
    { label: 'El Salvador', value: 'El Salvador' },
    { label: 'Equatorial Guinea', value: 'Equatorial Guinea' },
    { label: 'Eritrea', value: 'Eritrea' },
    { label: 'Estonia', value: 'Estonia' },
    { label: 'Ethiopia', value: 'Ethiopia' },
    { label: 'Falkland Islands (Malvinas)', value: 'Falkland Islands (Malvinas)' },
    { label: 'Faroe Islands', value: 'Faroe Islands' },
    { label: 'Fiji', value: 'Fiji' },
    { label: 'Finland', value: 'Finland' },
    { label: 'France', value: 'France' },
    { label: 'French Guiana', value: 'French Guiana' },
    { label: 'French Polynesia', value: 'French Polynesia' },
    { label: 'French Southern Territories', value: 'French Southern Territories' },
    { label: 'Gabon', value: 'Gabon' },
    { label: 'Gambia', value: 'Gambia' },
    { label: 'Georgia', value: 'Georgia' },
    { label: 'Germany', value: 'Germany' },
    { label: 'Ghana', value: 'Ghana' },
    { label: 'Gibraltar', value: 'Gibraltar' },
    { label: 'Greece', value: 'Greece' },
    { label: 'Greenland', value: 'Greenland' },
    { label: 'Grenada', value: 'Grenada' },
    { label: 'Guadeloupe', value: 'Guadeloupe' },
    { label: 'Guam', value: 'Guam' },
    { label: 'Guatemala', value: 'Guatemala' },
    { label: 'Guernsey', value: 'Guernsey' },
    { label: 'Guinea', value: 'Guinea' },
    { label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
    { label: 'Guyana', value: 'Guyana' },
    { label: 'Haiti', value: 'Haiti' },
    { label: 'Heard Island and Mcdonald Islands', value: 'Heard Island and Mcdonald Islands' },
    { label: 'Holy See (Vatican City State)', value: 'Holy See (Vatican City State)' },
    { label: 'Honduras', value: 'Honduras' },
    { label: 'Hong Kong', value: 'Hong Kong' },
    { label: 'Hungary', value: 'Hungary' },
    { label: 'Iceland', value: 'Iceland' },
    { label: 'India', value: 'India' },
    { label: 'Indonesia', value: 'Indonesia' },
    { label: 'Iran, Islamic Republic of', value: 'Iran, Islamic Republic of' },
    { label: 'Iraq', value: 'Iraq' },
    { label: 'Ireland', value: 'Ireland' },
    { label: 'Isle of Man', value: 'Isle of Man' },
    { label: 'Israel', value: 'Israel' },
    { label: 'Italy', value: 'Italy' },
    { label: 'Jamaica', value: 'Jamaica' },
    { label: 'Japan', value: 'Japan' },
    { label: 'Jersey', value: 'Jersey' },
    { label: 'Jordan', value: 'Jordan' },
    { label: 'Kazakhstan', value: 'Kazakhstan' },
    { label: 'Kenya', value: 'Kenya' },
    { label: 'Kiribati', value: 'Kiribati' },
    { label: "Korea, Democratic People's Republic of", value: "Korea, Democratic People's Republic of" },
    { label: 'Korea, Republic of', value: 'Korea, Republic of' },
    { label: 'Kosovo', value: 'Kosovo' },
    { label: 'Kuwait', value: 'Kuwait' },
    { label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
    { label: "Lao People's Democratic Republic", value: "Lao People's Democratic Republic" },
    { label: 'Latvia', value: 'Latvia' },
    { label: 'Lebanon', value: 'Lebanon' },
    { label: 'Lesotho', value: 'Lesotho' },
    { label: 'Liberia', value: 'Liberia' },
    { label: 'Libyan Arab Jamahiriya', value: 'Libyan Arab Jamahiriya' },
    { label: 'Liechtenstein', value: 'Liechtenstein' },
    { label: 'Lithuania', value: 'Lithuania' },
    { label: 'Luxembourg', value: 'Luxembourg' },
    { label: 'Macao', value: 'Macao' },
    { label: 'Macedonia, the Former Yugoslav Republic of', value: 'Macedonia, the Former Yugoslav Republic of' },
    { label: 'Madagascar', value: 'Madagascar' },
    { label: 'Malawi', value: 'Malawi' },
    { label: 'Malaysia', value: 'Malaysia' },
    { label: 'Maldives', value: 'Maldives' },
    { label: 'Mali', value: 'Mali' },
    { label: 'Malta', value: 'Malta' },
    { label: 'Marshall Islands', value: 'Marshall Islands' },
    { label: 'Martinique', value: 'Martinique' },
    { label: 'Mauritania', value: 'Mauritania' },
    { label: 'Mauritius', value: 'Mauritius' },
    { label: 'Mayotte', value: 'Mayotte' },
    { label: 'Mexico', value: 'Mexico' },
    { label: 'Micronesia, Federated States of', value: 'Micronesia, Federated States of' },
    { label: 'Moldova, Republic of', value: 'Moldova, Republic of' },
    { label: 'Monaco', value: 'Monaco' },
    { label: 'Mongolia', value: 'Mongolia' },
    { label: 'Montenegro', value: 'Montenegro' },
    { label: 'Montserrat', value: 'Montserrat' },
    { label: 'Morocco', value: 'Morocco' },
    { label: 'Mozambique', value: 'Mozambique' },
    { label: 'Myanmar', value: 'Myanmar' },
    { label: 'Namibia', value: 'Namibia' },
    { label: 'Nauru', value: 'Nauru' },
    { label: 'Nepal', value: 'Nepal' },
    { label: 'Netherlands', value: 'Netherlands' },
    { label: 'Netherlands Antilles', value: 'Netherlands Antilles' },
    { label: 'New Caledonia', value: 'New Caledonia' },
    { label: 'New Zealand', value: 'New Zealand' },
    { label: 'Nicaragua', value: 'Nicaragua' },
    { label: 'Niger', value: 'Niger' },
    { label: 'Nigeria', value: 'Nigeria' },
    { label: 'Niue', value: 'Niue' },
    { label: 'Norfolk Island', value: 'Norfolk Island' },
    { label: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
    { label: 'Norway', value: 'Norway' },
    { label: 'Oman', value: 'Oman' },
    { label: 'Pakistan', value: 'Pakistan' },
    { label: 'Palau', value: 'Palau' },
    { label: 'Palestinian Territory, Occupied', value: 'Palestinian Territory, Occupied' },
    { label: 'Panama', value: 'Panama' },
    { label: 'Papua New Guinea', value: 'Papua New Guinea' },
    { label: 'Paraguay', value: 'Paraguay' },
    { label: 'Peru', value: 'Peru' },
    { label: 'Philippines', value: 'Philippines' },
    { label: 'Pitcairn', value: 'Pitcairn' },
    { label: 'Poland', value: 'Poland' },
    { label: 'Portugal', value: 'Portugal' },
    { label: 'Puerto Rico', value: 'Puerto Rico' },
    { label: 'Qatar', value: 'Qatar' },
    { label: 'Reunion', value:'Reunion'},
    { label: 'Romania', value: 'Romania' },
    { label: 'Russian Federation', value: 'Russian Federation' },
    { label: 'Rwanda', value: 'Rwanda' },
    { label: 'Saint Barthelemy', value: 'Saint Barthelemy' },
    { label: 'Saint Helena', value: 'Saint Helena' },
    { label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
    { label: 'Saint Lucia', value: 'Saint Lucia' },
    { label: 'Saint Martin', value: 'Saint Martin' },
    { label: 'Saint Pierre and Miquelon', value: 'Saint Pierre and Miquelon' },
    { label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines' },
    { label: 'WS', value: 'WS' }, // Please provide the full country name for 'WS' if available
    { label: 'San Marino', value: 'San Marino' },
    { label: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
    { label: 'Saudi Arabia', value: 'Saudi Arabia' },
    { label: 'Senegal', value: 'Senegal' },
    { label: 'Serbia', value: 'Serbia' },
    // If 'Serbia and Montenegro' is not used, you may remove it from the list
    { label: 'Seychelles', value: 'Seychelles' },
    { label: 'Sierra Leone', value: 'Sierra Leone' },
    { label: 'Singapore', value: 'Singapore' },
    { label: 'Sint Maarten', value: 'Sint Maarten' },
    { label: 'Slovakia', value: 'Slovakia' },
    { label: 'Slovenia', value: 'Slovenia' },
    { label: 'Solomon Islands', value: 'Solomon Islands' },
    { label: 'Somalia', value: 'Somalia' },
    { label: 'South Africa', value: 'South Africa' },
    { label: 'South Georgia and the South Sandwich Islands', value: 'South Georgia and the South Sandwich Islands' },
    { label: 'South Sudan', value: 'South Sudan' },
    { label: 'Spain', value: 'Spain' },
    { label: 'Sri Lanka', value: 'Sri Lanka' },
    { label: 'Sudan', value: 'Sudan' },
    { label: 'Suriname', value: 'Suriname' },
    { label: 'Svalbard and Jan Mayen', value: 'Svalbard and Jan Mayen' },
    { label: 'Swaziland', value: 'Swaziland' },
    { label: 'Sweden', value: 'Sweden' },
    { label: 'Switzerland', value: 'Switzerland' },
    { label: 'Syrian Arab Republic', value: 'Syrian Arab Republic' },
    { label: 'Taiwan, Province of China', value: 'Taiwan, Province of China' },
    { label: 'Tajikistan', value: 'Tajikistan' },
    { label: 'Tanzania, United Republic of', value: 'Tanzania, United Republic of' },
    { label: 'Thailand', value: 'Thailand' },
    { label: 'Timor-Leste', value: 'Timor-Leste' },
    { label: 'Togo', value: 'Togo' },
    { label: 'Tokelau', value: 'Tokelau' },
    { label: 'Tonga', value: 'Tonga' },
    { label: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
    { label: 'Tunisia', value: 'Tunisia' },
    { label: 'Turkey', value: 'Turkey' },
    { label: 'Turkmenistan', value: 'Turkmenistan' },
    { label: 'Turks and Caicos Islands', value: 'Turks and Caicos Islands' },
    { label: 'Tuvalu', value: 'Tuvalu' },
    { label: 'Uganda', value: 'Uganda' },
    { label: 'Ukraine', value: 'Ukraine' },
    { label: 'United Arab Emirates', value: 'United Arab Emirates' },
    { label: 'United Kingdom', value: 'United Kingdom' },
    { label: 'United States', value: 'United States' },
    { label: 'United States Minor Outlying Islands', value: 'United States Minor Outlying Islands' },
    { label: 'Uruguay', value: 'Uruguay' },
    { label: 'Uzbekistan', value: 'Uzbekistan' },
    { label: 'Vanuatu', value: 'Vanuatu' },
    { label: 'Venezuela', value: 'Venezuela' },
    { label: 'Viet Nam', value: 'Viet Nam' },
    { label: 'Virgin Islands, British', value: 'Virgin Islands, British' },
    { label: 'Virgin Islands, U.S.', value: 'Virgin Islands, U.S.' },
    { label: 'Wallis and Futuna', value: 'Wallis and Futuna' },
    { label: 'Western Sahara', value: 'Western Sahara' },
    { label: 'Yemen', value: 'Yemen' },
    { label: 'Zambia', value: 'Zambia' },
    { label: 'Zimbabwe', value: 'Zimbabwe' },
];

export const CLIENT_ADDRESS_INPUT = [
  {
    addressInfo: true,
    rowNumber: 3,
  },
  {
    mailingInfo: true,
    rowNumber: 3,
  },
]