import React from 'react'
import AdminServiceHeader from '../AdminServiceHeader'
import { Link, useLocation } from 'react-router-dom'

const ProviderHeader = () => {
    const location = useLocation()
    return (
        <div>
            <AdminServiceHeader />
            <div className='card mb-5 mb-xl-10'>
                <div className='card-body pt-9 pb-0'>
                    <p className='text-gray-800 fs-2 fw-bolder'>Service Provider</p>
                    <div className='d-flex overflow-auto h-55px'>
                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                            <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname.startsWith('/admin/service-directory/service-provider/new') && 'active')
                                    }
                                    to='/admin/service-directory/service-provider/new'
                                >
                                    New
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname.startsWith('/admin/service-directory/service-provider/list') && 'active')
                                    }
                                    to='/admin/service-directory/service-provider/list'
                                >
                                    List
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname.startsWith('/admin/service-directory/service-provider/search') && 'active')
                                    }
                                    to='/admin/service-directory/service-provider/search'
                                >
                                    Search
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProviderHeader