export const ALLERGY_LIST_COLUMNS = [
    'Allergy',
    'Allergy Status',
    'Severity',
    'Identification Date',
    'Description',
    'Reaction',
]
export const ALLERGY_LIST_COLUMNS_DATA_MAPPING = {
    Allergy: 'allergy',
    'Allergy Status': 'allergyState',
    Severity: 'severity',
    'Identification Date': 'identificationDate',
    Description: 'description',
    Reaction: 'reaction',
}
export const ALLERGY_LIST_SORTING_COLUMNS = {
    Allergy: 'allergy',
    'Allergy Status': 'allergyState',
    Severity: 'severity',
    'Identification Date': 'identificationDate',
    Description: 'description',
    Reaction: 'reaction',
}

export const CONTACT_LIST_COLUMNS = ['Contact Name', 'Contact Type', 'Agency', 'Comments']
export const CONTACT_LIST_COLUMNS_DATA_MAPPING = {
    'Contact Name': 'fullName',
    'Contact Type': 'relaiontoind',
    Agency: 'agency',
    Comments: 'comments',
}
export const CONTACT_LIST_SORTING_COLUMNS = {
    'Contact Name': 'fullName',
    'Contact Type': 'relaiontoind',
    Agency: 'agency',
    Comments: 'comments',
}
export const INDIVIDUAL_CONTACT_LIST_SORTING_COLUMNS ={
    'Client Name': 'fullName',
    'Date of Birth': 'birthDate',
    'Mailing Address': 'mailingaddress',
    'Social Security Number': 'socialSecurityNumber',
    'Medicaid Number': 'medicaidNumber',
    'Total Contacts': 'contactCount',
    // 'Status': 'status',
    // 'Entered By': 'enteredBy',
    // 'Admitted By': 'admittedBy',
    // 'Last Updated By': 'lastUpdatedBy',
}

export const INDIVIDUAL_CONTACT_LIST_COLUMNS = [
    'Client Name',
    'Date of Birth',
    'Mailing Address',
    'Social Security Number',
    'Medicaid Number',
    'Total Contacts',
    // 'Status',
    // 'Entered By',
    // 'Admitted By',
    // 'Last Updated By',
]
export const INDIVIDUAL_CONTACT_LIST_COLUMNS_DATA_MAPPING = {
    'Client Name': 'fullName',
    'Date of Birth': 'birthDate',
    'Mailing Address': 'mailingaddress',
    'Social Security Number': 'socialSecurityNumber',
    'Medicaid Number': 'medicaidNumber',
    'Total Contacts': 'contactCount',
    // 'Status': 'status',
    // 'Entered By': 'enteredBy',
    // 'Admitted By': 'admittedBy',
    // 'Last Updated By': 'lastUpdatedBy',
}

export const INDIVIDUAL_LIST_EXPORT_TO_XLSX_BUTTON_HEADERS = [
    { label: 'First Name', key: 'firstName', width: 15 },
    { label: 'Last Name', key: 'lastName', width: 15 },
    { label: 'Social Security Number', key: 'socialSecurityNumber', width: 15 },
    { label: 'Medicaid Number', key: 'medicaidNumber', width: 15 },
    // { label: 'Status', key: 'status' },
    // { label: 'Entered By', key: 'enteredBy' },
    // { label: 'Admitted By', key: 'admittedBy' },
    { label: 'Date of Birth', key: 'birthDate', width: 15 },
    // { label: 'Last Updated By', key: 'lastUpdatedBy' },
    { label: 'Mailing Address', key: 'mailingaddress', width: 50 },
]
export const INDIVIDUAL_LIST_DETAIL_EXPORT_TO_XLSX_BUTTON_HEADERS = [
    { label: 'Title', key: 'title', width: 15 },
    { label: 'Full Name', key: 'fullName', width: 20 },
    { label: 'Gender', key: 'gender', width: 15 },
    { label: 'Date of Birth', key: 'birthDate', width: 15 },
    { label: 'Social Security Number', key: 'socialSecurityNumber', width: 15 },
    { label: 'Medicaid Number', key: 'medicaidNumber', width: 15 },
    { label: 'Status', key: 'status', width: 15 },
    { label: 'Email', key: 'email', width: 15 },
    { label: 'Phone Number', key: 'phoneNumber', width: 15 },
    { label: 'Ethnicity', key: 'ethnicity', width: 15 },
    { label: 'race', key: 'race', width: 15 },
    { label: 'Address', key: `addressInfo`, width: 50 },
    { label: 'Mailing Address', key: 'mailingaddress', width: 50 },
]



export const DIAGNOSTIC_LIST_COLUMNS = [
    'Diagnosis Coding Type',
    'Diagnosis Name',
    'Diagnosis Date',
    'Description',
]
export const DIAGNOSTIC_LIST_COLUMNS_DATA_MAPPING = {
    'Diagnosis Coding Type': 'diagnosisCodeType',
    'Diagnosis Name': 'diagnosisName',
    'Diagnosis Date': 'diagnosisDate',
    'Description': 'description'
}
export const DIAGNOSTIC_LIST__SORTING_COLUMNS = {
    'Diagnosis Coding Type': 'diagnosisCodeType',
    'Diagnosis Name': 'diagnosisName',
    'Diagnosis Date': 'diagnosisDate',
    'Description': 'description'
}

export const LIST_PROGRAMS_COLUMNS = [
    'Program Name',
    'Program Type',
    'PCCN',
    'Site Name',
    'Program Code',
    'Program Id',
]
export const LIST_PROGRAMS_COLUMNS_DATA_MAPPING = {
    'Program Name': 'programName',
    'Program Type': 'programType',
    'PCCN': 'programCostCenterNumber',
    'Site Name': 'siteNameMod',
    'Program Code': 'programCode',
    'Program Id': 'programID',
}
export const LIST_PROGRAMS_SORTING_COLUMNS = {
    'Program Name': 'programName',
    'Program Type': 'programType',
    'PCCN': 'programCostCenterNumber',
    'Site Name': 'siteNameMod',
    'Program Code': 'programCode',
    'Program Id': 'programID',
}

export const LIST_PROGRAM_ENROLLMENT_COLUMNS = [
    'Program Name',
    'Program Type',
    'Site Name',
    'Create Date',
]
export const LIST_PROGRAM_ENROLLMENT_COLUMNS_DATA_MAPPING = {
    'Program Name': 'programName',
    'Program Type': 'programType',
    'Site Name': 'siteNameMod',
    'Create Date': 'createDate',
}
export const LIST_PROGRAM_ENROLLMENT_SORTING_COLUMNS = {
    'Program Name': 'programName',
    'Program Type': 'programType',
    'Site Name': 'siteNameMod',
    'Create Date': 'createDate',
}

export const LIST_ENROLLMENT_COLUMNS = [
    'Client Name',
    'Date of Birth',
    'Mailing Address',
    'Social Security Number',
    'Medicaid Number',
    // 'Status',
]
export const LIST_ENROLLMENT_COLUMNS_DATA_MAPPING = {
    'Client Name': 'fullName',
    'Date of Birth': 'birthDate',
    'Mailing Address': 'mailingaddress',
    'Social Security Number': 'socialSecurityNumber',
    'Medicaid Number': 'medicaidNumber',
    // 'Status': 'status',
}
export const LIST_ENROLLMENT_SORTING_COLUMNS = {
    'Client Name': 'fullName',
    'Date of Birth': 'birthDate',
    'Mailing Address': 'mailingaddress',
    'Social Security Number': 'socialSecurityNumber',
    'Medicaid Number': 'medicaidNumber',
    // 'Status': 'status',
}

export const ADDRESS_LIST_COLUMNS = [
    'Name',
    'Address Type',
    'Address & Location',
    'Primary Phone',
    'Secondary Phone',
    'Entered Date',
]
export const ADDRESS_LIST_COLUMNS_DATA_MAPPING = {
    Name: 'name',
    'Address Type': 'addrType',
    'Address & Location': 'address',
    'Primary Phone': 'primaryPhone',
    'Secondary Phone': 'secondaryPhone',
    'Entered Date': 'enteredDate',
}
export const ADDRESS_LIST_SORTING_COLUMNS = [
    'Name',
    'Address Type',
    'Address & Location',
    'Primary Phone',
    'Secondary Phone',
    'Entered Date',
]

export const GUARDIAN_LIST_COLUMNS = [
    'Name',
    'Guardian Type',
    'Guardian Authority',
    'Guardian Establish Date',
    'Guardian Establish End Date',
]
export const GUARDIAN_LIST_COLUMNS_DATA_MAPPING = {
    Name: 'fullName',
    'Guardian Type': 'guardianType',
    'Guardian Authority': 'guardianAuthority',
    'Guardian Establish Date': 'guardianEstablishDate',
    'Guardian Establish End Date': 'guardianEstablishEndDate',
}

export const GUARDIAN_LIST_SORTING_COLUMNS = [
    'Name',
    'Guardian Type',
    'Guardian Authority',
    'Guardian Establish Date',
    'Guardian Establish End Date',
]

export const LIST_0F_SHARED_CONTACTS_COLUMNS = [
    'Name',
    'Type',
    'Organization Name',
    'Speciality',
    'NPI Number',
    'Mailing Address',
    'Primary Phone',
    'Email',
]
export const LIST_0F_SHARED_CONTACTS_COLUMNS_DATA_MAPPING = {
    "Name": 'fullName',
    "Type": 'type',
    'Organization Name': 'organizationName',
    "Speciality": 'speciality',
    'NPI Number': 'npiNumber',
    'Mailing Address': 'mailingaddress',
    'Primary Phone': 'primaryPhone',
    "Email": 'email',
}

export const LIST_0F_SHARED_CONTACTS_SORTING_COLUMNS = [
    'Name',
    'Type',
    'Organization Name',
    'Speciality',
    'NPI Number',
    'Mailing Address',
    'Primary Phone',
    'Email',
]

export const LIST_OF_CLIENT_LOG_COLUMNS= [
    'Client Name',
    'Intensity Level',
    'Summary',
    'Description',
    'Reported On',
]
export const LIST_OF_CLIENT_LOG_COLUMNS_DATA_MAPPING = {
    'Client Name': 'clientName',
    'Intensity Level': 'intensityLevel',
    'Mailing Address': 'mailingaddress',
    'Social Security Number': 'socialSecurityNumber',
    'Medicaid Number': 'medicaidNumber',
    Summary: 'summary',
    Description: 'description',
    'Reported On': 'reportedOn',
}
export const LIST_OF_CLIENT_LOG_SORTING_COLUMNS = {
    'Client Name': 'clientName',
    'Intensity Level': 'intensityLevel',
    Summary: 'summary',
    Description: 'description',
    'Reported On': 'reportedOn',
}