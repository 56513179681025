import { createSlice } from "@reduxjs/toolkit";
import { getIncidentReport } from "../action";


interface incidentReport {
    _id: String,
    type: String,
    summary: String,
    description: String,
    reportedOn: String,
    intensityLevel: String,
    clientID: String,
}

interface IncidentReportState {
    incidentReport: incidentReport[] | any;
    isLoading: boolean
}
const initialState: IncidentReportState = {
    incidentReport: [],
    isLoading: false
}

export const IncidentReportInitialState = createSlice({
    name: "clientLog",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getIncidentReport.fulfilled, (state, action: any) => {
            state.incidentReport = action.payload.data
            state.isLoading = false;
        })
            .addCase(getIncidentReport.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getIncidentReport.rejected, (state, action) => {
                state.isLoading = false
            })
    }
})
export default IncidentReportInitialState.reducer