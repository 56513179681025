import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import HealthProfileMenu from './HealthProfileMenu'
import axios from 'axios'
import DynamicForm from '../../../../reusable-components/forms/DynamicForm'
import { HEALTH_DATA_INPUTS } from '../CARE_CONSTANTS'
import AdminCareHeader from '../AdminCareHeader'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import { toast } from 'react-toastify'

const HealthBody = () => {
    const [client, setClient] = useState({})
    const [editHealthProfile, setEditHealthProfile] = useState({
        heightFeet: '',
        heightInch: '',
        weightRangeFrom: '',
        weightRangeTo: '',
        temperatureFrom: '',
        temperatureTo: '',
        pulseFrom: '',
        pulseTo: '',
        oxygenSaturationFrom: '',
        oxygenSaturationTo: '',
        respirationFrom: '',
        respirationTo: '',
        bloodSystolicFrom: '',
        bloodSystolicTo: '',
        bloodDiastolicFrom: '',
        bloodDiastolicTo: '',
    })
    const history = useHistory()
    const parameters = useParams();
    useEffect(() => {
        const fetchData = async () => {
            const id = parameters.clientID
            if (id) {
                const [ClientsData, HealthData] = await Promise.all([
                    axios.get('/clients/getOne/' + id),
                    axios.get(`/health/getHealth?clientID=${id}`),
                ])
                setClient(ClientsData.data.client)
                setEditHealthProfile(HealthData.data.data)
            } else {
                history.push('/admin/care/health/list')
            }
        }
        fetchData()
    }, [])

    const handleSubmitForm = async (formData) => {
        try {
            let tempObj = {
                clientID: client.length > 0 ? client._id : parameters.clientID,
                healthInfo: formData,
            };
            const response = await axios.put("/health/updateHealthDetails", tempObj)
            if (response.status === 200 && response.data.success === true) {
                toast.success('Health Data Updated Successfully')
                history.push(`/admin/care/health/list`)
            }
        } catch (err) {
            console.log(err)
            toast.error('Error Updating Health Data')
        }

    };

    const buttons = [
        { label: 'Cancel', variant: 'contained', type: 'button' },
        { label: 'Save', variant: 'contained', type: 'submit' },
    ];

    return (
        <div>
            <AdminCareHeader />
            <HealthProfileMenu />
            <div className="card my-10">
                <div className='card-body'>
                    <div className='mt-5'>
                    <TableTitle title='Edit Health Data'></TableTitle>
                        <div className='mt-8'>
                            <DynamicForm
                                fields={HEALTH_DATA_INPUTS}
                                data={editHealthProfile}
                                onSubmit={handleSubmitForm}
                                buttons={buttons}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HealthBody