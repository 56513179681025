import AdminCareHeader from "../AdminCareHeader"

const SubMenuIndex = () => {
  return (
    <div>
        <AdminCareHeader />
    </div>
  )
}

export default SubMenuIndex