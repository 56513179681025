import { Link } from 'react-router-dom'
import MainLogo from '../../../../assets/logo-files/PNGs - SVGs/1x/Asset 1.png'

export function MenuInner() {
  
  return (
    <>
      <Link to={`/dashboard`} className='mx-4 btn btn-icon btn-active-light-primary btn-custom ' style={{paddingLeft: "40px", paddingRight: "70px", marginTop: "10px"}}>
        <img src={MainLogo} style={{
            // height: '3rem',
            // width: '3rem',
        }} className='h-25px' alt="Trumemos Logo" />
      </Link>
      {/* <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' /> */}
      {/* <MenuItem title='Layout Builder' to='/builder' /> */}

      {/* INDIVIDUAL */}
      {/* <MenuInnerWithSub
        title='Individual'
        to='/individual'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >

        <MenuInnerWithSub
          isMega={true}
          title='Care'
          to='/individual/care'
          hasArrow={true}
          // hasBullet={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MegaMenu />

        </MenuInnerWithSub>


        <MenuInnerWithSub
          title='Individual Plans'
          to='/individual/individualplans'
          hasArrow={true}
          // hasBullet={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/individualplans/individual-plan' title='Individual Plan' hasBullet={true} />
          <MenuItem to='/individualplans/individual-plan-agenda' title='Individual Plan Agenda' hasBullet={true} />
          <MenuItem to='/individualplans/personal-focus-worksheet' title='Personal Focus Worksheet' hasBullet={true} />
        </MenuInnerWithSub>

      </MenuInnerWithSub> */}



      {/* BILLING */}
      {/* <MenuInnerWithSub
        title='Billing'
        to='/billing'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >

        <MenuItem to='/billing/attendance' title='Attendance' />

        <MenuInnerWithSub
          title='Professional Claim'
          to='/billing/professionalclaim'
          hasArrow={true}
          // hasBullet={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/billing/professionalclaim/agency-based-reports' title='Agency Based Reports' hasBullet={true} />
          <MenuItem to='/billing/professionalclaim/billing-conversion' title='Billing Conversion' hasBullet={true} />
          <MenuItem to='/billing/professionalclaim/claim-template' title='Claim Template' hasBullet={true} />
          <MenuItem to='/billing/professionalclaim/excel-upload' title='Excel Upload' hasBullet={true} />
          <MenuItem to='/billing/professionalclaim/professional-claim' title='Professional Claim' hasBullet={true} />
          <MenuItem to='/billing/professionalclaim/service-authorization' title='Service Authorization' hasBullet={true} />
        </MenuInnerWithSub>


      </MenuInnerWithSub> */}

      {/* Settings */}
      {/* <MenuItem title='Settings' to='#' /> */}
      {/* <MenuInnerWithSub
        title='Settings'
        to='/settings'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >

        <MenuItem to='/individual-home-shows' title='Individual Home Shows' />
        <MenuItem to='/personal-details' title='Personal Details' />
        <MenuItem to='/super-admin-list' title='Super Admin List' />
        <MenuItem to='/scomm-settings' title='SComm Settings' />
        <MenuItem to='/password' title='Password' /> 
        <MenuItem to='/test-mode' title='Test Mode' /> 

      </MenuInnerWithSub> */}

      {/* ERRORS */}
       {/* <MenuInnerWithSub
          title='Errors'
          to='/error'
          fontIcon='bi-sticky'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </MenuInnerWithSub>  */}

     {/* <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'> */}
      {/* PAGES */}
       {/* <MenuInnerWithSub
          title='Chat'
          to='/apps/chat'
          icon='/media/icons/duotune/communication/com012.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub> */}

{/* 
      <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  )
}
