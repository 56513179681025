export const PHONE_NUMBER_VALIDATION = {
    pattern: /^[2-9][0-9]{9}$/,
    message: 'Please enter a valid phone number in the format XXXXXXXXXX',
};

export const ZIP_CODE_VALIDATION = {
    pattern: /^\d{5}(-\d{4})?$/,
    message: 'Please enter a valid zip code. (00000 or 00000-0000)'
}
export const NAME_VALIDATION = {
    pattern: /^[a-zA-Z0-9\s-']{3,50}$/,
    message: 'A name should have at least 3 length and max 50'
}
export const STREET_VALIDATION = {
    pattern: /^[a-zA-Z0-9\s\-#]{3,50}$/,
    message: 'A field should have at least 3 characters and a maximum of 50'
}
export const SSN_VALIDATION = {
    pattern: /^(?!000|666|9\d{2})\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/,
    message: 'Please enter a valid social security number in the format XXX-XX-XXXX',
}
export const EMAIL_VALIDATION = {
    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    message: 'Please enter a valid email'
}
export const PASSWORD_VALIDATION = {
    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    message: 'Password must be at least 8 characters long and include at least one letter, one digit, and one special character (@$!%*?&).',
}
export const FAX_NUMBER_VALIDATION = {
    pattern: /^(?:\+\d{1,2}\s?)?(\d{9})$/,
    message: 'Please enter a valid fax number',
};

export const NUMBER_VALIDATION = {
    pattern: /^[0-9]{3,50}$/,
    message: 'A number should have at least 3 length and max 50'
};
