import React from 'react'
import {Route, Switch} from 'react-router-dom'

import {useSelector} from 'react-redux'
import {hasPermission} from '../../../../../../_helper/hasPermission'
import {RouteWithPermission} from '../../../../../../_helper/routesWithPermission'
import {Error404} from '../../../../../modules/errors/components/Error404'
import {NewMethod} from '../../../../../pages/admin/general/scoring-method/new/New'
import { ListMethod } from '../../../../../pages/admin/general/scoring-method/List'
import { EditMethod } from '../../../../../pages/admin/general/scoring-method/edit/edit'


export const ScoringMethodIndexRoutes: React.FC = () => {
  const auth = useSelector((state: {auth: any}) => state.auth)
  const userPermissions = auth.user.roleId
  const canUpdate = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.PROGRAM.UPDATE')
  const canCreate = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.PROGRAM.CREATE')
  const canRead = hasPermission(userPermissions, 'ADMIN.ADMIN_GENERAL.PROGRAM.VIEW')

  return (
    <Switch>
      <RouteWithPermission
        path='/admin/general/scoring-method/new'
        component={NewMethod}
        hasPerm={canCreate}
      />
      <RouteWithPermission
        path='/admin/general/scoring-method/edit/:id'
        component={EditMethod}
        hasPerm={canUpdate}
      />
      <RouteWithPermission
        path='/admin/general/scoring-method/list'
        component={ListMethod}
        hasPerm={canRead}
      />
      <Route path='/error/404' component={Error404} />
    </Switch>
  )
}
