import ScoringMethodHeader from './ScoringMethodHeader'

function ScoringMethodIndex() {
  return (
    <div>
      <ScoringMethodHeader />
    </div>
  )
}

export default ScoringMethodIndex
