export const columns = ['Name', 'Dates', 'Start Time', 'End Time','Zone', 'Role', 'TotalHours']
export const columnDataMapping = {
  Name: 'name',
  Dates: 'dates',
  "Start Time": 'startTime',
  "End Time": 'endTime',
  Zone: 'timezone',
  Role: 'role',
  TotalHours: 'totalHours',
}
export const columnSorting = {
  Name: 'name',
  Dates: 'dates',
  "Start Time": 'startTime',
  "End Time": 'endTime',
  Zone: 'timezone',
  Role: 'role',
  TotalHours: 'totalHours',
}
