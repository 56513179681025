import React from 'react'
import { Route, Switch } from 'react-router-dom'
import New from '../../../../../pages/admin/billing/attendance-type/new/New'
import List from '../../../../../pages/admin/billing/attendance-type/list/List'
import EditAttendanceType from '../../../../../pages/admin/billing/attendance-type/list/EditAttendanceType'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { RouteWithPermission } from '../../../../../../_helper/routesWithPermission'
import { Error404 } from '../../../../../modules/errors/components/Error404'
import { RootState } from '../../../../../../setup'
import { permissions } from '../../../../../../_constants/permissions'

const AttendanceTypeIndexRoutes: React.FC = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const userPermissions = auth.user.roleId;
    const canUpdate = hasPermission(userPermissions, permissions.admin_billing_attendanceType_view)
    const canCreate = hasPermission(userPermissions, permissions.admin_billing_attendanceType_create)
    const canRead = hasPermission(userPermissions, permissions.admin_billing_attendanceType_view)
    return (
        <Switch>
            <RouteWithPermission path='/admin/billing/attendance-type/new' component={New} hasPerm={canRead && canCreate} />
            <RouteWithPermission path='/admin/billing/attendance-type/list/:id' component={EditAttendanceType} hasPerm={canRead && canUpdate} />
            <RouteWithPermission path='/admin/billing/attendance-type/list' component={List} hasPerm={canRead} />
            <Route path='/error/404' component={Error404} />
        </Switch>
    )
}

export default AttendanceTypeIndexRoutes
