import axios from "axios"
import React, { useEffect, useState } from 'react'
import {
    TextField,
    Autocomplete
} from "@mui/material";

const GetICDValues = ({ field, handleInputChange, initialValue }) => {
    const [filteredOptions, setFilteredOptions] = useState([]);
  const [value, setValue] = useState(initialValue ? { label: '', value: '' } : null);

  useEffect(() => {
      if (initialValue) {
        if (field.name === 'allergy') {
            setValue({ label: initialValue.allergy, value: initialValue.allergy });
        } 
        // For future use if we have to use icd9 or icd10 through DynamicForm
        // else if (field.name === 'icd9') {
        //     //   setValue({ label: initialValue, value: initialValue });
        // } else if (field.name === 'icd10') {
        //      //   setValue({ label: initialValue, value: initialValue });
        // }
       
      }
  }, [initialValue]);
    const getData = async (value) => {
        try {
            let response = []
            if (field.name === 'allergy') {
                response = await axios.post(`/allergies/findAllergy`, {
                    value: value
                });
            } else if (field.name === 'icd9') {
                response = await axios.post(`/icd9/findICD`, {
                    value: value
                })
            } else if (field.name === 'icd10') {
                response = await axios.post(`/icd10/findICD`, {
                    value: value
                })
            }
            return response.data?.data?.map(item => ({
                label: item?.info,
                value: item?.info
            }));
        } catch (error) {
            console.error("Error fetching allergies:", error);
            return [];
        }
    };


    const loadOptions = async (inputValue) => {
        if (inputValue.length >= 3) {
            const response = await getData(inputValue)
            setFilteredOptions(response);
        } else {
            setFilteredOptions([]); // Clear options when input length is less than 3
        }
    };

    const handleAutocompleteChange = (_, newValue) => {
        if (newValue) {
            handleInputChange(field.name, newValue.value); // Pass the selected value to the parent component
        }
    };

    return (
        <Autocomplete
            id={field.name}
            size="small"
            options={filteredOptions}
            value={value}
            onInputChange={(_, newValue) => loadOptions(newValue)}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(event, newValue) => {
                setValue(newValue);
                handleAutocompleteChange(event, newValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={`Search for ${field.name}`}
                    required={field.required}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}

export default GetICDValues