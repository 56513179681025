export const caregiverSchedulescolumns = [
  'WeeklyDatePicker',
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
export const caregiverSchedulescolumnsDataMapping = {
  WeeklyDatePicker: 'WeeklyDatePicker',
  Monday: 'mondayShifts',
  Tuesday: 'tuesdayShifts',
  Wednesday: 'wednesdayShifts',
  Thursday: 'thursdayShifts',
  Friday: 'fridayShifts',
  Saturday: 'saturdayShifts',
  Sunday: 'sundayShifts',
}
