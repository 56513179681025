
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom'
import { hasPermission } from '../../_helper/hasPermission';
import { string } from 'yup';
import { RootState } from '../../setup';

const PermittedRoute = ({ component: Component, permission, componentHeader: ComponentHeader,
  // permissions, 
  ...rest }) => {


    const auth = useSelector((state: RootState) => state.auth)
  let isAuthorized = true
  if (typeof permission === 'string') {
   
    const userPermissions = auth.user.roleId;
    isAuthorized = hasPermission(userPermissions, permission)
    console.log({ userPermissions, permission, isAuthorized })
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthorized ? (
          <>
            {ComponentHeader && <ComponentHeader />}
            <Component {...props} />
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/unauthorized',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default PermittedRoute
