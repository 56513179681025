import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import PayersSettingHeader from './PayersSettingHeader'
import List from './List/List'
import New from './New/New'

function PayersSettingIndex() {
    return (
        <div>
            <PayersSettingHeader />
        </div>
    )
}

export default PayersSettingIndex