
import GeneralHeader from './GeneralHeader'

function General() {
    return (
        <div>
            <GeneralHeader />
        </div>
    )
}

export default General