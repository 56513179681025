import { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import {  useHistory } from 'react-router-dom'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import { INDIVIDUAL_CONTACT_LIST_COLUMNS_DATA_MAPPING, INDIVIDUAL_CONTACT_LIST_COLUMNS, INDIVIDUAL_CONTACT_LIST_SORTING_COLUMNS } from '../../care_table_constants'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { permissions } from '../../../../../../_constants/permissions'

const selectAuth = (state) => state.auth

const InsuranceClients = () => {
    const history = useHistory()
    const [clientsListing, setClientsListing] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [queryData, setQueryData] = useState({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'createdAt',
        sortDir: 'asc',
        searchTerm: ''
    })

    const auth = useSelector(selectAuth)
    const userPermissions = auth.user.roleId;


    useEffect(() => {
        const fetchClients = async (page, pageSize, sortBy, sortDir) => {
            try {
                const response = await axios.get(`/clients/get?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${queryData.searchTerm}`);
                const { data, currentPage, totalCounts } = response.data;
                    setClientsListing(data);
                    setFilteredData(data);
                    setQueryData((prevState)=> {
                        return {
                            ...prevState,
                            currentPage,
                            totalCounts,
                        }
                    });
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };
    
        fetchClients(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir);
    
    }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm]);
    
    const onView = (row) => {
        history.push(`/admin/care/insurance/${row._id}`)
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <TableTitle title='Client Insurance List'></TableTitle>
                <TableSearchBar
                    label='Search'
                    variant='outlined'
                    size='small'
                    value={searchTerm}
                    setQueryData={setQueryData}
                    setSearchTerm={setSearchTerm}
                    margin='normal'
                    inputAdornmentPosition='start'
                ></TableSearchBar>

                <DynamicTable
                    columns={INDIVIDUAL_CONTACT_LIST_COLUMNS}
                    data={filteredData}
                    canView={hasPermission(userPermissions, permissions.admin_care_insurance_update)}
                    canDelete={false}
                    columnDataMapping={INDIVIDUAL_CONTACT_LIST_COLUMNS_DATA_MAPPING}
                    sortingColumns={INDIVIDUAL_CONTACT_LIST_SORTING_COLUMNS}
                    onView={onView}
                    title='Are you sure you want to delete this client?'
                    setQueryData={setQueryData}
                    queryData={queryData}
                />
            </div>
        </div>
    )
}




export default InsuranceClients