import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {MoonLoader} from 'react-spinners'
import '../../../../../../_helper/cssfiles/attendanceTable.css'
import {addAttendance, getAttendance} from '../../store/action'
import {toast} from 'react-toastify'
import axios from 'axios'
import {CurrentDate} from '../../../../../../_helper/currentDate'
import ExportXLSX from '../../../../../../_helper/exportCSVHelper/ExportToXlsx'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  TextareaAutosize,
} from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

const selectAuth = (state: {auth: any}) => state.auth
const selectAtt = (state: {attendanceType: any}) => state.attendanceType
// interface attType {
//   status: string
//   client: Object
//   startDate: string
//   endDate: string
//   attendanceType: {
//     label: string, value: string
//   }
//   serviceDescription: string
//   programNames: string
// }

interface serviceAuthType {
  clientName: string
  clientID: string
  status: string
  programName: string
  serviceID: string
  _id: string
  beginDate: string
  endDate: string
}

// interface serviceDesc {
//   _id: string
//   serviceDescription: string
//   serviceID: string
//   attendanceType: string
//   serviceCode: string
// }

interface Attendance {
  attendanceOption: string
  approved: boolean
  billingGenerated: boolean
  durationHours: number
  durationMinutes: number
  billableSlot: boolean
  clientID: string
  clientName: string
  comments: string
  date: string
  timeIn: string
  timeOut: string
  totalUnits: number
  totalRemainingUnits: number
}

interface AttendanceMain {
  [key: string]: {
    [key: string]: Attendance
  }
}

const SearchTable = ({
  searchValues,
  setShowEditingForm,
  showEditingForm,
}: {
  searchValues
  setShowEditingForm: (value: boolean) => void
  showEditingForm: boolean
}) => {
  const [attendanceType, setAttendanceType] = useState<
    {
      _id: string
      name: string
      attendanceType: string
      options: {
        billable: boolean
        optionName: string
        optionCode: string
      }[]
    }[]
  >([])
  const [serviceDescription, setServiceDescription] = useState([])
  const [selectedServDesc, setSelectedServDesc] = useState(searchValues.serviceDescription)
  const [selectedAtt, setSelectedAtt] = useState({
    attendanceOption: '',
    clientID: '',
    clientName: '',
    comments: '',
    program: '',
    programName: '',
    date: '',
    serviceID: '',
    timeIn: '',
    timeOut: '',
    approved: false,
    billingGenerated: false,
    durationHours: 0,
    durationMinutes: 0,
    totalUnits: 0,
    totalRemainingUnits: 0,
    billableSlot: false,
  })
  const [selectedProgram, setSelectedProgram] = useState<string>('')
  const [timeIn, setTimeIn] = useState('00:00')
  const [timeOut, setTimeOut] = useState('00:00')
  const [servAuths, setServAuths] = useState<serviceAuthType[]>([])
  const [attendanceMain, setAttendanceMain] = useState<AttendanceMain>({})
  const [attendanceMainData, setAttendanceMainData] = useState<any[]>([])
  const [attendanceTypeOptions, setAttendanceTypeOptions] = useState<
    {billable: boolean; optionName: string; optionCode: string}[]
  >([])
  const [timeInOut, setTimeInOut] = useState<{label: string; value: string}[]>([])
  const [billable, setBillable] = useState<string[]>([])
  const [timeInError, setTimeInError] = useState<boolean>(false)
  const [timeOutError, setTimeOutError] = useState<boolean>(false)
  const [servBool, setSevBool] = useState(false)
  const [date, setDate] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const [loadingPDF, setLoadingPDF] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [headers, setHeaders] = useState<any[]>([])
  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      try {
        let obj = {
          attendanceType: searchValues.attendanceType.value,
          program: searchValues.programNames,
          serviceID: selectedServDesc,
          startDate: searchValues.startDate,
          endDate: searchValues.endDate,
          status: searchValues.status,
        }
        const [attendanceResult, serviceAuths]: any = await Promise.all([
          dispatch(getAttendance(obj)),
          await axios.post('/attendance/getAttendanceData', obj),
        ])

        const dateArray = await serviceAuths.data.dateArray
        let tempAttendanceResult = attendanceResult.payload.data || []

        let filteredServiceAuths = serviceAuths.data.serviceAuthsFiltered

        const tempObj1: any = {}

        filteredServiceAuths.forEach((serviceAuth: any) => {
          const clientID = serviceAuth.clientID
          tempObj1[clientID] = {}

          serviceAuth.clientName = serviceAuth.name
          // check if tempAttendanceResult has data for this name
          if (Object.keys(tempAttendanceResult).length > 0 && tempAttendanceResult[clientID]) {
            dateArray.forEach((date: string) => {
              tempObj1[clientID][date] =
                tempAttendanceResult[clientID][date] !== undefined
                  ? Object.assign({}, tempAttendanceResult[clientID][date])
                  : {
                      attendanceOption: '',
                      clientName: serviceAuth.name,
                      clientID: serviceAuth.clientID,
                      comments: '',
                      timeIn: '00:00',
                      timeOut: '00:00',
                      approved: false,
                      billingGenerated: false,
                      serviceID: selectedServDesc,
                      attendanceDate: date,
                      durationHours: 0,
                      durationMinutes: 0,
                      billableSlot: false,
                      totalUnits: 0,
                      totalRemainingUnits: 0,
                    }

              // Assign default values if original values are falsy
              tempObj1[clientID][date].attendanceOption =
                tempObj1[clientID][date].attendanceOption || ''
              tempObj1[clientID][date].clientName = serviceAuth.name
              tempObj1[clientID][date].clientID =
                tempObj1[clientID][date].clientID || serviceAuth.clientID
              tempObj1[clientID][date].comments = tempObj1[clientID][date].comments || ''
              tempObj1[clientID][date].timeIn = tempObj1[clientID][date].timeIn || '00:00'
              tempObj1[clientID][date].timeOut = tempObj1[clientID][date].timeOut || '00:00'
              tempObj1[clientID][date].approved = tempObj1[clientID][date].approved || false
              tempObj1[clientID][date].billingGenerated =
                tempObj1[clientID][date].billingGenerated || false
              tempObj1[clientID][date].durationMinutes =
                tempObj1[clientID][date].durationMinutes || 0
              tempObj1[clientID][date].durationHours = tempObj1[clientID][date].durationHours || 0
              tempObj1[clientID][date].totalUnits = tempObj1[clientID][date].totalUnits || 0
              tempObj1[clientID][date].totalRemainingUnits =
                tempObj1[clientID][date].totalRemainingUnits || 0
              tempObj1[clientID][date].billableSlot = tempObj1[clientID][date].billableSlot || false
              tempObj1[clientID][date].serviceID =
                tempObj1[clientID][date].serviceID || selectedServDesc
              tempObj1[clientID][date].attendanceDate =
                tempObj1[clientID][date].attendanceDate || date
            })
          } else {
            // if tempAttendanceResult is empty or does not have data for this name,
            // set default values for all dates
            dateArray.forEach((date: string) => {
              tempObj1[clientID][date] = {
                attendanceOption: '',
                clientName: serviceAuth.name,
                clientID: serviceAuth.clientID,
                comments: '',
                timeIn: '00:00',
                timeOut: '00:00',
                approved: false,
                billingGenerated: false,
                serviceID: selectedServDesc,
                attendanceDate: date,
                durationHours: 0,
                durationMinutes: 0,
                billableSlot: false,
                totalUnits: 0,
                totalRemainingUnits: 0,
              }
            })
          }
        })
        setServAuths(filteredServiceAuths)
        setAttendanceMain(tempObj1)
        setDate(dateArray)
        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
    generateTimeOptions()
  }, [servBool])

  useEffect(() => {
    const fetchData = async () => {
      let obj = {
        attendanceType: searchValues.attendanceType.value,
        programID: searchValues.programNames,
      }
      await axios.post('/attendance/getAttendanceOptions', obj).then((res) => {
        setSelectedProgram(res.data.program)
        setBillable(res.data.attendanceBillableOptions)
        setAttendanceTypeOptions(res.data.attendanceOptions)
        setServiceDescription(res.data.serviceDescriptions)
      })
    }

    fetchData()
  }, [])

  useEffect(() => {
    const tempArr = Object.values(attendanceMain).flatMap(Object.values)

    const uniqueAttendance = tempArr.reduce((result, record) => {
      const {clientName, attendanceOption, clientID} = record
      if (attendanceOption !== '') {
        const existingRecord = result.find(
          (item: {[x: string]: any}) => item['clientID'] === clientID
        )

        if (existingRecord) {
          if (existingRecord.hasOwnProperty(attendanceOption)) {
            existingRecord[attendanceOption]++
          } else {
            existingRecord[attendanceOption] = 1
          }
        } else {
          const newRecord = {
            'Client Name': clientName, // Update key name to "Client Name"
            clientID,
            [attendanceOption]: 1,
          }
          result.push(newRecord)
        }
      }
      return result
    }, [])

    const attendanceTypeOptionsBill =
      attendanceType
        .find((data) => data._id === searchValues.attendanceType.value)
        ?.options.map((option) => ({
          label: option.billable
            ? `${option.optionName} (billable)`
            : `${option.optionName} (non-billable)`,
          key: option.optionCode,
          width: 15,
        })) ?? [] // use ?? instead of || here

    // Create an object with all the possible keys and a value of 0
    const defaultKeys: any = {}
    attendanceTypeOptionsBill.forEach((option) => {
      const {key} = option
      defaultKeys[key] = 0
    })

    // Assign the default keys to each item in uniqueAttendance
    uniqueAttendance.forEach((item: any) => {
      Object.assign(item, defaultKeys)
    })

    setHeaders(attendanceTypeOptionsBill)
    setAttendanceMainData(uniqueAttendance)
  }, [attendanceMain])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const generateTimeOptions = () => {
    const timeOptions = []
    let time = new Date()
    time.setHours(0, 0, 0, 0)

    for (let i = 0; i < 1440; i++) {
      const hours = time.getHours()
      const formattedHour = hours % 12 === 0 ? 12 : hours % 12
      const formattedMinute = time.getMinutes().toString().padStart(2, '0')
      const period = hours < 12 ? 'AM' : 'PM'

      const label = `${formattedHour}:${formattedMinute} ${period}`
      const value = `${formattedHour}:${formattedMinute}`

      timeOptions.push({label, value})

      time.setMinutes(time.getMinutes() + 5)
    }

    setTimeInOut(timeOptions)
  }

  const generatePDF = () => {
    setLoadingPDF(true)
    let attendanceOptions = attendanceTypeOptions
    const startDate = new Date(searchValues.startDate)
    const endDate = new Date(searchValues.endDate)
    const datesHeader = []

    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`
      datesHeader.push(formattedDate)
    }

    let serviceDescriptionName = serviceDescription?.find((sd) => sd.value == selectedServDesc)

    const parameters = {
      fileName: 'Attendance-Report-' + CurrentDate(),
      descriptionCode: serviceDescriptionName?.label,
      attendanceOptions,
      date: CurrentDate(),
      attendanceType: searchValues.attendanceType.label,
      program: selectedProgram,
      attendanceData: Object.values(attendanceMain).flatMap(Object.values),
      headers: datesHeader,
    }

    axios
      .post('pdf/generateReport', parameters, {responseType: 'blob'})
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Attendance-Report-' + CurrentDate() + '.pdf')
        document.body.appendChild(link)
        link.click()
        setLoadingPDF(false)
      })
      .catch((error) => {
        console.log(error)
        setLoadingPDF(false)
      })
  }

  const submitAttendance = async () => {
    setSubmitLoading(true)
    let filteredAttendance = Object.keys(attendanceMain).reduce((acc: any, key: any) => {
      let obj = attendanceMain[key]
      let filteredObj = Object.keys(obj).reduce((acc2: any, key2: any) => {
        let innerObj = obj[key2]
        if (innerObj.attendanceOption === '-') {
          acc2[key2] = innerObj
        } else if (innerObj.attendanceOption !== '') {
          acc2[key2] = innerObj
        }
        return acc2
      }, {})
      if (Object.keys(filteredObj).length > 0) {
        acc[key] = filteredObj
      }
      return acc
    }, {})
    if (Object.keys(filteredAttendance).length > 0) {
      let tempObj: {
        clients: object
        attendanceType: string
        serviceID: string
        program: string
        status: string
        startDate: string
        endDate: string
      } = {
        clients: filteredAttendance,
        attendanceType: searchValues.attendanceType.value,
        serviceID: selectedServDesc,
        program: searchValues.programNames,
        status: searchValues.status,
        startDate: searchValues.startDate,
        endDate: searchValues.endDate,
      }

      const response: any = await dispatch(addAttendance(tempObj))
      if (response?.payload.success) {
        setSubmitLoading(false)
        toast.success(response?.payload?.message || 'success', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      } else {
        setSubmitLoading(false)
        toast.error(
          response?.payload?.response?.data?.message || response?.payload?.message || 'Error',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        )
      }
    }
  }

  const submitApproval = async () => {
    setSubmitLoading(true)
    let filteredAttendance = Object.entries(attendanceMain).reduce((acc, [key, obj]) => {
      let filteredObj = Object.entries(obj).reduce((acc2, [key2, innerObj]) => {
        if (
          innerObj.attendanceOption === '-' ||
          (innerObj.attendanceOption !== '' &&
            (innerObj.timeOut !== '00:00' || !billable.includes(innerObj.attendanceOption)))
        ) {
          innerObj.approved = true
          acc2[key2] = innerObj
        }
        return acc2
      }, {})

      if (Object.keys(filteredObj).length > 0) {
        acc[key] = filteredObj
      }
      return acc
    }, {})

    if (Object.keys(filteredAttendance).length > 0) {
      let tempObj: {
        clients: object
        attendanceType: string
        serviceID: string
        program: string
        status: string
        startDate: string
        endDate: string
      } = {
        clients: filteredAttendance,
        attendanceType: searchValues.attendanceType.value,
        serviceID: selectedServDesc,
        program: searchValues.programNames,
        status: searchValues.status,
        startDate: searchValues.startDate,
        endDate: searchValues.endDate,
      }
      const response: any = await dispatch(addAttendance(tempObj))
      if (response?.payload.success) {
        setSubmitLoading(false)
        toast.success(response?.payload?.message || 'success', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })

        setAttendanceMain((prevState: any) => {
          let newState = {...prevState}
          Object.keys(newState).forEach((clientID) => {
            Object.keys(newState[clientID]).forEach((date) => {
              if (!newState[clientID][date].approved) {
                if (
                  newState[clientID][date].timeOut !== '00:00' &&
                  newState[clientID][date].attendanceOption !== ''
                ) {
                  newState[clientID][date].approved = true
                }
              }
            })
          })
          return newState
        })
      } else {
        setSubmitLoading(false)
        toast.error(
          response?.payload?.response?.data?.message || response?.payload?.message || 'Error',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        )
      }
    }
  }

  // const generateBilling = async () => {
  //   let filteredAttendance = Object.entries(attendanceMain).reduce((acc, [key, obj]) => {
  //     let filteredObj = Object.entries(obj).reduce((acc2, [key2, innerObj]) => {
  //       if (innerObj.approved) {
  //         if (billable.includes(innerObj.attendanceOption)) {
  //           let [tempTimeIn, tempTimeOut] = [
  //             innerObj.timeIn.slice(0, 5),
  //             innerObj.timeOut.slice(0, 5),
  //           ]
  //           let [time1, time2] = [
  //             new Date(`2000-01-01T${tempTimeIn}`),
  //             new Date(`2000-01-01T${tempTimeOut}`),
  //           ]

  //           let diffMilliseconds = time2 - time1
  //           let diffMinutes = Math.floor(diffMilliseconds / (1000 * 60))
  //           let hours = Math.floor(diffMinutes / 60)
  //           let minutes = diffMinutes % 60

  //           innerObj.durationHours = hours
  //           innerObj.durationMinutes = hours * 60 + minutes
  //           innerObj.billableSlot = true
  //         } else {
  //           innerObj.durationHours = 0
  //           innerObj.durationMinutes = 0
  //           innerObj.billableSlot = false
  //         }
  //         innerObj.billingGenerated = true
  //         acc2[key2] = innerObj
  //       }
  //       return acc2
  //     }, {})

  //     if (Object.keys(filteredObj).length > 0) {
  //       acc[key] = filteredObj
  //     }
  //     return acc
  //   }, {})

  //   if (Object.keys(filteredAttendance).length > 0) {
  //     let tempObj: {
  //       clients: object
  //       attendanceType: string
  //       serviceID: string
  //       program: string
  //       status: string
  //       startDate: string
  //       endDate: string
  //     } = {
  //       clients: filteredAttendance,
  //       attendanceType: searchValues.attendanceType,
  //       serviceID: selectedServDesc,
  //       program: searchValues.programNames,
  //       status: searchValues.status,
  //       startDate: searchValues.startDate,
  //       endDate: searchValues.endDate,
  //     }
  //     const response: any = await dispatch(addAttendance(tempObj))
  //     if (response?.payload.success) {
  //       toast.success(response?.payload?.message || 'success', {
  //         position: 'top-right',
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       })

  //       setAttendanceMain((prevState: any) => {
  //         let newState = {...prevState}
  //         Object.keys(newState).forEach((clientID) => {
  //           Object.keys(newState[clientID]).forEach((date) => {
  //             if (!newState[clientID][date].billingGenerated) {
  //               newState[clientID][date].billingGenerated = true
  //             }
  //           })
  //         })
  //         return newState
  //       })
  //     } else {
  //       toast.error(
  //         response?.payload?.response?.data?.message || response?.payload?.message || 'Error',
  //         {
  //           position: 'top-right',
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         }
  //       )
  //     }
  //   }
  // }
  const handleSubmit = (values) => {
    try {
      if (values.timeIn < values.timeOut) {
        let clientID = values['clientID']
        let tempDate = values['date']
        setAttendanceMain((prevState) => {
          let newState = {...prevState}
          newState[clientID as keyof object][tempDate as keyof object] = {
            ...newState[clientID as keyof object][tempDate as keyof object], // spread existing values
            attendanceOption: values.attendanceOption,
            timeIn: values.timeIn,
            timeOut: values.timeOut,
            comments: values.comments,
          }
          return newState
        })

        handleClose()
      } else {
        toast.error('Time In and Time Out values are incorrect', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className='fv-row mb-7 fv-plugins-icon-container p-5 card'>
      <div className='card mb-5 col-mb-10' id='kt_content'>
        <div className='post d-flex flex-column-fluid' id='kt_post'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='d-flex justify-content-start mb-4'>
              <button
                className='btn btn-primary btn-active-secondary btn-sm'
                onClick={() => setShowEditingForm(true)}
                disabled={loading}
              >
                Back
              </button>
              <button
                className='btn btn-primary btn-sm mx-2'
                onClick={submitAttendance}
                disabled={submitLoading}
              >
                Submit
              </button>
              <button
                className='btn btn-success btn-sm'
                disabled={submitLoading}
                onClick={submitApproval}
              >
                Approve
              </button>
              {/* disabled this butten until we setup billing */}
              {/* <button
                className='btn btn-danger btn-sm mx-2'
                disabled={loading}
                onClick={generateBilling}
              >
                Generate Billing Data
              </button> */}
            </div>
            {loading ? (
              <div className='overlay d-flex justify-content-center'>
                <MoonLoader color='#9db2fc' size={80} loading={loading} />
              </div>
            ) : (
              <>
                <div className='alert alert-success'>
                  <h6>Attendance Options</h6>
                  <div className='row'>
                    {attendanceTypeOptions?.map((option: any) => (
                      <div
                        className='col-sm-4'
                        key={option.optionCode}
                        style={{marginBottom: '-10px'}}
                      >
                        <p>
                          {option.optionName} - {option.optionCode}{' '}
                          {option.billable === true ? '(billable)' : '(non-billable)'}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                    <label className=' fw-bold fs-6 mb-2'>Select Description (Code)</label>
                    <select
                      className='form-select form-select-lg mb-3'
                      autoComplete='off'
                      placeholder='Enter State here...'
                      name='serviceDescription'
                      value={selectedServDesc}
                      onChange={(e: {target: {value: string}}) => {
                        setSelectedServDesc(e.target.value)
                        setLoading(true)
                        setSevBool(!servBool)
                      }}
                    >
                      {serviceDescription.map((des) => (
                        <option value={des.value} key={des.value}>
                          {des.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* these do not work */}
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs} locale='en'>
                    <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 my-3'>
                      <label className='fw-bold fs-6 mb-2'>Time Insssssss</label>
                      <TimePicker
                        ampm={true}
                        className='mui-time-picker'
                        value={dayjs(timeIn, 'HH:mm')}
                        onChange={(e) => {
                          setTimeInError(false)
                          const hour = e.$H.toString().padStart(2, '0')
                          const min = e.$m.toString().padStart(2, '0')
                          const newDate = `${hour}:${min}`
                          setTimeIn(newDate)
                          setAttendanceMain((prevState) => {
                            let newState = {...prevState}
                            Object.keys(newState).forEach((clientID) => {
                              Object.keys(newState[clientID]).forEach((date) => {
                                if (!newState[clientID][date].approved) {
                                  if (
                                    newState[clientID][date].timeIn === '00:00' &&
                                    newState[clientID][date].timeOut === '00:00'
                                  ) {
                                    newState[clientID][date].timeIn = e!
                                  } else if (newState[clientID][date].attendanceOption === '') {
                                    newState[clientID][date].timeOut = e
                                  }
                                }
                              })
                            })
                            return newState
                          })
                        }}
                      />
                      {timeInError === true ? (
                        <p className='text-danger'>* Time In is required</p>
                      ) : null}
                    </div>
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDayjs} locale='en'>
                    <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 my-3'>
                      <label className='fw-bold fs-6 mb-2'>Time Out</label>
                      <TimePicker
                        ampm={true}
                        className='mui-time-picker'
                        value={dayjs(timeOut, 'HH:mm')}
                        onChange={(e) => {
                          const hour = e.$H.toString().padStart(2, '0')
                          const min = e.$m.toString().padStart(2, '0')
                          const newDate = `${hour}:${min}`
                          setTimeOut(newDate)
                          setTimeOutError(false)
                          setTimeInError(false)
                          setAttendanceMain((prevState) => {
                            let newState = {...prevState}
                            Object.keys(newState).forEach((clientID) => {
                              Object.keys(newState[clientID]).forEach((date) => {
                                if (!newState[clientID][date].approved) {
                                  if (newState[clientID][date].timeOut === '00:00') {
                                    newState[clientID][date].timeOut = e.value!
                                  } else if (newState[clientID][date].attendanceOption === '') {
                                    newState[clientID][date].timeOut = e.value
                                  }
                                }
                              })
                            })
                            return newState
                          })
                        }}
                      />
                      {timeOutError === true ? (
                        <p className='text-danger'>* Time Out is required</p>
                      ) : new Date(`1970-01-01T${timeOut}:00Z`) <
                        new Date(`1970-01-01T${timeIn}:00Z`) ? (
                        <p className='text-danger'>* Time Out must be less than Time In</p>
                      ) : null}
                    </div>
                  </LocalizationProvider> */}

                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                    <label className=' fw-bold fs-6 mb-2'>Mark all clients as: </label>
                    <select
                      className='form-select form-select lg mb-3'
                      onChange={(e: {target: {value: string}}) => {
                        setAttendanceMain((prevState) => {
                          const newState = {...prevState}
                          Object.keys(newState).forEach((clientID: any) => {
                            Object.keys(newState[clientID]).forEach((date) => {
                              if (!newState[clientID][date].approved)
                                newState[clientID][date].attendanceOption = e.target.value
                            })
                          })
                          return newState
                        })
                      }}
                    >
                      <option value='-'>- Code for all clients -</option>
                      {attendanceTypeOptions?.map(
                        (option: {optionCode: string}, index: React.Key) => (
                          <option value={option.optionCode} key={index}>
                            {option.optionCode}
                          </option>
                        )
                      )}
                    </select>
                  </div>

                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                    <div className='mt-10'>
                      <div className='d-flex'>
                        <div className='d-flex w-50'>
                          <div
                            style={{
                              height: '20px',
                              width: '30px',
                              backgroundColor: '#ffabab',
                              border: '1px solid',
                              marginRight: '5px',
                            }}
                          ></div>
                          <label className='fs-8'>Attendance Option Missing</label>
                        </div>
                        <div className='d-flex w-50'>
                          <div
                            style={{
                              height: '20px',
                              width: '30px',
                              backgroundColor: '#ffecad',
                              border: '1px solid',
                              marginRight: '5px',
                            }}
                          ></div>
                          <label className='fs-8'>Time Out Missing</label>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div className='d-flex w-50'>
                          <div
                            style={{
                              height: '20px',
                              width: '30px',
                              backgroundColor: '#fffbae',
                              border: '1px solid',
                              marginRight: '5px',
                            }}
                          ></div>
                          <label className='fs-8'>Approval Pending</label>
                        </div>
                         <div className='d-flex w-50'>
                          <div
                            style={{
                              height: '20px',
                              width: '30px',
                              backgroundColor: '#b4f8c6',
                              border: '1px solid',
                              marginRight: '5px',
                            }}
                          ></div>
                          <label className='fs-8'>Attendance Approved</label>
                        </div>
                      </div>
                        {/* <div className='d-flex w-50'>
                          <div
                            style={{
                              height: '20px',
                              width: '30px',
                              backgroundColor: '#b4f8c6',
                              border: '1px solid',
                              marginRight: '5px',
                            }}
                          ></div>
                          <label className='fs-8'>Pending Billing Generation</label>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div
                          style={{
                            height: '20px',
                            width: '30px',
                            backgroundColor: '#aaf0fe',
                            border: '1px solid',
                            marginRight: '5px',
                          }}
                        ></div>
                        <label className='fs-8'>Billing Generated</label>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className='table-responsive' style={{padding: '0rem 0rem'}}>
                  <table
                    className='table table-light table-rounded border border-2 fs-6 gy-5 mt-5'
                    id='kt_individual_table'
                  >
                    <thead>
                      <tr className='text-start table-dark text-gray-400 table-rounded fw-bolder fs-7 text-uppercase gs-0'>
                        <th className='px-3 min-w-150px' style={{cursor: 'pointer'}}>
                          Client Name
                        </th>
                        {date.map((data, index) => {
                          return (
                            <th
                              className='px-3'
                              style={{cursor: 'pointer', minWidth: '120px'}}
                              key={index}
                            >
                              <div className='d-flex justify-content-center'>{data}</div>
                              <div className='d-flex justify-content-center mt-3'>
                                <select
                                  className='form-select-arrow-only attendanceCode'
                                  autoComplete='off'
                                  onChange={(e: {target: {value: string; name: string}}) => {
                                    setAttendanceMain((prevState) => {
                                      const newState = {...prevState}
                                      Object.keys(prevState).forEach((clientID) => {
                                        if (!newState[clientID][data].approved) {
                                          if (newState[clientID as keyof object]) {
                                            newState[clientID as keyof object][
                                              data
                                            ].attendanceOption = e.target.value
                                          }
                                        }
                                      })
                                      return newState
                                    })
                                  }}
                                >
                                  <option value='-'>-</option>
                                  {attendanceTypeOptions?.map(
                                    (option: {optionCode: string}, index: React.Key) => (
                                      <option value={option.optionCode} key={index}>
                                        {option.optionCode}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {servAuths.length > 0 ? (
                        servAuths.map((serv, index) => (
                          <tr key={index}>
                            <td className='px-3'>
                              <div style={{paddingBottom: '5px'}}>{serv.clientName}</div>
                              <select
                                className='form-select-arrow-only attendanceCode'
                                onChange={(e: {target: {value: string}}) => {
                                  let clientID = serv.clientID
                                  setAttendanceMain((prevState) => {
                                    const newState = {...prevState}
                                    const clientName = newState[clientID as keyof object]
                                    Object.keys(clientName).forEach((date) => {
                                      if (!clientName[date].approved) {
                                        clientName[date].attendanceOption = e.target.value
                                      }
                                    })
                                    return newState
                                  })
                                }}
                              >
                                <option value={'-'}>-</option>
                                {attendanceTypeOptions?.map(
                                  (option: {optionCode: string}, index: React.Key) => (
                                    <option value={option.optionCode} key={index}>
                                      {option.optionCode}
                                    </option>
                                  )
                                )}
                              </select>
                            </td>
                            {date.map((data, index) => {
                              let clientID = serv.clientID
                              return (
                                <td
                                  key={index}
                                  className={
                                    clientID +
                                    ' selectattendancecode tableAtt td-' +
                                    data +
                                    ' ' +
                                    (attendanceMain &&
                                    attendanceMain[clientID] &&
                                    attendanceMain[clientID][data] &&
                                    attendanceMain[clientID][data].approved === true &&
                                    attendanceMain[clientID][data].billingGenerated === true
                                      ? 'billingGenerated'
                                      : attendanceMain &&
                                        attendanceMain[clientID] &&
                                        attendanceMain[clientID][data] &&
                                        attendanceMain[clientID][data].attendanceOption !== '' &&
                                        attendanceMain[clientID][data].approved === true
                                      ? 'billingPending'
                                      : attendanceMain &&
                                        attendanceMain[clientID] &&
                                        attendanceMain[clientID][data] &&
                                        attendanceMain[clientID][data].attendanceOption !== '' &&
                                        attendanceMain[clientID][data].approved === false &&
                                        attendanceMain[clientID][data].timeOut !== '00:00'
                                      ? 'approvalMissing'
                                      : attendanceMain &&
                                        attendanceMain[clientID] &&
                                        attendanceMain[clientID][data] &&
                                        attendanceMain[clientID][data].attendanceOption !== '' &&
                                        attendanceMain[clientID][data].approved === false &&
                                        attendanceMain[clientID][data].timeOut === '00:00'
                                      ? 'timeOutMissing'
                                      : 'noneSelected')
                                  }
                                >
                                  <select
                                    className={
                                      'form-select form-select-sm selectattendancecode attendanceCode select-' +
                                      data
                                    }
                                    autoComplete='off'
                                    // defaultValue={attendanceMain[clientID][data].attendanceOption}
                                    value={attendanceMain[clientID][data].attendanceOption}
                                    name={clientID}
                                    disabled={attendanceMain[clientID][data].approved}
                                    onChange={(e: {target: {value: string; name: string}}) => {
                                      setAttendanceMain((prevState) => {
                                        // Create a shallow copy of the previous state object
                                        const newState = {...prevState}

                                        // Check if the clientID exists in the state object
                                        if (clientID in newState) {
                                          // Update the desired property of the clientID object
                                          newState[clientID as keyof object][
                                            data
                                          ].attendanceOption = e.target.value
                                          if (timeIn) {
                                            newState[clientID as keyof object][data].timeIn = timeIn
                                          }
                                          if (timeOut) {
                                            newState[clientID as keyof object][data].timeOut =
                                              timeOut
                                          }
                                        } else {
                                          // Handle the error, e.g. by logging a message or returning the prevState
                                          console.error(
                                            `clientID ${clientID} does not exist in attendanceMain state`
                                          )
                                          return prevState
                                        }
                                        // Return the updated newState
                                        return newState
                                      })
                                    }}
                                  >
                                    {/* {attendanceMain[clientID][data].attendanceOption === "" ? <option value=''>-</option> : <option hidden>{attendanceMain[clientID as keyof object][data].attendanceOption}</option>} */}
                                    <option value='-'>-</option>
                                    {attendanceTypeOptions?.map(
                                      (option: {optionCode: string}, index: React.Key) => (
                                        <option value={option.optionCode} key={index}>
                                          {option.optionCode}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  <div className='d-flex justify-content-center'>
                                    <p
                                      className='attPTag'
                                      onClick={() => {
                                        let tempVar: any = JSON.stringify(
                                          attendanceMain[clientID as keyof object][data]
                                        )
                                        tempVar = JSON.parse(tempVar)
                                        tempVar.serviceID = selectedServDesc
                                        if (!tempVar.timeIn) {
                                          tempVar.timeIn = timeIn
                                        }
                                        if (!tempVar.timeIn) {
                                          tempVar.timeOut = timeOut
                                        }
                                        tempVar.date = data
                                        tempVar.program = searchValues.programNames
                                        tempVar.programName = selectedProgram
                                        tempVar.serviceID = searchValues.serviceDescription
                                        setSelectedAtt(tempVar)
                                        handleOpen()
                                      }}
                                    >
                                      {
                                        attendanceMain[clientID as keyof object][data]
                                          .attendanceOption
                                      }
                                    </p>
                                  </div>
                                </td>
                              )
                            })}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td align='center' colSpan={12}>
                            <span className='text-gray-600 fs-2 fw-bolder'>
                              No Service Auths Found
                            </span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className='d-flex mt-4'>
                  <div className='mr-2'>
                    {/* <ExportCSV headerProp={headers} data={attendanceMainData} title={"Export to CSV"} filename={"Attendance-Summary-" + CurrentDate()} /> */}
                    {
                      <ExportXLSX
                        headerProp={headers}
                        data={attendanceMainData}
                        title={'Export to XLSX'}
                        filename={'Attendance-Summary-' + CurrentDate()}
                      />
                    }
                  </div>
                  {loadingPDF ? (
                    <div className='overlay d-flex justify-content-center mx-5'>
                      <MoonLoader color='#9db2fc' size={30} loading={loadingPDF} className='mx-6' />
                    </div>
                  ) : (
                    <div className='mx-2'>
                      <button
                        className='btn btn-sm btn-success'
                        onClick={generatePDF}
                        disabled={attendanceMainData.length < 1}
                      >
                        Generate PDF
                        <i className='far fa-file-alt mx-2'></i>
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
        <DialogTitle variant='h5' fontWeight='bold' alignSelf='center'>
          Attendance Data
        </DialogTitle>
        <DialogContent>
          <div className='d-flex justify-content-center pb-3'>
            {selectedAtt.approved === true ? (
              <label className='fw-bold fs-5 text-primary my-5'>
                (This attendance has been approved)
              </label>
            ) : null}
          </div>
          <form
            className='card-body'
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit(selectedAtt)
            }}
          >
            <div className='row px-9 mt-2 pb-7'>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                <label className='fw-bold fs-6 mb-2'>Client Name:</label>
                <label className='fs-6 mb-2'>&emsp;{selectedAtt.clientName}</label>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-3'>
                <label className='fw-bold fs-6 mb-2'>Attendance Options:</label>
                <Autocomplete
                  options={attendanceTypeOptions || []}
                  getOptionLabel={(option) => option.optionCode}
                  value={
                    attendanceTypeOptions.find(
                      (option) => option.optionCode === selectedAtt.attendanceOption
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setSelectedAtt({
                      ...selectedAtt,
                      attendanceOption: newValue?.optionCode || '',
                    })
                  }}
                  disableCloseOnSelect
                  size='small'
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='attendanceOption'
                      label='Attendance Option'
                      placeholder='Select Attendance Option'
                      disabled={selectedAtt.approved}
                    />
                  )}
                />
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2'>
                  <TimePicker
                    value={dayjs(selectedAtt.timeIn, 'HH:mm')}
                    onChange={(e) => {
                      const min = e?.minute().toString().padStart(2, '0');
                      const hour = e?.hour().toString().padStart(2, '0');
                      const newDate = `${hour}:${min}`
                      if (e && e.toDate) {
                        setSelectedAtt({
                          ...selectedAtt,
                          timeIn: newDate,
                        })
                      }
                    }}
                    ampm
                    label='Time In'
                    slotProps={{textField: {size: 'small', style: {width: '100%'}, placeholder:'Select Time In', disabled :selectedAtt.approved }}}
                    openTo='hours'
                    views={['hours', 'minutes']}
                    format='hh:mm A'
                    components={{
                        OpenPickerIcon: AccessTimeIcon,
                    }}
                  />
                </div>
              </LocalizationProvider>

              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 my-2'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={dayjs(selectedAtt.timeOut, 'HH:mm')}
                    onChange={(e) => {
                      const min = e?.minute().toString().padStart(2, '0');
                      const hour = e?.hour().toString().padStart(2, '0');
                      const newDate = `${hour}:${min}`
                      if (e && e.toDate) {
                        setSelectedAtt({
                          ...selectedAtt,
                          timeOut: newDate,
                        })
                      }
                    }}
                    slotProps={{textField: {size: 'small', style: {width: '100%'}, placeholder:'Select Time Out', disabled :selectedAtt.approved}}}
                    ampm
                    openTo='hours'
                    label='Time Out'
                    views={['hours', 'minutes']}
                    format='hh:mm A'
                    components={{
                        OpenPickerIcon: AccessTimeIcon,
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-3'>
                <TextareaAutosize
                  key='comments'
                  value={selectedAtt.comments}
                  onChange={(e: {target: {value: string}}) => {
                    if (e) {
                      setSelectedAtt({
                        ...selectedAtt,
                        comments: e.target.value,
                      })
                    }
                  }}
                  name='comments'
                  className='TextareaAutosize'
                  aria-label='comments'
                  placeholder='Comments'
                  minRows={4}
                  disabled={selectedAtt.approved}
                />
              </div>

              <div className='d-flex justify-content-end'>
                <Button
                  variant='contained'
                  color='primary'
                  style={{marginRight: '3px'}}
                  type='submit'
                  disabled={selectedAtt.approved}
                >
                  Update
                </Button>

                <Button variant='contained' onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default SearchTable
