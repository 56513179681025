import React from 'react'
interface TableWrapperProps {
    searchField?: React.ReactNode;
    tableButtons?: React.ReactNode;
    dynamicTable?: React.ReactNode;
}

const TableWrapper: React.FC<TableWrapperProps> = ({ searchField, tableButtons, dynamicTable }) => {
    return (
        <div className='container' style={{ margin: '0px', padding: '0px' }}>
            <div className='row' style={{ display: 'flex', width: 'auto', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className='col-xs-4 col-sm-4 col-md-6' style={{ width: 'auto' }} >
                    {searchField}
                </div>
                <div className='col-xs-8 col-sm-8 col-md-6 text-sm-center' style={{ width: 'auto' }} >
                    <div className='float-md-right'>
                        {tableButtons}
                    </div>
                </div>
            </div>
            {dynamicTable}
        </div>
    )
}

export default TableWrapper