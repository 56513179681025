import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../setup';
import { hasPermission } from '../../../../../_helper/hasPermission';
import { permissions } from '../../../../../_constants/permissions';

const CareGiverScheduleTabs = () => {
  const history = useHistory();
  const auth = useSelector((state: RootState) => state.auth);
  const userPermissions = auth?.user?.roleId;
  const isCaregiver = auth?.user?.isCaregiver;
  const path = history?.location?.pathname;

  const [value, setValue] = useState<number | false>(false);
  const [availableTabs, setAvailableTabs] = useState<string[]>([]);
  
  const hasMyAvailabilityPermission = hasPermission(
    userPermissions,
    permissions.care_giver_schedules_myAvailability_view
  );
  const hasSchedulesPermission = hasPermission(
    userPermissions,
    permissions.care_giver_schedules_schedules_view
  );
  const hasTeamAvailabilityPermission = hasPermission(
    userPermissions,
    permissions.care_giver_schedules_teamAvailability_view
  );
  const hasTimeOffPermission = hasPermission(
    userPermissions,
    permissions.care_giver_schedules_timeOff_view
  );

  // Effect for updating the available tabs based on permissions
  useEffect(() => {
    const tabs = [
      hasMyAvailabilityPermission && isCaregiver ? 'schedules/mySchedules' : null,
      hasTeamAvailabilityPermission ? 'schedules/schedule' : null,
      !isCaregiver && hasSchedulesPermission ? 'schedules/allSchedules' : null,
      !isCaregiver && hasTimeOffPermission ? 'schedules/timeOffs' : null,
    ].filter(Boolean) as string[];

    setAvailableTabs(tabs);

    // Handle initial redirect based on current path or available tabs
    const currentTab = tabs.findIndex((tab) => path.includes(tab));
    if (currentTab !== -1) {
      setValue(currentTab);
    } else if (tabs.length > 0) {
      history.push(`/caregivers/${tabs[0]}`);
    } 
  }, [
    hasMyAvailabilityPermission,
    hasSchedulesPermission,
    hasTeamAvailabilityPermission,
    hasTimeOffPermission,
    isCaregiver,
    path,
    history,
  ]);

  // Handle tab change
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    history.push(`/caregivers/${availableTabs[newValue]}`);
  };

  return (
    <div>
      <div className="card mb-5 mb-xl-10">
        <div className="card-body pt-3 pb-0">
          <div className="d-flex overflow-auto h-60px">
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{
                '.MuiTab-root': {
                  textTransform: 'none',
                  minWidth: 150,
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                },
              }}
            >
              {hasMyAvailabilityPermission && isCaregiver && (
                <Tab label="My Availability" />
              )}
              {hasTeamAvailabilityPermission && (
                <Tab label="Team Availability" />
              )}
              {!isCaregiver && hasSchedulesPermission && (
                <Tab label="Schedules" />
              )}
              {!isCaregiver && hasTimeOffPermission && (
                <Tab label="Time Offs" />
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareGiverScheduleTabs;
