export const formatName = (name) => {
  if (!name) return '';
  const cleanName = name.replace(/\s+/g, ' ').trim();

  const nameParts = cleanName.split(' ');
  const firstName = nameParts[0] 
    ? nameParts[0].charAt(0).toUpperCase() + nameParts[0].slice(1).toLowerCase() 
    : '';
  
  const lastName = nameParts.length > 1 
    ? nameParts[nameParts.length - 1].charAt(0).toUpperCase() + nameParts[nameParts.length - 1].slice(1).toLowerCase() 
    : '';

  return `${firstName} ${lastName}`.trim();
};

