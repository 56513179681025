import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface ScoringMethod {
    _id: String
    method_name: String
    level_name: String
    level_acronym: String
  }
interface DeleteScoringMethodResponse {
    success: boolean;
    message?: string;
}
interface DeleteScoringMethodPayload {
    providerCode: string;
    _id: string;
  }

export const getScoringMethod = createAsyncThunk(
    'scoringMethod/getAll',
    async ({ page, pageSize, sortBy, sortDir, searchTerm }: { page?: number, pageSize?: number, sortBy?: string, sortDir?: string, searchTerm?: string  }) => {
        try {
            if(page && pageSize) {
                const response = await axios.get(`scoringMethod/getAll`)
                return {
                
                    data: response.data
                }
            } else {
                const response = await axios.get(`scoringMethod/getAll`)
                return {
                    data: response.data
                }
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getOneScoringMethod = createAsyncThunk(
    'scoringMethod/getOne',
    async(scmId: string | undefined) => {
        try{
            const response = await axios.get('scoringMethod/getOne/' + scmId)
            return {
                data: response.data
            }
        } catch (err) {
            console.log(err)
            return err
        }
    }
)

export const addScoringMethod = createAsyncThunk(
    'scoringMethod/create',
    async (data: ScoringMethod) => {
        try {
            const response = await axios.post('scoringMethod/create', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }

)

export const deleteScoringMethod = createAsyncThunk<DeleteScoringMethodResponse, string>(
    'scoringMethod/softDelete/',
    async (id: string) => {
        const response = await axios.delete(`scoringMethod/softDelete`, {
            data: { _id: id }
        });
        return response.data as DeleteScoringMethodResponse;
    }
);

export const updateScoringMethod = createAsyncThunk(
    'scoringMethod/update',
    async (data: ScoringMethod) => {
        try {
            const response = await axios.put('scoringMethod/update', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)