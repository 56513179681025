
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import New from './new/New'
import SiteHeader from './SiteHeader'
import List from './list/List'

const SiteIndex = () => {
    return (
        <div>
            <SiteHeader />
        </div>
    )
}

export default SiteIndex