import {Fragment, useEffect, useState} from 'react'


import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import axios from 'axios'
import {useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {MoonLoader} from 'react-spinners'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import TableTitle from '../../../../reusable-components/tables/TableTitle'
import '../../Style.css'
import { RootState } from '../../../../../setup'
const selectAuth = (state: RootState) => state.auth

const getRoleNameAndWages = (role) => {
  let name = ''
  let wage = 0
  name = role.role[0].name
  wage = role.wage
  return `${name} $${wage}/hr`
}

const getHoursWorked = (role) => {
  let minsWorked = role?.workingTime || 0
  let numberOfHours = Math.floor(minsWorked / 60)
  let numberOfMins = Math.floor(minsWorked % 60)

  return `${numberOfHours.toFixed(0)}h ${numberOfMins.toFixed(0)}m`
}

function Row(props) {
  const {row, index} = props
  let totalWages = 0

  const getTotalWagesPerRole = (role) => {
    let minsWorked = role?.workingTime || 0
    let numberOfHours = Math.floor(minsWorked / 60)
    let numberOfMins = Math.floor(minsWorked % 60)
    let wagePerHour = role.wage
    let wagePerMin = wagePerHour / 60
    let wages =
      wagePerHour * parseInt(numberOfHours.toFixed(0)) +
      wagePerMin * parseInt(numberOfMins.toFixed(0))
    totalWages = totalWages + wages
    return `$${wages.toFixed(2)}`
  }

  return (
    <Fragment>
      <TableRow  className={index % 2 === 0 ? 'table-row-even' : 'table-row-odd'} sx={{'& > *': {borderBottom: 'unset'}}}>
        <TableCell></TableCell>
        <TableCell component='th' scope='row' sx={{ width: '25%' }}>
        {`${row.firstName.charAt(0).toUpperCase() + row.firstName.slice(1).toLowerCase()} ${row.lastName.charAt(0).toUpperCase() + row.lastName.slice(1).toLowerCase()}`}
      </TableCell>
        <TableCell align='right'  sx={{ width: '25%' }}>
          {row?.roles.map((eachRole) => (
            <>
              <span>{getRoleNameAndWages(eachRole)}</span>
              <br />
            </>
          ))}
        </TableCell>
        <TableCell align='right' sx={{ width: '25%' }}>
          {row?.roles.map((eachRole) => (
            <>
              <span>{getHoursWorked(eachRole)}</span>
              <br />
            </>
          ))}
        </TableCell>
        <TableCell align='right' sx={{ width: '25%' }}>
          {row?.roles.map((eachRole, index) => (
            <>
              <span>{getTotalWagesPerRole(eachRole)}</span>
              <br />
            </>
          ))}
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default function SummariesTableÏ() {
  const auth = useSelector(selectAuth)
  const isCaregiver = auth?.user?.isCaregiver
  const [allEmployesList, setAllEmployes] = useState([])
  const [rolesList, setRoles] = useState([])
  const [statsList, setStats] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [estWages, setEstWages] = useState(0.0)
  let curr = new Date()
  const [startDate, setStartDate] = useState(new Date(curr.setDate(curr.getDate() - curr.getDay())))
  const [lastDate, setLastDate] = useState(
    new Date(curr.setDate(curr.getDate() - curr.getDay() + 6))
  )

  const moveDatesForward = () => {
    var firstDay = new Date(startDate.setDate(startDate.getDate() + 7))
    var lastDay = new Date(lastDate.setDate(lastDate.getDate() + 7))
    setStartDate(firstDay)
    setLastDate(lastDay)
  }

  const moveDatesBackward = () => {
    var firstDay = new Date(startDate.setDate(startDate.getDate() - 7))
    var lastDay = new Date(lastDate.setDate(lastDate.getDate() - 7))
    setStartDate(firstDay)
    setLastDate(lastDay)
  }

  const addHoursToResponse = (allEmployes) => {
    allEmployes.map((eachEmploye) => {
      eachEmploye['totalWages'] = 0
      if (eachEmploye.timeSheets.length > 0) {
        eachEmploye.roles.map((eachRole) => {
          eachRole['workingTime'] = 0
          eachEmploye.timeSheets[0].timeSheets.map((eachSheet) => {
            eachSheet.shifts.map((eachShift) => {
              let conflict = false
              eachShift.breaks.map((eachBreak) => {
                if (eachBreak.startTime && !eachBreak.endTime) {
                  conflict = true
                }
              })
              if (eachShift.startTime && eachShift.endTime && !conflict) {
                if (eachShift.roleId == eachRole.roleId) {
                  eachRole['workingTime'] = eachRole['workingTime'] + eachShift.numberOfMinutes
                }
              }
            })
          })
          let wagePerMinute = eachRole['wage'] / 60
          eachEmploye['totalWages'] =
            eachEmploye['totalWages'] + eachRole['workingTime'] * wagePerMinute
        })
      }
    })

    setIsLoading(false)
  }

  const calculateTotalWages = (allEmployes) => {
    let totalWages = 0
    allEmployes.map((eachEmploye) => {
      totalWages = totalWages + eachEmploye.totalWages
    })
    setEstWages(totalWages)
  }

  useEffect(() => {
    axios
      .get(
        `/timesheets/getSummariesData?startDate=${startDate}&endDate=${lastDate}`
      )
      .then(async (res) => {
        const {allEmployes, roles, stats} = res.data
        setRoles(roles)
        setAllEmployes(allEmployes)
        setStats(stats)
        await addHoursToResponse(allEmployes)
        await calculateTotalWages(allEmployes)
      })
      .catch((e) => {
        toast.error(e)
      })
  }, [startDate])
  return isLoading ? (
    <div className='d-flex justify-content-center'>
      <MoonLoader color='#9db2fc' size={80} />
    </div>
  ) : (
    !isCaregiver &&
    <TableContainer component={Paper}>
      <div style={{margin: '2rem'}}>
        <TableTitle title='Summaries'></TableTitle>
      </div>
      <h3 style={{margin: '2rem'}}>Est. Wages: ${estWages.toFixed(2)}</h3>
      <div  style={{margin: '2rem'}} >
        <ArrowCircleLeftIcon style={{fontSize:'24px'}} onClick={moveDatesBackward}></ArrowCircleLeftIcon>
        <span className='schedules-table-date-text p-2'>
          {startDate.toLocaleString('default', {month: 'short'}) +
            ' ' +
            startDate.getDate() +
            ' - ' +
            lastDate.toLocaleString('default', {month: 'short'}) +
            ' ' +
            lastDate.getDate() +
            ', ' +
            lastDate.getFullYear()}
        </span>
        <ArrowCircleRightIcon style={{fontSize:'24px'}} onClick={moveDatesForward}></ArrowCircleRightIcon>
      </div>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell className='timesheets-table-header-cell'/>
            <TableCell className='timesheets-table-header-cell'>Full Name</TableCell>
            <TableCell className='timesheets-table-header-cell' align='right'>Wage Rate</TableCell>
            <TableCell className='timesheets-table-header-cell' align='right'>Regular Hours</TableCell>
            <TableCell className='timesheets-table-header-cell' align='right'>Wages Per Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allEmployesList.map((row, index) => (
            <Row key={row.name} row={row} roles={rolesList} stats={statsList} index={index} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
