import React, {useEffect, useState, useMemo} from 'react'
import {Link, useHistory, useLocation, useParams} from 'react-router-dom'
import {Tabs, Tab, useTheme, useMediaQuery} from '@mui/material'
import {hasPermission} from '../../../../../_helper/hasPermission'
import {useSelector} from 'react-redux'
import {permissions} from '../../../../../_constants/permissions'

const HealthProfileMenu = () => {
  const [value, setValue] = useState(0)
  const [client, setClient] = useState()
  const params = useParams()
  const history = useHistory()
  const location = useLocation()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const auth = useSelector((state) => state.auth)
  const userPermissions = auth?.user?.roleId
  const path = location.pathname

  const permissionsMap = {
    healthData: hasPermission(userPermissions, permissions.admin_care_healthData_view),
    allergy: hasPermission(userPermissions, permissions.admin_care_allergy_view),
    diagnosis: hasPermission(userPermissions, permissions.admin_care_diagnosis_view),
  }

  const availableTabs = useMemo(() => {
    const tabs = [
      permissionsMap.healthData
        ? {label: 'Health Data', path: '/admin/care/health/health-data'}
        : null,
      permissionsMap.allergy
        ? {label: 'Allergy Profile', path: '/admin/care/health/allergy'}
        : null,
      permissionsMap.diagnosis ? {label: 'Diagnosis', path: '/admin/care/health/diagnosis'} : null,
    ].filter(Boolean)

    // Handle initial redirect or select the current tab
    const currentTabIndex = tabs.findIndex((tab) => path.includes(tab.path))
    if (currentTabIndex !== -1) {
      setValue(currentTabIndex)
    } else if (tabs.length > 0) {
      if (permissionsMap.healthData) {
        history.push(`${tabs[0].path}/${params?.clientID}`)
      } else {
        history.push(`${tabs[0].path}/list/${params?.clientID}`)
      }
    }

    return tabs
  }, [params.clientID])

  // Handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue)
    if (permissionsMap.healthData) {
      history.push(`${availableTabs[newValue].path}/${params?.clientID}`)
    } else {
      history.push(`${availableTabs[newValue].path}/list/${params?.clientID}`)
    }
  }

  useEffect(() => {
    if (params?.clientID) {
      setClient(params.clientID)
    } else {
      history.push('/admin/care/health/list')
    }
  }, [params.clientID])

  return (
    <div className='card'>
      <div className='card-body pb-0 pt-3'>
        <div className='d-flex overflow-auto h-60px'>
          <Tabs
            value={value}
            onChange={handleChange}
            variant='scrollable'
            scrollButtons={isSmallScreen ? 'auto' : 'on'}
            aria-label='Health Profile Tabs'
            sx={{
              flexGrow: 1,
              '.MuiTab-root': {
                textTransform: 'none',
                minWidth: 150,
                fontSize: '1rem',
                fontWeight: 'bold',
              },
              '.Mui-selected': {
                color: theme.palette.primary.main,
              },
              '.MuiTabs-indicator': {
                backgroundColor: theme.palette.primary.main,
              },
            }}
          >
            {availableTabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                component={Link}
                to={`${
                  tab.path === `/admin/care/health/health-data` ? `${tab.path}/${client}` : `${tab.path}/list/${client}`
                }`}
              />
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default HealthProfileMenu
