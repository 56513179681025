import React from 'react'
import { Route, Switch } from 'react-router-dom'
import New from '../../../../../pages/billing/attendance/attendance/new/New'
import Summary from '../../../../../pages/billing/attendance/attendance/summary/Summary'
import Search from '../../../../../pages/billing/attendance/attendance/search/Search'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { RouteWithPermission } from '../../../../../../_helper/routesWithPermission'
import { Error404 } from '../../../../../modules/errors/components/Error404'

const AttendanceIndexRoutes: React.FC = () => {
    const auth = useSelector((state: { auth: any }) => state.auth);
    const userPermissions = auth.user.roleId;
    const canRead = hasPermission(userPermissions, "BILLING.ATTENDANCE.VIEW");

    return (
        <Switch>
            <RouteWithPermission path='/billing/attendance/new' component={New} hasPerm={canRead} />
            <RouteWithPermission path='/billing/attendance/search' component={Search} hasPerm={canRead} />
            <RouteWithPermission path='/billing/attendance/summary' component={Summary} hasPerm={canRead} />
            <Route path='/error/404' component={Error404} />
        </Switch>
    )
}

export default AttendanceIndexRoutes
