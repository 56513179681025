//Redux imports
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface FundingSource {
    _id: string;
    name: string;
    fundingProvider: Number;
    vendorID: Number;
    address: {
        street1: string;
        street2: string;
        city: string;
        state: string
        zipCode: Number;
        country: string;
    },
    title: string;
    firstName: string;
    lastName: string;
    phoneNumber: Number;
    ext: Number;
    faxNumber: Number;
    payer: string;
    payerType: string;
    email: string;
}

export const getFundingSource = createAsyncThunk(
    'fs/get',
    async ({ page, pageSize, sortBy, sortDir, searchTerm  }: { page?: number, pageSize?: number, sortBy?: string, sortDir?: string, searchTerm?: string  }) => {
        try {
            if(page && pageSize){
                const response = await axios.get(`fs/get?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${searchTerm}`)
                return {
                    data: response.data,
                }
            } else {
                const response = await axios.get(`fs/get`)
                return {
                    data: response.data,
                }}
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getOneFundingSource = createAsyncThunk(
    'fs/getOne/',
    async (companyid: string | undefined) => {
        try {
            const response = await axios.get('fs/getOne/' + companyid)
            return {
                data: response.data,
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const addFundingSource = createAsyncThunk(
    'fs/create',
    async (data: FundingSource) => {
        try {
            const response = await axios.post('fs/create', data)
            return response.data
        } catch (error) {
            console.log(error)
            return error
        }
    }
)

export const deleteFundingSource = createAsyncThunk(
    'fs/delete/',
    async (data: string) => {
        try {
            const response = await axios.delete('fs/delete/' + data);
            return response.data;
        } catch (error) {
            console.log(error)
            return error;
        }
    }
)

export const updateFundingSource = createAsyncThunk(
    'fs/update',
    async (data: FundingSource) => {
        try {
            const response = await axios.put('fs/update', data)
            return response.data
        } catch (error) {
            console.log(error)
            return error
        }
    }
)