import React from 'react'
import GeneralHeader from '../GeneralHeader'

function SharedContactHeader() {
    return (
        <div>
            <GeneralHeader />
        </div>
    )
}

export default SharedContactHeader