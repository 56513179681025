import { useEffect } from 'react';

const DisableNumberInputScroll = () => {

    useEffect(() => {
        document.addEventListener('wheel', handleWheel, { passive: false });

        return () => {
            document.removeEventListener('wheel', handleWheel);
        };
    }, []);

    const handleWheel = (event) => {
        const { type } = event.target;
        if (type === 'number') {
            event.preventDefault();
        }
    };


    return null;
};

export default DisableNumberInputScroll;
