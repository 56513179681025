import { createSlice } from "@reduxjs/toolkit";
import { addSite, deleteSite, getSites, updateSite } from "../action";

interface Sites {
    _id: string;
    providerName: String;
    providerCode: String;
    siteName: String;
    siteID: Number;
    siteCode: String;
    programCostCenterNumber: Number;
    st1: String;
    st2: String;
    city: String;
    state: String;
    zipCode: String;
    country: String;
    phoneNumber: String;
    secondaryPhone: String;
}

interface SitesState {
    sites: Sites[] | any;
    isLoading: boolean
}

const initialState: SitesState = {
    sites: [],
    isLoading: false,
}

export const siteInitialState = createSlice({
    name: 'sites',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getSites.fulfilled, (state, action) => {
            state.sites = action.payload
            state.isLoading = false;
        })
            .addCase(getSites.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getSites.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(addSite.fulfilled, (state,action)=> {
                state.sites = action.payload
                state.isLoading = false
            })
            .addCase(addSite.pending, (state, action)=> {
                state.isLoading = true
            })
            .addCase(addSite.rejected, (state, action)=> {
                state.isLoading = false
            })
            .addCase(deleteSite.fulfilled, (state,action)=> {
                state.sites = action.payload
                state.isLoading = false
            })
            .addCase(deleteSite.pending, (state, action)=> {
                state.isLoading = true
            })
            .addCase(deleteSite.rejected, (state, action)=> {
                state.isLoading = false
            })
            .addCase(updateSite.fulfilled, (state,action)=> {
                state.sites = action.payload
                state.isLoading = false
            })
            .addCase(updateSite.pending, (state, action)=> {
                state.isLoading = true
            })
            .addCase(updateSite.rejected, (state, action)=> {
                state.isLoading = false
            })
    }
})

export default siteInitialState.reducer