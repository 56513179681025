import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAttendanceType } from '../../../../admin/billing/attendance-type/store/action'
import { GetDescriptionCode } from '../../../../admin/billing/description-code/store/action'
import { getProgram } from '../../../../admin/care/enrollment/store/actions'
import { getServiceAuth } from '../../../professionalclaim/service-authorization/store/actions'
import NewTable from './NewTable'
import axios from 'axios'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { Autocomplete, TextField, Button, Box } from '@mui/material'
import { toast } from 'react-toastify'

const selectAuth = (state: { auth: any }) => state.auth;
interface selectOptionType {
    label: string,
    value: string
}
interface att {
    client: selectOptionType,
    startDate: string,
    attendanceType: selectOptionType,
    serviceDescription: selectOptionType,
    programNames: selectOptionType,
    status: selectOptionType,
}
function New() {
    const [serviceDescription, setServiceDescription] = useState([])
    const [programNames, setProgramNames] = useState([])
    const [clients, setClients] = useState([])
    const [attendanceTypes, setAttendanceTypes] = useState([])
    const [showEditingForm, setShowEditingForm] = useState(true)
    const auth = useSelector(selectAuth);
    const [attendanceValues, setAttendanceValues] = useState<att>({
        client: {
            label: '',
            value: ''
        },
        startDate: '',
        attendanceType: {
            label: '',
            value: ''
        },
        serviceDescription: {
            label: '',
            value: ''
        },
        programNames: {
            label: '',
            value: ''
        },
        status: {
            label: 'Approved',
            value: 'Approved'
        },
    })
    const dispatch = useDispatch()
    // const currentDate = new Date();
    // const formattedDate = `${currentDate.getFullYear().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
    const [formValues, setFormValues] = useState<att>({
        client: {
            label: '',
            value: ''
        },
        startDate: '',
        attendanceType: {
            label: '',
            value: ''
        },
        serviceDescription: {
            label: '',
            value: ''
        },
        programNames: {
            label: '',
            value: ''
        },
        status: {
            label: 'Approved',
            value: 'Approved'
        },
    })
    const history = useHistory()


    // const handleFormChange = (e: { target: { name: string; value: string | number } }) => {
    //     const { name, value } = e.target;
    //     setFormValues((prevValues) => ({
    //         ...prevValues,
    //         [name]: value
    //     }))
    // }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [attendanceResult, descriptionResult, programNamesResult, serviceAuthResult, clientsResult]: any = await Promise.all([
                    dispatch(getAttendanceType({})),
                    dispatch(GetDescriptionCode({})),
                    dispatch(getProgram({})),
                    dispatch(getServiceAuth({})),
                    (await axios.get('/clients/get')).data!
                ])
                const tempAtt = attendanceResult?.payload?.data;
                const updatedAT = [
                    ...tempAtt.map((AT: { name: any; _id: any }) => ({ label: AT.name, value: AT._id }))
                ]
                const tempDes = descriptionResult?.payload.data;
                setServiceDescription(tempDes);

                const tempProgNames = programNamesResult?.payload.data;
                const updatedPrograms = [
                    ...tempProgNames.map((P: { programName: any; _id: any }) => ({ label: P.programName, value: P._id }))
                ]
                setProgramNames(updatedPrograms);

                const tempAuths = serviceAuthResult.payload.data;

                const seen = new Set();

                let options = tempAuths.filter((data: { clientID: string; clientName: string; }) => {
                    const duplicate = seen.has(data.clientID); seen.add(data.clientID); return !duplicate;
                })
                options = options.map((obj: { clientID: string }) => {
                    let clientObj = clientsResult.find((clobj: { _id: string }) => clobj._id === obj.clientID)
                    const updatedElement = {
                        value: clientObj && clientObj._id,
                        label: clientObj && clientObj.firstName + ' ' + clientObj.lastName
                    }
                    return updatedElement
                })
                setClients(options);
                setAttendanceTypes(updatedAT)
            }
            catch (error) {
                console.error(error);
            }
        }
        fetchData()
    }, [])
    const handleSubmit = (values) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');

        // Create the formatted date string
        const currentDateFormatted = `${year}-${month}-${day}`;
        if(values.startDate <= currentDateFormatted){
            const tempObj = {
                startDate: values.startDate,
                attendanceType: values.attendanceType?.value,
                serviceDescription: values.serviceDescription.value,
                programNames: values.programNames.value,
                client: values.client.value,
                status: values.status.value,
            }
            setAttendanceValues(tempObj)
            setShowEditingForm(false)
        }else {
            toast.error('Start Date should not be later than today')
        }
       
    }

    return (
        <>
            {
                showEditingForm === false ? (
                    <NewTable searchValues={attendanceValues} setShowEditingForm={setShowEditingForm} showEditingForm={showEditingForm} />
                )
                    : (
                        <div className='card'>
                            <form className='card-body' onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(formValues);
                            }}>
                                <TableTitle title='Search Service for Attendance (New)'></TableTitle>
                                <div className='row mt-4 g-3'>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={dayjs(formValues.startDate)}
                                                format="YYYY-MM-DD"
                                                onChange={(newValue) => {
                                                    if(newValue) {
                                                        const newDate = newValue.format('YYYY-MM-DD');
                                                        setFormValues({ ...formValues, startDate: newDate });
                                                    }
                                                }}
                                                slotProps={{ textField: { label:'Start Date', size: 'small', style: { width: '100%' } } }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                                        <Autocomplete
                                            options={attendanceTypes}
                                            size='small'
                                            freeSolo={true}
                                            forcePopupIcon={true}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            value={formValues.attendanceType || { label: '', value: '' }}
                                            onChange={(event, newValue) => {
                                                setFormValues({ ...formValues, attendanceType: newValue });
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" required label='Attendance Type' />}
                                        />
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3'>
                                        <Autocomplete
                                            id='serviceDescription'
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            size='small'
                                            forcePopupIcon={true}
                                            value={formValues?.serviceDescription || { label: '', value: '' }}
                                            options={
                                                serviceDescription
                                                    .filter((des) => des.attendanceType === formValues.attendanceType.value)
                                                    .map((des) => ({ label: des.serviceDescription, value: des._id }))
                                            }
                                            disabled={!formValues.attendanceType}
                                            onChange={(e, newValue) => {
                                                setFormValues({ ...formValues, serviceDescription: newValue });
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label='Description Code'
                                                required
                                                />
                                            )}
                                        />

                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3'>
                                        <Autocomplete
                                            id='programNames'
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            size='small'
                                            options={programNames}
                                            freeSolo={true}
                                            forcePopupIcon={true}
                                            value={formValues.programNames}
                                            disabled={!formValues.serviceDescription}
                                            onChange={(e, newValue) => {
                                                setFormValues({ ...formValues, programNames: newValue });
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label='Program Name'
                                                    required
                                                />
                                            )}
                                        />

                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3'>
                                        <Autocomplete
                                            id='status'
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            size='small'
                                            forcePopupIcon={true}
                                            options={[{ label: 'Approved', value: 'Approved' }, { label: 'Discontinued', value: 'Discontinued' }]}
                                            // getOptionLabel={(option) => option.label}
                                            value={formValues.status}
                                            onChange={(e, newValue) => {
                                                setFormValues({ ...formValues, status: newValue });
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label='Status'
                                                    required
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3'>
                                        <Autocomplete
                                            id='client'
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            size='small'
                                            freeSolo={true}
                                            forcePopupIcon={true}
                                            options={clients}
                                            value={formValues.client || ''}
                                            onChange={(e, newValue) => {
                                                setFormValues({ ...formValues, client: newValue ? newValue : '' });
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label='Client'
                                                />
                                            )}
                                        />
                                    </div>

                                </div>
                                <Box style={{ marginTop: '24px', }}>
                                    <Box style={{
                                        display: 'flex',
                                        justifyContent: 'right',
                                        borderBottomLeftRadius: '4px',
                                        borderBottomRightRadius: '4px',
                                        padding: '10px 0',
                                    }}>

                                        <Button
                                            type='button'
                                            sx={{ padding: '4px', margin: '4px' }}
                                            variant='contained'
                                            style={{
                                                color: '#000',
                                                backgroundColor: '#fff'
                                            }}
                                            onClick={() => history.goBack()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type='submit'
                                            sx={{ padding: '4px', margin: '4px' }}
                                            variant='contained'
                                            style={{
                                                marginLeft: '6px',
                                                backgroundColor: '#28a745',
                                                color: '#fff'
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        </div>
                    )
            }
        </>
    )
}

export default New