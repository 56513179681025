import { Redirect, Route, Switch } from 'react-router-dom'
import IndividualIntakeHeader from './IndividualIntakeHeader'
import NewIndividual from './new/New'
import IndividualList from './listIndividual/List'
import SearchFields from './searchFields/SearchFields.jsx'
import EditIndividualForm from './listIndividual/Edit'
import ImportFromExcel from './import-from-excel/ImportFromExcel'
// import PendingAdmissionNotes from './pending-admission-notes/PendingAdmissionNotes'
// import SearchImportedExcel from './search-imported-excel/SearchImportedExcel'

function IndividualIntakeIndex() {
    return (
        <div>
            <IndividualIntakeHeader />
        </div>
    )
}

export default IndividualIntakeIndex