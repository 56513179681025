import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import SearchClaimTemplate from '../../../../../pages/billing/professionalclaim/claim-template/search/SearchClaimTemplate'
import NewClaimTemplate from '../../../../../pages/billing/professionalclaim/claim-template/new/NewClaimTemplate'

const ClaimTemplateIndexRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path='/professionalclaim/claim-template/new'>
        <NewClaimTemplate />
      </Route>
      <Route path='/professionalclaim/claim-template/search'>
        <SearchClaimTemplate />
      </Route>
      <Redirect
        from='/professionalclaim/claim-template'
        exact={true}
        to='/care/isp-program-template-liabrary/new'
      />
      <Redirect to='/professionalclaim/claim-template/' />
    </Switch>
  )
}

export default ClaimTemplateIndexRoutes
