export const USERS_COLUMNS= ['Name', 'Email', 'Updated At']
export const USERS_COLUMNS_DATA_MAPPING= {
  Name: 'fullName',
  Email: 'email',
  'Updated At': 'updatedAt',
}
export const USERS_SORTING_COLUMNS= {
  Name: 'fullName',
  Email: 'email',
  'Updated At': 'updatedAt',
}
