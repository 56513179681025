import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface ProfessionalClaim {
    billingProvider: String;
    renderingProvider: String;
    referringProvider: String;
    serviceFacilityLocation: String;
    signatureOnFire: String;
    placeOfService: String;
    payer: string;
    TCNNumber: string;
    claimFrequencyTypeCode: String;
    medicalAssignmentCode: String;
    filingIndicator: String;
    benefitsIndicator: String;
    payerResponsibility: String;
    relationToIndividual: String;
    specialProgramCode: String;
    delayReasonCode: String;
    providerServiceLine: boolean;
    informationCode: String;
    patientSignature: String;
    claimGenSearch: {
        serviceDescription: String[];
        serviceDateFrom: String;
        serviceDateTo: String;
    };
    noteInfo: {
        noteReferenceCode: String;
        note: string;
    };
    comment: string;
}

export const getProfessionalClaim = createAsyncThunk(
    'professionalClaim/get',
    async () => {
        try {
            const response = await axios.get('professionalClaim/get')
            return {
                data: response.data
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const getOneProfessionalClaim = createAsyncThunk(
    'professionalClaim/getOne/',
    async (companyId: String | undefined) => {
        try {
            const response = await axios.get('professionalClaim/getOne/' + companyId)
            return {
                data: response.data
            }
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const addProfessionalClaim = createAsyncThunk(
    'professionalClaim/create',
    async (data: ProfessionalClaim) => {
        try {
            const response = await axios.post('professionalClaim/create', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const deleteProfessionalClaim = createAsyncThunk(
    'professionalClaim/delete/',
    async (data: String) => {
        try {
            const response = await axios.delete('professionalClaim/delete/' + data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

export const updateProfessionalClaim = createAsyncThunk(
    'professionalClaim/update',
    async (data: ProfessionalClaim) => {
        try {
            const response = await axios.put('professionalClaim/update', data)
            return response.data
        }
        catch (err) {
            console.log(err)
            return err
        }
    }
)

