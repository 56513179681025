import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import InsuranceClients from '../../../../../pages/admin/care/insurance/list/InsuranceClients'
import MainInsuranceForm from '../../../../../pages/admin/care/insurance/Main Insurance Form/New'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { Error404 } from '../../../../../modules/errors/components/Error404'
import { RouteWithPermission } from '../../../../../../_helper/routesWithPermission'

const InsuranceIndexRoutes: React.FC = () => {
    const auth = useSelector((state: { auth: any }) => state.auth);
    const userPermissions = auth.user.roleId;
    const canUpdate = hasPermission(userPermissions, 'ADMIN.CARE.INSURANCE.UPDATE');
    const canCreate = hasPermission(userPermissions, 'ADMIN.CARE.INSURANCE.CREATE');
    const canRead = hasPermission(userPermissions, 'ADMIN.CARE.INSURANCE.VIEW');

    const getDefaultPath = () => {
        if (canRead) return '/admin/care/insurance/list'
        if (canUpdate) return '/admin/care/insurance/:id'
        return '/error/404'
      }

    return (
        <Switch>
        <Route exact path='/admin/care/insurance'>
          <Redirect to={getDefaultPath()} />
        </Route>
            <RouteWithPermission hasPerm={canRead} path='/admin/care/insurance/list' component={InsuranceClients} />
            <Route path='/error/404' component={Error404} />
            <RouteWithPermission hasPerm={canRead && canUpdate} path='/admin/care/insurance/:id' component={MainInsuranceForm} />
        </Switch>
    )
}

export default InsuranceIndexRoutes
