import React from "react";

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    FormControlLabel,
    styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Indent = ({ level }) => <div style={{ marginLeft: `${level * 20}px` }} />;

function removeBeforeFirstDot(inputString) {
    const dotIndex = inputString.indexOf(".");
    if (dotIndex !== -1 && dotIndex + 1 < inputString.length) {
        const resultString = inputString.substring(dotIndex + 1);
        return resultString.trim();
    }
    return null;
}



function formatString(roleString) {
    const words = roleString.toLowerCase().split('_');

    const formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    const formattedString = formattedWords.join(' ');
    return formattedString;
}

export default function PermissionSection({
    permissions,
    setPermissions,
    sectionName,
    level,
    handleCheckboxChange,
    isTopLevel = false,
    parentSections = []
}) {
    const currentSection = isTopLevel ? sectionName : null; // Don't add currentSection for nested sections
    const combinedSectionName = removeBeforeFirstDot(
        [...parentSections, currentSection, sectionName]
            .filter(Boolean) // Remove any null or undefined values
            .join(".")
    );


    const AccordionHeader = styled(AccordionSummary)({
        backgroundColor: '#2f3347',
        fontFamily: 'sans-serif',
        minHeight: "40px",
        "& .MuiSvgIcon-root": {
            fontSize: "2rem",
            color: 'white',
        },
        '& .Mui-expended': {
            minHeight: '30px'
        },
        "& .MuiAccordionSummary-content": {
            display: "flex",
            alignItems: "center",
            h3: {
                margin: 0,
                fontSize: "1rem",
                color: "white",
            },
        },
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',

    });

    const FormControlLabelStyled = styled(FormControlLabel)({
        display: "block",
        marginBottom: "5px",
        fontSize: "1rem",
    });

    const CheckboxStyled = styled(Checkbox)({
        "& .MuiIconButton-root": {
            margin: 0,
            padding: 0,
            "&:hover": {
                backgroundColor: "transparent",
            },
            borderRadius: 4,
            width: 24,
            height: 24,
        },
        "& .MuiSvgIcon-root": {
            fontSize: "2rem",
            border: '1px solid',
            borderRadius: '4px',
        },
        "&.Mui-checked": {
            "& .MuiIconButton-root": {
                backgroundColor: "transparent",
            },
        },

    });


    return (
        <div>
            {Object.keys(permissions).map((subSection) => {
                if (typeof permissions[subSection] === "object") {
                    return (
                        <Accordion key={subSection} sx={{
                            marginBottom: '10px',
                            minHeight: '40px',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px',
                            borderBottomLeftRadius: '10px',
                        }}>
                            <AccordionHeader expandIcon={<ExpandMoreIcon />}>
                                <h3>{formatString(subSection)}</h3>
                            </AccordionHeader>
                            <AccordionDetails sx={{
                                paddingLeft: '1rem',
                                borderBottomRightRadius: '10px',
                                borderBottomLeftRadius: '4px',
                            }}>
                                <div style={{ marginLeft: `${level * 20}px` }}>
                                    <PermissionSection
                                        permissions={permissions[subSection]}
                                        setPermissions={setPermissions}
                                        sectionName={subSection}
                                        level={level + 1}
                                        isTopLevel={false}
                                        handleCheckboxChange={handleCheckboxChange}
                                        parentSections={
                                            isTopLevel
                                                ? parentSections
                                                : [...parentSections, sectionName]
                                        }
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    );
                } else {
                    return (
                        <React.Fragment key={subSection}>
                            <Indent level={level + 1} />
                            <FormControlLabelStyled
                                control={
                                    <CheckboxStyled
                                        checked={permissions?.[subSection]} // Use combinedSectionName to access the specific checkbox state
                                        onChange={(event) => handleCheckboxChange(
                                            event,
                                            combinedSectionName,
                                            subSection
                                        )}
                                    />
                                }
                                label={formatString(subSection)}
                            />
                        </React.Fragment>
                    );
                }
            })}

        </div>
    )
}   