import React from 'react'
import { Button } from '@mui/material'

const TableButton: React.FC<{
  variant?: any
  color?: any
  onClick?: () => void
  title?: string
  style?: any
}> = ({ children, variant = 'contained', color = 'primary', onClick, title, style }) => {
  return (
    <Button
      style={style}
      variant={variant}
      color={color}
      sx={{ height:'2.3rem', minWidth:'86px', float: 'right', marginRight:'5px' }}
      onClick={onClick}
    >
      {title}
    </Button>
  )
}

export default TableButton
