export const CAREGIVER_ADD_TIMESHEET_FORM_INPUTS = (rolesToShow, startDate, endDate) => {
  return [
    {
      sectionHeading: 'Date',
      rowNumber: 1,
    },
    {
      label: 'Date',
      name: 'shiftDate',
      type: 'date',
      required: true,
      rowNumber: 2,
      maxDate: endDate,
      minDate: startDate,
    },
    {
      sectionHeading: 'Time Tracker',
      rowNumber: 3,
    },
    {
      label: 'Shift Start',
      name: 'shiftTimeStart',
      type: 'time',
      required: true,
      rowNumber: 4,
      minutesStep: 5,
    },
    {
      label: 'Shift End',
      name: 'shiftTimeEnd',
      type: 'time',
      required: true,
      rowNumber: 4,
      minutesStep: 5,
    },
    {
      sectionHeading: 'Break',
      rowNumber: 5,
    },
    {
      label: 'Break Start',
      name: 'breakTimeStart',
      type: 'time',
      required: true,
      rowNumber: 6,
      minutesStep: 5,
    },
    {
      label: 'Break End',
      name: 'breakTimeEnd',
      type: 'time',
      required: true,
      rowNumber: 6,
      minutesStep: 5,
    },
    {
      sectionHeading: 'Role',
      rowNumber: 7,
    },
    {
      label: 'Search Role',
      name: 'roleId',
      type: 'select',
      options: rolesToShow,
      required: true,
      rowNumber: 8,
    },
  ]
}