import { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import {
    INDIVIDUAL_CONTACT_LIST_COLUMNS_DATA_MAPPING,
    INDIVIDUAL_CONTACT_LIST_COLUMNS,
    INDIVIDUAL_CONTACT_LIST_SORTING_COLUMNS,
} from '../../care_table_constants'
import { permissions } from '../../../../../../_constants/permissions'
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper'
import AddButton from '../../../../../reusable-components/Buttons/AddButton'
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes'


const IndividualContactList = () => {
    const history = useHistory()
    const [filteredData, setFilteredData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [queryData, setQueryData] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: '_id',
        sortDir: 'desc',
        searchTerm: ''
    })

    const authUser = useSelector((state: { auth: any }) => state.auth)
    const userPermissions = authUser.user.roleId;

    useEffect(() => {
        const fetchContants = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
            try {
                const response = await axios.get(`/clients/get?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&searchTerm=${queryData.searchTerm}`);
                const { data, currentPage, totalCounts } = response.data;
                    setFilteredData(data);
                    setQueryData((prevState: any) => {
                        return {
                            ...prevState,
                            currentPage,
                            totalCounts,
                        };
                    });
            } catch (err) {
                console.error('Error fetching contants:', err);
            }
        };
    
        fetchContants(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir);
    
    }, [queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm]);
    
    // Function to handle deletion of a row by its ID
    const onClickAddButton = () => {
        history.push('/admin/care/contact/new')
    }

    const onView = (row: any) => {
        history.push(`/admin/care/contact/list/${row._id}`, row)
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <TableTitle title='Clients List'></TableTitle>
                <TableWrapper
                    searchField={
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={searchTerm}
                            setQueryData={setQueryData}
                            setSearchTerm={setSearchTerm}
                            margin='normal'
                            inputAdornmentPosition='start'
                        ></TableSearchBar>
                    }
                    tableButtons={
                        !!hasPermission(userPermissions, permissions.admin_care_contact_create) && (
                            <AddButton
                                onClick={onClickAddButton}
                            />
                        )
                    }
                    dynamicTable={
                        <DynamicTable
                            columns={INDIVIDUAL_CONTACT_LIST_COLUMNS}
                            data={filteredData}
                            canView={hasPermission(userPermissions, permissions.admin_care_contact_view)}
                            onView={onView}
                            canDelete={false}
                            columnDataMapping={INDIVIDUAL_CONTACT_LIST_COLUMNS_DATA_MAPPING}
                            sortingColumns={INDIVIDUAL_CONTACT_LIST_SORTING_COLUMNS}
                            title='Are you sure you want to delete this client?'
                            setQueryData={setQueryData}
                            queryData={queryData}
                        />
                    }
                />
            </div>
        </div>
    )
}

export default IndividualContactList
