import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import New from '../../../../../pages/admin/general/user/new/New'
import List from '../../../../../pages/admin/general/user/list/List'
import ImportFromExcel from '../../../../../pages/admin/general/user/import-from-excel/ImportFromExcel'

const UserIndexRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path='/admin/general/user/new'>
        <New />
      </Route>
      <Route path='/admin/general/user/list'>
        <List />
      </Route>
      <Route path='/admin/general/user/import-from-excel'>
        <ImportFromExcel />
      </Route>
      <Redirect from='/admin/general/user' exact={true} to='/admin/general/user/new' />
      <Redirect to='/admin/general/user/new' />
    </Switch>
  )
}

export default UserIndexRoutes
