import BehaviorHeader from './BehaviorHeader'

function BehaviorIndex() {
    return (
        <div>
            <BehaviorHeader />
        </div>
    )
}

export default BehaviorIndex