import React from 'react'
import {Typography} from '@mui/material'

const TableTitle: React.FC<{title: string}> = ({children, title}) => {
  return (
    <Typography fontSize={20} fontWeight={'bold'} fontFamily='helvetica'>
      {title}
    </Typography>
  )
}

export default TableTitle
