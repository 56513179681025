import React, { useState } from 'react'
import { requestPassword } from '../redux/AuthCRUD'
import { TextField, Button, Box } from '@mui/material';

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [email, setEmail] = useState<string>("")
  const handleSubmitForm = async (formData: any) => {
    await requestPassword(formData.email)
      .then(() => {
        setHasErrors(false)
        setLoading(false)
      })
      .catch(() => {
        setHasErrors(true)
        setLoading(false)
      })
  }

  return (
    <>
      <div
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        id='kt_login_password_reset_form'
      >
        <div>
          <form onSubmit={handleSubmitForm}>
            <TextField
              label="Email"
              type="email"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Box mt={2} />
            <div style={{ textAlign: "center" }}>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
};