import { createSlice } from '@reduxjs/toolkit';

interface TimeZoneState {
    timeZone: string | null;
}

const initialState: TimeZoneState = {
    timeZone: localStorage.getItem('timeZone') || 'America/New_York',
};


const timeZoneSlice = createSlice({
    name: 'timeZone',
    initialState,
    reducers: {
        setTimeZone: (state, action) => {
            state.timeZone = action?.payload;
            localStorage.setItem('timeZone', action.payload);
        }

    },
});

export const { setTimeZone } = timeZoneSlice.actions;
export default timeZoneSlice.reducer;