import React from 'react'
import TimesheetsTabs from '../Tabs/TimesheetsTabs'
import TimeSheetsDynamicTable from '../../../../reusable-components/tables/TimeSheetsDynamicTable'

export default function CompanyTimesheet() {
  return (
    <>
      <TimesheetsTabs></TimesheetsTabs>
      <TimeSheetsDynamicTable></TimeSheetsDynamicTable>
    </>
  )
}
