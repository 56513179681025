import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ReactSelect from 'react-select'

export default function DescriptionDialog({open, data, type, setResponse, setOpen}: any) {
  const [selectedOption, setSelectedOption] = React.useState(null)
  const [options, setOptions] = React.useState([])
  const handleClose = () => {
    setOpen('')
  }

  React.useEffect(() => {
    console.log(selectedOption)
  }, [selectedOption])

  React.useEffect(() => {
    console.log(data)
    if (type === 'group') {
      setOptions(data.map((d: any) => ({value: d._id, label: d.name})))
      return
    }

    if (type === 'contact') {
      setOptions(data.map((d: any) => ({value: d.userId, label: d.userName})))
    }
  }, [data])

  const handleOptionSelect = (option) => {
    const filteredGroup = data.filter((d) => {
      if (type === 'group') {
        return d._id === option.value
      }
      return d.userId === option.value
    })
    if (filteredGroup.length) {
      setResponse(filteredGroup[0])
    }
    setSelectedOption(option)
    handleClose()
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}  fullWidth={true} maxWidth="sm">
        <DialogTitle>
          <h2>Search</h2>
        </DialogTitle>
        <DialogContent style={{
            zIndex: '2',
            height: '300px',
            padding: '16px', 
            width: '100%',
            maxWidth: '100%', 
            boxSizing: 'border-box',
            }}>
          <ReactSelect
            defaultMenuIsOpen
            // menuIsOpen={true}
            defaultValue={selectedOption}
            onChange={(option: any) => handleOptionSelect(option)}
            options={options}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
