import { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { RootState } from '../../../../../../setup';
import { deleteAttendanceType, getAttendanceType } from '../store/action'
import TableTitle from '../../../../../reusable-components/tables/TableTitle'
import TableSearchBar from '../../../../../reusable-components/tables/TableSearchBar'
import DynamicTable from '../../../../../reusable-components/tables/DynamicTable'
import { useHistory } from 'react-router-dom'
import { LIST_OF_ATTENDANCE_TYPE_COLUMNS, LIST_OF_ATTENDANCE_TYPE_COLUMN_DATA_MAPPING, LIST_OF_ATTENDANCE_TYPE_SORTING_COLUMNS, htmlColumns } from '../../BILLING_TABLE_CONSTANTS';
import { hasPermission } from '../../../../../../_helper/hasPermission';
import { permissions } from '../../../../../../_constants/permissions';
import TableWrapper from '../../../../../reusable-components/tables/TableWrapper';
import AddButton from '../../../../../reusable-components/Buttons/AddButton';
import { unwrapResult } from '@reduxjs/toolkit';
import { QueryData } from '../../../../../reusable-components/tables/DynamicTableTypes';
import { AppDispatch } from '../../../../../../setup/redux/Store';
const selectAuth = (state: RootState) => state.auth;

export default function List() {
    const [attendanceType, setAttendancesType] = useState<any[]>([])
    const [filteredData, setFilteredData] = useState<any>([])
    const [searchTerm, setSearchTerm] = useState('')
    const [deletedID, setDeletedID] = useState('')
    const [queryData, setQueryData] = useState<QueryData>({
        pageNumber: 1,
        pageSize: 5,
        currentPage: 1,
        totalCounts: 1,
        sortBy: 'createdAt',
        sortDir: 'asc',
        searchTerm: ''
        
    })
    const dispatch = useDispatch<AppDispatch>()
    const auth = useSelector(selectAuth);
    const userPermissions = auth.user.roleId;
    const history = useHistory()

    useEffect(() => {
        const fetchData = async (page: number, pageSize: number, sortBy: string, sortDir: string) => {
            try {
                const result: any = await dispatch(getAttendanceType({page, pageSize, sortBy, sortDir, searchTerm: queryData.searchTerm }))
                const { data, currentPage, totalCounts } = result.payload.data;
                console.log(result.payload.data)
                setAttendancesType(data)
                    const newData = data.map(item => ({
                        ...item,
                        options: (
                            <Fragment>
                                {item.options.slice(0, 5).map(option => (
                                    <Fragment key={option.optionCode}>
                                        <p className='mb-0 mt-0'>{option.optionName} {option.optionCode} <br /></p>
                                    </Fragment>
                                ))}
                            </Fragment>
                        )
                    }));
                    setFilteredData(newData)
                    setQueryData((prevState)=> {
                        return {
                            ...prevState,
                            currentPage,
                            totalCounts,
                        }
                    })
                }
            catch (err) {
                console.log(err)
            }
        }
        fetchData(queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir);
    }, [deletedID, queryData.pageNumber, queryData.pageSize, queryData.sortBy, queryData.sortDir, queryData.searchTerm])

    const onDeleteAttendanceType = async (row: { _id: string }) => {
        try {
            const actionResult = await dispatch(deleteAttendanceType(row._id));
            const response = unwrapResult(actionResult);
            
            if (response.success) {
                toast.success(response.message || 'Success', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                setDeletedID(row._id);
                setQueryData((prevState) => ({
                    ...prevState,
                    pageNumber: 1,
                    searchTerm: '',
                }));
            } else {
                toast.error(response.message || 'Error', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        } catch (error) {
            toast.error(error.message || 'Error', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    };
    const onUpdate = ({ _id }: { _id: string }) => {
        history.push({
            pathname: `/admin/billing/attendance-type/list/${_id}`
        })
    }
    const onClickAddButton = () => {
        history.push('/admin/billing/attendance-type/new')
    }
    return (
        <div className='card'>
            <div className='card-body'>
                <TableTitle title='Attendance Type List'></TableTitle>
                <TableWrapper
                    searchField={
                        <TableSearchBar
                            label='Search'
                            variant='outlined'
                            size='small'
                            value={searchTerm}
                            setQueryData={setQueryData}
                            setSearchTerm={setSearchTerm}
                            margin='normal'
                            inputAdornmentPosition='start'
                        ></TableSearchBar>
                    }
                    tableButtons={
                        !!hasPermission(userPermissions, permissions.admin_billing_attendanceType_create) &&
                        <AddButton
                                onClick={onClickAddButton}
                        />
                    }
                    dynamicTable={
                        <DynamicTable
                            htmlColumns={htmlColumns}
                            columns={LIST_OF_ATTENDANCE_TYPE_COLUMNS}
                            data={filteredData}
                            canUpdate={hasPermission(userPermissions, permissions.admin_billing_attendanceType_update)}
                            canDelete={hasPermission(userPermissions, permissions.admin_billing_attendanceType_delete)}
                            columnDataMapping={LIST_OF_ATTENDANCE_TYPE_COLUMN_DATA_MAPPING}
                            sortingColumns={LIST_OF_ATTENDANCE_TYPE_SORTING_COLUMNS}
                            clickableRow={false}
                            onUpdate={onUpdate}
                            onDelete={onDeleteAttendanceType}
                            title='Are you sure you want to delete the Attendance Type'
                            setQueryData={setQueryData}
                            queryData={queryData}
                        />
                    }
                />
            </div>
        </div>
    )
}
