import axios from 'axios'
import React, {useEffect, useState} from 'react'
import SchedulesDynamicTable from '../../../../reusable-components/tables/SchedulesDynamicTable'
import {
  caregiverSchedulescolumns,
  caregiverSchedulescolumnsDataMapping,
} from '../../CAREGIVER_SCHEDULES_TABLE_CONSTANTS'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import CareGiverScheduleTabs from '../Tabs/SchedulesTabs'

const selectAuth = (state: RootState) => state.auth

export default function CaregiverSchedules() {
  const [schedulesList, setSchedulesList] = useState([])
  const [employes, setEmployes] = useState([])
  let curr = new Date()
  const [startDate, setStartDate] = useState(new Date(curr.setDate(curr.getDate() - curr.getDay())))
  const [lastDate, setLastDate] = useState(
    new Date(curr.setDate(curr.getDate() - curr.getDay() + 6))
  )
  const [dataSwitch, setDataSwitch] = useState(false)

  const auth = useSelector(selectAuth)

  useEffect(() => {
    let axiosArray = []
    let postData = {}
    let newPromise
    postData['startDate'] = startDate
    postData['endDate'] = lastDate
    newPromise = axios({
      method: 'post',
      url: `/schedules/getAllEmployesSchedules`,
      data: postData,
    })
    axiosArray.push(newPromise)

    axios
      .all(axiosArray)
      .then(
        axios.spread(async (...responses) => {
          setEmployes(responses[0].data.employes)
          await prepareSchdeules(responses[0].data.employes)
        })
      )
      .catch((error) => console.error(error))
  }, [startDate, dataSwitch])

  const prepareSchdeules = (employes) => {
    setSchedulesList([])
    employes.map((eachEmploye, index) => {
      let firstName,
        lastName,
        userId,
        days,
        scheduleId,
        isPublished,
        mondayShifts = [],
        tuesdayShifts = [],
        wednesdayShifts = [],
        thursdayShifts = [],
        fridayShifts = [],
        saturdayShifts = [],
        sundayShifts = []
      firstName = eachEmploye.firstName
      lastName = eachEmploye.lastName
      userId = eachEmploye.user
      days = eachEmploye.scheduleDetails[0]?.days
      isPublished = eachEmploye.scheduleDetails[0]?.isPublished
      scheduleId = eachEmploye.scheduleDetails[0]?._id
      if (eachEmploye.scheduleDetails.length > 0) {
        if (eachEmploye.scheduleDetails[0].days.length > 0) {
          eachEmploye.scheduleDetails[0].days.map((eachDay) => {
            if (eachDay.name == 'monday') {
              mondayShifts = eachDay.shifts
              mondayShifts.map((eachShift) => (eachShift.dayId = eachDay._id))
            }
            if (eachDay.name == 'tuesday') {
              tuesdayShifts = eachDay.shifts
              tuesdayShifts.map((eachShift) => (eachShift.dayId = eachDay._id))
            }
            if (eachDay.name == 'wednesday') {
              wednesdayShifts = eachDay.shifts
              wednesdayShifts.map((eachShift) => (eachShift.dayId = eachDay._id))
            }
            if (eachDay.name == 'thursday') {
              thursdayShifts = eachDay.shifts
              thursdayShifts.map((eachShift) => (eachShift.dayId = eachDay._id))
            }
            if (eachDay.name == 'friday') {
              fridayShifts = eachDay.shifts
              fridayShifts.map((eachShift) => (eachShift.dayId = eachDay._id))
            }
            if (eachDay.name == 'saturday') {
              saturdayShifts = eachDay.shifts
              saturdayShifts.map((eachShift) => (eachShift.dayId = eachDay._id))
            }
            if (eachDay.name == 'sunday') {
              sundayShifts = eachDay.shifts
              sundayShifts.map((eachShift) => (eachShift.dayId = eachDay._id))
            }
          })
        }
      }
      setSchedulesList((prevState) => [
        ...prevState,
        {
          firstName: firstName,
          lastName: lastName,
          userId: userId,
          mondayShifts: mondayShifts,
          tuesdayShifts: tuesdayShifts,
          wednesdayShifts: wednesdayShifts,
          thursdayShifts: thursdayShifts,
          fridayShifts: fridayShifts,
          saturdayShifts: saturdayShifts,
          sundayShifts: sundayShifts,
          scheduleId: scheduleId,
          isPublished: isPublished,
        },
      ])
    })
  }

  const moveDatesForward = () => {
    var firstDay = new Date(startDate.setDate(startDate.getDate() + 7))
    var lastDay = new Date(lastDate.setDate(lastDate.getDate() + 7))
    setStartDate(firstDay)
    setLastDate(lastDay)
  }

  const moveDatesBackward = () => {
    var firstDay = new Date(startDate.setDate(startDate.getDate() - 7))
    var lastDay = new Date(lastDate.setDate(lastDate.getDate() - 7))
    setStartDate(firstDay)
    setLastDate(lastDay)
  }

  return (
    <>
      <CareGiverScheduleTabs></CareGiverScheduleTabs>
      <div className='card'>
        <div className='card-body'>
          <SchedulesDynamicTable
            columns={caregiverSchedulescolumns}
            data={schedulesList}
            columnDataMapping={caregiverSchedulescolumnsDataMapping}
            title={'Are you sure you want to delete this schedule?'}
            startDate={startDate}
            lastDate={lastDate}
            moveDatesForward={moveDatesForward}
            moveDatesBackward={moveDatesBackward}
            typeOfSchedules={'caregiverSchedules'}
          />
        </div>
      </div>
    </>
  )
}
