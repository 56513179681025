import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NewTemplateForm from '../../../../../pages/billing/professionalclaim/professional-claim/new-template/NewTemplateForm'
import NewProfessionalClaimForm from '../../../../../pages/billing/professionalclaim/professional-claim/new/NewProfessionalClaimForm'
import SearchProfessionalClaim from '../../../../../pages/billing/professionalclaim/professional-claim/search/SearchProfessionalClaim'
import ListProfessionClaim from '../../../../../pages/billing/professionalclaim/professional-claim/list/ListProfessionClaim'
import Edit from '../../../../../pages/billing/professionalclaim/professional-claim/list/Edit'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../../../_helper/hasPermission'
import { RouteWithPermission } from '../../../../../../_helper/routesWithPermission'
import { Error404 } from '../../../../../modules/errors/components/Error404'

const ProfessionalClaimIndexRoutes: React.FC = () => {
    const auth = useSelector((state: { auth: any }) => state.auth);
    const userPermissions = auth.user.roleId;
    const canUpdate = hasPermission(userPermissions, "BILLING.PROFESSIONAL_CLAIM.PROFESSIONAL_CLAIM.UPDATE");
    const canCreate = hasPermission(userPermissions, "BILLING.PROFESSIONAL_CLAIM.PROFESSIONAL_CLAIM.CREATE");
    const canRead = hasPermission(userPermissions, "BILLING.PROFESSIONAL_CLAIM.PROFESSIONAL_CLAIM.VIEW");
    const canSearch = hasPermission(userPermissions, "BILLING.PROFESSIONAL_CLAIM.PROFESSIONAL_CLAIM.SEARCH");
    const canCreateNewTemplate = hasPermission(userPermissions, "BILLING.PROFESSIONAL_CLAIM.PROFESSIONAL_CLAIM.NEW_TEMPLATE");

    return (
        <Switch>
            <RouteWithPermission path='/professionalclaim/professional-claim/new' component={NewProfessionalClaimForm} hasPerm={canCreate} />
            <RouteWithPermission path='/professionalclaim/professional-claim/list/:id' component={Edit} hasPerm={canUpdate} />
            <RouteWithPermission path='/professionalclaim/professional-claim/list' component={ListProfessionClaim} hasPerm={canRead} />
            <RouteWithPermission path='/professionalclaim/professional-claim/search' component={SearchProfessionalClaim} hasPerm={canSearch} />
            <RouteWithPermission path='/professionalclaim/professional-claim/new-template' component={NewTemplateForm} hasPerm={canCreateNewTemplate} />
            <Route path='/error/404' component={Error404} />
        </Switch>
    )
}

export default ProfessionalClaimIndexRoutes
