import UserHeader from './UserHeader'

function UserIndex() {
    return (
        <div>
            <UserHeader />
        </div>
    )
}

export default UserIndex