import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IRole {
  name: string;
  permissions: object;
}

interface IRolesState {
  permissions: { [key: string]: IRole };
}

const initialState: IRolesState = {
  permissions: {}
}

const rolesSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setRoles(state, action: PayloadAction<{ [key: string]: IRole }>) {
      state.permissions = action.payload;
    }
  }
});

export const { setRoles } = rolesSlice.actions;

export default rolesSlice.reducer;
