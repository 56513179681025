import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import ListProvider from '../../../pages/mars/individual-medications/List/individual-medications-listing'
import IndividualMedicationForm from '../../../pages/mars/individual-medications/New/individual-medication-new'
import IndividualMedicationViewForm from '../../../pages/mars/individual-medications/View/individual-medication-view.jsx'
import IndividualMedicationDosage from '../../../pages/mars/individual-medications/Dosage'
import MissedMedicationListing from '../../../pages/mars/missed-medications/List/missed-medication-listing'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../setup/redux/RootReducer'
import { RouteWithPermission } from '../../../../_helper/routesWithPermission'
import { hasPermission } from '../../../../_helper/hasPermission'
import { permissions } from '../../../../_constants/permissions'
const selectAuth = (state: RootState) => state.auth;

const MarsIndexRouter: React.FC = () => {
  const auth = useSelector(selectAuth)
  const userPermissions = auth.user.roleId
  const canView = hasPermission(userPermissions, permissions.mars_individual_medication_view)
  const canViewContinuedMed = hasPermission(userPermissions, permissions.mars_individual_medication_continued_medication_view)
  const canViewDiscontinuedMed = hasPermission(userPermissions, permissions.mars_individual_medication_discontinued_medication_view)
  const canCreate = hasPermission(userPermissions, permissions.mars_individual_medication_continued_medication_create)
  const canCreateEntry = hasPermission(userPermissions, permissions.mars_individual_medication_continued_medication_create_entry)
  const canViewMissedMed = hasPermission(userPermissions, permissions.mars_individual_medication_missed_medication_view)
  return (
    <Switch>
      {
        (canView && canViewContinuedMed) && 
        <Route path='/mars/individual-medication/list'>
        <ListProvider tab={'ACTIVE'} />
      </Route>
      }
      {
        (canView && canViewDiscontinuedMed) && 
      <Route path='/mars/discontinued-mediciation/list'>
        <ListProvider tab={'DISCONTINUED'} />
      </Route>
      }
      <RouteWithPermission path='/mars/individual-medication/new' component={IndividualMedicationForm} hasPerm={canView && (canCreate && canViewContinuedMed)}/>
      <RouteWithPermission path='/mars/individual-medication/view' component={IndividualMedicationViewForm} hasPerm={(canView && canViewContinuedMed)}/>
      <RouteWithPermission path='/mars/individual-medication/:id' component={IndividualMedicationDosage} hasPerm={((canView && canViewContinuedMed) && canCreateEntry)}/>
      <RouteWithPermission path='/mars/missed-medication/list' component={MissedMedicationListing} hasPerm={(canView && canViewMissedMed)}/>

      <Redirect to='/mars/individual-medication/list' />
    </Switch>
  )
}

export default MarsIndexRouter
