import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import LeaveRuleHeader from './LeaveRuleHeader'
import New from './new/New'
import List from './list/List'
import Archive from './archive/Archive'

function LeaveRuleIndex() {
    return (
        <div>
            <LeaveRuleHeader />
        </div>
    )
}

export default LeaveRuleIndex