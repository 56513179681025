import React, {useState} from 'react'
import {updatePassword} from '../redux/AuthCRUD'
import {TextField, Button, Box} from '@mui/material'
import {toast} from 'react-toastify'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

export function ChangePassword() {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const userAuth = useSelector((state: RootState) => state?.auth)

  const handleSubmit = async (e) => {
    e.preventDefault()
      const userId = userAuth?.user?._id

    if (confirmPassword?.length && password !== confirmPassword) {
        toast.error('Password Mismatch');
        return;
    }

    try {
      const response = await updatePassword(currentPassword, password, confirmPassword, userId)
      if(response.status === 200 && response.data && response.data.success === true) {
      setPassword('')
      setConfirmPassword('')
      setCurrentPassword('')
      toast.success('Password Changed Successfully')
      setTimeout(()=> {
        localStorage.clear()
        window.location.reload()
      }, 1000)
    } else {
      toast.error('Something went wrong')
    }
    } catch (error) {
        console.error('Error:', error);

        if (error.response) {
            console.error('Error data:', error.response.data);
            toast.error(`Error: ${error.response.data.message || 'Server error'}`);
        } else if (error.request) {
            console.error('Error request:', error.request);
            toast.error('No response from server. Please try again later.');
        } else {
            console.error('Error message:', error.message);
            toast.error('Error in request setup. Please try again later.');
        }
    }
};


  return (
    <>
      <div className='d-flex justify-content-center'>
        <div className='card  col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12 col-12'>
          <div className='card-body'>
            <h1 className='mb-4'>Change Password</h1>
            <p className='col-xl-12 mb-5'>
              Your password must be at least 6 characters and should include a combination of
              numbers, letters, and special characters (!$@%).
            </p>
            <div style={{width: '100%'}}>
              <form style={{width: 'inherit'}} onSubmit={handleSubmit}>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xm-12 col-12'>
                  <TextField
                    label='Current Password'
                    type='password'
                    size='small'
                    className='textField'
                    required
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xm-12 col-12'>
                  <Box mt={2} />
                  <TextField
                    label='New Password'
                    type='password'
                    size='small'
                    className='textField'
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xm-12 col-12'>
                  <Box mt={2} />
                  <TextField
                    label='Confirm Password'
                    type='password'
                    className='textField'
                    required
                    size='small'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>

                <Box mt={2} />
                <div className='root'>
                  <div className='buttonContainer'>
                    <Button variant='contained' color='primary' type='submit'>
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
