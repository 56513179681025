import { useEffect, useState } from 'react'
import { CAREGIVER_PROFILE_JOB_DETAILS_FORM_INPUTS } from '../../../CAREGIVER_FORM_CONSTANTS'
import DynamicForm from '../../../../../reusable-components/forms/DynamicForm'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { permissions } from '../../../../../../_constants/permissions';
import { hasPermission } from '../../../../../../_helper/hasPermission';
import { useSelector } from 'react-redux'
import FormWrapper from '../../../../../reusable-components/Wrapper/Wrapper'
import { RootState } from '../../../../../../setup'
const selectAuth = (state: RootState) => state.auth

export default function JobDetails() {
  const [formData, setFormData] = useState({})
  const [prevLocationSettings, setPrevLocationSettings] = useState({})
  const [rolesToShow, setRolesToShow] = useState([])
  const [userRoles, setUserRoles] = useState([])
  const [uiElementsList, setUiElementsList] = useState([])
  const [updateRolesFields, setUpdateRolesFields] = useState(false)
  const [error, setError] = useState([false])
  const [i, setI] = useState(2)
  const [clickCount, setClickCount] = useState(7)
  const [addClick, setAddClick] = useState(0)
  const [inputArr, setInputArr] = useState([
    {
      roleId: '',
      wage: '',
      wageUnit: '',
      effectiveDate: null,
    },
  ])
  const params : { id: string }= useParams()
  const auth = useSelector(selectAuth)
  const userPermissions = auth.user.roleId

  useEffect(() => {
    axios
      .get('roleManagement/get')
      .then((res) => {
        let tempRoles = []
        res.data.map((obj) => {
          tempRoles.push({
            label: obj.name,
            value: obj._id,
          })
        })
        setRolesToShow(tempRoles)
      })
      .catch((err) => {
        console.error(err)
      })

    axios.get(`caregivers/getById/${params.id}`).then((res) => {
      let tempArrForInputArr = []
      setI(res.data.careGiver.role.length - 1)
      setUserRoles(res.data.careGiver.role)
      let prevFormData = formData
      res.data.careGiver.roles.map((eachRole, index) => {
        let wageKeyName = `${index}Wage`
        let wageKeyUnitName = `${index}wageUnit`
        let roleKeyName = `${index}Role`
        let effectiveDateKeyName = `${index}EffectiveDate`

        prevFormData[wageKeyName] = eachRole.wage
        prevFormData[wageKeyUnitName] = eachRole.wageUnit
        prevFormData[roleKeyName] = eachRole.roleId
        prevFormData[effectiveDateKeyName] = eachRole?.effectiveDate
          ? eachRole?.effectiveDate
          : new Date(Date.now())

        tempArrForInputArr.push({
          roleId: res.data.careGiver.role[index]['_id'],
          wage: eachRole?.wage,
          wageUnit: eachRole?.wageUnit,
          effectiveDate: new Date(eachRole?.effectiveDate ? eachRole?.effectiveDate : Date.now()),
        })
      })
      prevFormData['firstName'] = res?.data?.careGiver?.firstName
      prevFormData['lastName'] = res?.data?.careGiver?.lastName
      prevFormData['phone'] = res?.data?.careGiver?.phone
      prevFormData['email'] = res?.data?.careGiver?.email
      prevFormData['accessLevel'] = res?.data?.careGiver?.access?.accessLevel
      prevFormData['pin'] = res?.data?.careGiver?.access?.pin
      prevFormData['startDate'] = res?.data?.careGiver?.access?.startDate
      prevFormData['payrollId'] = res?.data?.careGiver?.access?.payrollId
      prevFormData['locationSettings'] = res?.data?.careGiver?.locationSettings
        ? Object.keys(res?.data?.careGiver?.locationSettings)
        : undefined
      setFormData(prevFormData)
      setPrevLocationSettings(res?.data?.careGiver?.locationSettings)
      setInputArr(tempArrForInputArr)
      setUpdateRolesFields((prevState) => {
        return !prevState
      })
    })
  }, [])

  useEffect(() => {
    const tempElementsList = CAREGIVER_PROFILE_JOB_DETAILS_FORM_INPUTS()
    setUiElementsList(tempElementsList)
    setUpdateRolesFields((prevstate) => {
      return !prevstate
    })
  }, [rolesToShow])

  useEffect(() => {
    let tempClickCount = clickCount
    let tempUIArray = uiElementsList
    userRoles.map((eachRole, index) => {
      tempClickCount = tempClickCount + 1
      const roleFieldToAdd = {
        index: index,
        label: 'Search Role',
        name: `${index}Role`,
        type: 'select',
        options: rolesToShow,
        required: true,
        rowNumber: tempClickCount,
        onChange: onChangeRoleName,
        error: error[index],
        helperText: error[index] ? 'Please select a different role' : '',
      }
      const wageFieldToAdd = {
        index: index,
        label: 'Wage',
        name: `${index}Wage`,
        type: 'number',
        required: true,
        rowNumber: tempClickCount,
        onChange: onChangeRoleWage,
      }
      const wageUnitFieldToAdd = {
        index: index,
        label: `Unit`,
        name: `${index}wageUnit`,
        type: 'select',
        options: [
          {
            label: '/hour',
            value: 'hour',
          },
          {
            label: '/day',
            value: 'day',
          },
          {
            label: '/week',
            value: 'week',
          },
          {
            label: '/month',
            value: 'month',
          },
        ],
        required: true,
        rowNumber: tempClickCount,
        onChange: onChangeRoleWageUnit,
      }
      const effectiveDateField = {
        index: index,
        label: 'Effective Date',
        name: `${index}EffectiveDate`,
        type: 'date',
        required: false,
        rowNumber: tempClickCount,
        onChange: onChangeEffectiveDate,
      }
      const deleteButton = {
        index: index,
        label: 'Delete',
        variant: 'outlined',
        type: 'button',
        onClick: onRemoveRoleButtonClick,
        rowNumber: tempClickCount,
      }
      if (index == 0) {
        tempUIArray.push(roleFieldToAdd)
        tempUIArray.push(wageFieldToAdd)
        tempUIArray.push(wageUnitFieldToAdd)
        tempUIArray.push(effectiveDateField)
      } else {
        tempUIArray.push(roleFieldToAdd)
        tempUIArray.push(wageFieldToAdd)
        tempUIArray.push(wageUnitFieldToAdd)
        tempUIArray.push(effectiveDateField)
        tempUIArray.push(deleteButton)
      }
    })
    setClickCount(tempClickCount)
    setUiElementsList(tempUIArray)
  }, [updateRolesFields])

  useEffect(() => {
    if (addClick != 0) {
      let tempI = i + 1
      let tempClickCount = clickCount + 1
      const roleFieldToAdd = {
        index: tempI,
        label: 'Search Role',
        name: `role${tempClickCount - 5}`,
        value: inputArr[tempI]['name'],
        type: 'select',
        options: rolesToShow,
        required: true,
        rowNumber: tempClickCount,
        onChange: onChangeRoleName,
      }
      const wageFieldToAdd = {
        index: tempI,
        label: 'Wage',
        name: `${tempI}Wage`,
        value: inputArr[tempI]['wage'],
        type: 'number',
        required: true,
        rowNumber: tempClickCount,
        onChange: onChangeRoleWage,
      }
      const effectiveDateFieldToAdd = {
        index: tempI,
        label: 'Effective Date',
        name: `${tempI}EffectiveDate`,
        value: inputArr[tempI]['effectiveDate'],
        type: 'date',
        required: false,
        rowNumber: tempClickCount,
        onChange: onChangeEffectiveDate,
      }
      const wageUnitFieldToAdd = {
        index: tempI,
        label: `Unit`,
        value: inputArr[tempI]['wageUnit'],
        name: `${tempI}wageUnit`,
        type: 'select',
        options: [
          {
            label: '/hour',
            value: 'hour',
          },
          {
            label: '/day',
            value: 'day',
          },
          {
            label: '/week',
            value: 'week',
          },
          {
            label: '/month',
            value: 'month',
          },
        ],
        required: true,
        rowNumber: tempClickCount,
        onChange: onChangeRoleWageUnit,
      }
      const deleteButton = {
        index: tempI,
        label: 'Delete',
        variant: 'outlined',
        type: 'button',
        onClick:() => onRemoveRoleButtonClick(tempI),
        rowNumber: tempClickCount,
      }
      setUiElementsList((prevElements) => [
        ...prevElements,
        roleFieldToAdd,
        wageFieldToAdd,
        wageUnitFieldToAdd,
        effectiveDateFieldToAdd,
        deleteButton,
      ])
      setI(tempI)
      setClickCount(tempClickCount)
    }
  }, [addClick])

  const onChangeRoleName = (value, index) => {
    let prevState = inputArr
    prevState[index]['roleId'] = value
    setInputArr(prevState)
  }

  const onChangeRoleWage = (name, value, checked, type, index) => {
    let prevState = inputArr
    prevState[index]['wage'] = value
    setInputArr(prevState)
  }

  const onChangeEffectiveDate = (value, index) => {
    let prevState = inputArr
    prevState[index]['effectiveDate'] = new Date(value)
    setInputArr(prevState)
  }

  const onChangeRoleWageUnit = (value, index) => {
    let prevState = inputArr
    prevState[index]['wageUnit'] = value
    setInputArr(prevState)
  }

  const onRemoveRoleButtonClick = (index) => {
    setInputArr(prevInputArr => {
        const updatedArr = [...prevInputArr];
        updatedArr[index].roleId = '';
        return updatedArr;
    });
    setUiElementsList(prevState => {
        return prevState.filter(item => !item.index || (item.index && item.index !== index));
    });
};

  const handleSubmitForm = async (formData) => {
    console.log(prevLocationSettings)
    let {firstName, lastName, phone, accessLevel, pin, startDate, payrollId, locationSettings} =
      formData
    let showInSchedule, eligibleForOpenShifts
    if (locationSettings?.includes('showInSchedule')) showInSchedule = true
    if (locationSettings?.includes('eligibleForOpenShifts')) eligibleForOpenShifts = true
    inputArr.map((eachDoc) => {
      if (!eachDoc.effectiveDate || eachDoc.effectiveDate == null)
        eachDoc.effectiveDate = new Date(Date.now())
    })
    axios
      .patch('caregivers/update', {
        id: params.id,
        firstName,
        lastName,
        phone,
        access: {
          accessLevel,
          pin,
          startDate,
          payrollId,
        },
        locationSettings: {
          showInSchedule,
          eligibleForOpenShifts,
        },
        roles: inputArr.filter((item) => item.roleId != ''),
      })
      .then((res) => {
        if (res.status == 201) {
          toast.success('Caregiver updated successfully')
        }
      })
      .catch((e) => toast.error("Couldn't update caregiver"))
  }

  const onAddAnotherRoleClick = () => {
    let prevInputArr = inputArr
    prevInputArr.push({roleId: '', wage: '', wageUnit: '', effectiveDate: new Date(Date.now())})
    setInputArr(prevInputArr)
    setAddClick(addClick + 1)
  }

  const buttons = [
    {
      label: 'Add Another Role',
      variant: 'outlined',
      type: 'button',
      onClick: onAddAnotherRoleClick,
    },
    {label: 'Cancel', variant: 'outlined', type: 'button'},
    {
      label: 'Save',
      variant: 'contained',
      type: 'submit',
      disabled: !hasPermission(userPermissions, permissions.care_giver_profile_jobDetails_update),
    },
  ]
  return (
    <FormWrapper
      title='Job Details'
      content={
        <DynamicForm
          fields={uiElementsList}
          data={formData}
          onSubmit={handleSubmitForm}
          buttons={buttons}
        />
      }
    />
  )
}
