import React from 'react'
import AdminBillingHeader from './AdminBillingHeader'
import AttCard from './attendance-type/card/AttCard'
import DesCard from './description-code/card/DesCard'
import FSCard from './funding-source/card/FSCard'
import PayerCard from './payers-setting/card/PayerCard'
import BPCard from './billing-provider/card/BPCard'
import LRCard from './leave-rule/card/LRCard'


function Billing() {
    return (
        <div>
            <AdminBillingHeader />
            {/* <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-xxl">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4 g-xl-2 g-lg-4 g-md-4 g-sm-4">
                        <div className="col-xl-12 col-lg-12 col-md-12 gy-xl-2 gy-lg-4">
                            <AttCard />
                        </div>
                        <div className="col-xl-6 col-md-6 gy-xl-2 gy-lg-4">
                            <DesCard />
                        </div>
                        <div className="col-xl-6 col-md-6 gy-xl-2 gy-lg-4">
                            <FSCard />
                        </div>
                        <div className="col-xl-6 col-md-6 gy-xl-2 gy-lg-4">
                            <PayerCard />
                        </div>
                        <div className="col-xl-6 col-md-6 gy-xl-2 gy-lg-4">
                            <BPCard />
                        </div>
                        <div className="col-xl-6 col-md-6 gy-xl-2 gy-lg-4">
                            <LRCard />
                        </div>


                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Billing